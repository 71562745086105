export const defaultTaskFiltersFilters = {
  task_types: {
    Sephora: false,
    Merchandising: false,
    'Pricing & Signing': false,
    Visual: false,
    Operations: false,
    'Loss Prevention': false,
    'Training & Recognition': false,
    'Marketing Promotions': false,
    Event: false,
    Holiday: false,
    'Risk & Compliance': false,
    'Special Projects': false,
    Surveys: false,
  },
};
