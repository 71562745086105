import React from 'react';
import { modalBackgroundStyles } from './modalBackgroundStyles';

export type ModalBackgroundProps = {
  isActive: boolean;
  setIsActive: Function;
  isInnerModal?: boolean;
};

const ModalBackground: React.FC<ModalBackgroundProps> = (props) => {
  const { isActive, setIsActive, isInnerModal } = props;
  const modalBackgroundClasses = modalBackgroundStyles({
    isActive,
    isInnerModal,
  });

  return (
    <>
      {isActive && (
        <div
          aria-label={'modal-background'}
          onClick={() => setIsActive()}
          style={modalBackgroundClasses.modalBackground}
        />
      )}
    </>
  );
};

export default ModalBackground;
