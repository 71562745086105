import { styled } from "@mui/material/styles";
import muiTheme from "../../../utils/mui/muiTheme";
import {Typography} from "@mui/material";
import {ColorModeTypeEnum} from "../../../stores/userPreferenceStore";

/** Typography **/
export const FilterGridTypography = styled(Typography)(({ theme}) => ({
    height: 'fit-content',
    marginTop: muiTheme.spacing(1),
    '& > *': {
        marginTop: muiTheme.spacing(1),
    },
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '10px',
    marginBottom: '1em',
    width: '94%'
})) as typeof Typography;

export const TypographyStyles = (colorMode: ColorModeTypeEnum) => {
    return {
        headerText: {
            fontSize: '1em',
            fontWeight: 'bold',
        },
    }

}