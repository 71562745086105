export const easyPrintCalendarLabels: Array<{
  dateIndex: number;
  dateLabelString: string;
}> = [
  {
    dateIndex: 0,
    dateLabelString: 'Sunday',
  },
  {
    dateIndex: 1,
    dateLabelString: 'Monday',
  },
  {
    dateIndex: 2,
    dateLabelString: 'Tuesday',
  },
  {
    dateIndex: 3,
    dateLabelString: 'Wednesday',
  },
  {
    dateIndex: 4,
    dateLabelString: 'Thursday',
  },
  {
    dateIndex: 5,
    dateLabelString: 'Friday',
  },
  {
    dateIndex: 6,
    dateLabelString: 'Saturday',
  },
];

export const monthLabels: Array<{
  monthIndex: number;
  monthLabelString: string;
}> = [
  {
    monthIndex: 0,
    monthLabelString: 'January',
  },
  {
    monthIndex: 1,
    monthLabelString: 'February',
  },
  {
    monthIndex: 2,
    monthLabelString: 'March',
  },
  {
    monthIndex: 3,
    monthLabelString: 'April',
  },
  {
    monthIndex: 4,
    monthLabelString: 'May',
  },
  {
    monthIndex: 5,
    monthLabelString: 'June',
  },
  {
    monthIndex: 6,
    monthLabelString: 'July',
  },
  {
    monthIndex: 7,
    monthLabelString: 'August',
  },
  {
    monthIndex: 8,
    monthLabelString: 'September',
  },
  {
    monthIndex: 9,
    monthLabelString: 'October',
  },
  {
    monthIndex: 10,
    monthLabelString: 'November',
  },
  {
    monthIndex: 11,
    monthLabelString: 'December',
  },
];
/* eslint-disable */
export enum ChangeMonthDirectionEnum {
  Prev = 'prev',
  Next = 'next',
}
