/* eslint-disable */
export enum IconEnum {
  CheckCircleOutline = 'CheckCircleOutline',
  AccessTime = 'AccessTime',
  ErrorOutline = 'ErrorOutline',
  Print = 'Print',
  TuneIcon = 'TuneIcon',
  PlusSign = 'PlusSign',
  DropDownArrow = 'DropDownArrow',
  Delete = 'Delete',
  Edit = 'Edit',
  ViewComments = 'ViewComments',
  Check = 'Check',
  Exit = 'Exit',
  ActivityHistory = 'ActivityHistory',
  ReturnArrow = 'ReturnArrow',
  OutlinedPlus = 'OutlinedPlus',
  Reset = 'Reset',
  Notification = 'Notification',
  NotificationWithRedCircle = 'NotificationWithRedCircle',
  Hide = 'Hide',
  Next = 'Next',
  Previous = 'Previous',
  MoreVert = 'MoreVert',
  Search = 'Search',
  Menu = 'Menu',
  Calendar = 'Calendar',
  List = 'List',
  SignOut = 'SignOut',
  ForwardArrow = 'ForwardArrow',
  BackArrow = 'BackArrow',
  Download = 'Download',
  UpArrow = 'UpArrow',
  DownArrow = 'DownArrow',
  UpDownArrow = 'UpDownArrow',
  Archive = 'Archive',
  Book = 'Book',
  WebSite = 'WebSite',
  Merchandising = 'Merchandising',
  Pdf = 'Pdf',
  Video = 'Video',
  Safety = 'Safety'
}

export enum ChipVariantEnum {
  Filled = 'Filled',
  Outlined = 'Outlined',
}
