import React from 'react';
import ModalBackground from './ModalBackground';

import ModalContent from './ModalContent';
import { baseModalStyles } from './baseModalStyles';
import DialogModalSwitch from './DialogModalSwitch';
import innerBaseModalStore, {
  innerBaseModalContentStoreState,
} from '../../stores/innerBaseModalStore';

const InnerBaseModal: React.FC = () => {
  const { innerBaseModalContentStoreProps, resetInnerBaseModalContent } =
    innerBaseModalStore(
      (state: {
        resetInnerBaseModalContent: Function;
        setInnerBaseModalContent: Function;
        innerBaseModalContentStoreProps: innerBaseModalContentStoreState;
      }) => ({
        innerBaseModalContentStoreProps: state.innerBaseModalContentStoreProps,
        setInnerBaseModalContent: state.setInnerBaseModalContent,
        resetInnerBaseModalContent: state.resetInnerBaseModalContent,
      })
    );

  const { isActive, width, height, dialogModalSwitchEnum } =
    innerBaseModalContentStoreProps;

  const baseModalClasses = baseModalStyles();

  return (
    <div style={baseModalClasses.baseModalContainer}>
      <ModalBackground
        isActive={isActive}
        setIsActive={resetInnerBaseModalContent}
        isInnerModal={true}
      />
      <ModalContent
        isActive={isActive}
        width={width}
        height={height}
        isInnerModal={true}
      >
        <DialogModalSwitch dialogModalEnum={dialogModalSwitchEnum} />
      </ModalContent>
    </div>
  );
};

export default InnerBaseModal;
