import React, { useState } from 'react';
import { IconContext } from 'react-icons';
import IconSelector from '../Chip/IconSelector';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import { iconButtonStyles } from './styles/iconButtonStyles';
import { AnalyticEventType, sendAnalyticsEvent } from '../../utils/analytics';

export type IconButtonProps = {
  label: string;
  icon: IconEnum;
  onClick: Function;
  color?: string | undefined;
  size?: string | undefined;
  margin?: string | undefined;
  analyticsEvent?: AnalyticEventType | undefined;
  backgroundColor?: string | undefined;
  padding?: string | undefined;
  borderRadius?: string | undefined;
};

export const IconButton: React.FC<IconButtonProps> = (props) => {
  const {
    label,
    icon,
    onClick,
    color,
    size,
    margin,
    analyticsEvent,
    backgroundColor,
    padding,
    borderRadius,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const iconButtonClasses = iconButtonStyles({
    color,
    size,
    margin,
    backgroundColor,
    padding,
    borderRadius,
    isActive,
  });

  return (
    <div
      style={iconButtonClasses.iconContainer}
      aria-label={label}
      onClick={(e) => {
        onClick(e);
        if (analyticsEvent !== undefined) {
          sendAnalyticsEvent(analyticsEvent);
        }
      }}
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
      }}
    >
      <IconContext.Provider value={iconButtonClasses.icon}>
        {IconSelector(icon)}
      </IconContext.Provider>
    </div>
  );
};

// export default IconButton;
