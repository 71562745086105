import { CompletionDashboardState } from '../stores/taskCompletionDashboardStore';
import { formatStoreNumber } from '../components/Drawers/FilterDrawer/FilterDrawer';

export type TaskCompletionDataStoreQueryStringParams = {
  completionDashboardState: CompletionDashboardState;
};

export const taskCompletionDataStoreQueryString = (
  params: TaskCompletionDataStoreQueryStringParams
) => {
  const { completionDashboardState } = params;
  const {
    groupBy,
    minQueryDate,
    maxQueryDate,
    storeNumber,
    districtNumber,
    territoryNumber,
    task_type,
    completion_status,
  } = completionDashboardState;

  const minDate = `&min_selected_date=${minQueryDate}`;
  const maxDate = `&max_selected_date=${maxQueryDate}`;
  const groupByString = `group_by=${groupBy}`;
  let number = '';
  const taskTypeString = task_type !== undefined ? task_type : '';
  const completionStatusString =
    completion_status !== undefined ? completion_status : '';

  if (storeNumber !== undefined) {
    number = `&store_number=${formatStoreNumber(storeNumber.toString())}`;
  } else if (districtNumber !== undefined) {
    number = `&district_number=${districtNumber}`;
  } else if (territoryNumber !== undefined) {
    number = `&territory_number=${territoryNumber}`;
  }

  const queryString = `?${groupByString}${minDate}${maxDate}${number}${taskTypeString}${completionStatusString}`;
  return queryString;
};
