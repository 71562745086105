import React, { useState } from 'react';
import { baseButtonStyles } from './styles/baseButtonStyles';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

export type BaseButtonProps = {
  children: React.ReactNode;
  onClick: Function;
  activeBackgroundColor: string;
  passiveBackgroundColor: string;
  activeTextColor: string;
  passiveTextColor: string;
  fontSize?: string;
  margin?: string;
  padding?: string;
  label?: string;
  borderRadius?: string | undefined;
  borderStyle?: string | undefined;
  borderWidth?: string | undefined;
  borderColor?: string | undefined;
  isSelected?: boolean | undefined;
  changeColorWhenSelected?: boolean | undefined;
  isDisabled?: boolean | undefined;
  colorMode?: ColorModeTypeEnum;
};

const BaseButton: React.FC<BaseButtonProps> = (props) => {
  const {
    children,
    onClick,
    activeBackgroundColor,
    passiveBackgroundColor,
    activeTextColor,
    passiveTextColor,
    fontSize,
    margin,
    padding,
    label,
    borderRadius,
    borderWidth,
    borderStyle,
    borderColor,
    isSelected,
    changeColorWhenSelected,
    isDisabled,
    colorMode,
  } = props;
  const [isActive, setIsActive] = useState(false);

  const baseButtonClasses = baseButtonStyles({
    isActive,
    activeBackgroundColor,
    passiveBackgroundColor,
    activeTextColor,
    passiveTextColor,
    fontSize,
    margin,
    padding,
    borderRadius,
    borderWidth,
    borderStyle,
    borderColor,
    isSelected,
    changeColorWhenSelected,
    colorMode,
  });

  return isDisabled ? (
    <button
      key={label}
      style={baseButtonClasses.disabledButton}
      aria-label={label !== undefined ? label : 'No label provided'}
      disabled={isDisabled}
    >
      {children}
    </button>
  ) : (
    <button
      key={label}
      onClick={() => onClick()}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      style={baseButtonClasses.baseButton}
      aria-label={label !== undefined ? label : 'No label provided'}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export default BaseButton;
