import { env } from '../envConfig';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../utils/analytics';

export const groups = {
  SUPER_GABE: 'siteline-superuser',
  GABE: 'siteline-taskcreator',
  JAN: 'siteline-fieldleader',
  MICHAEL: 'siteline-storeleader',
  STANLEY: 'siteline-storeassociate',
};

export const naviagtionMenuItems = {
  calendar: {
    name: 'Calendar View',
    path: '/',
  },
  easyPrint: {
    name: 'Easy Print Calendar',
    path: '/easy-print',
  },
  mobileMetrics: {
    name: 'Mobile Metrics',
    path: '/mobile-metrics',
  },
  completionDataDashboard: {
    name: 'Completion Reporting',
    path: '/completion-data-dashboard',
  },
  taskListView: {
    name: 'List View',
    path: '/task-list-view',
  },
  reviewAndApproval: {
    name: 'Review & Approvals',
    path: '/task-approvals',
  },
  digitalMerchandising: {
    name: 'Merchandising & Visual Digital Communication',
    path: '/digital-merchandising',
  },
  safetyRiskCompliance: {
    name: 'Safety Video & Communication',
    path: '/safety-risk-compliance',
  }
};

export const externalNavigationMenuItems = {
  keyContacts: {
    name: 'Key Contacts',
    path: env.REACT_APP_KEY_CONTACTS_URL,
    googleAnalyticsEvent: () =>
      sendAnalyticsEvent(analyticsEventConstants.KEY_CONTACTS),
  },
};
