export const editNotesSubmissionModalStyles = () => {
  /* istanbul ignore next */
  return {
    textArea: {
      fontFamily: 'Roboto-Light',
      minHeight: '4em',
      lineHeight: 'initial',
      marginTop: '.5em',
    },
  };
};
