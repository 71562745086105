import { Divider, Grid } from '@mui/material';
import { useField } from 'formik';
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { SingleFileUploadWithProgress } from './SingleFileUploadWithProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { IFileInformation } from '../types/IFileInformation';
import { validateCSV } from '../../utils/csvValidator';
import { AppContext } from '../../context/AppContext';
import muiTheme from "../../utils/mui/muiTheme";

let currentId = 0;

function getNewId() {
  return ++currentId;
}

export type UploadableFileProps = {
  id: number;
  file: File;
  errors: FileError[];
  fileName?: string;
  url?: string;
};

const useStyles = () => {
  return {
    paperWrapper: {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      padding: '10px',
      margin: '20px',
      background: 'rgba(0, 0, 0, 0.08)',
    },
    dropzone: {
      border: '1px dashed rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fafafa',
      height: muiTheme.spacing(10),
      outline: 'none',
      flexDirection: 'column' as 'column',
      color: 'rgba(0, 0, 0, 0.54)',
      cursor: 'pointer'
    },
    divider: {
      marginTop: '1em',
    },
  }
}


type fieldTextType = { fieldTitle: string; fieldSubTitle?: string };
type filesType = UploadableFileProps[] | IFileInformation[];

export type FileUploadProps = {
  name: string;
  fileTypes: string[];
  fieldText: fieldTextType;
  attachmentsId: string | undefined;
  maxNumOfUploadableFiles?: number;
  setDistroListFile?: Dispatch<
    SetStateAction<File | IFileInformation | undefined>
  >;
  cumulativeFileSize?: number;
  setFileUploading?: Dispatch<SetStateAction<boolean>>;
  previouslyUploadedFiles?: filesType;
  isShowDropZone?: boolean;
  callbackFunc?: Function;
};

export const FileUploadField: React.FC<FileUploadProps> = (props) => {
  const {
    name,
    fileTypes,
    fieldText,
    attachmentsId,
    maxNumOfUploadableFiles,
    setFileUploading,
    setDistroListFile,
    cumulativeFileSize,
    previouslyUploadedFiles,
    isShowDropZone,
    callbackFunc,
  } = props;
  const [, , helpers] = useField(name);
  const classes = useStyles();
  const { fieldTitle, fieldSubTitle } = fieldText;
  const [files, setFiles] = useState<filesType>([]);
  const [fileInfo, setFileInfo] = useState<object[]>([]);
  const sizeParameters = useRef({ size: 0, fileCount: 0 });

  const { appState } = useContext<any>(AppContext);
  const { storeLocationInformation } = appState;

  const onDrop = useCallback(
    async (accFiles: File[], rejFiles: FileRejection[]) => {
      if (setFileUploading) {
        setFileUploading(true);
      }

      const mappedRej = rejFiles.map((r) => ({ ...r, id: getNewId() }));
      const mappedAcc: any[] = [];

      if(mappedRej.length > 0) {
        setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
      }

      await new Promise<void>((resolve) => {
        accFiles.forEach(async (currFile, index) => {
          let isPassesCSVValidationOrNotCSV = true;

          if (setDistroListFile) {
            isPassesCSVValidationOrNotCSV = await validateCSV(
              currFile,
              storeLocationInformation
            );
            if (isPassesCSVValidationOrNotCSV) {
              setDistroListFile(currFile);
            }
          }

          if (
            cumulativeFileSize &&
            sizeParameters.current.size + currFile.size < cumulativeFileSize &&
            maxNumOfUploadableFiles &&
            sizeParameters.current.fileCount + 1 <= maxNumOfUploadableFiles &&
            isPassesCSVValidationOrNotCSV
          ) {
            mappedAcc.push({
              file: currFile,
              errors: [],
              id: getNewId(),
            });

            setFileInfo((currentFiles) => [
              ...currentFiles,
              {
                name: currFile.name,
                size: currFile.size,
                type: currFile.type,
              },
            ]);
            sizeParameters.current.size =
              sizeParameters.current.size + currFile.size;
            sizeParameters.current.fileCount++;
          } else {
            let error = { message: '', code: '' };
            if (!isPassesCSVValidationOrNotCSV) {
              error = {
                message:
                  'There was an error while validating your CSV file, information to fix was provided in the alert window',
                code: 'csv-validation-failed',
              };
            } else {
              error =
                cumulativeFileSize &&
                sizeParameters.current.size + currFile.size > cumulativeFileSize
                  ? {
                      message: `Rejected due to cumulative file size total being greater than ${
                        cumulativeFileSize / (130 * 1000 * 1024)
                      } MB. Please delete a previously uploaded file to make room and upload this file again`,
                      code: 'files-exceed-size-limit',
                    }
                  : {
                      message: `Rejected due to too many files being uploaded; maximum allowed: ${maxNumOfUploadableFiles}. Please delete a previously uploaded file to make room and upload this file again`,
                      code: 'total-num-files-exceeds-limit',
                    };
            }

            const fileRejection = {
              file: currFile,
              errors: [error],
              id: getNewId(),
            };
            mappedRej.push(fileRejection);
          }
          if (index === accFiles.length - 1) resolve();
        });
      });
      setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
    },
    [cumulativeFileSize, maxNumOfUploadableFiles, setDistroListFile]
  );

  useEffect(() => {
    helpers.setValue(fileInfo);
    if (fileInfo !== undefined) {
      if (setFileUploading) {
        setFileUploading(false);
      }
    }
  }, [files]);

  useEffect(() => {
    if (previouslyUploadedFiles) {
      setFiles(previouslyUploadedFiles);
      setFileInfo(previouslyUploadedFiles);
    }
  }, []);

  const onUpload = useCallback(
    (file: File | IFileInformation, url: string) => {
      // todo: write better tests so we can remove this if statement
      if (setDistroListFile) {
        setDistroListFile(file);
      }
      setFiles((currentFile: any) =>
        currentFile.map((filewrapper: any) => {
          if (filewrapper.file === file) {
            return { ...filewrapper, url };
          }
          return filewrapper;
        })
      );
    },
    [setDistroListFile]
  );

  const onDelete = (file: File | IFileInformation) => {
    if (setDistroListFile) {
      setDistroListFile(undefined);
    }
    files?.forEach((uploadedFile: any) => {
      const fileObj = uploadedFile as Object;
      // eslint-disable-next-line
      if (fileObj['file'] === file && fileObj['errors'].length === 0) {
        sizeParameters.current.size = sizeParameters.current.size - file.size;
        sizeParameters.current.fileCount--;
      }
    });

    setFiles((curr: any) =>
      curr.filter((fw: any) => fw?.file !== file && fw?.name !== file.name)
    );
    setFileInfo((curr: any) =>
      curr.filter((fw: any) => fw?.file !== file && fw?.name !== file.name)
    );
    if (callbackFunc) {
      callbackFunc();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: fileTypes,
    maxSize: 130 * 1000 * 1024, // max-limit: 50 MB
    maxFiles: maxNumOfUploadableFiles,
  });

  return (
    <>
      {(isShowDropZone === undefined || isShowDropZone) && (
        <Grid item>
          <div
            data-testid="file-upload"
            aria-label={`File upload for ${name}`}
            {...getRootProps({ style: classes.dropzone })}
          >
            <input {...getInputProps()} />
            <div>
              <CloudUploadIcon color="action" />
            </div>
            <div>{fieldTitle}</div>
            {fieldSubTitle && <div>{fieldSubTitle}</div>}
          </div>
        </Grid>
      )}

      {files && files.length > 0 && (
        <Grid item>
          <Divider sx={classes.divider} />
          <p>Uploaded Files</p>
        </Grid>
      )}

      {files?.map((fileWrapper: any) => {
        return (
          <Grid item key={fileWrapper.id}>
            <SingleFileUploadWithProgress
              onDelete={onDelete}
              onUpload={onUpload}
              file={
                fileWrapper.previouslyUploadedFile
                  ? fileWrapper
                  : fileWrapper.file
              }
              errors={fileWrapper.errors}
              attachmentsId={attachmentsId}
              previouslyUploadedFile={fileWrapper.previouslyUploadedFile}
            />
          </Grid>
        );
      })}
    </>
  );
};
