import styled from 'styled-components';

export const FormikSelectElementStylesWrapper = styled.div`
  &.FormikField,
  &.FormikSelect {
    margin: 10px 0;
  }
`;
export const FormikElementStylesWrapper = styled.div`
    &.FormikField {
      margin: 0 0 10px 0;
    }, 
`;
