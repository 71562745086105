import React from 'react';
import { breadcrumbBoxContentStyles } from './breadcrumbBoxContentStyles';

export type BreadcrumbContentData = {
  path: string;
  title: string;
};

export type BreadcrumbBoxContentProps = {
  contentData: BreadcrumbContentData;
  onClick: Function | undefined;
};

const BreadcrumbBoxContent: React.FC<BreadcrumbBoxContentProps> = (props) => {
  const { contentData, onClick } = props;
  const breadcrumbBoxContentClasses = breadcrumbBoxContentStyles({
    data: contentData,
  });

  const handleOnClick = (navigationPathString: string) => {
    if (onClick !== undefined) {
      return onClick(navigationPathString);
    } else {
      return null;
    }
  };

  return (
    <div
      className={'breadcrumbBoxContentContainer'}
      onClick={() => handleOnClick(contentData.path)}
      style={breadcrumbBoxContentClasses.breadcrumbBoxContentContainer}
    >
      {decodeURIComponent(contentData.title)}
    </div>
  );
};

export default BreadcrumbBoxContent;
