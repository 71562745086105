export const singleDropdownSelectorStyles = () => {
  /* istanbul ignore next */
  return {
    singleDropboxContainer: {
      marginLeft: '0.5em',
      width: '100%',
    },
    singleDropboxTextContainer: {
      display: 'flex',
      margin: '0.3em 0',
    },
  };
};
