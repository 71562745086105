import { create, SetState } from 'zustand';
import { endOfWeek, startOfWeek, subDays } from 'date-fns';

/* eslint-disable */
export enum GroupByEnum {
  TaskType = 'TaskType',
  Territory = 'Territory',
  Region = 'Region',
  District = 'District',
  Store = 'Store',
}

/* eslint-disable */
export enum GroupByEnumStackedHistogram {
  TaskType = 'task_type',
  Territory = 'territory_number',
  Region = 'region_number',
  District = 'district_number',
  Store = 'store_number',
}

/* eslint-disable */
export enum AreaScopeEnum {
  Territory = 'territoryNumber',
  Region = 'regionNumber',
  District = 'districtNumber',
  Store = 'storeNumber',
}

export type CompletionDashboardState = {
  groupBy: GroupByEnum;
  minQueryDate: string;
  maxQueryDate: string;
  storeNumber?: string;
  districtNumber?: string;
  regionNumber?: string;
  territoryNumber?: string;
  areaScope?: AreaScopeEnum;
  task_type?: string;
  completion_status?: string;
};

export const getDateRangeThreeWeeksAgoFromThisWeekSaturday = () => {
  return {
    start_date: startOfWeek(new Date(subDays(Date.now(), 21))),
    end_date: subDays(endOfWeek(new Date(Date.now())), 1),
  };
};

// todo - update this to be a function that takes in userInfo to preset values
export const defaultCompletionDashboardState: CompletionDashboardState = {
  groupBy: GroupByEnum.TaskType,
  minQueryDate: getDateRangeThreeWeeksAgoFromThisWeekSaturday()
    .start_date.toISOString()
    .split('T')[0],
  maxQueryDate: getDateRangeThreeWeeksAgoFromThisWeekSaturday()
    .end_date.toISOString()
    .split('T')[0],
};

const taskCompletionDashboardStore = create((set: SetState<any>) => ({
  completionDashboardState: { ...defaultCompletionDashboardState },
  setCompletionDashboardState: (
    completionDashboardState: CompletionDashboardState
  ) => {
    set(() => ({ completionDashboardState }));
  },
  resetCompletionDashboardState: () => {
    set(() => ({
      completionDashboardState: { ...defaultCompletionDashboardState },
    }));
  },
}));

export default taskCompletionDashboardStore;
