import React from 'react';
import BaseButton from '../Buttons/BaseButton';
import { theme } from '../../assets/siteLineTheme';
import { confirmModalStyles } from './confirmModalStyles';
import confirmModalStore, {
  ConfirmModalStoreState,
} from '../../stores/confirmModalStore';
import baseModalStore from '../../stores/baseModalStore';
import innerBaseModalStore, {
  innerBaseModalContentStoreState,
} from '../../stores/innerBaseModalStore';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

const ConfirmModal: React.FC = () => {
  const confirmModalClasses = confirmModalStyles();

  const { confirmModalStoreProps } = confirmModalStore(
    (state: { confirmModalStoreProps: ConfirmModalStoreState }) => ({
      confirmModalStoreProps: state.confirmModalStoreProps,
    })
  );
  const { resetBaseModalContent } = baseModalStore(
    (state: { resetBaseModalContent: Function }) => ({
      resetBaseModalContent: state.resetBaseModalContent,
    })
  );

  const { resetInnerBaseModalContent, isActive } = innerBaseModalStore(
    (state: {
      resetInnerBaseModalContent: Function;
      innerBaseModalContentStoreProps: innerBaseModalContentStoreState;
    }) => ({
      resetInnerBaseModalContent: state.resetInnerBaseModalContent,
      isActive: state.innerBaseModalContentStoreProps.isActive,
    })
  );

  const { message, confirmButtonText, confirmOnClickAction } =
    confirmModalStoreProps;

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  return (
    <>
      <div style={confirmModalClasses.confirmModalContainer}>
        <div style={confirmModalClasses.confirmModalMessageContainer}>
          {message}
        </div>
        <div style={confirmModalClasses.confirmModalButtonContainer}>
          <BaseButton
            onClick={() => {
              /* istanbul ignore next */
              if (isActive) {
                resetInnerBaseModalContent();
              } else {
                resetBaseModalContent();
              }
            }}
            activeBackgroundColor={
              theme.newColorPalette.buttons.commentLightCancelDarkGrey[
                userPreference.colorMode
              ]
            }
            passiveBackgroundColor={
              theme.newColorPalette.buttons.commentDarkCancelBlack[
                userPreference.colorMode
              ]
            }
            activeTextColor={theme.colorPalette.plainWhite}
            passiveTextColor={theme.colorPalette.plainWhite}
            fontSize={'0.9em'}
            label={'Cancel'}
          >
            Cancel
          </BaseButton>
          <BaseButton
            onClick={() => confirmOnClickAction()}
            activeBackgroundColor={
              theme.newColorPalette.buttons.commentLightSubmitDarkGrey[
                userPreference.colorMode
              ]
            }
            passiveBackgroundColor={
              theme.newColorPalette.buttons.commentDarkSubmitBlack[
                userPreference.colorMode
              ]
            }
            activeTextColor={theme.colorPalette.plainWhite}
            passiveTextColor={theme.colorPalette.plainWhite}
            fontSize={'0.9em'}
            margin={'0 0 0 2em'}
            label={`${confirmButtonText}`}
          >
            {confirmButtonText}
          </BaseButton>
        </div>
      </div>
    </>
  );
};

export default ConfirmModal;
