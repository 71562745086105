import { theme } from '../../../assets/siteLineTheme';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

/* istanbul ignore */
export const genericButtonStyles = (
  colorMode: ColorModeTypeEnum,
  buttonBackgroundColor?: string,
  buttonTextColor?: string
) => {
    return {
        generalButton: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            fontSize: '.875rem',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: '4px',
            letterSpacing: '0.02857em',
            textTransform: 'uppercase',
            padding: '6px 8px',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            minWidth: '64px',
            cursor: 'pointer',
        },
        filledButton: {
            /* istanbul ignore next */
            color:
                colorMode !== ColorModeTypeEnum.BlackWhite
                    ? buttonTextColor
                    : theme.colorPalette.plainBlack,
            backgroundColor:
                colorMode !== ColorModeTypeEnum.BlackWhite
                    ? buttonBackgroundColor
                    : theme.colorPalette.plainWhite,
            '&:hover': {
                backgroundColor:
                    theme.newColorPalette.zoidbergLightLighterGrey[colorMode],
            },
        },
        iconContainer: {
            display: 'inherit',
            marginLeft: -4,
            marginRight: '8px',
            alignItems: 'center',
        },
    }
}
