import { theme } from 'src/assets/siteLineTheme';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

export const calendarToolbarStylesV2 = (colorMode: ColorModeTypeEnum) => {
  return {
    toolbarContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
    },
    toolbarTopRow: {
      display: 'flex',
      flexDirection:
        window.innerWidth > 420 ? ('row' as 'row') : ('column' as 'column'),
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: window.innerWidth > 420 ? '0.5em' : undefined,
    },
    toolbarDateLabel: {
      color: theme.newColorPalette.plainWhiteBlack[colorMode],
      margin: window.innerWidth > 420 ? '0 0.2em' : 0,
      fontSize: window.innerWidth > 420 ? '1.7em' : '1.3em',
      fontWeight: 'bold',
      marginLeft: '.5em',
    },
    toolbarLeftSection: {
      display: 'flex',
      alignItems: 'center',
      width: window.innerWidth > 420 ? `${window.innerWidth * 0.3}px` : '100%',
      justifyContent: window.innerWidth > 420 ? 'flex-start' : 'center',
    },
    toolbarRightSection: {
      display: 'flex',
      width: window.innerWidth > 420 ? `${window.innerWidth * 0.3}px` : '100%',
      justifyContent: window.innerWidth > 420 ? 'flex-end' : 'center',
      marginTop: window.innerWidth > 420 ? 0 : '0.5em',
    },
    weekDaySelectionContainer: {
      display: 'flex',
      width: window.innerWidth > 420 ? `${window.innerWidth * 0.3}px` : '100%',
    },
    rowContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap' as 'wrap',
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      marginBottom: '.25em',
    },
  };
};
