import { Box } from '@mui/material';
import { theme } from '../../assets/siteLineTheme';
import React from 'react';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';
import {BoxStyles} from "./styles/boxStyles";

export type StyledBackgroundBoxProps = {
  taskType: string;
  colorMode: ColorModeTypeEnum;
};

const StyledBackgroundBox: React.FC<StyledBackgroundBoxProps> = (props) => {
  const { taskType, colorMode } = props;
  return (
    <Box
      sx={BoxStyles(colorMode).drawerStyledBackgroundBox}
      style={{
        backgroundColor:
          colorMode !== ColorModeTypeEnum.BlackWhite
            ? theme.colorPalette.taskTypes.dark[taskType]
            : theme.colorPalette.plainWhite,
      }}
    />
  );
};

export default StyledBackgroundBox;
