import { theme } from '../../../assets/siteLineTheme';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

type drawStylesProps = {
  colorMode: ColorModeTypeEnum;
};

export const drawerStyles = (props: drawStylesProps) => {
  const { colorMode } = props;

  /* istanbul ignore next */
  return {
    paperAnchorRight: {
      width: window.innerWidth > 420 ? '32%' : '100%',
      minWidth: '320px',
    },
    backgroundBox: {
      zIndex: -10,
      height: '33vh',
      backgroundColor: theme.newColorPalette.backgroundDarkWhite[colorMode],
      width: '100%',
      position: 'absolute',
    },
    filterSectionContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '.5em',
      padding: '1em',
    },
    filterDrawerContentContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      maxHeight: '81vh',
      overflowY: 'auto',
    },
    sectionHeaderText: {
      color: theme.colorPalette.drawerHeaderText,
    },
    drawerHeaderSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '1.5em',
      padding: '.25em 2em .25em 2em',
      marginRight: '48px',
      width: '100%',
    },
    filterDrawerHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px 0px',
      width: '100%',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    drawerHeaderText: {
      fontSize: '2em',
      fontWeight: 'bold',
      alignItems: 'center',
      margin: '0.5em auto',
      width: 'fit-content',
    },
    drawerHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    drawerSubHeaderText: {
      fontSize: '1.5em',
      width: 'fit-content',
      marginLeft: '0.7em',
      fontWeight: 'bold',
    },
    drawerAllCaughtUpText: {
      display: 'flex',
      margin: '0.5em 1.3em',
      fontSize: '1.2em',
    },
    archiveContainer: {
      marginTop: '1em',
    },
  };
};
