import { menuListProps } from '../TaskManagementNavigation/TaskManagementNavigation';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React from 'react';
import { linkComponentStyles } from './AppHeaderBarStyles/linkComponentStyles';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

type ExternalLinkComponentProps = {
  menuItem: menuListProps;
  colorMode: ColorModeTypeEnum;
  handleClick: Function;
  clearSearch: Function;
  googleAnalyticsEvent?: () => void;
};

const ExternalLinkComponent: React.FC<ExternalLinkComponentProps> = (props) => {
  const {
    menuItem,
    colorMode,
    handleClick,
    clearSearch,
    googleAnalyticsEvent,
  } = props;
  const isDesktop: boolean = window.innerWidth > 421;
  const { taskManagementArrowIcon, taskManagementListItem, menuItemTitle } =
    linkComponentStyles(colorMode);

  return (
    <div>
      <a
        href={menuItem.path}
        style={{ textDecoration: 'none', color: 'black' }}
        target={isDesktop ? '_blank' : ''}
        rel={'noreferrer'}
      >
        <li
          style={taskManagementListItem}
          onClick={() => {
            handleClick();
            clearSearch();
            googleAnalyticsEvent?.();
          }}
          value={menuItem.name}
          aria-label={menuItem.name}
        >
          <h3
            style={menuItemTitle}
            aria-label={`Navigation Link ${menuItem.name}`}
          >
            {menuItem.name}
          </h3>
          <ArrowForwardIosIcon style={taskManagementArrowIcon} />
        </li>
      </a>
    </div>
  );
};

export default ExternalLinkComponent;
