import { FormControlLabel } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { Field } from 'formik';
import { FormikElementStylesWrapper } from './FormikStyles';
import React from 'react';
import { theme } from '../../../assets/siteLineTheme';
import userPreferenceStore, {
  ColorModeTypeEnum,
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import muiTheme from 'src/utils/mui/muiTheme';

type Props = SwitchProps & {
  label: String;
  disabled?: boolean;
  initialValue?: boolean;
};
/* istanbul ignore */
const styles = (colorMode: ColorModeTypeEnum) => {
    return {
        root: {
            width: 40,
            height: 19,
            padding: 0,
            overflow: 'visible',
            margin: muiTheme.spacing(1),
        },
        labelRoot: {
            margin: 0,
        },
        switchBase: {
            padding: 4,
            '&$checked': {
                transform: 'translateX(20px)',
                color: theme.colorPalette.plainWhite,
                '& + $track': {
                    backgroundColor:
                    /* istanbul ignore */
                        colorMode !== ColorModeTypeEnum.BlackWhite
                            ? 'rgba(0, 130, 148, 0.54)'
                            : theme.colorPalette.plainBlack,
                    opacity: 1,
                    border:
                        colorMode !== ColorModeTypeEnum.BlackWhite
                            ? '1px solid rgba(0, 130, 148, 0.54)'
                            : theme.colorPalette.plainBlack,
                },
            },
            '&$focusVisible $thumb': {
                color:
                    colorMode !== ColorModeTypeEnum.BlackWhite
                        ? 'rgba(0, 130, 148, 0.54)'
                        : 'orange',
                border: '6px solid rgba(0, 130, 148, 0.54)',
            },
        },
        thumb: {
            width: 12,
            height: 12,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${muiTheme.palette.grey[400]}`,
            backgroundColor: 'rgba(0, 0, 0, 0.54)',
            opacity: 1,
            transition: muiTheme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }
}

export const MuiSwitch: React.FC<Props> = (props) => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const { label, value, ...otherProps } = props;
  const classes = styles(userPreference.colorMode);
  return (
    <FormControlLabel
      sx={{
        root: classes.labelRoot,
      }}
      control={ // todo - style not working.
        <Switch
          disableRipple
          sx={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          checked={!!value}
          {...otherProps}
        />
      }
      label={label}
      labelPlacement="start"
    />
  );
};

type FormikFieldProps = {
  name: string;
  label?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
};

const FormikSwitch: React.FC<FormikFieldProps> = ({
  name,
  label,
  disabled = false,
}) => {
  return (
    <FormikElementStylesWrapper className="FormikSwitch">
      <Field as={MuiSwitch} label={label} name={name} disabled={disabled} />
    </FormikElementStylesWrapper>
  );
};

export default FormikSwitch;
