export type BoxArrayStylesParams = {
  boxHeight: string | undefined;
  boxWidth: string;
  margin?: string | undefined;
  justifyContent?: string | undefined;
  alignItems?: string | undefined;
  gap?: string | undefined;
};

export const boxArrayStyles = (params: BoxArrayStylesParams) => {
  const { boxHeight, boxWidth, margin, justifyContent, alignItems, gap } =
    params;
  return {
    boxArrayContainer: {
      display: 'flex',
      flexWrap: 'wrap' as 'wrap',
      justifyContent:
        justifyContent !== undefined ? justifyContent : 'space-evenly',
      alignItems: alignItems !== undefined ? alignItems : 'center',
      width: '100%',
      margin,
      gap: gap !== undefined ? gap : '0',
    },
    boxContainer: {
      width: boxWidth,
      maxWidth: boxWidth,
      height: boxHeight,
    },
  };
};
