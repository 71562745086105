import {ColorModeTypeEnum} from "../../../stores/userPreferenceStore";
import {theme} from "../../../assets/siteLineTheme";
import muiTheme from "../../../utils/mui/muiTheme";

/** SearchComponent **/
export const SearchComponentStyles = (colorMode: ColorModeTypeEnum) => {
  return {
    searchIcon: {
      color: theme.newColorPalette.textDarkBlack[colorMode],
    },
    optionContainer: {
      display: 'flex',
      width: '88%',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '1em',
      borderBottom: `1px solid ${theme.colorPalette.taskTypes.dark.Sephora}`,
    },
    autoCompleteBasePaper: {
      minWidth: '100%',
    },
    halfBox: {
      width: '50%',
      display: 'flex',
    },
    optionTitleBox: {
      width: '100%',
      display: 'block',
    },
    optionSubBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
      paddingBottom: '3px',
    },
    capitalize: {
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
    optionTitle: {
      fontSize: '1em',
      fontWeight: 'bold',
    },
    optionSubLabel: {
      paddingLeft: '.25em',
    },
    optionSubText: {
      paddingLeft: '.25em',
    },
    autoCompleteComponent: {
      display: 'flex',
      minWidth: '25%',
      [muiTheme.breakpoints.between('xs', 'sm')]: {
        width: '67%',
      },
    },
    autoCompleteTextField: {
      backgroundColor: theme.colorPalette.zoidberg,
    },
  }
}