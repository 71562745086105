import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AppContext } from '../../../context/AppContext';
import { Grid, Paper, Typography } from '@mui/material';
import { theme } from '../../../assets/siteLineTheme';
import { TaskStoreCompletionStatus } from '../../../models/TaskStoreCompletionStatus';
import { format, addDays } from 'date-fns';
import appliedFiltersStore from '../../../stores/appliedFiltersStore';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import Chip from '../../Chip/Chip';
import { ChipVariantEnum, IconEnum } from '../../Chip/chipUtils/ChipEnums';
import {
  chipBackgroundColorPicker,
  chipTextColorPicker,
} from '../../Chip/chipUtils/chipColorPickers';
import { chipCompletionStatusIconPicker } from '../../../utils/completionUtils';
import {
  countNumberOfStoresForAllStoreTaskByTaskID,
  createOrUpdateScheduledDates,
  deleteTaskStoreScheduledDates,
  getTaskStoreNumberCompletion,
  getTaskStoreScheduledDates,
  ScheduledDatesObj,
} from '../../../api/apiV1Task';
import { ViewTaskStyles } from './ViewTaskStyles';
import IconButtonWithLabel from '../../Buttons/IconButtonWithLabel';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../utils/analytics';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './dateRangePickerStyle.css';
import { convertDateToUTC } from '../../../utils/dateUtils';
import { Attachments } from '../../ViewAttachments/Attachments';
import { TextEditor } from '../../TextEditor/TextEditor';
import StoreNumberAccordion from '../../Forms/StoreNumberAccordion';
import { groups } from '../../../context/constants';
import confirmModalStore from '../../../stores/confirmModalStore';
import baseModalStore from '../../../stores/baseModalStore';
import { DialogModalSwitchEnum } from '../../Modal/DialogModalSwitch';
import TaskHistorySection from '../TaskDrawerComponents/TaskHistorySection/TaskHistorySection';
import SuperUserToggleFieldsSection from '../TaskDrawerComponents/SuperUserToggleFieldsSection/SuperUserToggleFieldsSection';
import Papa from 'papaparse';
import { downloadCsv } from 'src/utils/csvValidator';
import useFetchUserGroup from '../../../hooks/fetchUserGroup';
import { useHistory } from 'react-router-dom';
import { exportTaskViewedReadReceiptsCsv } from '../../../utils/exportCsvUtils';
import {PaperStyles} from "../../Forms/styles/paperStyles";

export type ViewTaskProps = {
  viewEvent: any;
  taskId: string;
  resetToDefault: Function;
};

const ViewTask: React.FC<ViewTaskProps> = (props) => {
  const { appState } = useContext(AppContext);
  const { userInfo, storeLocationInformation } = appState;
  const userOktaGroup = useFetchUserGroup(userInfo);
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const { appliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
    })
  );
  const { viewEvent, taskId } = props;
  const editorContents = useRef<{}>();
  const { distro_file_information } = viewEvent;

  const foundIncomplete =
    viewEvent.completion_statuses &&
    viewEvent.completion_statuses.some(
      (status: any) =>
        status.completion_status === TaskStoreCompletionStatus.Incomplete
    );

  const overallCompletionStatus: TaskStoreCompletionStatus =
    viewEvent.completion_status
      ? viewEvent.completion_status
      : foundIncomplete
      ? TaskStoreCompletionStatus.Incomplete
      : TaskStoreCompletionStatus.Completed;

  const [displayDate, setDisplayDate] = useState<displayDateObj>({
    displayStartDate: undefined,
    displayEndDate: undefined,
  });

  const viewClasses = ViewTaskStyles({
    colorMode: userPreference.colorMode,
    task_type: viewEvent && viewEvent.task_type,
    completion_status:
      (viewEvent.completion_status || viewEvent.completion_statuses) &&
      overallCompletionStatus,
    single_day_task:
      viewEvent !== null
        ? viewEvent.start_date === viewEvent.end_date ||
          displayDate.displayStartDate === displayDate.displayEndDate
        : false,
  });

  const [numberOfStoresMatchingTaskID, setNumberOfStoresMatchingTaskID] =
    useState(0);

  const [showDateRangePicker, setShowDateRangerPicker] = useState(true);

  const fetchNumberOfStoresMatchingTaskID = useCallback(async () => {
    if (taskId) {
      const response = await countNumberOfStoresForAllStoreTaskByTaskID(taskId);
      response && setNumberOfStoresMatchingTaskID(response.countStore);
    }
  }, [taskId]);

  type stateObj = {
    startDate: Date | any;
    endDate: Date | any;
    key: string | undefined;
  };

  const startDateForSet = new Date(viewEvent.start_date);
  const endDateForSet = new Date(viewEvent.end_date);

  const [state, setState] = useState<stateObj>({
    startDate: new Date(startDateForSet.setDate(startDateForSet.getDate() + 1)),
    endDate: new Date(endDateForSet.setDate(endDateForSet.getDate() + 1)),
    key: 'selection',
  });

  type displayDateObj = {
    displayStartDate: Date | any;
    displayEndDate: Date | any;
  };

  const getScheduledDates = async () => {
    try {
      if (
        viewEvent.task_type !== 'Marketing Promotions' &&
        viewEvent.schedule_date &&
        appliedFilters.store_number !== undefined
      ) {
        const storeScheduledDates = await getTaskStoreScheduledDates(
          taskId,
          appliedFilters.store_number
        );
        if (
          storeScheduledDates !== undefined &&
          storeScheduledDates[0] !== undefined
        ) {
          setDisplayDate({
            displayStartDate: storeScheduledDates[0].store_scheduled_start_date,
            displayEndDate: storeScheduledDates[0].store_scheduled_end_date,
          });
          setState({
            startDate: new Date(
              storeScheduledDates[0].store_scheduled_start_date
            ),
            endDate: new Date(storeScheduledDates[0].store_scheduled_end_date),
            key: 'selection',
          });
          setShowDateRangerPicker(false);
        }
      }
    } catch (error) {
      const history = useHistory();

      console.error(error);
      history.replace('/error');
    }
  };

  useEffect(() => {
    fetchNumberOfStoresMatchingTaskID();
  }, [fetchNumberOfStoresMatchingTaskID]);

  const saveScheduledDate = async () => {
    const scheduledDate: ScheduledDatesObj = {
      store_number: appliedFilters.store_number,
      store_scheduled_start_date: state.startDate,
      store_scheduled_end_date: state.endDate,
    };

    await createOrUpdateScheduledDates(taskId, scheduledDate);

    setDisplayDate({
      displayStartDate: state.startDate,
      displayEndDate: state.endDate,
    });
    setShowDateRangerPicker(false);
  };

  useEffect(() => {
    getScheduledDates();
  }, []);

  const windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const { setBaseModalContent, resetBaseModalContent } = baseModalStore(
    (state: {
      setBaseModalContent: Function;
      resetBaseModalContent: Function;
    }) => ({
      setBaseModalContent: state.setBaseModalContent,
      resetBaseModalContent: state.resetBaseModalContent,
    })
  );

  const { setConfirmModal } = confirmModalStore(
    (state: { setConfirmModal: Function }) => ({
      setConfirmModal: state.setConfirmModal,
    })
  );

  const deleteScheduledDate = async (task_id: string, store_number: string) => {
    await deleteTaskStoreScheduledDates(task_id, store_number);
    sendAnalyticsEvent(analyticsEventConstants.RESET_TASK_SCHEDULED_DATE);
    resetBaseModalContent();
    setDisplayDate({
      displayStartDate: null,
      displayEndDate: null,
    });
    setShowDateRangerPicker(true);
  };

  const schedulePickerLabelText = "Select Your Store's Scheduled Date(s)";

  const history = useHistory();

  const getAllStoreCompletionList = async (task_id: string, title: string) => {
    try {
      let allStoreCompletionList;
      if (appliedFilters.district_number !== undefined) {
        allStoreCompletionList = await getTaskStoreNumberCompletion(
          task_id,
          parseInt(appliedFilters.district_number),
          'district'
        );
      } else if (appliedFilters.region_number !== undefined) {
        allStoreCompletionList = await getTaskStoreNumberCompletion(
            task_id,
            parseInt(appliedFilters.region_number),
            'region'
        );
      } else if (appliedFilters.territory_number !== undefined) {
        allStoreCompletionList = await getTaskStoreNumberCompletion(
          task_id,
          parseInt(appliedFilters.territory_number),
          'territory'
        );
      } else {
        allStoreCompletionList = await getTaskStoreNumberCompletion(
          task_id,
          undefined,
          undefined
        );
      }
      const csv = Papa.unparse(allStoreCompletionList);
      downloadCsv(csv, title);
      sendAnalyticsEvent(analyticsEventConstants.TASK_COMPLETION_STATUS_LIST_DRAWER);
    } catch (error) {
      console.error(error);
      history.replace('/error');
    }
  };

  return viewEvent ? (
    <div style={viewClasses.root}>
      <Grid container>
        <Paper
          sx={{...PaperStyles(userPreference.colorMode).basicPaper, width: '100%' }}
          elevation={4}
        >
          {/* Task Type */}
          <Typography sx={viewClasses.titleHeader}>Task Type</Typography>
          <Chip
            chipBackgroundColor={chipBackgroundColorPicker(
              userPreference.colorMode,
              viewEvent.task_type,
              undefined
            )}
            chipTextColor={chipTextColorPicker(
              userPreference.colorMode,
              viewEvent.task_type,
              undefined
            )}
            variant={ChipVariantEnum.Filled}
          >
            {viewEvent.task_type !== 'Merchandising'
              ? viewEvent.task_type
              : `${viewEvent.task_type} - ${viewEvent.department}`}
          </Chip>

          {/* Completion Status */}
          {/* TODO - Style Completion text display */}
          {(viewEvent.completion_status || viewEvent.completion_statuses) &&
            viewEvent.completion_status !== 'N/A' && (
              <>
                <Typography sx={viewClasses.titleHeader}>
                  Completion Status
                </Typography>
                <Chip
                  variant={ChipVariantEnum.Filled}
                  chipBackgroundColor={chipBackgroundColorPicker(
                    userPreference.colorMode,
                    undefined,
                    overallCompletionStatus
                  )}
                  chipTextColor={chipTextColorPicker(
                    userPreference.colorMode,
                    undefined,
                    overallCompletionStatus
                  )}
                  iconEnum={chipCompletionStatusIconPicker(
                    overallCompletionStatus
                  )}
                >
                  {overallCompletionStatus}
                </Chip>
                {viewEvent.completion_status ===
                  TaskStoreCompletionStatus.Completed && (
                  <div>
                    <Typography sx={viewClasses.titleText}>
                      {viewEvent.last_modified_by.name}
                    </Typography>
                    <Typography sx={viewClasses.titleText}>
                      {format(
                        new Date(viewEvent.date_last_modified),
                        'LL/dd/yyyy KK:mm a'
                      )}
                    </Typography>
                  </div>
                )}
              </>
            )}

          {/* date range display */}
          {appliedFilters.store_number !== undefined &&
            viewEvent.store_number !== '' &&
            viewEvent.schedule_date &&
            showDateRangePicker &&
            overallCompletionStatus === TaskStoreCompletionStatus.Incomplete &&
            (userOktaGroup === groups.SUPER_GABE ||
              userOktaGroup === groups.MICHAEL) && (
              <>
                <Typography sx={viewClasses.titleHeader}>
                  {schedulePickerLabelText}
                </Typography>
                {}
                <div style={viewClasses.scheduledDatePickerContainer}>
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) =>
                      setState({
                        startDate: item.selection.startDate,
                        endDate: item.selection.endDate,
                        key: 'selection',
                      })
                    }
                    moveRangeOnFirstSelection={false}
                    ranges={[state]}
                    showMonthAndYearPickers={false}
                    minDate={addDays(new Date(viewEvent.start_date), 1)}
                    maxDate={addDays(new Date(viewEvent.end_date), 1)}
                    showDateDisplay={false}
                    className={'dateRangerPicker'}
                  />
                  <IconButtonWithLabel
                    label={'Confirm Date'}
                    icon={IconEnum.Check}
                    backgroundColor={theme.colorPalette.plainBlack}
                    color={theme.colorPalette.plainWhite}
                    padding={'0.25em'}
                    borderRadius={'0.25em'}
                    margin={'0 0.5em 0 0.5em'}
                    buttonLabel={'Confirm Date'}
                    size={'1.5em'}
                    onClick={() => {
                      saveScheduledDate();
                      setShowDateRangerPicker(false);
                    }}
                    analyticsEvent={
                      analyticsEventConstants.SELECT_TASK_SCHEDULED_DATE
                    }
                  />
                </div>{' '}
                {/* when you open the task, how do you get the schedule date? */}
              </>
            )}
          {/* Date Range */}

          <div style={viewClasses.displayDateContainer}>
            {viewEvent.schedule_date &&
              !showDateRangePicker &&
              viewEvent.completion_status ===
                TaskStoreCompletionStatus.Incomplete && (
                <div style={viewClasses.selectNewDatesButtonContainer}>
                  <div>
                    <IconButtonWithLabel
                      label={'Reselect'}
                      buttonLabel={'Reselect'}
                      padding={'0.25em'}
                      borderRadius={'0.25em'}
                      margin={'0 0.5em 0 0.5em'}
                      backgroundColor={theme.colorPalette.plainBlack}
                      size={'1.5em'}
                      color={theme.colorPalette.plainWhite}
                      icon={IconEnum.Check}
                      onClick={() => setShowDateRangerPicker(true)}
                    />
                  </div>
                  <div>
                    <IconButtonWithLabel
                      label={'Reset'}
                      buttonLabel={'Reset'}
                      padding={'0.25em'}
                      borderRadius={'0.25em'}
                      margin={'0 0.5em 0 0.5em'}
                      backgroundColor={theme.colorPalette.plainBlack}
                      size={'1.5em'}
                      color={theme.colorPalette.plainWhite}
                      icon={IconEnum.Reset}
                      onClick={() => {
                        setBaseModalContent({
                          isActive: true,
                          width: windowDimensions.width > 420 ? 0.5 : 0.8,
                          height: windowDimensions.width > 420 ? 0.0845 : 0.15,
                          dialogModalSwitchEnum:
                            DialogModalSwitchEnum.ConfirmDeleteComment,
                        });

                        setConfirmModal({
                          message:
                            'Are you sure you would like to reset this task to the Corporate designated start and end dates?',
                          confirmButtonText: 'Confirm',
                          confirmOnClickAction: () =>
                            deleteScheduledDate(
                              taskId,
                              appliedFilters.store_number
                            ),
                        });
                      }}
                    />
                  </div>
                </div>
              )}

            <Typography sx={viewClasses.titleText}>
              {displayDate.displayStartDate &&
              displayDate.displayEndDate &&
              displayDate.displayStartDate !== displayDate.displayEndDate
                ? `${format(
                    convertDateToUTC(displayDate.displayStartDate),
                    'MM/dd/yyyy'
                  )} - ${format(
                    convertDateToUTC(displayDate.displayEndDate),
                    'MM/dd/yyyy'
                  )}`
                : displayDate.displayStartDate &&
                  displayDate.displayEndDate &&
                  displayDate.displayStartDate === displayDate.displayEndDate
                ? `${format(
                    convertDateToUTC(displayDate.displayStartDate),
                    'MM/dd/yyyy'
                  )}`
                : viewEvent.start_date === viewEvent.end_date
                ? `${format(
                    new Date(convertDateToUTC(viewEvent.start_date)),
                    'MM/dd/yyyy'
                  )}`
                : `${format(
                    new Date(convertDateToUTC(viewEvent.start_date)),
                    'MM/dd/yyyy'
                  )} - ${format(
                    new Date(convertDateToUTC(viewEvent.end_date)),
                    'MM/dd/yyyy'
                  )}`}
            </Typography>
            <Typography sx={viewClasses.titleHeader}>
              {displayDate.displayStartDate && displayDate.displayEndDate
                ? `Your Store's Scheduled Date(s)`
                : `Approved Date Range For Task`}
            </Typography>
          </div>
          {/* Labor Hours */}
          {viewEvent.funded && (
            <>
              <Typography sx={viewClasses.titleHeader}>
                Task Hours Allocated
              </Typography>
              <Typography sx={viewClasses.titleText}>
                {viewEvent.labor_hours}
              </Typography>
            </>
          )}

          {/* Store(s) */}
          {(viewEvent.store_number ||
            appliedFilters.district_number || appliedFilters.region ||
            appliedFilters.territory_number ||
            distro_file_information.length > 0) && taskId !== undefined && (
            <>
              <Typography sx={viewClasses.titleHeader}>Store(s)</Typography>
              {distro_file_information.length > 0 &&
                userOktaGroup !== groups.MICHAEL &&
                userOktaGroup !== groups.STANLEY &&
                !appliedFilters.store_number && (
                  <Attachments
                    name={'distro_file_information'}
                    files={distro_file_information}
                    attachmentsId={viewEvent.attachments_id}
                  />
                )}
              <Typography
                sx={
                  viewEvent.store_number === 'All' &&
                  windowDimensions.width > 420
                    ? viewClasses.storeTitleText
                    : viewClasses.titleText
                }
              >
                {viewEvent.store_number === 'All'
                  ? viewEvent.require_completion
                    ? `${viewEvent.store_number} - ${numberOfStoresMatchingTaskID} of ${storeLocationInformation.length}`
                    : `${viewEvent.store_number} - ${storeLocationInformation.length} of ${storeLocationInformation.length}`
                  : viewEvent.store_number}
              </Typography>

              <div style={{ display: 'flex', }}>
              {(userOktaGroup !== groups.MICHAEL &&
                      userOktaGroup !== groups.STANLEY) &&
                  (viewEvent.task_type === 'Sephora' ||
                      viewEvent.task_type === 'Merchandising' ||
                      viewEvent.task_type === 'Visual' ||
                      viewEvent.task_type === 'Holiday') && (
                  <div style={{ width: '45%' }}>
                    <IconButtonWithLabel
                      label={'Store Views'}
                      buttonLabel={'Store Views'}
                      padding={'0.25em'}
                      borderRadius={'0.25em'}
                      margin={'0.5em'}
                      backgroundColor={theme.colorPalette.plainBlack}
                      size={'1.5em'}
                      color={theme.colorPalette.plainWhite}
                      icon={IconEnum.Download}
                      onClick={() =>
                        exportTaskViewedReadReceiptsCsv(taskId, viewEvent.title)
                      }
                    />
                  </div>
                )}
              {(userOktaGroup !== groups.MICHAEL &&
                      userOktaGroup !== groups.STANLEY) && !appliedFilters.store_number && taskId !== undefined && (
                      <div style={{ width: '45%' }}>
                        <IconButtonWithLabel
                            label={'Reporting'}
                            buttonLabel={'Reporting'}
                            padding={'0.25em'}
                            borderRadius={'0.25em'}
                            margin={'0.5em'}
                            backgroundColor={theme.colorPalette.plainBlack}
                            size={'1.5em'}
                            color={theme.colorPalette.plainWhite}
                            icon={IconEnum.Download}
                            onClick={() => getAllStoreCompletionList(taskId, viewEvent.title + '_completion') }
                        />
                      </div>
                  )}
              </div>
            </>
          )}
          {/* My Store(s) accordion */}
          {(distro_file_information?.length ||
            viewEvent.store_number === 'All') &&
            (appliedFilters.district_number || appliedFilters.region_number ||
              appliedFilters.territory_number) &&
            !appliedFilters.store_number && (
              <StoreNumberAccordion
                taskId={taskId}
                completionStatuses={viewEvent.completion_statuses}
              />
            )}
        </Paper>
      </Grid>

      {/* Task Optional Settings */}
      {userInfo.SiteLineGroups.includes('siteline-superuser') && (
        <SuperUserToggleFieldsSection viewEvent={viewEvent} />
      )}

      {/* Task History */}
      {taskId !== undefined && (
        <TaskHistorySection task_id={taskId} refresh={displayDate} />
      )}

      {/* Task Description */}
      <Grid container>
        <Paper
          sx={{...PaperStyles(userPreference.colorMode).basicPaper, width: '100%' }}
          elevation={4}
        >
          <Typography sx={viewClasses.titleHeader}>
            Task Description
          </Typography>
          <Grid item xs={12} sx={viewClasses.taskDescContainer}>
            {viewEvent && (
              <TextEditor
                formMode={'view'}
                delta={viewEvent.task_description}
                editorContents={editorContents}
                label={'Task Description'}
              />
            )}
          </Grid>
        </Paper>
      </Grid>
      {/* Attachments */}
      {viewEvent.attachment_information.length > 0 && (
        <Grid container>
          <Paper
            sx={{...PaperStyles(userPreference.colorMode).basicPaper, width: '100%' }}
            elevation={4}
          >
            <Typography sx={viewClasses.titleHeader}>Attachments</Typography>
            <Attachments
              name={'attachment_information'}
              files={viewEvent.attachment_information}
              attachmentsId={viewEvent.attachments_id}
            />
          </Paper>
        </Grid>
      )}
    </div>
  ) : null;
};

export default ViewTask;
