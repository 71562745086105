import { Box, Button, ButtonGroup } from '@mui/material';
import { TaskStatus } from '../../models/TaskStatus';
import { theme } from '../../assets/siteLineTheme';
import { ReviewApprovalToolbarProps } from '../Toolbar/ReviewApprovalToolbar';
import React from 'react';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

const useStyles = (colorMode: ColorModeTypeEnum) => {
  return {
    taskStatusButton: {
      backgroundColor: theme.colorPalette.plainWhite,
      color: theme.newColorPalette.textDarkBlack[colorMode],
      padding: '4px 10px',
      '&:hover': {
        backgroundColor: theme.colorPalette.plainWhite,
        filter: 'brightness(85%)',
      },
    },
    selectedTaskStatusButton: {
      backgroundColor: theme.newColorPalette.zoidbergBlack[colorMode],
      color: theme.colorPalette.plainWhite,
      transform: `scale(1.05)`,
      zIndex: 10,
      padding: '4px 10px',
      '&:hover': {
        backgroundColor: theme.newColorPalette.zoidbergMediumGrey[colorMode],
        transform: `scale(1.1)`,
      },
    },
    fullBox: {
      width: '100%',
    },
    buttonGroup: {
      margin: '.75em 0em .25em 0em',
    },
  };
}


const ReviewApprovalTaskStatusButtonGroup: React.FC<
  ReviewApprovalToolbarProps
> = (props) => {
  const { colorMode, eventFilterStatus, setEventFilterStatus } = props;

  const classes = useStyles(colorMode);

  const handleSetEventFilterStatus = (taskStatus: TaskStatus) => {
    setEventFilterStatus(taskStatus);
  };

  return (
    <Box sx={classes.fullBox}>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        sx={classes.buttonGroup}
      >
        <Button
          sx={eventFilterStatus === TaskStatus.Submitted ? classes.selectedTaskStatusButton : classes.taskStatusButton }
          style={{ borderRadius: '5px 0px 0px 5px' }}
          onClick={() => handleSetEventFilterStatus(TaskStatus.Submitted)}
        >
          Submitted
        </Button>
        <Button
            sx={eventFilterStatus === TaskStatus.Returned ? classes.selectedTaskStatusButton : classes.taskStatusButton }
            onClick={() => handleSetEventFilterStatus(TaskStatus.Returned)}
        >
          Returned
        </Button>
        <Button
          sx={eventFilterStatus === TaskStatus.Approved ? classes.selectedTaskStatusButton : classes.taskStatusButton }
          style={{ borderRadius: '0px 5px 5px 0px' }}
          onClick={() => handleSetEventFilterStatus(TaskStatus.Approved)}
        >
          Published
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default ReviewApprovalTaskStatusButtonGroup;
