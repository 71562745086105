import { useQuery } from '@tanstack/react-query';
import {getSafetyRiskComplianceData} from "../api/apiSafetyRiskCompliance";

export const useFetchSafetyRiskComplianceData = (triggerTime: Date) => {
  const { data, error, isSuccess } = useQuery(
    ['safetyRiskCompliance', triggerTime.getTime()],
    () => getSafetyRiskComplianceData()
  );
  return { data, error, isSuccess };
};
