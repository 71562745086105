import { FormikSelectItem } from './FormikSelect';
import { Grid, RadioGroup, Radio } from '@mui/material';
import FormikField from './FormikField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FileUploadField } from '../../FileUpload/FileUploadField';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { AppContext } from '../../../context/AppContext';

export type RadioGroupProps = {
  items: FormikSelectItem[];
  handleRadioChange: Function;
  gridItemSize: boolean;
  setDistroListFile: any;
  storeAssignmentSelected: string;
  attachmentsId: string | undefined;
  setFileUploading?: Dispatch<SetStateAction<boolean>>;
  formMode: string;
};

const RadioGroupComponent: React.FC<RadioGroupProps> = (props) => {
  const {
    items,
    handleRadioChange,
    gridItemSize,
    setDistroListFile,
    storeAssignmentSelected,
    attachmentsId,
    setFileUploading,
    formMode,
  } = props;

  const { appState } = useContext(AppContext);
  const { storeLocationInformation } = appState;
  return (
    <RadioGroup
      defaultValue={'store-assignment-single-store'}
      onChange={(e) => handleRadioChange(e)}
      style={{ width: '100%', flexDirection: 'row' }}
    >
      {items.map((radioItem: any) => (
        <Grid key={radioItem.name} item xs={gridItemSize ?? 12}>
          <FormControlLabel
            value="other"
            control={<Radio value={radioItem.name} name={radioItem.name} />}
            label={`${radioItem.label}${
              radioItem.label === 'All' && formMode === 'create'
                ? ` (${storeLocationInformation.length})`
                : ''
            }`}
          />
          {radioItem.name === 'store-assignment-single-store' &&
          storeAssignmentSelected === 'store-assignment-single-store' ? (
            // <div className={classes.accordionFieldOneOpen}>
            <FormikField
              name={'store_number'}
              label={'Store Number'}
              maxLength={4}
              type="tel"
            />
          ) : // </div>

          null}
          {radioItem.name === 'store-assignment-store-list' &&
          storeAssignmentSelected === 'store-assignment-store-list' ? (
            // <div className={classes.accordionFieldTwoOpen}>
            <FileUploadField
              name="distro_file_information"
              data-testid="distro-upload"
              setDistroListFile={setDistroListFile}
              fileTypes={['.csv']}
              maxNumOfUploadableFiles={1}
              fieldText={{ fieldTitle: 'drop .csv here' }}
              cumulativeFileSize={30 * 1000 * 1024}
              attachmentsId={attachmentsId}
              setFileUploading={setFileUploading}
            />
          ) : // </div>
          null}
        </Grid>
      ))}
    </RadioGroup>
  );
};

export default RadioGroupComponent;
