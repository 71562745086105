import React from 'react';
import { CalendarEvent } from '../../types/Calendar/ICalendarIndex';
import { FeatureObjects } from '../../Drawers/NewFeaturesDrawer/NewFeaturesDrawer';
import ListViewWeekDayContent from './ListViewWeekDayContent';
import { listViewWeekDayContainerStyles } from './styles/listViewWeekDayContainerStyles';

/* eslint-disable */
export enum BoxContentTypeEnum {
  NavigationBoxContent = 'NavigationBoxContent',
  BreadcrumbContent = 'BreadcrumbContent',
  TaskListViewContent = 'TaskListViewContent',
  NotificationContent = 'NotificationContent',
  ListViewWeekDayContent = 'ListViewWeekDayContent',
}

export type BoxArrayProps = {
  dataArray: Array<CalendarEvent | FeatureObjects | any>;
  boxContentType?: BoxContentTypeEnum | undefined;
  boxHeight: string;
  boxWidth: string;
  margin?: string | undefined;
  justifyContent?: string | undefined;
  onClick?: Function | undefined;
  customWidthArray?: Array<string> | undefined;
  startRowPage?: number | undefined;
  endRowPage?: number | undefined;
  callBack?: Function | undefined;
  resetList?: Function | undefined;
};

const ListViewWeekDaysContainer: React.FC<BoxArrayProps> = (props) => {
  const { dataArray, boxHeight, boxWidth, margin, justifyContent } = props;
  const listViewWeekDayContainerClasses = listViewWeekDayContainerStyles({
    boxHeight,
    boxWidth,
    margin,
    justifyContent,
  });

  return (
    <div style={listViewWeekDayContainerClasses.boxArrayContainer}>
      {dataArray.map((data, index) => {
        return (
          <ListViewWeekDayContent
            key={index}
            day={data.day}
            date={data.date}
            month={data.month}
            year={data.year}
          />
        );
      })}
    </div>
  );
};

export default ListViewWeekDaysContainer;
