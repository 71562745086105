import { DigitalMerchandisingObj } from '../DigitalMerchandisingCreate';
import { v4 as uuidv4 } from 'uuid';
import {
  DigitalMerchandisingLinkTypeEnum,
  NavigationObj,
} from '../../../utils/testData/mockHierarchyData';
import { sortArray } from '../../../utils/sortUtils';
import {
  createOrUpdateNewMerchMenu,
  deleteMerchMenu,
  NewMerchMenuObjType,
} from '../../../api/apiDigitalMerchandising';
import fireUseQueryStore from '../../../stores/fireUseQueryStore';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../utils/analytics';
// import {NewMerchMenuObjType, setNewMerchMenu} from "../../../api/apiDigitalMerchandising";

export type HandlerButtonClickParams = {
  digitalObj: DigitalMerchandisingObj;
  setDigitalState: Function;
  type: 'Add' | 'Edit' | 'Delete' | 'Save' | 'Cancel';
};

let subMenuList: NavigationObj[] = [];

const getSubMenuList = (
  baseData: NavigationObj[],
  id: string,
  returnData: NavigationObj[]
): NavigationObj[] => {
  const checkSubMenus = baseData.filter((menu: NavigationObj) => {
    return menu.parent_code === id;
  });
  if (checkSubMenus !== undefined) {
    if (checkSubMenus.length > 0) {
      subMenuList = [...returnData, ...checkSubMenus];
      checkSubMenus.forEach((menu: NavigationObj) => {
        return getSubMenuList(baseData, menu.code, subMenuList);
      });
      return subMenuList;
    } else {
      return subMenuList;
    }
  } else {
    return subMenuList;
  }
};

const getSubMenuRowNumber = (
  baseData: NavigationObj[],
  id: string,
  curMenuRowNumber: number,
  curMenuSortNumber: number
) => {
  subMenuList = [];
  getSubMenuList(baseData, id, []);

  let rowNumber: number = 0;
  let sortNumber: number = 0;

  if (subMenuList.length > 0) {
    subMenuList.forEach((menu: NavigationObj) => {
      if (menu.row_number > rowNumber) {
        rowNumber = menu.row_number;
        sortNumber = menu.sort_number;
      }
    });
  } else {
    rowNumber = curMenuRowNumber;
    sortNumber = curMenuSortNumber;
  }
  return { rowNumber, sortNumber };
};

const createOrUpdateNewMenu = async (newMenu: NewMerchMenuObjType) => {
  await createOrUpdateNewMerchMenu(newMenu);
  fireUseQueryStore.getState().setFireUseQuery({ triggerTime: new Date() });
};

const deleteMenu = async (menuCode: string) => {
  await deleteMerchMenu(menuCode);
};

export const handlerButtonClick = async (params: HandlerButtonClickParams) => {
  const { digitalObj, setDigitalState, type } = params;

  if (type === 'Save') {
    const newestMenu = digitalObj.selectItem;
    if (newestMenu) {
      if (newestMenu.title === '') {
        window.alert('Please enter a name.');
      } else {
        const newMenu: NewMerchMenuObjType = {
          code: newestMenu.code,
          title: encodeURIComponent(decodeURIComponent(newestMenu.title)),
          parent_code: newestMenu.parent_code,
          level: newestMenu.level,
          url: newestMenu.url,
          link_type: newestMenu.link_type,
          // @ts-ignore
          is_active: newestMenu.is_active,
          sort_number: newestMenu.sort_number,
        };

        if (newestMenu.link_type === DigitalMerchandisingLinkTypeEnum.Pdf) {
          newMenu.url = '';
          newMenu.pdf_file_information = newestMenu.pdf_file_information;
        }

        if (
          newMenu.link_type === DigitalMerchandisingLinkTypeEnum.Flipping ||
          newMenu.link_type === DigitalMerchandisingLinkTypeEnum.GSite
        ) {
          if (!newMenu.url || newMenu.url === '') {
            window.alert('Please enter URL.');
            return;
          } else if (
            newMenu.link_type === DigitalMerchandisingLinkTypeEnum.Flipping
          ) {
            if (
              !newMenu.url.startsWith('https://online.flippingbook.com/view/')
            ) {
              window.alert(
                'Enter the URL starting with https://online.flippingbook.com/view/'
              );
              return;
            }
          } else {
            if (!newMenu.url.startsWith('https://sites.google.com/kohls.com')) {
              window.alert(
                'Enter the URL starting with https://sites.google.com/kohls.com'
              );
              return;
            }
          }
        }

        await createOrUpdateNewMenu(newMenu);
        digitalObj.isModify
          ? sendAnalyticsEvent(
              analyticsEventConstants.MERCHANDISING_SAVE_FROM_EDIT
            )
          : sendAnalyticsEvent(
              analyticsEventConstants.MERCHANDISING_SAVE_FROM_ADD
            );

        setDigitalState({
          ...digitalObj,
          isAdd: false,
          isModify: false,
          nameValue: '',
          urlValue: '',
          currentSelectedId: '',
          selectItem: [],
        });
      }
    }
  } else if (type === 'Add') {
    if (digitalObj.selectItem !== undefined) {
      const curMenuId = digitalObj.selectItem.code;
      const curMenuRowNumber = digitalObj.selectItem.row_number;
      const curMenuSortNumber = digitalObj.selectItem.sort_number;
      const curMenuLevel = digitalObj.selectItem.level;

      let levelRowNumber = 0.1;
      for (let i = 0; i < curMenuLevel; i++) {
        levelRowNumber += Number((levelRowNumber * 0.1).toFixed(12));
      }

      const subMenuRowNumber: { rowNumber: number; sortNumber: number } =
        getSubMenuRowNumber(
          digitalObj.data,
          curMenuId,
          curMenuRowNumber,
          curMenuSortNumber
        );
      const calculateRowNumber = Number(
        (Number(subMenuRowNumber.rowNumber) + levelRowNumber).toFixed(12)
      );
      const newId = uuidv4();

      const newData: NavigationObj[] = [
        {
          id: newId,
          code: newId,
          menu_path: digitalObj.selectItem.menu_path + '/',
          title: '',
          url: null,
          parent_code: digitalObj.selectItem.code,
          level: digitalObj.selectItem.level + 1,
          row_number: calculateRowNumber,
          link_type: null,
          is_active: true,
          sort_number: subMenuRowNumber.sortNumber + 1,
        },
      ];
      setDigitalState({
        ...digitalObj,
        data: sortArray([...digitalObj.data, ...newData], 'row_number', 'desc'),
        isAdd: true,
        isModify: false,
        nameValue: '',
        urlValue: '',
        currentSelectedId: newId,
        selectItem: newData[0],
      });
    } else {
      // if you want to add as high level
      let subMenuRowNumber = 0;
      let subMenuSortNumber = 0;

      if (digitalObj.data.length > 0) {
        // bring last sort Number and row Number
        subMenuRowNumber =
          digitalObj.data[digitalObj.data.length - 1].row_number;
        subMenuSortNumber =
          digitalObj.data[digitalObj.data.length - 1].sort_number;
      }

      const newId = uuidv4();

      const newData: NavigationObj[] = [
        {
          id: newId,
          code: newId,
          menu_path: '/',
          title: '',
          url: null,
          parent_code: null,
          level: 0,
          row_number: Number((Number(subMenuRowNumber) + 1).toFixed(12)),
          link_type: null,
          is_active: true,
          sort_number: Number((Number(subMenuSortNumber) + 1).toFixed(12)),
        },
      ];
      setDigitalState({
        ...digitalObj,
        data: sortArray([...digitalObj.data, ...newData], 'row_number', 'desc'),
        isAdd: true,
        isModify: false,
        nameValue: '',
        urlValue: '',
        currentSelectedId: newId,
        selectItem: newData[0],
      });
    }
  } else if (type === 'Edit') {
    if (digitalObj.selectItem !== undefined) {
      // find parentName
      const parentCode = digitalObj.selectItem.parent_code;
      const selectedParentObj = digitalObj.data.find((menu: NavigationObj) => {
        return menu.code === parentCode;
      });

      setDigitalState({
        ...digitalObj,
        isAdd: false,
        isModify: true,
        nameValue: digitalObj.selectItem.title,
        urlValue: digitalObj.selectItem.url ? digitalObj.selectItem.url : '',
        parentName:
          selectedParentObj && selectedParentObj.title !== undefined
            ? selectedParentObj.title
            : '',
        currentSelectedId: digitalObj.selectItem.code,
      });
      // fireUseQueryStore.getState().setFireUseQuery({ triggerTime: new Date() });
    }
  } else if (type === 'Delete') {
    if (digitalObj.selectItem !== undefined) {
      const curMenuId = digitalObj.selectItem.code;
      subMenuList = [];
      getSubMenuList(digitalObj.data, curMenuId, []);

      const newData = digitalObj.data.filter((menu: NavigationObj) => {
        let returnValue = false;
        if (curMenuId === menu.code) {
          returnValue = true;
        }
        subMenuList.forEach(async (subMenu: NavigationObj) => {
          if (menu.code === subMenu.code) returnValue = true;
          await deleteMenu(subMenu.code);
        });
        return !returnValue;
      });

      setDigitalState({
        ...digitalObj,
        data: newData,
        isAdd: false,
        isModify: false,
      });
      await deleteMenu(curMenuId);
      sendAnalyticsEvent(analyticsEventConstants.MERCHANDISING_DELETE);
      fireUseQueryStore.getState().setFireUseQuery({ triggerTime: new Date() });
    }
  } else if (type === 'Cancel') {
    fireUseQueryStore.getState().setFireUseQuery({ triggerTime: new Date() });
  }
};
