import React from 'react';
import { CalendarEvent } from '../../types/Calendar/ICalendarIndex';
import TaskTableRow from '../TaskTableRow/TaskTableRow';
import NotificationTableRow from '../NotificationTableRow/NotificationTableRow';
import TaskCompletionDashboardTableRow from '../TaskCompletionDashboardTableRow/TaskCompletionDashboardTableRow';
import { TableRowTypeEnum } from 'src/utils/enum/TableRowTypeEnum';

type TableRowSwitchProps = {
  contentData: CalendarEvent | any;
  tableRowType: TableRowTypeEnum | undefined;
  callBack?: Function | undefined;
  resetList?: Function | undefined;
};

const TableRowSwitch: React.FC<TableRowSwitchProps> = (props) => {
  const { contentData, tableRowType, callBack, resetList } = props;
  switch (tableRowType) {
    case TableRowTypeEnum.Task:
      return <TaskTableRow contentData={contentData} />;
    case TableRowTypeEnum.Notification:
      return (
        <NotificationTableRow
          contentData={contentData}
          callBack={callBack}
          resetList={resetList}
        />
      );
    case TableRowTypeEnum.CompletionDashboard:
      return (
        <TaskCompletionDashboardTableRow
          contentData={contentData}
          callBack={callBack}
        />
      );
    default: {
      return <div aria-label={'default'}></div>;
    }
  }
};

export default TableRowSwitch;
