import React from 'react';
import { toggleStyles } from './toggleStyles';
import ToggleIcon from './ToggleIcon';
import { theme } from '../../assets/siteLineTheme';

export type RadioProps = {
  label: string;
  isActive: boolean;
  onClickFunc?: Function;
};

const Toggle: React.FC<RadioProps> = (props) => {
  const { label, isActive, onClickFunc } = props;

  const toggleClasses = toggleStyles();

  return (
    <div
      aria-label={`${label}-toggle`}
      style={toggleClasses.toggleContainer}
      onClick={() => (onClickFunc !== undefined ? onClickFunc() : null)}
    >
      <div style={toggleClasses.toggleLabelContainer}>
        <div style={toggleClasses.toggleLabelText}>{label}</div>
      </div>
      <ToggleIcon
        name={'colorMode'}
        isActive={isActive}
        activeBarBackgroundColor={
          theme.newColorPalette.buttons.commentLightSubmitDarkGrey.Zoidberg
        }
        passiveBarBackgroundColor={theme.colorPalette.zoidbergLight}
        activeCircleBackgroundColor={
          theme.newColorPalette.buttons.commentDarkSubmitBlack.Zoidberg
        }
        passiveCircleBackgroundColor={theme.colorPalette.zoidberg}
      />
    </div>
  );
};

export default Toggle;
