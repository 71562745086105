export const taskbarHeight = document
  .getElementsByClassName('rbc-event')[0]
  ?.getBoundingClientRect().height;

// For determining
export const useMobileBreakpoint = () => {
  const screenWidth = screen.width;
  if (screenWidth <= 450) {
    return true;
  } else {
    return false;
  }
};
