import React from 'react';
import { toggleIconStyles } from './toggleIconStyles';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

export type ToggleIconProps = {
  name: string;
  isActive: boolean;
  onClickFunc?: Function;
  activeBarBackgroundColor: string;
  passiveBarBackgroundColor: string;
  activeCircleBackgroundColor: string;
  passiveCircleBackgroundColor: string;
  circleSize?: string | undefined;
  width?: string | undefined;
};

const ToggleIcon: React.FC<ToggleIconProps> = (props) => {
  const {
    name,
    isActive,
    onClickFunc,
    activeBarBackgroundColor,
    passiveBarBackgroundColor,
    activeCircleBackgroundColor,
    passiveCircleBackgroundColor,
    circleSize,
    width,
  } = props;

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const toggleIconClasses = toggleIconStyles({
    colorMode: userPreference.colorMode,
    isActive,
    activeBarBackgroundColor,
    passiveBarBackgroundColor,
    activeCircleBackgroundColor,
    passiveCircleBackgroundColor,
    circleSize,
    width,
  });

  return (
    <div
      aria-label={`${name}-toggle`}
      onClick={() => (onClickFunc !== undefined ? onClickFunc() : null)}
    >
      <div style={toggleIconClasses.toggleSwitchContainer}>
        <div style={toggleIconClasses.toggleSwitchBar}>
          <div style={toggleIconClasses.toggleSwitchCircle} />
        </div>
      </div>
    </div>
  );
};

export default ToggleIcon;
