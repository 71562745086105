import { create, SetState } from 'zustand';
import { SnackbarProps } from './models/SnackbarProps';

const snackbarProps: SnackbarProps = {
  actionStatus: '',
  isSnackbarVisible: false,
  correlationId: undefined,
};
const snackbarPropsStore = create((set: SetState<any>) => ({
  snackbarProps: { ...snackbarProps },
  setSnackbarProps: (snackbarProps: SnackbarProps) => {
    set(() => ({ snackbarProps }));
  },
}));

export default snackbarPropsStore;
