import React, { useState } from 'react';
import { columnSortDirection, sortArray } from '../../../utils/sortUtils';
import Table from '../../Table/Table';
import { BoxContentTypeEnum } from '../../BoxArray/BoxArray';
import PageNavigation from '../../PageNavigation/PageNavigation';
import { DirectionEnum } from '../../../utils/enum/DirectionEnum';
import { ChipVariantEnum } from '../../Chip/chipUtils/ChipEnums';
import Chip from '../../Chip/Chip';
import { theme } from '../../../assets/siteLineTheme';
import { ColorModeTypeEnum } from '../../../stores/userPreferenceStore';
import { ChartBarInformation } from '../../../dataWrappers/TaskCompletionDashboardDataWrapper';

type TaskCompletionDashboardDataListProps = {
  taskCompletionList: any;
  rowNumberByPage: number;
  colorMode: ColorModeTypeEnum;
  chartBarInformation: ChartBarInformation;
  handlerExportCsv: Function;
};

export type TaskCompletionListParams = {
  completion_status: string;
  end_date: Date;
  id: string;
  scheduled_end_date: Date | null;
  task_type: string;
  title: string;
  store_number_count: string;
};

const TaskCompletionDashboardDataList: React.FC<
  TaskCompletionDashboardDataListProps
> = (props) => {
  const {
    taskCompletionList,
    rowNumberByPage,
    colorMode,
    chartBarInformation,
    handlerExportCsv,
  } = props;

  const emptyColumnSortDirection = {
    title: '',
    end_date: 'desc',
  };

  const [tableColumnSortDirection, setTableColumnSortDirection] = useState(
    emptyColumnSortDirection
  );
  const [startRowPage, setStartRowPage] = useState<number>(0);
  const [endRowPage, setEndRowPage] = useState<number>(rowNumberByPage);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const taskCompletionDashboardColumnSorting = (selectedCol: string) => {
    const sortDirection = columnSortDirection(
      selectedCol,
      tableColumnSortDirection
    );

    sortArray(taskCompletionList, selectedCol, sortDirection);
    setPageNumber(1);
    setStartRowPage(0);
    setEndRowPage(rowNumberByPage);

    setTableColumnSortDirection({
      ...emptyColumnSortDirection,
      [selectedCol]: sortDirection,
    });
  };

  const columnHeadersArray = [
    {
      content: 'Title',
      column: 'title',
      cellWidth: '50%',
      justifyContent: undefined,
      onClick: () => taskCompletionDashboardColumnSorting('title'),
    },
    {
      content: 'Store(s)',
      column: 'store_number_count',
      cellWidth: '20%',
      justifyContent: 'center',
      onClick: undefined,
    },
    {
      content: 'End Date',
      column: 'end_date',
      cellWidth: '20%',
      justifyContent: 'center',
      onClick: () => taskCompletionDashboardColumnSorting('end_date'),
    },
    {
      content: '  ',
      column: 'export',
      cellWidth: '10%',
      justifyContent: 'center',
      onClick: undefined,
    },
  ];

  const handlerChangePage = (
    type: DirectionEnum,
    curPage: number,
    pageRow: number
  ) => {
    if (type === DirectionEnum.Forward) {
      setPageNumber(curPage);
      setStartRowPage(startRowPage + pageRow);
      setEndRowPage(endRowPage + pageRow);
    } else {
      setPageNumber(curPage);
      setStartRowPage(startRowPage - pageRow);
      setEndRowPage(endRowPage - pageRow);
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', margin: '0 0 0.5em 0' }}>
        <Chip
          variant={ChipVariantEnum.Filled}
          chipBackgroundColor={theme.newColorPalette.zoidbergWhite[colorMode]}
        >{`${chartBarInformation.title}: ${chartBarInformation.value}`}</Chip>
        <Chip
          variant={ChipVariantEnum.Filled}
          chipBackgroundColor={theme.newColorPalette.zoidbergWhite[colorMode]}
        >{`Completed Status: ${chartBarInformation.completion_status}`}</Chip>
      </div>
      <Table
        data={taskCompletionList.slice(startRowPage, endRowPage)}
        columnHeadersArray={columnHeadersArray}
        boxContentType={BoxContentTypeEnum.CompletionDataDashboardListContent}
        optionalState={tableColumnSortDirection}
        optionalFunction={handlerExportCsv}
      />
      <PageNavigation
        curPage={pageNumber}
        dataCount={taskCompletionList ? taskCompletionList.length : 0}
        onChangePage={handlerChangePage}
      />
    </div>
  );
};

export default TaskCompletionDashboardDataList;
