import Papa from 'papaparse';
import { AnalyticEventType, sendAnalyticsEvent } from './analytics';
import { isValid, parse } from 'date-fns';

const headerFieldChecker = (fields: string[]) => {
  const correctFields = [
    'Store Number',
    'Start Date',
    'End Date',
    'Labor Hours',
  ];
  const fieldStatus = { fieldsGood: true, errorMessages: <string[]>[] };
  if (fields.length !== 4) {
    fieldStatus.fieldsGood = false;
    if (fields.length < 4) {
      fieldStatus.errorMessages.push('You have too few columns in the table');
      return fieldStatus;
    } else {
      fieldStatus.errorMessages.push('You have too many columns in the table');
      return fieldStatus;
    }
  }

  fields.forEach((field: string, index: string | number) => {
    if (field.trim() !== correctFields[index]) {
      fieldStatus.fieldsGood = false;
      fieldStatus.errorMessages.push(
        'Check your column labels. You have: ' +
          field +
          ' It should be: ' +
          correctFields[index]
      );
    }
  });

  return fieldStatus;
};

export type StoreLocationInformation = {
  location_number: number;
  location_name: string;
  district_number: number;
  district_name: string;
  territory_number: number;
  territory_name: string;
  store_number: string;
};

const dataChecker = (
  data: any[],
  storeLocationInformation?: Array<StoreLocationInformation>
) => {
  const dataStatus = { dataGood: true, errorMessages: <string[]>[] };

  const storeLocationNumberArray = storeLocationInformation?.map(
    (sli: StoreLocationInformation) => {
      return sli.location_number;
    }
  );

  const newSetOfStoreNumbers = new Set(
    data.map((task) => task['Store Number'])
  );
  if (newSetOfStoreNumbers.size !== data.length) {
    dataStatus.dataGood = false;
    dataStatus.errorMessages.push(
      'You have duplicated store numbers, please check your distribution list for any repeated store numbers'
    );
  }

  data.forEach((row, index) => {
    const startDate = row['Start Date'];
    const endDate = row['End Date'];
    const storeNumber = row['Store Number'];

    if (storeNumber === '') {
      dataStatus.dataGood = false;
      dataStatus.errorMessages.push(
        'Please enter a Store Number on row ' + (index + 2)
      );
    }

    if (storeNumber.length > 5) {
      dataStatus.dataGood = false;
      dataStatus.errorMessages.push(
        'Please enter a Store Number with 5 or less digits on row ' +
          (index + 2)
      );
    }

    if (
      storeLocationNumberArray !== undefined &&
      !storeLocationNumberArray.includes(parseInt(storeNumber))
    ) {
      dataStatus.dataGood = false;
      dataStatus.errorMessages.push(
        'Ensure the store numbers are valid on rows:' + (index + 2)
      );
    }

    if (startDate === '') {
      dataStatus.dataGood = false;
      dataStatus.errorMessages.push(
        'Please enter a value for the Start Date on row ' + (index + 2)
      );
    }

    if (new Date(startDate) < new Date(Date.now())) {
      dataStatus.dataGood = false;
      dataStatus.errorMessages.push(
        'Please enter a Start Date that is after today on row ' + (index + 2)
      );
    }

    if (new Date(endDate) < new Date(Date.now())) {
      dataStatus.dataGood = false;
      dataStatus.errorMessages.push(
        'Please enter an End Date that is after today on row ' + (index + 2)
      );
    }

    if (new Date(endDate) < new Date(startDate)) {
      dataStatus.dataGood = false;
      dataStatus.errorMessages.push(
        'Please enter an End Date that is after the Start Date on row ' +
          (index + 2)
      );
    }

    if (endDate === '') {
      dataStatus.dataGood = false;
      dataStatus.errorMessages.push(
        'Please enter a value for the End Date on row ' + (index + 2)
      );
    }

    const isStartDateSeparatorCorrect = startDate?.indexOf('-') === -1;
    const isEndDateSeparateCorrect = endDate?.indexOf('-') === -1;

    if (!isStartDateSeparatorCorrect) {
      dataStatus.dataGood = false;
      dataStatus.errorMessages.push(
        "Please replace the '-' on row " +
          (index + 2) +
          " Start Date with a '/'"
      );
    }

    if (!isEndDateSeparateCorrect) {
      dataStatus.dataGood = false;
      dataStatus.errorMessages.push(
        "Please replace the '-' on row " + (index + 2) + " End Date with a '/'"
      );
    }

    if (isStartDateSeparatorCorrect && isEndDateSeparateCorrect) {
      const isStartDateValid = isValid(parse(startDate, 'P', new Date()));
      const isEndDateValid = isValid(parse(endDate, 'P', new Date()));

      if (!isStartDateValid) {
        dataStatus.dataGood = false;
        dataStatus.errorMessages.push(
          'Please make sure the Start Date formatting on row ' +
            (index + 2) +
            ' is MM/DD/YYYY'
        );
      } else if (!isEndDateValid) {
        dataStatus.dataGood = false;
        dataStatus.errorMessages.push(
          'Nope. Fix the Start Date formatting on row ' +
            (index + 2) +
            ' is MM/DD/YYYY'
        );
      }
    }

    if (
      isStartDateSeparatorCorrect &&
      isEndDateSeparateCorrect &&
      dataStatus.dataGood
    ) {
      const startDateArr = startDate.split('/');
      const endDateArr = endDate.split('/');

      const startYear = parseInt(startDateArr[2]);
      const startMonth = parseInt(startDateArr[0]);
      const startDay = parseInt(startDateArr[1]);

      const endYear = parseInt(endDateArr[2]);
      const endMonth = parseInt(endDateArr[0]);
      const endDay = parseInt(endDateArr[1]);

      if (
        isValidDate(startYear, startMonth, startDay) &&
        isValidDate(endYear, endMonth, endDay)
      ) {
        if (new Date(startDate) > new Date(endDate)) {
          dataStatus.dataGood = false;
          dataStatus.errorMessages.push(
            'Please check your dates. Your End Date is before your Start Date on row ' +
              (index + 2)
          );
        }
      } else {
        dataStatus.dataGood = false;
        dataStatus.errorMessages.push(
          'Please check your dates. One of your dates is invalid on row ' +
            (index + 2)
        );
      }
    }
  });

  return dataStatus;
};

const csvValidator = (
  responses: any,
  storeLocationInformation?: Array<any>
) => {
  let dataStatus = { dataGood: true, errorMessages: <string[]>[] };
  const csvStatus = { csvGood: true, errorMessages: <string[]>[] };
  const headerStatus = headerFieldChecker(responses.meta.fields);

  if (headerStatus.fieldsGood) {
    dataStatus = dataChecker(responses.data, storeLocationInformation);
  }
  if (!headerStatus.fieldsGood || !dataStatus.dataGood) {
    csvStatus.csvGood = false;
    csvStatus.errorMessages = headerStatus.errorMessages.concat(
      dataStatus.errorMessages
    );
  }
  return csvStatus;
};

export const validateCSV = async (
  currFile: File,
  storeLocationInformation?: Array<StoreLocationInformation>
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    let csvStatus = { csvGood: true, errorMessages: <string[]>[] };
    Papa.parse(currFile, {
      header: true,
      complete: function (responses) {
        csvStatus = csvValidator(responses, storeLocationInformation);
        if (!csvStatus.csvGood) {
          let errorString = 'We had some trouble processing your file: \n';
          csvStatus.errorMessages.forEach((err) => {
            errorString = errorString + err + '\n';
          });
          window.alert(errorString);
        }
        return resolve(csvStatus.csvGood);
      },
      error: (error) => {
        return reject(error);
      },
    });
  });
};

function isValidDate(year: number, month: number, day: number) {
  month = month - 1;
  const d = new Date(year, month, day);

  if (
    d.getFullYear() === year &&
    d.getMonth() === month &&
    d.getDate() === day
  ) {
    return true;
  }
  return false;
}

export const downloadCsv = (csv: any, filename: string) => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob([csv], { type: 'text/csv' });
  fakeLink.setAttribute('href', URL.createObjectURL(blob));
  fakeLink.setAttribute('download', `${filename}.csv`);
  fakeLink.click();
};

export const handleDownloadCSV = (
  jsonData: any,
  filename: string,
  analyticsEvent?: AnalyticEventType
) => {
  const csv = Papa.unparse(jsonData);
  downloadCsv(csv, filename);
  analyticsEvent !== undefined && sendAnalyticsEvent(analyticsEvent);
};
