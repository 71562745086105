export type DigitalMerchandisingScreenStylesParams = {};

export const digitalMerchandisingScreenStyles = () => {
  const isMobile = () => {
    return window.innerWidth <= 430;
  };

  return {
    screenContainer: {
      height: isMobile() ? '92%' : '85%',
      width: isMobile() ? '90%' : '80%',
      margin: isMobile() ? '.5em auto' : '2.5em auto',
    },
    navSection: {
      width: isMobile() ? '100%' : '1/3',
      height: 'fit-content',
    },
    navSectionTitle: {
      fontWeight: 700,
      fontSize: '1.25em',
      margin: '.35em 0em',
      display: 'flex',
      alignItems: 'center',
    },
  };
};
