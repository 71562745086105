import React from 'react';
import dateSelectionForDisplayStore, {
  dateLabelGenerator,
  DateSelectionForDisplayStoreProps,
} from '../../stores/dateSelectionForDisplayStore';
import { calendarToolbarStylesV2 } from './styles/calendarToolbarStylesV2';
import { theme } from '../../assets/siteLineTheme';
import { DateForwardBackIconButtons } from '../Buttons/IconButtonGroupElementArrays/DateForwardBackIconButtons';
import { getDaysOfWeekArray, removeTime } from '../../utils/dateUtils';
import appliedFiltersStore from '../../stores/appliedFiltersStore';
import TaskFilterButton from '../Drawers/FilterDrawer/TaskFilterButton';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import PrintButton from '../Buttons/PrintButton';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../utils/analytics';

export type CalendarToolbarV2Props = {
  BaseButtonComponent: any;
  ListViewWeekDaysContainerComponent: any;
  GroupedSelectionButtonsV2Component: any;
  getIntervalArray: Function;
};

/* eslint-disable */
export enum OrientationEnum {
  Row = 'row',
  Column = 'column',
}

const CalendarToolbarV2: React.FC<CalendarToolbarV2Props> = (props) => {
  const {
    GroupedSelectionButtonsV2Component,
    BaseButtonComponent,
    ListViewWeekDaysContainerComponent,
    getIntervalArray,
  } = props;
  const { dateSelectionForDisplayProps, setDateSelectionForDisplayProps } =
    dateSelectionForDisplayStore(
      (state: {
        dateSelectionForDisplayProps: DateSelectionForDisplayStoreProps;
        setDateSelectionForDisplayProps: any;
      }) => ({
        dateSelectionForDisplayProps: state.dateSelectionForDisplayProps,
        setDateSelectionForDisplayProps: state.setDateSelectionForDisplayProps,
      })
    );

  const { appliedFilters, setAppliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any; setAppliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
      setAppliedFilters: state.setAppliedFilters,
    })
  );

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const dateSetterHandler = () => {
    const newDateSelectionForDisplayProps = {
      dateRangeType: dateSelectionForDisplayProps.dateRangeType,
      selectedDisplayDate: new Date(Date.now()),
      dateLabel: dateLabelGenerator({
        dateRangeTypeEnum: dateSelectionForDisplayProps.dateRangeType,
        selectedDisplayDate: new Date(Date.now()),
      }),
    };
    setDateSelectionForDisplayProps(newDateSelectionForDisplayProps);
  };

  const todayClickHandler = () => {
    sendAnalyticsEvent(analyticsEventConstants.CALENDAR_TODAY);
    dateSetterHandler();
    setAppliedFilters({
      ...appliedFilters,
      current_date: removeTime(new Date()).toLocaleString(),
      view_type: dateSelectionForDisplayProps.dateRangeType.toLowerCase(),
    });
  };
  const { dateLabel } = dateSelectionForDisplayProps;

  const calendarToolbarClasses = calendarToolbarStylesV2(
    userPreference.colorMode
  );

  return (
    <div style={calendarToolbarClasses.toolbarContainer}>
      <div
        className={'toolbarTopRow'}
        style={calendarToolbarClasses.toolbarTopRow}
      >
        <div style={calendarToolbarClasses.toolbarLeftSection}>
          <BaseButtonComponent
            onClick={() => todayClickHandler()}
            activeBackgroundColor={
              theme.newColorPalette.zoidbergLightLighterGrey[
                userPreference.colorMode
              ]
            }
            passiveBackgroundColor={
              theme.newColorPalette.CalendarTodayBackgroundWhite[
                userPreference.colorMode
              ]
            }
            activeTextColor={
              theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
            }
            passiveTextColor={
              theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
            }
            fontSize={'.875rem'}
          >
            Today
          </BaseButtonComponent>
          <div style={{ marginLeft: '1em' }}>
            <GroupedSelectionButtonsV2Component
              label={'time navigation button group'}
              GroupedSelectionButtonArray={DateForwardBackIconButtons(
                userPreference.colorMode
              )}
              buttonOrientation={OrientationEnum.Row}
            />
          </div>
          <div
            className={'toolbarDateLabel'}
            style={calendarToolbarClasses.toolbarDateLabel}
          >
            {dateLabel}
          </div>
        </div>
        <div style={{ marginTop: window.innerWidth > 420 ? '' : '0.2em' }}>
          <GroupedSelectionButtonsV2Component
            label={'day week button group'}
            GroupedSelectionButtonArray={getIntervalArray(
              userPreference.colorMode
            )}
            buttonOrientation={OrientationEnum.Row}
          />
        </div>
        <div style={calendarToolbarClasses.toolbarRightSection}>
          {window.innerWidth > 420 && (
            <PrintButton
              analyticsEvent={analyticsEventConstants.PRINT_TASK_LIST_VIEW}
            />
          )}
        </div>
      </div>
      <div style={calendarToolbarClasses.rowContainer}>
        <ListViewWeekDaysContainerComponent
          dataArray={getDaysOfWeekArray(
            dateSelectionForDisplayProps.selectedDisplayDate
          )}
          boxHeight={'1em'}
          boxWidth={'1em'}
          justifyContent={'flex-start'}
          margin={'0.3em 0 1em 0'}
        />
        <div style={calendarToolbarClasses.filterContainer}>
          <TaskFilterButton />
        </div>
      </div>
    </div>
  );
};

export default CalendarToolbarV2;
