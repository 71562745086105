import React from 'react';
import { TableCellObj } from './Table';
import { tableCellStyles } from './TableCell/tableCellStyles';
import TableCell from './TableCell/TableCell';
import { IconButton } from '../Buttons/IconButton';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import { theme } from '../../assets/siteLineTheme';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

export type TableHeaderProps = {
  columnHeadersArray: Array<TableCellObj>;
  optionalState?: any;
};

const TableHeader: React.FC<TableHeaderProps> = (props) => {
  const { columnHeadersArray, optionalState } = props;
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const sortingDirectionArrows = (column: string, onClick: Function) => {
    if (optionalState !== undefined && optionalState[column] === 'desc') {
      return (
        <IconButton
          label={column}
          icon={IconEnum.DownArrow}
          onClick={() => onClick()}
          color={theme.newColorPalette.zoidbergBlack[userPreference.colorMode]}
        />
      );
    } else if (optionalState !== undefined && optionalState[column] === 'asc') {
      return (
        <IconButton
          label={column}
          icon={IconEnum.UpArrow}
          onClick={() => onClick()}
          color={theme.newColorPalette.zoidbergBlack[userPreference.colorMode]}
        />
      );
    } else {
      return (
        <IconButton
          label={column}
          icon={IconEnum.UpDownArrow}
          onClick={() => onClick()}
          color={theme.newColorPalette.zoidbergBlack[userPreference.colorMode]}
        />
      );
    }
  };

  return (
    <div style={tableCellStyles({}).headerRowContainer}>
      {columnHeadersArray.map((headerCell: TableCellObj, index) => {
        return (
          <TableCell
            key={index}
            styleProps={{
              cellWidth: headerCell.cellWidth,
              justifyContent: headerCell.justifyContent,
            }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() =>
                headerCell.onClick !== undefined ? headerCell.onClick() : null
              }
            >
              <div style={{ marginRight: '0.2em' }}>{headerCell.content} </div>
              {headerCell.onClick &&
                headerCell.onClick !== undefined &&
                sortingDirectionArrows(headerCell.column, headerCell.onClick)}
            </div>
          </TableCell>
        );
      })}
    </div>
  );
};

export default TableHeader;
