import { Box, Typography } from '@mui/material';
import ReviewApprovalTaskStatusButtonGroup from '../Buttons/ReviewApprovalTaskStatusButtonGroup';
import { theme } from '../../assets/siteLineTheme';
import React, { Dispatch, SetStateAction } from 'react';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';
import muiTheme from 'src/utils/mui/muiTheme';

export type ReviewApprovalToolbarProps = {
  colorMode: ColorModeTypeEnum;
  eventFilterStatus: string;
  setEventFilterStatus: Dispatch<SetStateAction<string>>;
};

export const useStyles = (colorMode: ColorModeTypeEnum) => {
    return {
      halfBox: {
        width: '50%',
        [muiTheme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      fullBox: {
        width: '100%',
      },
      toolbarContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        paddingBottom: '.75em',
      },
      toolbarSubContainer: {
        display: 'flex',
        [muiTheme.breakpoints.down('xs')]: {
          flexDirection: 'column',
        },
      },
      titleText: {
        color: theme.newColorPalette.plainWhiteBlack[colorMode],
      },
    };
  };

const ReviewApprovalToolbar: React.FC<ReviewApprovalToolbarProps> = (props) => {
  const { colorMode, eventFilterStatus, setEventFilterStatus } = props;
  const classes = useStyles(colorMode);

  return (
    <Box sx={classes.toolbarContainer}>
      <Box sx={classes.fullBox}>
        <Typography variant="h3" sx={classes.titleText }>
          Task Review & Approval
        </Typography>
      </Box>
      <Box sx={classes.toolbarSubContainer}>
        <ReviewApprovalTaskStatusButtonGroup
          colorMode={colorMode}
          eventFilterStatus={eventFilterStatus}
          setEventFilterStatus={setEventFilterStatus}
        />
      </Box>
    </Box>
  );
};

export default ReviewApprovalToolbar;

export class IReviewApprovalToolbarProps {}
