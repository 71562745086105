import { theme } from '../../assets/siteLineTheme';

export const breadcrumbNavigationBarStyles = () => {
  return {
    barContainer: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    },
    iconBackground: {
      borderRadius: '100%',
      backgroundColor: theme.colorPalette.textDark,
      opacity: 0.8,
      padding: '.5em',
      marginRight: '.75em',
      display: 'flex',
      alignItems: 'center',
      border: `2px solid ${theme.colorPalette.plainWhite}`,
      cursor: 'pointer',
    },
    icon: {
      color: theme.colorPalette.plainWhite,
      size: '2em',
    },
  };
};
