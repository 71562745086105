import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../utils/analytics';

export type TypeRouteDetails = {
  routeName: string;
  screen: string;
  path: string;
  showTwoToneBackground: boolean;
  googleAnalyticsEvent?: () => void;
};
export const routeDetails: TypeRouteDetails[] = [
  {
    routeName: 'root',
    screen: 'calendar',
    path: '/',
    showTwoToneBackground: true,
  },
  {
    routeName: 'easyPrint',
    screen: 'easyPrint',
    path: '/easy-print',
    showTwoToneBackground: true,
  },
  {
    routeName: 'taskApprovals',
    screen: 'taskApprovals',
    path: '/task-approvals',
    showTwoToneBackground: true,
  },
  {
    routeName: 'completionDataDashboard',
    screen: 'completionDataDashboard',
    path: '/completion-data-dashboard',
    showTwoToneBackground: false,
  },
  {
    routeName: 'taskListView',
    screen: 'taskListView',
    path: '/task-list-view',
    showTwoToneBackground: true,
    googleAnalyticsEvent: () =>
      sendAnalyticsEvent(analyticsEventConstants.NAVIGATION_LIST_VIEW),
  },
  {
    routeName: 'user',
    screen: 'userInfo',
    path: '/user',
    showTwoToneBackground: false,
  },
  {
    routeName: 'mobileMetrics',
    screen: 'mobileMetrics',
    path: '/mobile-metrics',
    showTwoToneBackground: false,
    googleAnalyticsEvent: () =>
      sendAnalyticsEvent(analyticsEventConstants.MOBILE_METRICS),
  },
];
