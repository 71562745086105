import { create, SetState } from 'zustand';

/* eslint-disable */
export enum ColorModeTypeEnum {
  Zoidberg = 'Zoidberg',
  BlackWhite = 'BlackWhite',
  Teal = 'Teal',
}
/* eslint-disable */
export enum ViewTypeEnum {
  Calendar = 'Calendar',
  List = 'List',
  Merchandising = 'Merchandising',
  Safety = 'Safety',
}

export type UserPreferenceStoreState = {
  colorMode: ColorModeTypeEnum;
  viewType: ViewTypeEnum;
  viewRowNumber: number;
};

export const defaultUserPreferenceStoreState: UserPreferenceStoreState = {
  colorMode: ColorModeTypeEnum.Zoidberg,
  viewType: ViewTypeEnum.Calendar,
  viewRowNumber: 10,
};

const userPreferenceStore = create((set: SetState<any>) => ({
  userPreference: { ...defaultUserPreferenceStoreState },
  setUserPreference: (userPreference: UserPreferenceStoreState) => {
    set(() => ({ userPreference }));
  },
}));

export default userPreferenceStore;
