import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { SelectOptionType } from '../../../utils/Data/dropboxDataList';
import { singleDropdownSelectorStyles } from './singleDropdownSelectorStyles';

export type SingleDropdownSelectorProps = {
  dataOption: Array<SelectOptionType>;
  placeholderText: string;
  onChange: Function;
  targetObjField: string;
  labelText?: string;
  redLabelText?: string;
  defaultValue?: any;
  isDisabled?: boolean;
  resetValue?: boolean;
};

const SingleDropdownSelector: React.FC<SingleDropdownSelectorProps> = (
  props
) => {
  const {
    dataOption,
    placeholderText,
    onChange,
    targetObjField,
    labelText,
    redLabelText,
    defaultValue,
    isDisabled,
    resetValue,
  } = props;
  const [value, setValue] = useState<SingleValue<SelectOptionType>>();

  const handleChange = (newSelections: SingleValue<SelectOptionType>) => {
    setValue(newSelections);
    onChange(newSelections, targetObjField);
  };

  const singleDropdownSelectorClasses = singleDropdownSelectorStyles();

  useEffect(() => {
    if (resetValue) {
      setValue(null);
      onChange(null, targetObjField);
    }
  }, [resetValue]);

  return (
    <div
      aria-label={'SingleDropdownSelector Component'}
      style={singleDropdownSelectorClasses.singleDropboxContainer}
    >
      <div>
        {labelText && (
          <div style={singleDropdownSelectorClasses.singleDropboxTextContainer}>
            {labelText} <span style={{ color: 'red' }}>{redLabelText}</span>
          </div>
        )}
        <Select
          aria-label={'SingleDropdownSelector DropBox'}
          value={value}
          onChange={handleChange}
          options={dataOption}
          placeholder={placeholderText}
          defaultValue={defaultValue}
          isClearable
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default SingleDropdownSelector;
