import React from 'react';
import { FeatureObjects } from '../../Drawers/NewFeaturesDrawer/NewFeaturesDrawer';
import {
  createNewFeaturesViewedEmployee,
  getNewFeatures,
  getNewFeaturesViewedEmployee,
} from '../../../api/apiNewFeature';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import IconButtonWithLabel from '../../Buttons/IconButtonWithLabel';
import { IconEnum } from '../../Chip/chipUtils/ChipEnums';
import { theme } from '../../../assets/siteLineTheme';
import { notificationTableRowStyles } from './notificationTableRowStyles';
import drawerPropsStore from '../../../stores/drawerPropsStore';
import { useHistory } from 'react-router-dom';

export type NotificationTableRowProps = {
  contentData: FeatureObjects | any;
  callBack: Function | undefined;
  resetList: Function | undefined;
};

const NotificationTableRow: React.FC<NotificationTableRowProps> = (props) => {
  const { contentData, callBack, resetList } = props;
  const { index, id, title, file_information, attachments_id } = contentData;

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const { drawerProps, setDrawerProps } = drawerPropsStore(
    (state: { drawerProps: any; setDrawerProps: any }) => ({
      drawerProps: state.drawerProps,
      setDrawerProps: state.setDrawerProps,
    })
  );

  const setNotViewingNewFeatureForEmployee = async (feature_id: string) => {
    try {
      await createNewFeaturesViewedEmployee(feature_id);

      const getNewFeatureList = await getNewFeatures();
      const getNewFeaturesViewedEmployeeList =
        await getNewFeaturesViewedEmployee();

      if (resetList !== undefined) resetList(getNewFeatureList, getNewFeaturesViewedEmployeeList);
      setDrawerProps({
        ...drawerProps,
        drawerContentProps: {
          newFeaturesList: getNewFeatureList,
          viewedFeaturesList: getNewFeaturesViewedEmployeeList,
        },
      });
    } catch (error) {
      const history = useHistory();
      console.error(error);
      history.replace('/error');
    }
  };

  const notificationTableRowClasses = notificationTableRowStyles({
    index,
  });

  return (
    <div style={notificationTableRowClasses.titleContainer} key={id}>
      <div
        style={notificationTableRowClasses.title}
        onClick={() => {
          /* istanbul ignore next */
          if (callBack !== undefined) callBack(file_information.name, attachments_id);
        }}
      >
        {title}
      </div>
      <div>
        {resetList === undefined ? (
          <></>
        ) : (
          <IconButtonWithLabel
            label={'Archive'}
            icon={IconEnum.Archive}
            onClick={() => setNotViewingNewFeatureForEmployee(id)}
            buttonLabel={'Archive'}
            backgroundColor={
              theme.newColorPalette.completionStatus.completedBlack[
                userPreference.colorMode
              ]
            }
            color={theme.colorPalette.plainWhite}
            padding={'0.25em'}
            borderRadius={'0.25em'}
            margin={'0 0.5em 0 0.5em'}
            size={'1.5em'}
          />
        )}
      </div>
    </div>
  );
};

export default NotificationTableRow;
