export const actions = {
  SET_USER_PERMISSION: 'SET_USER_PERMISSION',
  SET_STORE_LOCATIONS: 'SET_STORE_LOCATIONS',
};

const appReducer = (state: any, action: any) => {
  const { SET_USER_PERMISSION, SET_STORE_LOCATIONS } = actions;
  const { type, payload } = action;

  switch (type) {
    case SET_USER_PERMISSION:
      const { userInfo, authState, userPermissions, screens } = payload;
      return {
        ...state,
        formMode: 'create',
        userInfo,
        authState,
        userPermissions,
        screens,
      };
    case SET_STORE_LOCATIONS:
      return {
        ...state,
        storeLocationInformation: payload.storeLocationInformation,
      };
    default:
      return state;
  }
};

export default appReducer;
