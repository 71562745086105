export const logoutStyles = () => {
  return {
    logoutContainer: {
      display: 'flex',
      cursor: 'pointer',
      marginTop: '0.3em',
    },
    logoutText: {
      fontSize: '1.2em',
    },
  };
};
