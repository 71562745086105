import { useOktaAuth } from '@okta/okta-react';
import { useState, useEffect } from 'react';

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (authState && !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info: any) => {
        delete info.AllGroups;
        delete info.headers;
        delete info.groups;
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  return (
    <div>
      {userInfo && (
        <div>
          <p>Welcome back!</p>
          {userInfo && <pre>{JSON.stringify(userInfo, null, 2)}</pre>}
        </div>
      )}
    </div>
  );
};

export default Home;
