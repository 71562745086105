import {
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CircularProgressWithLabel } from './CircularProgressWithLabel';

import { DeleteForever } from '@mui/icons-material';
import { FileError } from 'react-dropzone';
import { deleteUploadedFile, generateSignedUrl } from 'src/api/apiFile';
import { IFileInformation } from '../types/IFileInformation';

export type SingleFileUploadWithProgressProps = {
  file: File;
  onDelete: (file: File | IFileInformation) => void;
  onUpload: (file: File | IFileInformation, url: string) => void;
  errors?: FileError[];
  attachmentsId?: string;
  previouslyUploadedFile: boolean;
};

const useStyles = () => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
    },
  }
}

export const SingleFileUploadWithProgress: React.FC<
  SingleFileUploadWithProgressProps
> = (props) => {
  const { file, onDelete, errors, attachmentsId, previouslyUploadedFile } =
    props;
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);


  const handleDelete = async (file: File | IFileInformation) => {
    onDelete(file);
    const { name } = file;
    deleteUploadedFile(name, attachmentsId);
  };

  useEffect(() => {
    if (errors?.length) {
      setLoading(false);
      return;
    }

    async function upload() {
      const { name } = file;
      const { signedUrl } = await generateSignedUrl(
        name,
        'write',
        attachmentsId
      );
      const responseUrl = await uploadFile(signedUrl, file, setProgress);
      setLoading(false);
      console.info('responseUrl    ', responseUrl);
      // onUpload(file, responseUrl);
    }

    if (!previouslyUploadedFile) {
      upload();
    }
  }, []);

  return (
    <Grid item>
      <div style={classes.root}>
        <div style={{position: 'relative'}}>
          {!loading || previouslyUploadedFile ? (
            <IconButton
              aria-label="delete"
              size="small"
              style={{
                padding: '8px 7px 7px',
                transitionDelay: '500ms',
              }}
              color="secondary"
              onClick={() => handleDelete(file)}
            >
              <DeleteForever />
            </IconButton>
          ) : (
            <CircularProgressWithLabel value={progress} />
          )}
        </div>
        <div style={{position: 'relative'}}>
          <Typography color={errors?.length ? 'error' : 'initial'}>
            {file.name}
          </Typography>
        </div>
      </div>
      {errors?.map((error) => (
        <div key={error.code}>
          <Typography color="error">{error.message}</Typography>
        </div>
      ))}
    </Grid>
  );
};

export const uploadFile = (
  url: string,
  file: File,
  onProgress: (percentage: number) => void
) => {
  return new Promise<string>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);

    xhr.onload = () => resolve(xhr?.responseURL);
    xhr.onerror = (evt) => reject(evt);
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;
        onProgress(Math.round(percentage));
      }
    };

    xhr.send(file);
  });
};
