import IconSelector from '../Chip/IconSelector';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import { notificationWithRedCircleStyles } from './notificationWithRedCircleStyles';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

const NotificationWithRedCircle = () => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const classes = notificationWithRedCircleStyles(userPreference.colorMode);

  return (
    <div
      style={classes.notifContainer}
      aria-label={'Notification with red circle'}
    >
      <div style={classes.redCircle} />
      {IconSelector(IconEnum.Notification)}
    </div>
  );
};

export default NotificationWithRedCircle;
