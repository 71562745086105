import { groups } from 'src/context/constants';
import { useEffect, useState } from 'react';

type userInfoType = {
  SiteLineGroups: string[];
};
const useFetchUserGroup = (userInfo: userInfoType) => {
  const [userGroup, setUserGroup] = useState<string>('');
  const { SUPER_GABE, GABE, JAN, MICHAEL, STANLEY } = groups;
  useEffect(() => {
    if (userInfo?.SiteLineGroups) {
      const { SiteLineGroups } = userInfo;
      const getUserGroup = (): string => {
        switch (true) {
          case SiteLineGroups?.includes(SUPER_GABE):
            return SUPER_GABE;
          case SiteLineGroups?.includes(GABE):
            return GABE;
          case SiteLineGroups?.includes(JAN):
            return JAN;
          case SiteLineGroups?.includes(MICHAEL):
            return MICHAEL;
          case SiteLineGroups?.includes(STANLEY):
            return STANLEY;
          default:
            return STANLEY;
        }
      };
      setUserGroup(getUserGroup);
    }
  }, [userInfo]);
  return userGroup;
};

export default useFetchUserGroup;
