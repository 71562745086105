import { env } from '../envConfig';
import { TaskHistoryObj } from '../components/Modal/ActivityHistoryModal/TaskHistoryModal';
import { getDataWithOktaTokens } from './apiUtils';

export const getActivityHistoryListByTaskId = async (
  task_id: string,
  store_number?: string
): Promise<TaskHistoryObj[] | null> => {
  try {
    if (store_number) {
      const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}/history/?store_number=${store_number}`;
      return getDataWithOktaTokens(url);
    } else {
      const url = `${env.REACT_APP_BACKEND_URL}/api/v1/tasks/${task_id}/history`;
      return getDataWithOktaTokens(url);
    }
  } catch (error) {
    return null;
  }
};
