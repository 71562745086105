import { CalendarEvent } from '../components/types/Calendar/ICalendarIndex';
import { TaskApiResponse } from '../models/apiV1TaskResponses';

export const TaskToCalendarEvent = (task: TaskApiResponse): CalendarEvent => {
  /* istanbul ignore next */
  return {
    id: task.id,
    title: task.title,
    start_date: new Date(task.start_date),
    end_date: new Date(task.end_date),
    task_type: task.task_type,
    distro_file_information: task.distro_file_information
      ? [task.distro_file_information]
      : [],
    task_status: task.task_status,
    store_scheduled_start_date:
      task.store_scheduled_start_date !== null &&
      task.store_scheduled_start_date !== undefined
        ? new Date(task.store_scheduled_start_date)
        : undefined,
    store_scheduled_end_date:
      task.store_scheduled_end_date !== null &&
      task.store_scheduled_end_date !== undefined
        ? new Date(task.store_scheduled_end_date)
        : undefined,
    number_of_stores: task.number_of_stores,
    completion_status: task.store_completion_status,
    orderIndex: task.orderIndex ? task.orderIndex : '99',
    uc_event: task.uc_event,
    store_number_count: task.store_number_count,
    region_name: task.region_name,
  };
};
