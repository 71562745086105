import { theme } from '../../../assets/siteLineTheme';

export const easyPrintScreenStyles = () => {
    return {
        easyPrintBackground: {
            backgroundColor: theme.colorPalette.plainWhite,
            minHeight: '100vh',
            width: '100%',
            position: 'absolute',
        },
        buttonRow: {
            display: 'flex',
        },
        buttonRowThird: {
            width: '33.3%',
            display: 'flex',
            alignItems: 'center',
        },
        buttonRowLeft: {
            justifyContent: 'left',
        },
        buttonRowCenter: {
            justifyContent: 'center',
        },
        buttonRowRight: {
            justifyContent: 'right',
        },
        monthTitleText: {
            fontSize: '1.25em',
        },
    }
}
