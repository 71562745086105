import { min, max } from 'd3-array';
import { timeFormat } from 'd3-time-format';
// import { addDays } from 'date-fns';
import { CalendarEvent } from '../components/types/Calendar/ICalendarIndex';

export const day = timeFormat('%w');
export const month = timeFormat('%B');
export const monthIndex = timeFormat('%m');
export const year = timeFormat('%Y');
export const dayOfMonth = timeFormat('%d');
export const monthDayYear = timeFormat('%x');

export type EasyPrintDateObjProps = {
  formattedDate: string;
  rawDate: Date;
  taskArray: Array<CalendarEvent>;
};

export const earliestDate = (rawEventData: Array<CalendarEvent>) =>
  min(rawEventData.map((d: any) => d.start_date));

export const latestDate = (rawEventData: Array<CalendarEvent>) =>
  max(rawEventData.map((d: any) => d.end_date));

export const easyPrintDataSculptor = (
  rawTaskData: Array<CalendarEvent>,
  selectedMonth: number,
  selectedYear: number
) => {
  const dataFilterForSelectedMonth: Array<CalendarEvent> = rawTaskData.filter(
    (task: CalendarEvent) => {
      const taskStartMonth = task.start_date
        ? monthIndex(task.start_date)
        : '0';
      const taskStartYear = task.start_date ? year(task.start_date) : '0';
      return (
        parseInt(taskStartMonth) === selectedMonth + 1 &&
        parseInt(taskStartYear) === selectedYear
      );
    }
  );
  const returnData: Array<EasyPrintDateObjProps> = [];
  const firstDate = new Date(selectedYear, selectedMonth, 1);
  const currentDateIndex = firstDate;
  const formatDate = timeFormat('%Y/%m/%d');

  do {
    const dateObj: EasyPrintDateObjProps = {
      formattedDate: formatDate(currentDateIndex),
      rawDate: new Date(currentDateIndex),
      taskArray: [],
    };
    // dateObj.formattedDate = formatDate(currentDateIndex);
    // dateObj.rawDate = new Date(currentDateIndex);
    dateObj.taskArray = dataFilterForSelectedMonth.filter(
      (task: CalendarEvent) => {
        const taskDayOfMonthIndex = task.start_date
          ? dayOfMonth(task.start_date)
          : '0';
        const currentDateOfTheMonthIndex = dayOfMonth(currentDateIndex);
        return (
          parseInt(taskDayOfMonthIndex) === parseInt(currentDateOfTheMonthIndex)
        );
      }
    );
    returnData.push(dateObj);
    currentDateIndex.setDate(currentDateIndex.getDate() + 1);
  } while (parseInt(monthIndex(currentDateIndex)) - 1 === selectedMonth);

  return returnData;
};
// todo -use in future data visualizations
// export const heatmapDataSculptor = (rawEventData: Array<TaskApiResponse>) => {
//   const returnData: any = [];
//   let currentDate = earliestDate(rawEventData);
//   const formatDate = timeFormat('%Y/%m/%d');
//
//   do {
//     const dateObj: any = {};
//     dateObj.formattedDate = formatDate(currentDate);
//     dateObj.rawDate = currentDate;
//     dateObj.totalEventCount = 0;
//     // todo - generate these dynamically? (if this is the obj getting passed elsewhere, like a histogram for the day, these fields cannot be undefined)
//     dateObj.Sephora = 0;
//     dateObj.Merchandising = 0;
//     dateObj.Visual = 0;
//     dateObj.Operations = 0;
//     dateObj['Pricing & Signing'] = 0;
//     dateObj['Loss Prevention'] = 0;
//     dateObj['Marketing Promotions'] = 0;
//     dateObj.Training = 0;
//     dateObj.Event = 0;
//
//     const eventsForCurrentDay = rawEventData.filter((event: any) => {
//       return event.start <= currentDate && event.end >= currentDate;
//     });
//
//     eventsForCurrentDay.forEach((event: any) => {
//       dateObj[event.task_type]++;
//       dateObj.totalEventCount++;
//     });
//
//     returnData.push(dateObj);
//
//     const nextDate = addDays(currentDate, 1); // advances loop to next day
//     currentDate = nextDate;
//   } while (currentDate < latestDate(rawEventData));
//
//   return returnData;
// };

// todo -use in future data visualizations
// export const singleMonthDataSculptor = (
//   sculptedData: any,
//   selectedMonth: number,
//   selectedYear: number
// ) => {
//   return sculptedData.filter(
//     (node: any) =>
//       node.rawDate.getMonth() === selectedMonth &&
//       node.rawDate.getFullYear() === selectedYear
//   );
// };
//
// todo -use in future data visualizations
// export const findFullEventDataForSelectedDate = (
//   selectedDate: any,
//   rawEventData: any[]
// ) => {
//   return rawEventData.filter((event: any) => {
//     return event.start <= selectedDate && event.end >= selectedDate;
//   });
// };

// todo -use in future data visualizations
// export const calcFieldExtent = (eventsData: any, selectedEventType: string) => {
//   return extent(eventsData, (d: any) => d[selectedEventType]);
// };

// todo -use in future data visualizations
// export const getSingleMonthData = (formattedTaskData: any, selectedMonth: number, selectedYear: number) => {
//     return singleMonthDataSculptor(formattedTaskData, selectedMonth, selectedYear)
// }
