import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import React, { useEffect, useState } from 'react';
import { Box, InputLabel } from '@mui/material';
import {TextEditorDiv} from "../Forms/styles/divStyles";

const toolbarOptions = [
  [{ size: [] }, 'bold', 'italic', 'underline'],
  [
    { list: 'ordered' },
    { list: 'bullet' },
    { align: [] },
    { indent: '-1' },
    { indent: '+1' },
  ],
  ['link', 'video'],
  ['clean'],
];

export type TextEditorProps = {
  formMode?: string;
  delta?: any;
  editorContents: React.MutableRefObject<{} | undefined>;
  label?: string;
  editorId?: string;
  name?: string;
};

export const TextEditor: React.FC<TextEditorProps> = (props) => {
  const [editor, setEditor] = useState<Quill>();
  const {
    formMode,
    delta,
    editorContents,
    label,
    editorId = 'taskEventEditor',
  } = props;

  let quill: Quill;
  useEffect(() => {
    quill = new Quill(`#${editorId}`, {
      modules: {
        toolbar: formMode !== 'view' ? toolbarOptions : false,
      },
      placeholder: 'Add task description...',
      theme: 'snow',
      readOnly: formMode === 'view',
    });

    setEditor(quill);
  }, [formMode]);

  useEffect(() => {
    editor?.setContents(delta);
    editorContents.current = editor?.getContents();
  }, [editor, formMode, delta]);

  useEffect(() => {
    if (formMode === 'view') {
      editor?.disable();
    } else {
      editor?.enable(true);
    }
    editor?.on('text-change', () => {
      editorContents.current = editor?.getContents();
    });
  }, [editor, formMode]);

  return (
    <Box style={{ height: '100%' }}>
      {formMode !== 'view' ? (
        <InputLabel style={{ marginBottom: 10 }}>{label}</InputLabel>
      ) : null}
      <TextEditorDiv
        id={editorId}
        formMode={formMode}
      />
    </Box>
  );
};
