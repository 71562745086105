import React from 'react';
import {
  DigitalMerchandisingLinkTypeEnum,
  NavigationObj,
} from '../../utils/testData/mockHierarchyData';
import IconButtonWithLabel from '../Buttons/IconButtonWithLabel';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import { theme } from '../../assets/siteLineTheme';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import { digitalMerchandisingCreateStyles } from './styles/digitalMerchandisingCreateStyles';
import { generalStyles } from './styles/generalStyles';
import { IconButton } from '../Buttons/IconButton';
import ToggleIcon from '../Toggle/ToggleIcon';
import confirmModalStore from '../../stores/confirmModalStore';
import { DialogModalSwitchEnum } from '../Modal/DialogModalSwitch';
import baseModalStore from '../../stores/baseModalStore';
import { FileUploadField } from '../FileUpload/FileUploadField';
import { Form, Formik } from 'formik';
import {
  NewMerchMenuObjPDFType,
  updateMerchMenuFileInfo,
} from '../../api/apiDigitalMerchandising';
import { v4 as uuidv4 } from 'uuid';
import { BsCheckLg } from 'react-icons/bs';
import DigitalMerchandisingFormTypeSelection from "./DigitalMerchandisingFormTypeSelection";

export type DigitalMerchandisingCreateProps = {
  navigationObjList: NavigationObj[];
  setIsCreate: Function;
  handlerChange: Function;
  handlerButtonClick: Function;
  handlerMenuClick: Function;
  clearNavigationArrays: Function;
};

export type DigitalMerchandisingObj = {
  data: NavigationObj[];
  selectItem: NavigationObj | undefined;
  isAdd: boolean;
  isModify: boolean;
  parentName: string;
  nameValue: string;
  currentSelectedId: string;
  pdf_file_information?: NewMerchMenuObjPDFType;
};

export type FileObj = {
  attachmentId: string;
  isShowDropZone: boolean;
  previousFile: NewMerchMenuObjPDFType | undefined;
};

const DigitalMerchandisingCreate: React.FC<DigitalMerchandisingCreateProps> = (
  props
) => {
  const {
    setIsCreate,
    handlerChange,
    handlerButtonClick,
    handlerMenuClick,
    navigationObjList,
    clearNavigationArrays,
  } = props;

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const [digitalObj, setDigitalObj] = React.useState<DigitalMerchandisingObj>({
    data: navigationObjList,
    selectItem: undefined,
    isAdd: false,
    isModify: false,
    parentName: '',
    nameValue: '',
    currentSelectedId: '',
  });

  const [fileObj, setFileObj] = React.useState<FileObj>({
    attachmentId: '',
    isShowDropZone: true,
    previousFile: undefined,
  });

  // const desktopMode = window.innerWidth > 420;

  const generalClasses = generalStyles();

  const { setConfirmModal } = confirmModalStore(
    (state: { setConfirmModal: Function }) => ({
      setConfirmModal: state.setConfirmModal,
    })
  );
  const { setBaseModalContent, resetBaseModalContent } = baseModalStore(
    (state: {
      setBaseModalContent: Function;
      resetBaseModalContent: Function;
    }) => ({
      setBaseModalContent: state.setBaseModalContent,
      resetBaseModalContent: state.resetBaseModalContent,
    })
  );

  const deleteModal = () => {
    setBaseModalContent({
      isActive: true,
      width: window.innerWidth > 420 ? 0.5 : 0.8,
      height: window.innerWidth > 420 ? 0.1 : 0.2,
      dialogModalSwitchEnum: DialogModalSwitchEnum.ConfirmDeleteComment,
    });
    setConfirmModal({
      message: 'Are you sure you want to delete this menu?',
      confirmButtonText: 'Delete',
      confirmOnClickAction: () => deleteMenu(),
    });
  };

  const deleteMenu = () => {
    handlerButtonClick({
      digitalObj,
      setDigitalState: setDigitalObj,
      type: 'Delete',
    });
    resetBaseModalContent();
  };

  const resetDigitalObject = () => {
    handlerButtonClick({
      digitalObj,
      setDigitalState: setDigitalObj,
      type: 'Cancel',
    });
  };

  const removeEmptyMenu = () => {
    const newDigitalObj = digitalObj.data.filter(
      (navObj) => navObj.title !== ''
    );
    digitalObj.data = newDigitalObj;
    setDigitalObj(digitalObj);
  };

  const findFolderNameToAddIn = () => {
    const menu = digitalObj.data.find(
      (element) => element.code === digitalObj.currentSelectedId
    );
    if (menu !== undefined) {
      return menu.menu_path;
    } else return '';
  };

  const handleSubmit = (values: any) => {
    if (values.attachment_information[0] === undefined) {
      window.alert('Please attach Pdf file.');
    } else {
      const { name, size, type } = values.attachment_information[0];

      if (digitalObj.selectItem) {
        digitalObj.selectItem.pdf_file_information = {
          name,
          size,
          attachments_id: fileObj.attachmentId,
          type,
        };
        digitalObj.selectItem.link_type = DigitalMerchandisingLinkTypeEnum.Pdf;
        setDigitalObj(digitalObj);
      }
      handlerButtonClick({
        digitalObj,
        setDigitalState: setDigitalObj,
        type: 'Save',
      });
    }
  };

  const callbackFileObjFunc = async () => {
    setFileObj({
      attachmentId: uuidv4(),
      isShowDropZone: true,
      previousFile: undefined,
    });
    if (digitalObj.selectItem?.code)
      await updateMerchMenuFileInfo(digitalObj.selectItem?.code);
  };

  return (
    <>
      <div style={generalClasses.titleContainer}> Menu Management </div>




      <div className={'screenContainer'} style={generalClasses.screenContainer}>

        <div className={'menuContainer'} style={generalClasses.menuContainer}>
          {digitalObj.data.map((menu: NavigationObj) => {
            const digitalMerchandisingCreateClasses =
              digitalMerchandisingCreateStyles({
                level: menu.level,
                isActive: menu.code === digitalObj.currentSelectedId,
                colorMode: userPreference.colorMode,
              });
            return (
              <div
                key={menu.id}
                aria-label={menu.id}
                onClick={() => {
                  if (digitalObj.currentSelectedId === menu.code) {
                    setDigitalObj({
                      ...digitalObj,
                      selectItem: undefined,
                      currentSelectedId: '',
                    });
                    setFileObj({
                      attachmentId: '',
                      isShowDropZone: true,
                      previousFile: undefined,
                    });
                  } else {
                    if (digitalObj.isAdd || digitalObj.isModify) {
                      window.alert(
                        'Please click SAVE or CANCEL button before attempting to add or edit other menu.'
                      );
                    } else {
                      handlerMenuClick({
                        digitalObj,
                        paramMenu: menu,
                        setDigitalState: setDigitalObj,
                      });
                      setFileObj({
                        attachmentId: menu.pdf_file_information?.attachments_id
                          ? menu.pdf_file_information?.attachments_id
                          : '',
                        isShowDropZone: !menu.pdf_file_information,
                        previousFile: menu.pdf_file_information,
                      });
                    }
                  }
                }}
                style={digitalMerchandisingCreateClasses.menuItemContainer}
              >
                {menu.url !== null &&
                  (menu.link_type === 'Flipping' ||
                    menu.link_type === 'GSite') && (
                    <div
                      style={digitalMerchandisingCreateClasses.iconContainer}
                    >
                      <IconButton
                        icon={IconEnum.Book}
                        onClick={() => null}
                        label={'Book'}
                        color={
                          theme.newColorPalette.plainBlackWhite[
                            userPreference.colorMode
                          ]
                        }
                      />
                    </div>
                  )}
                {menu.link_type !== null && menu.link_type === 'Pdf' && (
                  <div style={digitalMerchandisingCreateClasses.iconContainer}>
                    {menu.pdf_file_information?.type === 'video/mp4' ? (
                      <IconButton
                        icon={IconEnum.Video}
                        onClick={() => null}
                        label={'Video'}
                        color={
                          theme.newColorPalette.plainBlackWhite[
                            userPreference.colorMode
                          ]
                        }
                      />
                    ) : (
                      <IconButton
                        icon={IconEnum.Pdf}
                        onClick={() => null}
                        label={'Pdf'}
                        color={
                          theme.newColorPalette.plainBlackWhite[
                            userPreference.colorMode
                          ]
                        }
                      />
                    )}
                  </div>
                )}
                {decodeURIComponent(menu.title)}
              </div>
            );
          })}
        </div>
        {/* form selections */}

        <div>
          {/* TODO - Find this */}
          <DigitalMerchandisingFormTypeSelection
           clearNavigationArrays={clearNavigationArrays}
           deleteModal={deleteModal}
           digitalObj={digitalObj}
           handlerButtonClick={handlerButtonClick}
           removeEmptyMenu={removeEmptyMenu}
           setDigitalObj={setDigitalObj}
           setIsCreate={setIsCreate}
           userPreference={userPreference}
          />

          {/* edit menu stuff */}
          <div style={generalClasses.digitalMerchChangeForm}>
            {(digitalObj.isAdd || digitalObj.isModify) && digitalObj.selectItem && (
                <div style={generalClasses.formInputsContainer}>
                  <div>
                    <div style={generalClasses.formTitle}>
                      {digitalObj.isModify ? (
                          <div>
                            Modify in : {decodeURIComponent(digitalObj.parentName)}{' '}
                          </div>
                      ) : (
                          <div>
                            Add in : {decodeURIComponent(findFolderNameToAddIn())}
                          </div>
                      )}
                    </div>
                    <label
                        htmlFor={'Name of Navigation Item'}
                        style={generalClasses.textInputItem}
                    >
                      Name:*
                      <input
                          aria-label={'NameInputBox'}
                          type={'text'}
                          value={decodeURIComponent(digitalObj.nameValue)}
                          placeholder={'Please input Name...'}
                          onChange={(e) => {
                            handlerChange({
                              e,
                              setValue: setDigitalObj,
                              digitalObj,
                              type: 'title',
                            });
                          }}
                      />
                    </label>
                    {(digitalObj.selectItem.link_type === null ||
                        digitalObj.selectItem.link_type === undefined) && (
                        <label
                            htmlFor={'Is this menu active'}
                            style={generalClasses.toggleContainer}
                        >
                          Make this menu active:
                          <ToggleIcon
                              isActive={
                                digitalObj.selectItem.is_active === undefined
                                    ? false
                                    : digitalObj.selectItem.is_active
                              }
                              activeBarBackgroundColor={
                                theme.newColorPalette.buttons
                                    .commentLightSubmitDarkGrey[userPreference.colorMode]
                              }
                              passiveBarBackgroundColor={
                                theme.newColorPalette.zoidbergLightBlack[
                                    userPreference.colorMode
                                    ]
                              }
                              activeCircleBackgroundColor={
                                theme.newColorPalette.buttons.commentDarkSubmitBlack[
                                    userPreference.colorMode
                                    ]
                              }
                              passiveCircleBackgroundColor={
                                theme.newColorPalette.zoidbergBlack[
                                    userPreference.colorMode
                                    ]
                              }
                              name={'Is Active'}
                              width={'3em'}
                              onClickFunc={() => {
                                handlerChange({
                                  e: digitalObj.selectItem?.is_active,
                                  setValue: setDigitalObj,
                                  digitalObj,
                                  type: 'is_active',
                                });
                              }}
                          />
                        </label>
                    )}
                    <label
                        htmlFor={'Link Type'}
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <legend>Select a Link Type:</legend>
                      <div
                          style={{ display: 'flex', marginLeft: '0.5em' }}
                          aria-label={'Link Type'}
                      >
                        <div>
                          <input
                              aria-label={'folderLink'}
                              type="radio"
                              id="folderLink"
                              name="drone"
                              value={undefined}
                              checked={
                                  digitalObj.selectItem.link_type === null ||
                                  digitalObj.selectItem.link_type === undefined
                              }
                              onChange={(e) => {
                                handlerChange({
                                  e,
                                  setValue: setDigitalObj,
                                  digitalObj,
                                  type: 'link_type',
                                });
                              }}
                          />
                          <label htmlFor="folderLink">Folder</label>
                        </div>
                        <div>
                          <input
                              aria-label={'flippingbook'}
                              type="radio"
                              id="flippingbook"
                              name="drone"
                              value={DigitalMerchandisingLinkTypeEnum.Flipping}
                              checked={
                                  digitalObj.selectItem.link_type ===
                                  DigitalMerchandisingLinkTypeEnum.Flipping
                              }
                              onChange={(e) => {
                                handlerChange({
                                  e,
                                  setValue: setDigitalObj,
                                  digitalObj,
                                  type: 'link_type',
                                });
                              }}
                          />
                          <label htmlFor="flippingbook">Flipping Book</label>
                        </div>
                        <div>
                          <input
                              aria-label={'gsite'}
                              type="radio"
                              id="gsite"
                              name="drone"
                              value={DigitalMerchandisingLinkTypeEnum.GSite}
                              checked={
                                  digitalObj.selectItem.link_type ===
                                  DigitalMerchandisingLinkTypeEnum.GSite
                              }
                              onChange={(e) =>
                                  handlerChange({
                                    e,
                                    setValue: setDigitalObj,
                                    digitalObj,
                                    type: 'link_type',
                                  })
                              }
                          />
                          <label htmlFor="gsite">Google Site</label>
                        </div>
                        <div>
                          <input
                              aria-label={'pdf'}
                              type="radio"
                              id="pdf"
                              name="drone"
                              value={DigitalMerchandisingLinkTypeEnum.Pdf}
                              checked={
                                  digitalObj.selectItem.link_type ===
                                  DigitalMerchandisingLinkTypeEnum.Pdf
                              }
                              onChange={(e) => {
                                setFileObj(
                                    digitalObj.selectItem?.pdf_file_information
                                        ? {
                                          attachmentId:
                                          digitalObj.selectItem?.pdf_file_information
                                              .attachments_id,
                                          isShowDropZone: false,
                                          previousFile:
                                          digitalObj.selectItem?.pdf_file_information,
                                        }
                                        : {
                                          attachmentId: uuidv4(),
                                          isShowDropZone: true,
                                          previousFile: undefined,
                                        }
                                );
                                handlerChange({
                                  e,
                                  setValue: setDigitalObj,
                                  digitalObj,
                                  type: 'link_type',
                                });
                              }}
                          />
                          <label htmlFor="pdf">PDF/MP4</label>
                        </div>
                      </div>
                    </label>
                    {(digitalObj.selectItem.link_type !== undefined ||
                        digitalObj.selectItem.link_type !== null) &&
                    (digitalObj.selectItem.link_type ===
                        DigitalMerchandisingLinkTypeEnum.Flipping ||
                        digitalObj.selectItem.link_type ===
                        DigitalMerchandisingLinkTypeEnum.GSite) ? (
                        <label
                            htmlFor={'Url if necessary'}
                            style={generalClasses.textInputItem}
                        >
                          URL:
                          <input
                              aria-label={'UrlInputBox'}
                              type={'text'}
                              value={
                                digitalObj.selectItem.url === null
                                    ? undefined
                                    : digitalObj.selectItem.url
                              }
                              onChange={(e) =>
                                  handlerChange({
                                    e,
                                    setValue: setDigitalObj,
                                    digitalObj,
                                    type: 'url',
                                  })
                              }
                          />
                        </label>
                    ) : digitalObj.selectItem.link_type ===
                    DigitalMerchandisingLinkTypeEnum.Pdf ? (
                        <Formik
                            initialValues={{ attachment_information: {} }}
                            onSubmit={handleSubmit}
                        >
                          <Form id={'fileForm'}>
                            <FileUploadField
                                aria-label={'attachment_information'}
                                name={'attachment_information'}
                                fileTypes={['.pdf', '.mp4']}
                                fieldText={{
                                  fieldTitle: 'drop attachments here',
                                  fieldSubTitle: 'or click to add files',
                                }}
                                maxNumOfUploadableFiles={1}
                                cumulativeFileSize={130 * 1000 * 1024}
                                attachmentsId={fileObj.attachmentId}
                                previouslyUploadedFiles={
                                  fileObj.previousFile
                                      ? [
                                        {
                                          name: fileObj.previousFile.name,
                                          type: fileObj.previousFile.type,
                                          size: fileObj.previousFile.size,
                                          previouslyUploadedFile: true,
                                        },
                                      ]
                                      : []
                                }
                                isShowDropZone={fileObj.isShowDropZone}
                                callbackFunc={callbackFileObjFunc}
                            />
                          </Form>
                        </Formik>
                    ) : (
                        <></>
                    )}
                  </div>
                  <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                  >
                    <IconButtonWithLabel
                        label={'Cancel'}
                        buttonLabel={'Cancel'}
                        icon={IconEnum.Reset}
                        onClick={() => {
                          resetDigitalObject();
                        }}
                        textColor={
                          theme.newColorPalette.textDarkBlack[
                              userPreference.colorMode
                              ]
                        }
                        borderRadius={'1em'}
                        margin={'1em'}
                        color={
                          theme.newColorPalette.plainWhiteBlack[
                              userPreference.colorMode
                              ]
                        }
                        backgroundColor={
                          theme.newColorPalette.zoidbergGrey[userPreference.colorMode]
                        }
                    />
                    {digitalObj.selectItem.link_type === 'Pdf' ? (
                        <button
                            data-testid="submit-button"
                            type={'submit'}
                            form={'fileForm'}
                            style={{
                              margin: '1em',
                              borderRadius: '1em',
                              backgroundColor:
                                  theme.newColorPalette.backgroundAddTaskIconDarkBlack[
                                      userPreference.colorMode
                                      ],
                              color:
                                  theme.newColorPalette.plainBlackWhite[
                                      userPreference.colorMode
                                      ],
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                        >
                          <div style={{ color: theme.colorPalette.plainWhite }}>
                            <BsCheckLg />
                          </div>
                          <div style={{ paddingLeft: '0.5em', fontSize: '1.15em' }}>
                            {'SAVE'}{' '}
                          </div>
                        </button>
                    ) : (
                        <IconButtonWithLabel
                            label={'Save'}
                            buttonLabel={'Save'}
                            icon={IconEnum.Check}
                            onClick={() => {
                              handlerButtonClick({
                                digitalObj,
                                setDigitalState: setDigitalObj,
                                type: 'Save',
                              });
                            }}
                            textColor={
                              theme.newColorPalette.plainBlackWhite[
                                  userPreference.colorMode
                                  ]
                            }
                            borderRadius={'1em'}
                            margin={'1em'}
                            color={theme.colorPalette.plainWhite}
                            backgroundColor={
                              theme.newColorPalette.backgroundAddTaskIconDarkBlack[
                                  userPreference.colorMode
                                  ]
                            }
                        />
                    )}
                  </div>
                </div>
            )}
          </div>



        </div>
        {/* end selector */}


      </div>
    </>
  );
};

export default DigitalMerchandisingCreate;
