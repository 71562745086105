import { theme } from '../../assets/siteLineTheme';
import { SelectionButtonProps } from '../types/ISelectionButton';
import userPreferenceStore, {
  ColorModeTypeEnum,
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

import React from 'react';
import BaseButton from './BaseButton';

const SelectionButton: React.FC<SelectionButtonProps> = (props) => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const { displayText, clickHandler, labelText, isSelected, start, end } =
    props;

  const borderRadius = start ? '4px 0 0 4px' : end ? '0 4px 4px 0' : '0';
  const borderWidth =
    userPreference.colorMode !== ColorModeTypeEnum.BlackWhite
      ? start
        ? '0 1px 0 0'
        : end
        ? '0 1px 0 0'
        : '0 1px 0 0'
      : start
      ? '1px 1px 1px 0'
      : end
      ? '1px 1px 1px 1px'
      : '1px 1px 1px 0';

  const borderColor =
    theme.newColorPalette.plainGreyBlack[userPreference.colorMode];
  const backgroundColor = isSelected
    ? theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
    : theme.colorPalette.plainWhite;
  const color = isSelected
    ? theme.newColorPalette.textDarkWhite[userPreference.colorMode]
    : theme.colorPalette.plainBlack;

  return (
    <BaseButton
      onClick={clickHandler}
      activeBackgroundColor={backgroundColor}
      passiveBackgroundColor={backgroundColor}
      activeTextColor={color}
      passiveTextColor={color}
      label={labelText}
      borderRadius={borderRadius}
      borderStyle={'solid'}
      borderWidth={borderWidth}
      borderColor={borderColor}
      isSelected={isSelected}
      padding={'5px 13px'}
    >
      {displayText}
    </BaseButton>
  );
};
export default SelectionButton;
