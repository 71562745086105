import { create, SetState } from 'zustand';

const isDialogModalOpenStore = create((set: SetState<any>) => ({
  isDialogModalOpen: false,
  toggleIsDialogModalOpen: () =>
    set((state: any) => ({
      isDialogModalOpen: !state.isDialogModalOpen,
    })),
}));

export default isDialogModalOpenStore;
