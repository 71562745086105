import {
  createFilterOptions,
  InputAdornment,
  Paper
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete, {
} from '@mui/material/Autocomplete';
import { Search } from '@mui/icons-material';
import TaskDropdownOption from './DropdownOptionComponents/TaskDropdownOption';
import GenericDropdownOption from './DropdownOptionComponents/GenericDropdownOption';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../utils/analytics';
import searchBarPropsStore from '../../stores/searchBarPropsStore';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import React from 'react';
import {SearchComponentStyles} from "./styles/searchComponentStyles";

const SearchComponent: React.FC = () => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const { searchBarProps } = searchBarPropsStore(
    (state: { searchBarProps: any }) => ({
      searchBarProps: state.searchBarProps,
    })
  );

  const handleOptionClick = (option: { identifier: any }) => {
    sendAnalyticsEvent({
      ...analyticsEventConstants.SEARCH_FOR_TASK_BY_TITLE,
      searchTaskId: option.identifier,
    });
    searchBarProps.clickHandler(option.identifier);
  };

  const DropdownOptionComponent = (props: any) => {
    const option = props.optionToPass;
    switch (option.optionType) {
      case 'task':
        return (
          <TaskDropdownOption
            option={option}
            classes={SearchComponentStyles(userPreference.colorMode)}
            onClick={() => handleOptionClick(option)}
            key={option.identifier}
          />
        );
      default:
        return (
          <GenericDropdownOption
            option={option}
            classes={SearchComponentStyles(userPreference.colorMode)}
            key={option.identifier}
          />
        );
    }
  };

  const AutoCompleteStyling = (props: any) => {
    return (
      <Paper
        elevation={8}
        {...props}
        sx={SearchComponentStyles(userPreference.colorMode).autoCompleteBasePaper}
      />
    );
  };

  const OPTIONS_LIMIT = 20;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  return (
    <Autocomplete
      aria-label={'Enter a task title to search'}
      sx={SearchComponentStyles(userPreference.colorMode).autoCompleteComponent}
      filterOptions={(options, state) => filterOptions(options, state)}
      options={searchBarProps.options}
      getOptionLabel={(option:any) => option.title || ''}
      // onInputChange={handleOnChange}
      renderInput={(params) => (
        <TextField
          {...params}
          hiddenLabel
          variant="filled"
          size={'small'}
          aria-label={'Search Function for Current Screen'}
          InputProps={{
            ...params.InputProps,
            style: { padding: '6px 0px 6px 6px' },
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
        />
      )}
      // onChange={() => handleOnChange}
      PaperComponent={AutoCompleteStyling}
      renderOption={(props, renderOption: any) => (
        <DropdownOptionComponent
          key={renderOption.identifier}
          optionToPass={renderOption}
        />
      )}
    />
  );
};

export default SearchComponent;
