import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { generateSignedUrl } from 'src/api/apiFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ListIcon from '@mui/icons-material/List';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import MovieIcon from '@mui/icons-material/Movie';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../utils/analytics';
import { IFileInformation } from '../types/IFileInformation';
import ContentPopOver from '../Modal/ContentPopOverModal/ContentPopOver';
import { attachmentsStyles } from './attachmentsStyles';

export type FileObjects = {
  fileName: string;
  signedUrl: string;
};

type AttachmentsProps = {
  name: string;
  files: IFileInformation[] | undefined;
  attachmentsId: string | undefined;
  header?: string;
};

export const Attachments: React.FC<AttachmentsProps> = (props) => {
  const { name, files, attachmentsId, header } = props;
  const attachmentClasses = attachmentsStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState('');
  const [selectedAttachmentUrl, setSelectedAttachmentUrl] = useState('');

  const fileTypeIdentifier = (fileName: any) => {
    return fileName.split('.').pop();
  };

  const FileIcon = (props: any) => {
    const { fileName } = props;
    const fileType = fileTypeIdentifier(fileName);
    const iconColor = 'primary';
    switch (fileType) {
      case 'jpeg':
      case 'jpg':
      case 'png':
        return <ImageIcon color={iconColor} />;
      case 'mov':
      case 'mp4':
        return <MovieIcon color={iconColor} />;
      case 'csv':
      case 'xlsx':
      case 'xls':
        return <ListIcon color={iconColor} />;
      case 'pdf':
        return <PictureAsPdfIcon color={iconColor} />;
      case 'docx':
        return <DescriptionIcon color={iconColor} />;
      default:
        return <FileCopyIcon color={iconColor} />;
    }
  };

  const handleListItemClick = async (fileName: string) => {
    const { signedUrl } = await generateSignedUrl(
              fileName,
              'read',
              attachmentsId
            );
    sendAnalyticsEvent({
      ...analyticsEventConstants.VIEW_ATTACHMENT,
      attachmentType: fileName.split('.').pop(),
    });

    const fileType = fileTypeIdentifier(fileName);

    if (['csv', 'xls', 'xlsx', 'doc', 'docx'].includes(fileType)) {
      window.open(signedUrl, '_blank');
    } else {
      setSelectedAttachmentUrl(signedUrl);
      setSelectedFileType(fileTypeIdentifier(fileName));
      setIsOpen(true);
    }
  };

  return (
    <>
      <ContentPopOver
        isOpen={isOpen}
        contentId="attachmentModalIframe"
        contentUrl={selectedAttachmentUrl}
        contentType={selectedFileType}
        handleClose={() => setIsOpen(false)}
      />
      {files?.length !== 0 && (
        <>
          {header && <Typography component="p">{header}</Typography>}
          <List aria-label={`${name}-attachment-section`}>
            {files && files.map((file, index: number) => {
              return (
                <ListItem
                  key={file.name + index}
                  style={attachmentClasses.root}
                  disableGutters
                  button
                  onClick={() => handleListItemClick(file.name)}
                >
                  <ListItemIcon style={attachmentClasses.listItemIconRoot}>
                    <FileIcon fileName={file.name} />
                  </ListItemIcon>
                  <ListItemText primary={file.name} />
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </>
  );
};
