import { completionStatus } from './completionUtils';

export const sortArray = (array: any[], key: string, sortType: string) => {
  return array.sort((a: any, b: any) => {
    if (sortType === 'desc') {
      return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
    } else {
      return b[key] > a[key] ? 1 : a[key] > b[key] ? -1 : 0;
    }
  });
};

export const sortCompletionStatus = (
  array: any[],
  key: string,
  sortType: string
) => {
  return array.sort((a: any, b: any) => {
    const completionStatusA =
      completionStatus(a) === null ? '' : completionStatus(a);
    const completionStatusB =
      completionStatus(b) === null ? '' : completionStatus(b);
    if (sortType === 'desc') {
      return completionStatusA > completionStatusB
        ? 1
        : completionStatusB > completionStatusA
        ? -1
        : 0;
    } else {
      return completionStatusB > completionStatusA
        ? 1
        : completionStatusA > completionStatusB
        ? -1
        : 0;
    }
  });
};

export const columnSortDirection = (
  selectedCol: string,
  tableColumnSortDirection: any
) => {
  let sortDirection = '';
  if (
    tableColumnSortDirection[selectedCol] !== '' &&
    tableColumnSortDirection[selectedCol] === 'desc'
  ) {
    sortDirection = 'asc';
    return sortDirection;
  } else {
    sortDirection = 'desc';
    return sortDirection;
  }
};
