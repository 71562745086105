import { ActiveSpringsArray, SpringTypeEnum } from './AnimatedComponentTypes';
import {
  backgroundColorSpring,
  loopedBackgroundColorSpring,
  textColorSpring,
} from './colorSpringUtils';
import {
  fontSizeSpring,
  heightSpring,
  scaleSpring,
  widthSpring,
} from './sizeSpringUtils';
import { borderRadiusSpring } from './borderSpringUtils';
import { rotateSpring } from './rotateSpringUtils';

export type SpringStylesConstructorParams = {
  springs: ActiveSpringsArray;
  isPassive: boolean;
  loop?: boolean | undefined;
};

export const springStylesConstructor = (
  params: SpringStylesConstructorParams
): object => {
  const { isPassive, springs, loop } = params;
  let springStylesObj: object = {};
  springs.forEach((spring: any) => {
    const springStylesConstructorObj = springStylesConstructorTypeSwitch({
      isPassive,
      spring,
      loop,
    });
    springStylesObj = { ...springStylesObj, ...springStylesConstructorObj };
  });
  return springStylesObj;
};

export type availableSpringParams = {
  springType: SpringTypeEnum;
  activeBackgroundColor?: string;
  passiveBackgroundColor?: string;
  activeTextColor?: string;
  passiveTextColor?: string;
  activeScale?: number;
  passiveScale?: number;
  activeFontSize?: string;
  passiveFontSize?: string;
  activeWidth?: string;
  passiveWidth?: string;
  activeHeight?: string;
  passiveHeight?: string;
  activeBorderRadius?: string;
  passiveBorderRadius?: string;
  activeRotateZ?: number;
  passiveRotateZ?: number;
};

export type SpringStylesConstructorTypeSwitchParams = {
  isPassive: boolean;
  spring: availableSpringParams;
  loop?: boolean | undefined;
};

export const springStylesConstructorTypeSwitch = (
  params: SpringStylesConstructorTypeSwitchParams
) => {
  const { isPassive, spring, loop } = params;

  switch (spring.springType) {
    case SpringTypeEnum.BackgroundColorSpring:
      return backgroundColorSpring(
        isPassive,
        spring.activeBackgroundColor as string,
        spring.passiveBackgroundColor as string
      );
    case SpringTypeEnum.LoopedBackgroundColorSpring:
      return loopedBackgroundColorSpring(
        spring.activeBackgroundColor as string,
        spring.passiveBackgroundColor as string,
        loop
      );
    case SpringTypeEnum.TextColorSpring:
      return textColorSpring(
        isPassive,
        spring.activeTextColor as string,
        spring.passiveTextColor as string
      );
    case SpringTypeEnum.ScaleSpring:
      return scaleSpring(
        isPassive,
        spring.activeScale as number,
        spring.passiveScale as number
      );
    case SpringTypeEnum.FontSizeSpring:
      return fontSizeSpring(
        isPassive,
        spring.activeFontSize as string,
        spring.passiveFontSize as string
      );
    case SpringTypeEnum.HeightSpring:
      return heightSpring(
        isPassive,
        spring.activeHeight as string,
        spring.passiveHeight as string
      );
    case SpringTypeEnum.WidthSpring:
      return widthSpring(
        isPassive,
        spring.activeWidth as string,
        spring.passiveWidth as string
      );
    case SpringTypeEnum.BorderRadiusSpring:
      return borderRadiusSpring(
        isPassive,
        spring.activeBorderRadius as string,
        spring.passiveBorderRadius as string
      );
    case SpringTypeEnum.RotateSpring:
      return rotateSpring(
        isPassive,
        spring.activeRotateZ as number,
        spring.passiveRotateZ as number
      );
    default:
      return {};
  }
};
