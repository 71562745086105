import React, { useState } from 'react';
import { IconContext } from 'react-icons';
import IconSelector from '../Chip/IconSelector';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import { iconButtonStyles } from './styles/iconButtonStyles';
import { AnalyticEventType, sendAnalyticsEvent } from '../../utils/analytics';

export type IconButtonProps = {
  label: string;
  icon: IconEnum;
  onClick: Function;
  color?: string | undefined;
  size?: string | undefined;
  margin?: string | undefined;
  analyticsEvent?: AnalyticEventType | undefined;
  backgroundColor?: string | undefined;
  padding?: string | undefined;
  borderRadius?: string | undefined;
  buttonLabel?: string | undefined;
  passedStyle?: object | undefined;
  isActive?: boolean | undefined;
  textColor?: string | undefined;
  isDisabled?: boolean | undefined;
};

const IconButtonWithLabel: React.FC<IconButtonProps> = (props) => {
  const {
    label,
    icon,
    onClick,
    color,
    size,
    margin,
    analyticsEvent,
    backgroundColor,
    padding,
    borderRadius,
    buttonLabel,
    passedStyle,
    textColor,
    isDisabled,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const iconButtonClasses = iconButtonStyles({
    color,
    size,
    margin,
    backgroundColor,
    padding,
    borderRadius,
    isActive,
    textColor,
    isDisabled
  });

  return (
    <>
      <div
        key={label}
        style={
        isDisabled ? {...iconButtonClasses.disabledStyle} :
          passedStyle !== undefined
            ? { ...passedStyle, ...iconButtonClasses.activeStyle }
            : {
                ...iconButtonClasses.iconWithTextContainer,
                ...iconButtonClasses.activeStyle,
              }
        }
        aria-label={label}
        onClick={() => {
          onClick();
          if (analyticsEvent !== undefined) {
            sendAnalyticsEvent(analyticsEvent);
          }
        }}
        onMouseEnter={() => {
          setIsActive(true);
        }}
        onMouseLeave={() => {
          setIsActive(false);
        }}
      >
        <IconContext.Provider value={iconButtonClasses.iconWithLabel}>
          {IconSelector(icon)}
          <div style={iconButtonClasses.label}>
            {buttonLabel?.toUpperCase()}
          </div>
        </IconContext.Provider>
      </div>
    </>
  );
};

export default IconButtonWithLabel;
