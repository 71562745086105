import { Field } from 'formik';
import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { FormikElementStylesWrapper } from './FormikStyles';
import { format } from 'date-fns';
import React from 'react';
import { TaskApiResponse } from '../../../models/apiV1TaskResponses';

export type FormikFieldProps = {
  name: string;
  label: string;
  helpText?: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
  value?: string;
  values?: TaskApiResponse | { start_date: string; end_date: string };
};

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.23)',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'rgba(0, 0, 0, 0.23)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
  },
})(TextField);

const FormikField: React.FC<FormikFieldProps> = (props) => {
  const {
    name,
    label,
    helpText,
    type = 'text',
    required = false,
    disabled = false,
    maxLength,
    values,
  } = props;

  const getMinMax = () => {
    const returnObj: { min: string | null; max: string | null } = {
      min: null,
      max: null,
    };
    if (values) {
      if (values.start_date === '' && values.end_date === '') {
        returnObj.min = format(new Date(), 'yyyy-MM-dd').toString();
      }

      if (
        values.start_date !== '' &&
        values.end_date === '' &&
        name === 'end_date'
      ) {
        returnObj.min = values.start_date;
      }

      if (
        values.end_date !== '' &&
        values.start_date === '' &&
        name === 'start_date'
      ) {
        returnObj.max = values.end_date;
        returnObj.min = format(new Date(), 'yyyy-MM-dd').toString();
      }
    }

    return returnObj;
  };

  const minMax = getMinMax();

  return (
    <FormikElementStylesWrapper className="FormikField">
      <Field
        id={label.toLocaleLowerCase().replace(' ', '_')}
        required={required}
        autoComplete="off"
        as={CssTextField}
        label={label}
        name={name}
        fullWidth
        type={type}
        helperText={helpText}
        disabled={disabled}
        variant="outlined"
        inputProps={{
          maxLength,
          'aria-label': label,
          role: type === 'date' ? type : null,
          min: type === 'date' ? minMax.min : null,
          max: type === 'date' ? minMax.max : null,
        }}
        InputLabelProps={type === 'date' ? { shrink: true } : null}
      />
    </FormikElementStylesWrapper>
  );
};

export default FormikField;
