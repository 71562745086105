export const taskCompletionDataToolbarStyles = () => {
  return {
    toolbarContainer: {
      display: 'flex',
      flexDirection:
        window.innerWidth > 420 ? ('row' as 'row') : ('column' as 'column'),
      flexWrap: 'wrap' as 'wrap',
      margin: window.innerWidth > 420 ? '0' : '0 1em 0 0',
    },
    dateSelectorsContainer: {
      display: 'flex',
      justifyContent: window.innerWidth > 420 ? 'normal' : 'flex-start',
      flexDirection:
        window.innerWidth <= 420 ? ('row' as 'row') : ('column' as 'column'),
    },
    taskFilterButton: {
      display: 'flex',
      marginLeft: '1em',
    },
    toolBarTitleContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
    },
    titleContainer: {
      display: 'flex',
      // justifyContent: 'space-between',
      margin: '0 0 0.2em 0.5em',
      width: 'auto',
    },
    titleText: {
      fontSize: '1.25em',
      fontWeight: 'bold',
    },
  };
};
