import { Box, Button } from '@mui/material';
import { AnalyticEventType, sendAnalyticsEvent } from '../../utils/analytics';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import React, { useState } from 'react';
import ContentPopOver from '../Modal/ContentPopOverModal/ContentPopOver';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import {ButtonStyles} from "../Forms/styles/buttonStyles";
import {BoxStyles} from "../Forms/styles/boxStyles";

type FeedbackButtonProps = {
  analyticsEvent: AnalyticEventType;
};

const FeedbackButton: React.FC<FeedbackButtonProps> = (props) => {
  const { analyticsEvent } = props;
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    sendAnalyticsEvent(analyticsEvent);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={BoxStyles(userPreference.colorMode).feedBackBox}>
      <Button
        // className={classes.styledButton}
        style={ButtonStyles(userPreference.colorMode).feedbackButton}
        startIcon={<ChatBubbleOutlineIcon titleAccess={'Feedback Icon'} />}
        onClick={() => handleOpen()}
      >
        FEEDBACK
      </Button>
      <ContentPopOver
        isOpen={open}
        contentId="feedbackModalIframe"
        contentUrl="https://docs.google.com/forms/d/e/1FAIpQLSdVeM_mS-xrXCiNpQMAjqgNDkOXpT_C2FSv0eIKpKV_B0nLMw/viewform?usp=sf_link"
        handleClose={handleClose}
      />
    </Box>
  );
};

export default FeedbackButton;
