/* eslint-disable no-eval */
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import DigitalMerchandisingDataWrapper from '../dataWrappers/DigitalMerchandisingDataWrapper';

const queryClient = new QueryClient();

const DigitalMerchandisingScreen: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <DigitalMerchandisingDataWrapper />
    </QueryClientProvider>
  );
};

export default DigitalMerchandisingScreen;
