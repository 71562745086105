type taskCompletionDashboardDataWrapperStylesParams = {
  taskListLength: number;
};

export const taskCompletionDashboardDataWrapperStyles = (
  params: taskCompletionDashboardDataWrapperStylesParams
) => {
  const { taskListLength } = params;

  return {
    completionDashboardWrapperContainer: {
      display: 'flex',
      margin: window.innerWidth > 420 ? '1em 1em' : '1em 0',
      flexDirection:
        window.innerWidth < 420 ? ('column' as 'column') : ('row' as 'row'),
      flexWrap:
        window.innerWidth > 420 ? ('nowrap' as 'nowrap') : ('wrap' as 'wrap'),
    },
    completionDashboardWrapperFilterContainer: {
      width: window.innerWidth > 420 ? '250px' : '100%',
      display: 'flex',
    },
    completionDashboardWrapperChartContainer: {
      display: 'flex',

      marginLeft: window.innerWidth > 420 ? '2em' : '0.5em',
      width:
        window.innerWidth > 420
          ? taskListLength === 0
            ? '95%'
            : '50%'
          : '95%',
    },
    completionDataDashboardList: {
      marginLeft: '1.2em',
      width: window.innerWidth > 420 ? '40%' : '95%',
    },
  };
};
