import { Box, Typography } from '@mui/material';
import GenericButton from '../GenericButton/GenericButton';
import { ButtonEnum } from '../GenericButton/genericButttonUtils/ButtonEnum';
import { theme } from '../../assets/siteLineTheme';
import {
  ChangeMonthDirectionEnum,
  monthLabels,
} from './utils/easyPrintCalendarConstants';
import TaskFilterButton from '../Drawers/FilterDrawer/TaskFilterButton';
import PrintButton from '../Buttons/PrintButton';
import { analyticsEventConstants } from '../../utils/analytics';
import { easyPrintScreenStyles } from './utils/easyPrintScreenStyles';
import React from 'react';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

export type EasyPrintNavigationRowProps = {
  changeMonth: Function;
  selectedMonth: number;
  selectedYear: number;
};

const EasyPrintNavigationRow: React.FC<EasyPrintNavigationRowProps> = (
  props
) => {
  const easyPrintScreenClasses = easyPrintScreenStyles();
  const { changeMonth, selectedMonth, selectedYear } = props;

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  return (
    <Box sx={easyPrintScreenClasses.buttonRow}>
      <Box
        sx={{...easyPrintScreenClasses.buttonRowThird, ...easyPrintScreenClasses.buttonRowLeft}}
      >
        <GenericButton
          clickCallback={() => changeMonth(ChangeMonthDirectionEnum.Prev)}
          variant={ButtonEnum.Filled}
          buttonTextColor={
            theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
          }
        >
          Prev Month
        </GenericButton>
        <GenericButton
          clickCallback={() => changeMonth(ChangeMonthDirectionEnum.Next)}
          variant={ButtonEnum.Filled}
          buttonTextColor={
            theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
          }
        >
          Next Month
        </GenericButton>
      </Box>
      <Box
        sx={{...easyPrintScreenClasses.buttonRowThird, ...easyPrintScreenClasses.buttonRowCenter}}
      >
        <Typography sx={easyPrintScreenClasses.monthTitleText}>
          {monthLabels[selectedMonth].monthLabelString} {selectedYear}
        </Typography>
      </Box>
      <Box
        sx={{...easyPrintScreenClasses.buttonRowThird, ...easyPrintScreenClasses.buttonRowRight}}
      >
        <TaskFilterButton />
        <PrintButton analyticsEvent={analyticsEventConstants.EASY_PRINT} />
      </Box>
    </Box>
  );
};

export default EasyPrintNavigationRow;
