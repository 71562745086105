export const commentsAccordionContentStyles = () => {
  /* istanbul ignore next */
  return {
    title: {
      fontSize: '1em',
      fontFamily: 'Roboto-Light',
      fontWeight: '700',
    },
    commentContainer: {
      display: 'flex',
      margin: '.25em 0em',
    },
    iconContainer: {
      marginRight: '.5em',
    },
    commentHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    commentMainContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      width: '100%',
    },
    dateText: {
      fontSize: '0.65em',
      fontFamily: 'Roboto-Light',
    },
    commentText: {
      fontSize: '0.85em',
      fontFamily: 'Roboto-Light',
      margin: '0.35em 0em',
      wordBreak: 'break-word' as 'break-word',
    },
    commentTextContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    commentButtonContainer: {
      display: 'flex',
      alignItems: 'top',
      marginLeft: '0.5em',
    },
    commentIconContainer: {
      marginBottom: '0.25em',
    },
  };
};
