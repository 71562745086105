import {
  GroupByEnum,
  GroupByEnumStackedHistogram,
} from '../../stores/taskCompletionDashboardStore';
import React from 'react';
import { histogramMargin } from './ChartControllers/CompletionDataHistogramController';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from '../../stores/userPreferenceStore';
import {
  axisBottomTickLabelProps,
  axisLabelsProps,
  axisLeftTickLabelProps,
  calculateXPosition,
  xMaxCalculator,
  xScaleCalculatorHorizontal,
  yMaxCalculator,
  yScaleCalculatorHorizontal,
} from '../../utils/stackedHistogramUtils';
import { Group } from '@visx/group';
import { Grid } from '@visx/grid';
import { AxisBottom, AxisLeft } from '@visx/axis';

type HorizontalStackedHistogramProps = {
  data: any[];
  width: number;
  height: number;
  barColors: any;
  squareSize: number;
  histogramMargin: histogramMargin;
  groupBy: GroupByEnum;
  histogramTitle: string;
  axisBottomLabel: string;
  axisLeftLabel: string;
  onClickBar: Function;
};

const HorizontalStackedHistogram: React.FC<HorizontalStackedHistogramProps> = (
  props
) => {
  const {
    data,
    width,
    height,
    barColors,
    squareSize,
    histogramMargin,
    groupBy,
    histogramTitle,
    axisBottomLabel,
    axisLeftLabel,
    onClickBar,
  } = props;

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const groupByValue = GroupByEnumStackedHistogram[groupBy];

  const xScale = xScaleCalculatorHorizontal(data);
  const yScale = yScaleCalculatorHorizontal(data, groupByValue);
  const xMax = xMaxCalculator(width, histogramMargin);
  const yMax = yMaxCalculator(height, histogramMargin);
  // const yAxisScale = yAxisScaleCalculatorHorizontal(data, groupBy);

  xScale.rangeRound([0, xMax]);
  yScale.range([yMax, 0]);
  // yAxisScale.rangeRound([yMax, 0]);

  const legendArray = [
    { name: 'Completed', color: barColors.completed[userPreference.colorMode] },
    {
      name: 'Incomplete',
      color: barColors.incomplete[userPreference.colorMode],
    },
    { name: 'Overdue', color: barColors.overdue[userPreference.colorMode] },
  ];

  return (
    <svg height={height} width={width}>
      <rect x={0} y={0} width={width} height={height} fill={'white'} />
      <Group>
        <text
          x={histogramMargin.left + xMax / 2}
          y={height * 0.045}
          textAnchor={'middle'}
          fill={'black'}
          fontSize={'1.25em'}
        >
          {histogramTitle}
        </text>
      </Group>
      <Group>
        {legendArray.map((legendItem: any, index: number) => {
          return (
            <rect
              key={index}
              aria-label={'legendSquares'}
              x={width * (1 / 3) * index + width * 0.1}
              y={height - height * 0.075}
              height={squareSize}
              width={squareSize}
              fill={legendItem.color}
            />
          );
        })}
        {legendArray.map((legendItem: any, index: number) => {
          return (
            <text
              key={index}
              aria-label={'legendValues'}
              x={width * (1 / 3) * index + width * 0.13 + 5}
              y={height - height * 0.075 + 13.5}
              textAnchor={'start'}
              fill={legendItem.color}
              fontSize={'1.05em'}
            >
              {legendItem.name}
            </text>
          );
        })}
      </Group>

      <Grid
        top={histogramMargin.top}
        left={histogramMargin.left}
        xScale={xScale}
        yScale={yScale}
        width={xMax}
        height={yMax}
        stroke="black"
        strokeOpacity={0.1}
      />
      <Group top={histogramMargin.top} left={histogramMargin.left}>
        {data[0].overdue !== undefined &&
          data.map((d: any, index: number) => {
            return (
              <rect
                key={index}
                aria-label={'overdue'}
                x={calculateXPosition(xMax, xScale, d, 'overdue')}
                y={
                  groupBy === 'TaskType'
                    ? // @ts-ignore
                      yScale(d[groupByValue]) + 18
                    : yScale(d[groupByValue])
                }
                width={xScale(Number(d.overdue))}
                height={
                  groupBy === 'TaskType' && yScale.bandwidth() > 15
                    ? yScale.bandwidth() - 15
                    : yScale.bandwidth()
                }
                fill={barColors.overdue[userPreference.colorMode]}
                cursor={'pointer'}
                onClick={() => onClickBar(`${d[groupByValue]}`, 'Overdue')}
              />
            );
          })}
        {data[0].incompleted !== undefined &&
          data.map((d: any, index: number) => {
            return (
              <rect
                key={index}
                aria-label={'incompleted'}
                x={calculateXPosition(xMax, xScale, d, 'incompleted')}
                y={
                  groupBy === 'TaskType'
                    ? // @ts-ignore
                      yScale(d[groupByValue]) + 18
                    : yScale(d[groupByValue])
                }
                width={xScale(Number(d.incompleted))}
                height={
                  groupBy === 'TaskType' && yScale.bandwidth() > 15
                    ? yScale.bandwidth() - 15
                    : yScale.bandwidth()
                }
                fill={barColors.incomplete[userPreference.colorMode]}
                cursor={'pointer'}
                onClick={() => onClickBar(`${d[groupByValue]}`, 'Incomplete')}
              />
            );
          })}

        {data[0].completed !== undefined &&
          data.map((d: any, index: number) => {
            return (
              <rect
                key={index}
                aria-label={'completed'}
                x={0}
                // @ts-ignore
                y={
                  groupBy === 'TaskType'
                    ? // @ts-ignore
                      yScale(d[groupByValue]) + 18
                    : yScale(d[groupByValue])
                }
                width={xScale(Number(d.completed))}
                height={
                  groupBy === 'TaskType' && yScale.bandwidth() > 15
                    ? yScale.bandwidth() - 15
                    : yScale.bandwidth()
                }
                fill={barColors.completed[userPreference.colorMode]}
                cursor={'pointer'}
                onClick={() => onClickBar(`${d[groupByValue]}`, 'Completed')}
              />
            );
          })}
        <AxisBottom
          top={yMax}
          scale={xScale}
          stroke={'black'}
          tickStroke={'black'}
          tickLabelProps={() => axisBottomTickLabelProps}
          label={axisBottomLabel}
          labelProps={axisLabelsProps}
        />
        <AxisLeft
          scale={yScale}
          stroke={'black'}
          tickStroke={'black'}
          tickLabelProps={() => axisLeftTickLabelProps(groupBy)}
          label={axisLeftLabel}
          labelProps={axisLabelsProps}
        />
      </Group>
    </svg>
  );
};

export default HorizontalStackedHistogram;
