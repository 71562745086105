import {ColorModeTypeEnum} from "../../../stores/userPreferenceStore";
import {theme} from "../../../assets/siteLineTheme";

/** Button **/
export const ButtonStyles = (colorMode: ColorModeTypeEnum) => {
    return {
        root: {
            minWidth: '45%',
            flex: 1,
            borderRadius: '4px',
        },
        saveApplyFiltersButton: {
            color: theme.newColorPalette.textDarkWhite[colorMode],
            backgroundColor: theme.newColorPalette.snackbar.successBlack[colorMode],
            '&:hover': {
                backgroundColor:
                    theme.newColorPalette.snackbar.successBlack[colorMode],
                color: theme.newColorPalette.textDarkWhite[colorMode],
                filter: 'brightness(120%)',
            },
        },
        clearFiltersButton: {
            color: theme.newColorPalette.textDarkWhite[colorMode],
            backgroundColor: theme.newColorPalette.plainWhiteBlack[colorMode],
            '&:hover': {
                backgroundColor: theme.newColorPalette.plainWhiteBlack[colorMode],
                color: theme.newColorPalette.textDarkWhite[colorMode],
                filter: 'brightness(120%)',
            },
        },
        approveButton: {
            color: theme.newColorPalette.textDarkWhite[colorMode],
            backgroundColor: theme.newColorPalette.snackbar.successBlack[colorMode],
            '&:hover': {
                backgroundColor:
                    theme.newColorPalette.snackbar.successBlack[colorMode],
                color: theme.newColorPalette.textDarkWhite[colorMode],
                filter: 'brightness(120%)',
            },
        },
        markCompleteButton: {
            color: theme.newColorPalette.textDarkWhite[colorMode],
            backgroundColor: theme.newColorPalette.snackbar.successBlack[colorMode],
            '&:hover': {
                backgroundColor:
                    theme.newColorPalette.snackbar.successBlack[colorMode],
                color: theme.newColorPalette.textDarkWhite[colorMode],
                filter: 'brightness(120%)',
            },
        },
        printButton: {
            color: theme.newColorPalette.textDarkWhite[colorMode],
            backgroundColor: theme.newColorPalette.zoidbergBlack[colorMode],
            '&:hover': {
                backgroundColor: theme.newColorPalette.zoidbergBlack[colorMode],
                color: theme.newColorPalette.textDarkWhite[colorMode],
                filter: 'brightness(120%)',
            },
        },
        duplicateButton: {
            color: theme.newColorPalette.textDarkWhite[colorMode],
            backgroundColor: theme.newColorPalette.zoidbergBlack[colorMode],
            '&:hover': {
                backgroundColor: theme.newColorPalette.zoidbergBlack[colorMode],
                color: theme.newColorPalette.textDarkWhite[colorMode],
                filter: 'brightness(120%)',
            },
        },
        returnButton: {
            color: theme.newColorPalette.textDarkWhite[colorMode],
            border: '2px black solid',
            borderRadius: '4px',
            '&:hover': {
                filter: 'brightness(120%)',
            },
        },
        editButton: {
            color: theme.colorPalette.plainWhite,
            backgroundColor: theme.colorPalette.plainBlack,
            '&:hover': {
                backgroundColor: theme.colorPalette.lightGrey,
                filter: 'brightness(120%)',
            },
        },
        feedbackButton: {
            background: theme.newColorPalette.feedbackBlack[colorMode],
            color: theme.newColorPalette.textDarkWhite[colorMode],
            '&:hover': {
                backgroundColor: theme.newColorPalette.zoidbergBlack[colorMode],
                color: theme.newColorPalette.textDarkWhite[colorMode],
            },
            transform: `rotate(-90deg)`,
            borderRadius: '10px 10px 0px 0px',
        },
        calendarToolbarViewButton: {
            padding: '0px 5px',
            fontSize: '1.05em',
            color: theme.newColorPalette.plainWhiteBlack[colorMode],
            marginBottom: '6px',
            textTransform: 'initial',
            '&:hover': {
                borderRadius: '24px',
                borderBottom: `2px solid ${theme.newColorPalette.zoidbergBlack[colorMode]}`,
                marginBottom: '4px',
            },
        },
        calendarSelectedToolBarViewButton: {
            fontSize: '1.05em',
            padding: '0px 5px',
            color: theme.newColorPalette.textDarkWhite[colorMode],
            backgroundColor: theme.newColorPalette.zoidbergBlack[colorMode],
            marginBottom: '6px',
            borderRadius: '24px',
            textTransform: 'initial',
            '&:hover': {
                borderRadius: '24px',
                backgroundColor: theme.newColorPalette.zoidbergBlack[colorMode],
                color: theme.newColorPalette.textDarkWhite[colorMode],
            },
        }
    }

}
