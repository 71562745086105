import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';
import { theme } from '../../../assets/siteLineTheme';
import { TaskStoreCompletionStatus } from '../../../models/TaskStoreCompletionStatus';

export const chipBackgroundColorPicker = (
  colorMode: ColorModeTypeEnum,
  task_type?: string | undefined,
  completionStatus?: TaskStoreCompletionStatus | undefined
) => {
  if (colorMode === ColorModeTypeEnum.BlackWhite) {
    return theme.colorPalette.plainBlack;
  } else if (task_type) {
    return theme.colorPalette.taskTypes.dark[task_type];
  } else if (completionStatus) {
    if (completionStatus === TaskStoreCompletionStatus.Completed) {
      return theme.colorPalette.completionStatus.completed;
    } else if (completionStatus === TaskStoreCompletionStatus.Overdue) {
      return theme.colorPalette.completionStatus.overdue;
    } else {
      return theme.colorPalette.completionStatus.incomplete;
    }
  }
  return 'orange';
};

export const chipTextColorPicker = (
  colorMode: ColorModeTypeEnum,
  task_type?: string | undefined,
  completionStatus?: TaskStoreCompletionStatus | undefined
) => {
  if (colorMode === ColorModeTypeEnum.BlackWhite) {
    return theme.colorPalette.plainWhite;
  } else if (task_type) {
    return theme.colorPalette.taskTypes.getTextColor(task_type, colorMode);
  } else if (completionStatus) {
    return theme.colorPalette.plainWhite;
  }
  return 'orange';
};
