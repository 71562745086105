import { groups, naviagtionMenuItems } from './constants';

export const menuSetter = (userInfo: any) => {
  const { SUPER_GABE, GABE, JAN, MICHAEL, STANLEY } = groups;
  const {
    calendar,
    reviewAndApproval,
    easyPrint,
    mobileMetrics,
    completionDataDashboard,
    taskListView,
    digitalMerchandising,
    safetyRiskCompliance,
  } = naviagtionMenuItems;
  const baseMenu = [easyPrint, digitalMerchandising, safetyRiskCompliance, calendar, taskListView];

  const superGabeMenu = [
    easyPrint,
    digitalMerchandising,
    safetyRiskCompliance,
    mobileMetrics,
    calendar,
    reviewAndApproval,
    taskListView,
    completionDataDashboard
  ];

  const gabeAndJanMenu = [
    easyPrint,
    digitalMerchandising,
    safetyRiskCompliance,
    calendar,
    taskListView,
    completionDataDashboard,
  ];

  const { SiteLineGroups } = userInfo;

  switch (true) {
    case SiteLineGroups?.includes(SUPER_GABE):
      return superGabeMenu;
    case SiteLineGroups?.includes(GABE):
      return [...gabeAndJanMenu];
    case SiteLineGroups?.includes(JAN):
      return [...gabeAndJanMenu];
    case SiteLineGroups?.includes(MICHAEL):
      return [...baseMenu];
    case SiteLineGroups?.includes(STANLEY):
      return [...baseMenu];
    default:
      return [...baseMenu];
  }
};
