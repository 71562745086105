import { Form, Formik } from 'formik';
import FormikSelect from './FormikElements/FormikSelect';
import FormikField from './FormikElements/FormikField';
import FormikSwitch from './FormikElements/FormikSwitch';
import { Box, Grid, Paper, Typography } from '@mui/material';
import {
  createTask,
  createTaskFromDistroList,
  deleteTaskById,
  updateTask,
  updateTaskStoreCompletionStatus,
} from '../../api/apiV1Task';
import {
  copyAttachmentsToNewGCPBucket,
  deleteUploadedFile,
} from '../../api/apiFile';
import { TaskApiResponse } from '../../models/apiV1TaskResponses';
import {
  ALL_STORES_STORE_VALUE,
  getTaskSchema,
  formattedUserName,
} from './TaskUtils';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { TextEditor } from '../TextEditor/TextEditor';
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FileUploadField } from '../FileUpload/FileUploadField';
import { Attachments } from '../ViewAttachments/Attachments';
import RadioGroupComponent from './FormikElements/RadioGroupComponent';
import { TaskStatus } from '../../models/TaskStatus';
import { AppContext } from '../../context/AppContext';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../utils/analytics';
import useFetchUserGroup from 'src/hooks/fetchUserGroup';
import StyledBackgroundBox from './StyledBackgroundBox';
import { convertDateToUTC } from '../../utils/dateUtils';
import ViewTask from '../Drawers/TaskDrawer/ViewTask';
import ViewTaskPrint from '../ViewTaskPrint/ViewTaskPrint';
import { useReactToPrint } from 'react-to-print';
import { TaskStoreCompletionStatus } from '../../models/TaskStoreCompletionStatus';
import DialogModal from '../Modal/DialogModal';
import isDialogModalOpenStore from '../../stores/isDialogModalOpenStore';
import TaskFormButtons from './TaskFormButtons';
import appliedFiltersStore from '../../stores/appliedFiltersStore';
import snackbarPropsStore from '../../stores/snackbarPropsStore';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import drawerPropsStore from '../../stores/drawerPropsStore';
import { CalendarEvent } from '../types/Calendar/ICalendarIndex';
import { completionStatus } from '../../utils/completionUtils';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import { IconButton } from '../Buttons/IconButton';
import { theme } from '../../assets/siteLineTheme';
import {DrawerHeaderContainerDiv, RootDiv} from "./styles/divStyles";
import {BoxStyles} from "./styles/boxStyles";
import {PaperStyles} from "./styles/paperStyles";
import {TypographyStyles} from "./styles/typographyStyles";
import {GridStyles} from "./styles/gridStyles";
import {FormStyles} from "./styles/formStyles";

export const TaskForm: React.FC = () => {
  const { appState } = useContext(AppContext);
  const { userInfo } = appState;
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const { appliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
    })
  );
  const { setSnackbarProps } = snackbarPropsStore(
    (state: { setSnackbarProps: any }) => ({
      setSnackbarProps: state.setSnackbarProps,
    })
  );
  const { drawerProps, setDrawerProps, resetDrawerProps } = drawerPropsStore(
    (state: {
      setDrawerProps: any;
      drawerProps: any;
      resetDrawerProps: Function;
    }) => ({
      setDrawerProps: state.setDrawerProps,
      drawerProps: state.drawerProps,
      resetDrawerProps: state.resetDrawerProps,
    })
  );

  const { viewEvent, formMode, selectedSlot } = drawerProps.drawerContentProps;

  const [headerComponentHeight, setHeaderComponentHeight] = useState('');
  const ref = useRef(null);

  const pageStyle = '@page { margin-bottom: .5in; }';

  const handlePrint = useReactToPrint({
    pageStyle,
    content: () => componentRef.current,
  });

  const handlePrintClick = () => {
    handlePrint && handlePrint();
    sendAnalyticsEvent(analyticsEventConstants.PRINT_TASK);
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const userOktaGroup = useFetchUserGroup(userInfo);

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      // had to @ts-ignore here because I know this won't be null but ts is worried it will be
      setHeaderComponentHeight(ref.current.clientHeight);
    }
  }, [ref, formMode]);

  const editorContents = useRef<{}>();
  const [storeAssignmentSelected, setStoreAssignmentSelected] = useState(
    'store-assignment-single-store'
  );
  const [distroListFile, setDistroListFile] = useState<File>();
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [attachmentsId, setAttachmentsId] = useState<string>();
  const taskId = viewEvent?.id ?? '';
  useEffect(() => {
    setAttachmentsId(viewEvent ? viewEvent.attachments_id : uuidv4());
  }, [viewEvent]);
  const initialValues = {
    attachments_id: attachmentsId,
    title: viewEvent?.title ?? '',
    task_type: viewEvent?.task_type ?? '',
    department: viewEvent?.department ?? '',
    store_number: viewEvent?.store_number ?? '',
    start_date: viewEvent?.start_date
      ? format(convertDateToUTC(viewEvent?.start_date), 'yyyy-MM-dd')
      : selectedSlot?.start_date
      ? format(convertDateToUTC(selectedSlot?.start_date), 'yyyy-MM-dd')
      : '',
    end_date: viewEvent?.end_date
      ? format(convertDateToUTC(viewEvent?.end_date), 'yyyy-MM-dd')
      : selectedSlot?.end_date
      ? format(convertDateToUTC(selectedSlot?.end_date), 'yyyy-MM-dd')
      : '',
    funded: viewEvent?.funded ?? false,
    labor_hours: viewEvent?.labor_hours ?? '',
    leadership_calendar: viewEvent?.leadership_calendar ?? false,
    year_at_a_glance: viewEvent?.year_at_a_glance ?? false,
    require_completion: viewEvent?.require_completion ?? true,
    schedule_date: viewEvent?.schedule_date ?? true,
    task_description: viewEvent?.task_description ?? {
      ops: [{ insert: '\n' }],
    },
    attachment_information: viewEvent?.attachment_information ?? [],
    store_list_distro_file: undefined,
    distro_file_information: viewEvent?.distro_file_information ?? [],
    task_status: viewEvent?.task_status ?? TaskStatus.Submitted,
    author_details: viewEvent?.author_details
      ? viewEvent.author_details
      : JSON.stringify(formattedUserName(userInfo)),
    completion_status: completionStatus(viewEvent),
    completion_statuses:
      viewEvent?.completion_statuses && viewEvent.completion_statuses,
    comments: viewEvent?.comments,
    edit_notes: viewEvent?.edit_notes,
    store_scheduled_start_date: viewEvent?.store_scheduled_start_date,
    store_scheduled_end_date: viewEvent?.store_scheduled_end_date,
  };

  const handleSubmit = async (values: TaskApiResponse): Promise<void> => {
    const {
      store_number,
      title,
      end_date,
      start_date,
      task_type,
      labor_hours,
    } = values;
    values.author_details = formattedUserName(userInfo);
    values.attachments_id = attachmentsId;
    if (labor_hours?.toString() === '') values.labor_hours = undefined;

    values.store_number =
      storeAssignmentSelected === 'store-assignment-all'
        ? ALL_STORES_STORE_VALUE
        : store_number && store_number.toString();

    const isDateOrderCorrect = start_date <= end_date;

    if (
      (title &&
        end_date &&
        start_date &&
        values.store_number &&
        task_type &&
        isDateOrderCorrect) ||
      (title &&
        task_type &&
        (distroListFile || viewEvent?.distro_file_information?.length))
    ) {
      values.task_description = JSON.stringify(editorContents.current);
      if (!values.task_description) {
        values.task_description = JSON.stringify({ ops: [{ insert: '\n' }] });
      }
      values.task_status = TaskStatus.Submitted;
      sendAnalyticsEvent(analyticsEventConstants.SUBMIT_TASK);
      if (formMode === 'create') {
        if (distroListFile) {
          values.store_list_distro_file = distroListFile;
          await createTaskFromDistroList(
            values,
            setSnackbarProps,
            resetDrawerProps
          );
        } else {
          await createTask(values, setSnackbarProps, resetDrawerProps);
        }
      }
      if (formMode === 'edit') {
        values.id = taskId;
        if (viewEvent?.distro_file_information?.length) {
          values.distro_file_information = viewEvent.distro_file_information[0];
        } else {
          values.distro_file_information = undefined;
        }
        await updateTask(values, setSnackbarProps, resetDrawerProps);
      }
    } else {
      isDateOrderCorrect
        ? window.alert(
            'There was an error inserting your task please make sure to fill the required fields'
          )
        : window.alert('Start Date cannot occur after End Date');
    }
  };

  const handleCancel = (values: any) => {
    const { attachment_information, distro_file_information } = values;
    let attachment_files_names = [];
    let distro_file_names = [];

    if (formMode !== 'edit') {
      attachment_files_names = attachment_information.map(
        (obj: any) => obj.name
      );
    }

    if (formMode !== 'edit') {
      distro_file_names = distro_file_information?.map((obj: any) => obj.name);
    }

    if (formMode === 'create') {
      const files = [...distro_file_names, ...attachment_files_names];
      if (files.length > 0) {
        files.forEach(async (fileName) => {
          await deleteUploadedFile(fileName, values.attachments_id);
        });
      }
    }
    resetDrawerProps();
  };

  const handleDelete = async (deleteTaskId: string) => {
    sendAnalyticsEvent(analyticsEventConstants.DELETE_TASK);
    await deleteTaskById(deleteTaskId);
    resetDrawerProps();
  };

  const handleMarkTaskComplete = async (
    taskId: string,
    storeNumber: string
  ) => {
    sendAnalyticsEvent(analyticsEventConstants.TASK_MARKED_COMPLETE);

    await updateTaskStoreCompletionStatus({
      id: taskId,
      store_number: storeNumber,
      completion_status: TaskStoreCompletionStatus.Completed,
    });
    resetDrawerProps();
  };

  const toggleIsDialogModalOpen = isDialogModalOpenStore(
    (state: any) => state.toggleIsDialogModalOpen
  );

  const handleReopenTask = async () => {
    toggleIsDialogModalOpen();
  };

  const openEdit = () => {
    setDrawerProps({
      ...drawerProps,
      isDrawerOpen: true,
      drawerContentProps: {
        ...drawerProps.drawerContentProps,
        formMode: 'edit',
      },
    });
  };

  const getNewAttachmentsIdAndCopyFilesInGCP = (viewEvent: CalendarEvent) => {
    const newViewEvent = { ...viewEvent, attachments_id: uuidv4() };
    const oldId = viewEvent.attachments_id;
    if (newViewEvent.attachment_information?.length !== 0) {
      copyAttachmentsToNewGCPBucket(
        oldId,
        newViewEvent.attachments_id,
        newViewEvent.attachment_information
      );
    }
    return newViewEvent;
  };

  // TODO: NEED TO HANDLE CLOSING OF DRAWER AFTER DUPLICATE CREATION
  // CURRENTLY THERE ARE ISSUES CREATING RECORDS IN GCP DUE TO THIS
  const duplicateTaskHandler = () => {
    if (viewEvent) {
      const viewEventToSet = getNewAttachmentsIdAndCopyFilesInGCP(
        viewEvent
      ) as CalendarEvent;

      setDrawerProps({
        ...drawerProps,
        isDrawerOpen: true,
        drawerContentProps: {
          ...drawerProps.drawerContentProps,
          formMode: 'create',
          viewEvent: viewEventToSet,
        },
      });
    }
  };

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStoreAssignmentSelected(e.target.value);
  };
  const toShow = (name: string, values: any) => {
    switch (name) {
      case 'department':
        return values.task_type === 'Merchandising';
      case 'labor_hours':
        return values.funded;
      default:
        return true;
    }
  };

  const taskSchema = getTaskSchema(initialValues, userOktaGroup);

  const formList = taskSchema[formMode || 'create'];
  const { fields: groups, formButtons } = formList;

  const viewEventAuthorEmployeeID = viewEvent?.author_details?.empID;

  const buttonActions = {
    handleReopenTask,
    handleMarkTaskComplete,
    handleDelete,
    handlePrintClick,
    duplicateTaskHandler,
    openEdit,
    resetToDefault: resetDrawerProps,
  };
  const buttonData = {
    viewEventAuthorEmployeeID,
    viewEvent,
    fileUploading,
  };

  return (
    <RootDiv colorMode={userPreference.colorMode}>
      {viewEvent && viewEvent.last_modified_by && appliedFilters.store_number && (
        <DialogModal
          resetToDefault={resetDrawerProps}
          completionInfo={{
            completion_status: viewEvent.completion_status,
            date_last_modified: viewEvent.date_last_modified,
            last_modified_by: viewEvent.last_modified_by,
            store_number: viewEvent.store_number,
            task_id: viewEvent.task_id,
          }}
        />
      )}

      {formMode === 'create' ? (
        <Box sx={BoxStyles(userPreference.colorMode).drawerBackgroundBox} />
      ) : (
        <StyledBackgroundBox
          taskType={initialValues.task_type}
          colorMode={userPreference.colorMode}
        />
      )}

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, dirty, isValid }) => {
          return (
            <>
              <DrawerHeaderContainerDiv
                id={'drawer-header-container'}
              >
                <IconButton
                  icon={IconEnum.Exit}
                  label={'Close Task Form Button'}
                  onClick={() => handleCancel(values)}
                  color={
                    theme.newColorPalette.plainWhiteBlack[
                      userPreference.colorMode
                    ]
                  }
                  size={'2em'}
                  margin={'0 0 0 0.7em'}
                />
                {formMode !== 'view' ? (
                  <Grid
                    ref={ref}
                    container
                    sx={{margin: '0em 1.5em', justifyContent: 'center',}}
                  >
                    <Paper style={PaperStyles(userPreference.colorMode).headerPaper}>
                      <Typography style={TypographyStyles(userPreference.colorMode).headerText}>
                        {formList.header}
                      </Typography>
                    </Paper>
                  </Grid>
                ) : (
                  viewEvent && (
                    <Grid
                      ref={ref}
                      container
                      sx={{margin: '0em 1.5em', justifyContent: 'center',}}
                    >
                      <Paper style={PaperStyles(userPreference.colorMode).headerPaper}>
                        <Typography style={TypographyStyles(userPreference.colorMode).headerText}>
                          {viewEvent.title}
                        </Typography>
                      </Paper>
                    </Grid>
                  )
                )}
              </DrawerHeaderContainerDiv>
              <Form
                role="task-creation-form"
                // style={{ height: `calc(100% - 58)` : 200 }}
                style={
                  headerComponentHeight !== '' ? FormStyles(headerComponentHeight).boxStretch : undefined
                }
              >
                <Grid
                  container
                  spacing={0}
                  sx={GridStyles(userPreference.colorMode).formContentContainer}
                >
                  <Box sx={BoxStyles(userPreference.colorMode).contentContainer}>
                    {
                      /* TODO: remove viewEvent from props, get value from drawerStore in ViewTask */ (formMode ===
                        'view' ||
                        formMode === 'approve') &&
                      viewEvent ? (
                        <ViewTask
                          viewEvent={{
                            ...initialValues,
                            last_modified_by: viewEvent.last_modified_by,
                            date_last_modified: viewEvent.date_last_modified,
                          }}
                          taskId={viewEvent.id}
                          resetToDefault={resetDrawerProps}
                        />
                      ) : (
                        groups.map((group: any, index: number) => {
                          // Checks to see if every item in group will be hidden to prevent rendering an empty grid and paper elements
                          const reducer = (
                            numberOfHiddenProperties: number,
                            item: any
                          ) =>
                            Object.prototype.hasOwnProperty.call(
                              item,
                              'hideOnListOfStoresRadioChosen'
                            )
                              ? numberOfHiddenProperties + 1
                              : numberOfHiddenProperties;
                          if (
                            storeAssignmentSelected ===
                              'store-assignment-store-list' &&
                            group.reduce(reducer, 0) === group.length
                          ) {
                            return null;
                          }
                          return (
                            group.length !== 0 && (
                              <Grid
                                container
                                className={`group-${index}`}
                                key={`group-${index}`}
                              >
                                <Paper
                                    sx={{...PaperStyles(userPreference.colorMode).basicPaper, width: '100%', marginBottom: '1em'}}
                                    elevation={4}
                                >
                                  {group.map((fieldObj: any, index: number) => {
                                    const {
                                      name,
                                      label,
                                      fieldType,
                                      type,
                                      items,
                                      disabled = false,
                                      helperText,
                                      maxLength,
                                      gridItemSize,
                                      hideOnListOfStoresRadioChosen,
                                      prompt,
                                      maxNumOfUploadableFiles,
                                      cumulativeFileSize,
                                    } = fieldObj;

                                    if (
                                      storeAssignmentSelected ===
                                        'store-assignment-store-list' &&
                                      hideOnListOfStoresRadioChosen
                                    ) {
                                      return null;
                                    } else {
                                      switch (fieldType) {
                                        case 'textbox':
                                          return (
                                            <Grid
                                              key={name + index}
                                              container
                                              item
                                              xs={12}
                                              style={
                                                toShow(name, values)
                                                  ? { display: 'block' }
                                                  : { display: 'none' }
                                              }
                                            >
                                              <Grid
                                                item
                                                xs={gridItemSize ?? 12}
                                              >
                                                <FormikField
                                                  name={name}
                                                  label={label}
                                                  type={type}
                                                  helpText={helperText}
                                                  maxLength={maxLength}
                                                  disabled={disabled}
                                                  values={values}
                                                />
                                              </Grid>
                                            </Grid>
                                          );

                                        case 'select':
                                          return (
                                            <Grid
                                              key={name + index}
                                              container
                                              item
                                              xs={12}
                                              style={
                                                toShow(name, values)
                                                  ? { display: 'block' }
                                                  : { display: 'none' }
                                              }
                                            >
                                              <Grid
                                                item
                                                xs={gridItemSize ?? 12}
                                              >
                                                <FormikSelect
                                                  name={name}
                                                  label={label}
                                                  items={items}
                                                  disabled={disabled}
                                                />
                                              </Grid>
                                            </Grid>
                                          );

                                        case 'switch':
                                          return (
                                            <Grid
                                              key={name + index}
                                              container
                                              item
                                              xs={12}
                                            >
                                              <Grid
                                                item
                                                xs={gridItemSize ?? 12}
                                              >
                                                <FormikSwitch
                                                  name={name}
                                                  label={label}
                                                  disabled={disabled}
                                                />
                                              </Grid>
                                            </Grid>
                                          );
                                        case 'editor':
                                          return (
                                            <Grid
                                              key={name + index}
                                              container
                                              item
                                              xs={12}
                                            >
                                              <Grid
                                                item
                                                xs={gridItemSize ?? 12}
                                              >
                                                <TextEditor
                                                  formMode={formMode}
                                                  delta={
                                                    values.task_description
                                                  }
                                                  name={name}
                                                  editorContents={
                                                    editorContents
                                                  }
                                                  label={label}
                                                />
                                              </Grid>
                                            </Grid>
                                          );

                                        case 'radio-group':
                                          return (
                                            <Grid
                                              key={name + index}
                                              container
                                              item
                                              xs={12}
                                            >
                                              {prompt ? (
                                                <div>{prompt}</div>
                                              ) : null}

                                              <RadioGroupComponent
                                                items={items}
                                                handleRadioChange={
                                                  handleRadioChange
                                                }
                                                gridItemSize={gridItemSize}
                                                setDistroListFile={
                                                  setDistroListFile
                                                }
                                                storeAssignmentSelected={
                                                  storeAssignmentSelected
                                                }
                                                attachmentsId={attachmentsId}
                                                setFileUploading={
                                                  setFileUploading
                                                }
                                                formMode={formMode}
                                              />
                                            </Grid>
                                          );
                                        case 'upload':
                                          const {
                                            fileTypes,
                                            fieldTitle,
                                            fieldSubTitle,
                                          } = fieldObj;
                                          const { attachment_information } =
                                            values;
                                          const previouslyUploadedFiles =
                                            attachment_information?.map(
                                              (obj: any) => {
                                                return {
                                                  ...obj,
                                                  previouslyUploadedFile: true,
                                                };
                                              }
                                            );

                                          return (
                                            <Grid
                                              key={name + index}
                                              container
                                              item
                                              xs={12}
                                            >
                                              <Grid
                                                item
                                                xs={gridItemSize ?? 12}
                                              >
                                                <FileUploadField
                                                  name={name}
                                                  fileTypes={fileTypes}
                                                  fieldText={{
                                                    fieldTitle,
                                                    fieldSubTitle,
                                                  }}
                                                  maxNumOfUploadableFiles={
                                                    maxNumOfUploadableFiles
                                                  }
                                                  cumulativeFileSize={
                                                    cumulativeFileSize
                                                  }
                                                  attachmentsId={attachmentsId}
                                                  setFileUploading={
                                                    setFileUploading
                                                  }
                                                  previouslyUploadedFiles={
                                                    previouslyUploadedFiles
                                                  }
                                                />
                                              </Grid>
                                            </Grid>
                                          );

                                        case 'attachments':
                                          const { fileNames } = fieldObj;
                                          return (
                                            <Grid
                                              key={name + index}
                                              container
                                              item
                                              xs={12}
                                            >
                                              <Grid
                                                item
                                                xs={gridItemSize ?? 12}
                                              >
                                                <Paper
                                                  style={PaperStyles(userPreference.colorMode).paperFileUpload}
                                                  elevation={4}
                                                >
                                                  <Attachments
                                                    name={name}
                                                    files={fileNames}
                                                    attachmentsId={
                                                      attachmentsId
                                                    }
                                                    header={label}
                                                  />
                                                </Paper>
                                              </Grid>
                                            </Grid>
                                          );

                                        default:
                                          return (
                                            <p key={index}>
                                              No form fields enabled
                                            </p>
                                          );
                                      }
                                    }
                                  })}
                                </Paper>
                              </Grid>
                            )
                          );
                        })
                      )
                    }
                  </Box>

                  {!viewEvent?.uc_event && (
                    <TaskFormButtons
                      formButtons={formButtons}
                      buttonData={buttonData}
                      buttonActions={buttonActions}
                      taskInfo={{
                        taskId,
                        formMode,
                      }}
                    />
                  )}
                </Grid>
              </Form>
              {viewEvent && (
                <div className="printContainer" style={{ display: 'none' }}>
                  <ViewTaskPrint ref={componentRef} />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </RootDiv>
  );
};
