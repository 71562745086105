import { theme } from '../../assets/siteLineTheme';

export const confirmModalStyles = () => {
  /* istanbul ignore next */
  return {
    confirmModalContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
    },
    confirmModalMessageContainer: {
      color: theme.colorPalette.lightGrey,
    },
    confirmModalButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row' as 'row',
      margin: '1em 1em 0 1em',
    },
  };
};
