import React from 'react';
import Box from '../Box/Box';
import { boxArrayStyles } from './boxArrayStyles';
import BoxContentSwitch from '../BoxContent/BoxContentSwitch/BoxContentSwitch';
import { CalendarEvent } from '../types/Calendar/ICalendarIndex';
import { FeatureObjects } from '../Drawers/NewFeaturesDrawer/NewFeaturesDrawer';

/* eslint-disable */
export enum BoxContentTypeEnum {
  NavigationBoxContent = 'NavigationBoxContent',
  BreadcrumbContent = 'BreadcrumbContent',
  TaskListViewContent = 'TaskListViewContent',
  NotificationContent = 'NotificationContent',
  RecentlyViewedBoxContent = 'RecentlyViewedBoxContent',
  CompletionDataDashboardListContent = 'CompletionDataDashboardListContent',
  SafetyRiskContent = 'SafetyRiskContent'
}

export type BoxArrayProps = {
  dataArray: Array<CalendarEvent | FeatureObjects | any>;
  boxContentType?: BoxContentTypeEnum | undefined;
  boxWidth: string;
  boxHeight: string | undefined;
  margin?: string | undefined;
  justifyContent?: string | undefined;
  alignItems?: string | undefined;
  onClick?: Function | undefined;
  customWidthArray?: Array<string> | undefined;
  startRowPage?: number | undefined;
  endRowPage?: number | undefined;
  callBack?: Function | undefined;
  resetList?: Function | undefined;
  gap?: string | undefined;
  determineIsSelected?: Function | undefined;
  fromDirectUrl?: { fromDirectUrl: boolean; url: string } | undefined;
};

const BoxArray: React.FC<BoxArrayProps> = (props) => {
  const {
    dataArray,
    boxHeight,
    boxWidth,
    boxContentType,
    onClick,
    margin,
    justifyContent,
    alignItems,
    callBack,
    resetList,
    gap,
    determineIsSelected,
    fromDirectUrl,
  } = props;
  const boxArrayClasses = boxArrayStyles({
    boxHeight,
    boxWidth,
    margin,
    justifyContent,
    alignItems,
    gap,
  });

  const dataLength = dataArray.length;

  return (
    <div style={boxArrayClasses.boxArrayContainer}>
      {dataArray.map((data, index) => {
        const isSelected: boolean | undefined =
          determineIsSelected !== undefined
            ? determineIsSelected(data)
            : undefined;

        return (
          <div style={boxArrayClasses.boxContainer} key={index}>
            <Box>
              <BoxContentSwitch
                contentData={data}
                boxContentType={boxContentType}
                onClick={onClick}
                callBack={callBack}
                resetList={resetList}
                isLastElementInArray={dataLength - 1 === index}
                isSelected={isSelected}
                fromDirectUrl={fromDirectUrl}
                boxHeight={boxHeight}
              />
            </Box>
          </div>
        );
      })}
    </div>
  );
};

export default BoxArray;
