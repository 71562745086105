import { isDateBeforeToday } from './dateUtils';
import { TaskStoreCompletionStatus } from '../models/TaskStoreCompletionStatus';
import { IconEnum } from '../components/Chip/chipUtils/ChipEnums';

export const completionStatus = (viewEvent: any) => {
  if (viewEvent && viewEvent.completion_status) {
    if (
      (isDateBeforeToday(viewEvent.end_date) ||
        (viewEvent.store_scheduled_end_date &&
          isDateBeforeToday(viewEvent.store_scheduled_end_date))) &&
      viewEvent.completion_status === TaskStoreCompletionStatus.Incomplete
    ) {
      return TaskStoreCompletionStatus.Overdue;
    }
    return viewEvent.completion_status;
  }
  return 'N/A';
};

export const chipCompletionStatusIconPicker = (
  completionStatus: TaskStoreCompletionStatus
) => {
  switch (completionStatus) {
    case TaskStoreCompletionStatus.Completed:
      return IconEnum.CheckCircleOutline;
    case TaskStoreCompletionStatus.Incomplete:
      return IconEnum.AccessTime;
    case TaskStoreCompletionStatus.Overdue:
      return IconEnum.ErrorOutline;
    default:
      return IconEnum.AccessTime;
  }
};
