import React, { useEffect, useState } from 'react';
import { IconButton } from '../Buttons/IconButton';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import { theme } from '../../assets/siteLineTheme';
import { pageNavigationStyles } from './pageNavigationStyles';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from '../../stores/userPreferenceStore';
import { DirectionEnum } from 'src/utils/enum/DirectionEnum';

export type PageNavigationProps = {
  dataCount: number;
  curPage: number;
  onChangePage: Function;
};

const PageNavigation: React.FC<PageNavigationProps> = (props) => {
  const { dataCount, curPage, onChangePage } = props;

  const pageNavigationClasses = pageNavigationStyles();

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const [pageRow, setPageRow] = useState<number>(10);
  const [lastPageNumber, setLastPageNumber] = useState<number>(1);

  useEffect(() => {
    if (userPreference.viewRowNumber !== undefined) {
      setPageRow(userPreference.viewRowNumber);
    }
  }, [userPreference.viewRowNumber]);

  useEffect(() => {
    const findLastPageNumber = Math.ceil(dataCount / pageRow);
    setLastPageNumber(findLastPageNumber > 0 ? findLastPageNumber : 1);
  }, [dataCount, pageRow]);

  return (
    <div
      aria-label={'PageNavigation Component'}
      style={pageNavigationClasses.pageNavButtonsContainer}
    >
      <IconButton
        icon={IconEnum.Next}
        label={'Next Page'}
        size={'2em'}
        onClick={() => {
          if (curPage < lastPageNumber) {
            onChangePage(DirectionEnum.Forward, curPage + 1, pageRow);
          }
        }}
        color={theme.newColorPalette.zoidbergBlack[userPreference.colorMode]}
      />
      <div>
        Page {curPage} of {lastPageNumber}
      </div>
      <IconButton
        icon={IconEnum.Previous}
        label={'Previous Page'}
        size={'2em'}
        onClick={() => {
          if (curPage > 1) {
            onChangePage(DirectionEnum.Back, curPage - 1, pageRow);
          }
        }}
        color={theme.newColorPalette.zoidbergBlack[userPreference.colorMode]}
      />
    </div>
  );
};

export default PageNavigation;
