import drawerPropsStore, {
  defaultDrawerProps,
} from '../../../stores/drawerPropsStore';
import { Drawer } from '@mui/material';
import ContentPopOver from '../../Modal/ContentPopOverModal/ContentPopOver';
import React, { useState } from 'react';
import { generateSignedUrl } from '../../../api/apiFile';
import BoxArray, { BoxContentTypeEnum } from '../../BoxArray/BoxArray';
import { drawerStyles } from '../styles/drawerStyles';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import { IconButton } from 'src/components/Buttons/IconButton';
import { IconEnum } from '../../Chip/chipUtils/ChipEnums';
import { theme } from 'src/assets/siteLineTheme';
import { sortArray } from 'src/utils/sortUtils';
import muiTheme from 'src/utils/mui/muiTheme';

export type FeatureObjects = {
  id: string;
  index: number;
  title: string;
  attachmentsId: string;
  fileInformation: {name: string, type: string}
};

const NewFeaturesDrawer: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState('');
  const [signedUrl, setSignedUrl] = useState('');

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const useStyles = () => {
    return {
      paperAnchorRight: {
        width: '32%',
        [muiTheme.breakpoints.down('xs')]: {
          width: '100%',
        },
        minWidth: '320px',
        overflowY: 'hidden',
      },
    }
  }
  const { drawerProps, setDrawerProps, resetDrawerProps } = drawerPropsStore(
    (state: {
      drawerProps: any;
      setDrawerProps: any;
      resetDrawerProps: Function;
    }) => ({
      drawerProps: state.drawerProps,
      setDrawerProps: state.setDrawerProps,
      resetDrawerProps: state.resetDrawerProps,
    })
  );

  const handleOnClose = () => {
    resetDrawerProps();
  };

  const addIndexToList = (featureList: FeatureObjects[]) => {
    const returnFeatureList:FeatureObjects[] = []
    featureList?.forEach((feature: any, index: number) => {
      returnFeatureList.push({...feature, index});
    })
    return returnFeatureList;
  }

  const [newFeatureLists, setNewFeatureLists] = useState<FeatureObjects[]>(addIndexToList(drawerProps.drawerContentProps.newFeaturesList));
  const [viewedFeatureLists, setViewedFeatureLists] = useState<FeatureObjects[]>(addIndexToList(drawerProps.drawerContentProps.viewedFeaturesList));

  const fileTypeIdentifier = (fileName: any) => {
    return fileName.split('.').pop();
  };

  const openFeatureFile = async (fileName: string, attachmentsId: string) => {

    const { signedUrl } = await generateSignedUrl(
        fileName,
        'read',
        attachmentsId
    );

    setIsOpen(true);
    setSignedUrl(signedUrl);
    setSelectedFileType(fileTypeIdentifier(fileName));
  };

  const classes = useStyles();
  const drawerClasses = drawerStyles({ colorMode: userPreference.colorMode });

  const closeDrawer = () => {
    setDrawerProps({ ...defaultDrawerProps });
  };

  const resetList = (getNewFeatureList:FeatureObjects[], getNewFeaturesViewedEmployeeList:FeatureObjects[]) => {
    setNewFeatureLists(addIndexToList(getNewFeatureList));
    setViewedFeatureLists(addIndexToList(getNewFeaturesViewedEmployeeList));
  };

  return (
    <Drawer
      ModalProps={{ disableEnforceFocus: true }}
      anchor="right"
      open={drawerProps.isDrawerOpen}
      onClose={handleOnClose}
      sx={classes.paperAnchorRight}
    >
      <div>
        <div style={drawerClasses.drawerHeaderContainer}>
          <IconButton
            label={'Exit'}
            icon={IconEnum.Exit}
            onClick={closeDrawer}
            color={theme.colorPalette.backgroundDark}
            margin={'0.3em 0 0.3em 1.5em'}
          />
          <div style={drawerClasses.drawerHeaderText}>Notifications</div>
        </div>
        <div>
          <div style={drawerClasses.drawerSubHeaderText}>New:</div>
          {newFeatureLists.length === 0 ? (
            <BoxArray
              boxContentType={BoxContentTypeEnum.NotificationContent}
              dataArray={[{ title: 'Woohoo! You are all caught up!' }]}
              boxHeight={'100%'}
              boxWidth={'100%'}
              callBack={undefined}
              resetList={undefined}
            />
          ) : (
            <BoxArray
              boxContentType={BoxContentTypeEnum.NotificationContent}
              dataArray={sortArray(newFeatureLists, 'index', 'asc')}
              boxHeight={'100%'}
              boxWidth={'100%'}
              callBack={openFeatureFile}
              resetList={resetList}
            />
          )}
        </div>
        <div style={drawerClasses.archiveContainer}>
          <div style={drawerClasses.drawerSubHeaderText}>Archive:</div>
          <BoxArray
            boxContentType={BoxContentTypeEnum.NotificationContent}
            dataArray={sortArray(viewedFeatureLists, 'index', 'asc')}
            boxHeight={'100%'}
            boxWidth={'100%'}
            callBack={openFeatureFile}
            resetList={undefined}
          />
        </div>
        <ContentPopOver
          isOpen={isOpen}
          contentId={'Notification'}
          contentUrl={signedUrl}
          handleClose={() => setIsOpen(false)}
          contentType={selectedFileType}
        />
      </div>
    </Drawer>
  );
};

export default NewFeaturesDrawer;
