import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import { easyPrintCalendarStyles } from './utils/easyPrintCalendarStyles';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

export type EmptyDateStartBoxesProps = {
  firstDateIndex: string;
};

const EmptyDateStartBoxes: React.FC<EmptyDateStartBoxesProps> = (props) => {
  const { firstDateIndex } = props;
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const easyPrintCalendarClasses = easyPrintCalendarStyles(
    userPreference.colorMode
  );
  const fillerDateBoxArray: ReactElement[] = [];
  for (let i = 0; i < parseInt(firstDateIndex); i++) {
    fillerDateBoxArray.push(
      <Box
        key={i}
        sx={easyPrintCalendarClasses.dateContainer}
        aria-label={'empty date box'}
      ></Box>
    );
  }
  return (
    <>
      {fillerDateBoxArray.map((fillerDateBox: ReactElement) => fillerDateBox)}
    </>
  );
};

export default EmptyDateStartBoxes;
