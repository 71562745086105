import React from 'react';
import { springStylesConstructor } from './springStylesConstructor';
import { animated } from '@react-spring/web';
import { ActiveSpringsArray } from './AnimatedComponentTypes';

export type AnimatedComponentProps = {
  children: React.ReactNode;
  springs: ActiveSpringsArray;
  label: string;
  isPassive: boolean;
  loop?: boolean;
  containerStyleObj?: any | undefined;
};

export const AnimatedComponent: React.FC<AnimatedComponentProps> = (props) => {
  const { isPassive, springs, label, loop, containerStyleObj } = props;
  const springStyles = springStylesConstructor({ isPassive, springs, loop });

  const { children } = props;

  return (
    <animated.div
      aria-label={label}
      style={{
        ...springStyles,
        ...containerStyleObj,
      }}
    >
      {children}
    </animated.div>
  );
};
