export const editNoteStyles = () => {
  /* istanbul ignore next */
  return {
    title: {
      fontSize: '1em',
      fontFamily: 'Roboto-Light',
      fontWeight: '700',
    },
    editNoteContainer: {
      display: 'flex',
      margin: '.25em 0em',
    },
    iconContainer: {
      marginRight: '.5em',
    },
    editNoteHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    editNoteMainContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      width: '100%',
    },
    dateText: {
      fontSize: '0.65em',
      fontFamily: 'Roboto-Light',
    },
    editNoteText: {
      fontSize: '0.85em',
      fontFamily: 'Roboto-Light',
      margin: '0.35em 0em',
    },
    editNoteTextContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
};
