import drawerPropsStore from '../../../stores/drawerPropsStore';
import FilterDrawer from '../FilterDrawer/FilterDrawer';
import { DrawerContentType } from '../../../stores/models/DrawerProps';
import TaskDrawer from '../TaskDrawer/TaskDrawer';
import React from 'react';
import NewFeaturesDrawer from '../NewFeaturesDrawer/NewFeaturesDrawer';

const MainDrawer: React.FC = () => {
  const { drawerProps } = drawerPropsStore((state: { drawerProps: any }) => ({
    drawerProps: state.drawerProps,
  }));

  return (
    <>
      {drawerProps.drawerContentType === DrawerContentType.TaskForm && (
        <TaskDrawer />
      )}
      {drawerProps.drawerContentType === DrawerContentType.Filter && (
        <FilterDrawer />
      )}
      {drawerProps.drawerContentType === DrawerContentType.NewFeatures && (
        <NewFeaturesDrawer />
      )}
    </>
  );
};

export default MainDrawer;
