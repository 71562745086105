import { Box, Typography } from '@mui/material';
import { theme } from '../../../assets/siteLineTheme';
import appliedFiltersStore from '../../../stores/appliedFiltersStore';
import drawerPropsStore from '../../../stores/drawerPropsStore';
import { DrawerContentType } from '../../../stores/models/DrawerProps';
import GenericButton from '../../GenericButton/GenericButton';
import { ButtonEnum } from '../../GenericButton/genericButttonUtils/ButtonEnum';
import { ChipVariantEnum, IconEnum } from '../../Chip/chipUtils/ChipEnums';
import React from 'react';
import Chip from '../../Chip/Chip';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import {ChipStyles} from "../../Forms/styles/chipStyles";


const TaskFilterButton: React.FC = () => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const { appliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
    })
  );
  const { drawerProps, setDrawerProps } = drawerPropsStore(
    (state: { drawerProps: any; setDrawerProps: any }) => ({
      drawerProps: state.drawerProps,
      setDrawerProps: state.setDrawerProps,
    })
  );

  const onClickHandler = () => {
    setDrawerProps({
      ...drawerProps,
      isDrawerOpen: true,
      drawerContentType: DrawerContentType.Filter,
    });
  };

  const countAppliedFilters = () => {
    let filterCount = 0;
    if (appliedFilters.territory_number) filterCount++;
    if (appliedFilters.region_number) filterCount++;
    if (appliedFilters.district_number) filterCount++;
    if (appliedFilters.store_number) filterCount++;
    for (const property in appliedFilters.task_types) {
      if (appliedFilters.task_types[property]) filterCount++;
    }
    return filterCount;
  };

  const screenSizeFunction = window.innerWidth > 421;

  return (
    <>
      {screenSizeFunction && appliedFilters.isSavedFilter !== undefined && (
        <Chip
          variant={ChipVariantEnum.Filled}
          chipBackgroundColor={
            theme.newColorPalette.zoidbergWhite[userPreference.colorMode]
          }
        >{`Saved Filter`}</Chip>
      )}
      {!screenSizeFunction && (
        <Box
          sx={ChipStyles(userPreference.colorMode).filterCountChip}
          aria-label={'Active Filters Count Chip'}
        >
          <Typography>{countAppliedFilters()}</Typography>
        </Box>
      )}

      <GenericButton
        variant={ButtonEnum.Filled}
        buttonTextColor={
          theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
        }
        iconEnum={IconEnum.TuneIcon}
        clickCallback={onClickHandler}
      >
        FILTERS
      </GenericButton>
    </>
  );
};

export default TaskFilterButton;
