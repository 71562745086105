import {theme} from "../../../assets/siteLineTheme";
import muiTheme from "../../../utils/mui/muiTheme";
import {ColorModeTypeEnum} from "../../../stores/userPreferenceStore";

/** Box **/
export const ToolbarStyles = (colorMode: ColorModeTypeEnum) => {
    return {
        root: {
            [muiTheme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        navArrow: {
            color: theme.newColorPalette.zoidbergBlack[colorMode],
            cursor: 'pointer',
            '& path': {
                boxShadow:
                    '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2)',
            },
        },
        toolBarViewOptionsLi: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            fontSize: '1em',
            cursor: 'pointer',
            padding: '1px 5px',
            width: '30%',
        },
        dateRangeLabel: {
            padding: '0rem',
            fontSize: '1.7em',
            fontWeight: 'bold',
            color: theme.newColorPalette.plainWhiteBlack[colorMode],
        },
        viewToggleContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: window.innerWidth > 420 ? 'flex-end' : 'center',
            width: '100%',
            fontSize: '.875rem',
        },
    }
}