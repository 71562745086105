import { theme } from '../../../assets/siteLineTheme';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

export const linkComponentStyles = (colorMode: ColorModeTypeEnum) => {
  /* istanbul ignore next */
  return {
    removeLinkStyles: {
      textDecoration: 'none',
      color: theme.colorPalette.textDark,
    },
    taskManagementListItem: {
      fontFamily: 'Roboto-Light',
      display: 'flex',
      flexDirection: 'row' as 'row',
      alignItems: 'center',
    },
    menuItemTitle: {
      margin: '.2em 0px',
    },
    taskManagementArrowIcon: {
      margin: '.5em',
      color: theme.newColorPalette.zoidbergBlack[colorMode],
    },
  };
};
