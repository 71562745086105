export const baseModalStyles = () => {
  /* istanbul ignore next */
  return {
    baseModalContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      placeContent: 'center',
    },
  };
};
