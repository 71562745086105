export const storeNumberAccordionStyles = () => {
  /* istanbul ignore next */
  return {
    districtAccordionHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
  };
};
