import { IconButton, Toolbar } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { ReactComponent as Logo } from '../../assets/images/Siteline-Icon.svg';
import searchBarPropsStore from '../../stores/searchBarPropsStore';
import { SetState } from 'zustand';
import ExternalLinkComponent from '../AppHeaderBar/ExternalLinkComponent';
import { externalNavigationMenuItems } from '../../context/constants';
import React from 'react';
import { navigationComponentStyles } from '../AppHeaderBar/AppHeaderBarStyles/navigationComponentStyles';
import { InternalLinkComponent } from '../AppHeaderBar/InternalLinkComponent';
import { sortArray } from '../../utils/sortUtils';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

export type menuListProps = {
  path: string;
  name: string;
};
export type TaskManagementNavigationProps = {
  setIsTaskManagementNavigationDrawerOpen: Function;
  menuList: menuListProps[];
  colorMode: ColorModeTypeEnum;
};

const TaskManagementNavigation: React.FC<TaskManagementNavigationProps> = (
  props
) => {
  const { setIsTaskManagementNavigationDrawerOpen, menuList, colorMode } =
    props;

  const packageJSON = require('../../../package.json');

  const toolsAndResourcesStringsForFiltering = [
    'Mobile Metrics',
    'Merchandising & Visual Digital Communication',
    'Safety Video & Communication'
  ];

  const toolsAndResourcesList = menuList.filter((listItem: menuListProps) => {
    let isTrue = false;
    toolsAndResourcesStringsForFiltering.forEach((checkString: string) => {
      if (listItem.name === checkString) {
        isTrue = true;
      }
    });
    return isTrue;
  });

  const filteredMenuList = menuList.filter((listItem: menuListProps) => {
    if (
      listItem.name === 'Mobile Metrics' ||
      listItem.name === 'Merchandising & Visual Digital Communication' ||
      listItem.name === 'Safety Video & Communication'
    ) {
      return false;
    } else {
      return true;
    }
  });

  const {
    leftDrawerCloseIcon,
    taskManagementItemsWrapper,
    taskManagementDrawerToolBar,
    sitelineLogoIcon,
    menuListTitle,
    menuListTitleNoTopMargin,
    menuListVersionWrapper,
    menuListVersion,
  } = navigationComponentStyles(colorMode);

  // zustand store
  const { setSearchBarProps } = searchBarPropsStore(
    (state: { setSearchBarProps: SetState<any> }) => ({
      setSearchBarProps: state.setSearchBarProps,
    })
  );

  const handleClick = () => {
    setIsTaskManagementNavigationDrawerOpen(false);
  };

  const clearSearch = () => {
    window.onhashchange &&
      setSearchBarProps({
        options: [{ title: 'Search for this page coming soon!' }],
        clickHandler: () => {},
      });
  };

  return (
    <>
      <Toolbar style={taskManagementDrawerToolBar}>
        <Logo title="siteline-logo" style={sitelineLogoIcon} />
        <IconButton
          role="close"
          onClick={() => handleClick()}
          style={leftDrawerCloseIcon}
        >
          <Cancel />
        </IconButton>
      </Toolbar>
      <div style={menuListVersionWrapper}>
        <div>
          <h4 style={menuListTitle}>Task Management</h4>
          <ul style={taskManagementItemsWrapper}>
            {sortArray(filteredMenuList, 'name', 'desc').map(
              (menuItem: menuListProps, index: number) => {
                return (
                  <InternalLinkComponent
                    key={index}
                    to={menuItem.path}
                    colorMode={colorMode}
                    menuItem={menuItem}
                    clearSearch={clearSearch}
                    handleClick={handleClick}
                  />
                );
              }
            )}
          </ul>
          <h4 style={menuListTitleNoTopMargin}>Tools & Resources</h4>
          <ul style={taskManagementItemsWrapper}>
            {toolsAndResourcesList.map(
              (menuItem: menuListProps, index: number) => {
                return (
                  <InternalLinkComponent
                    key={index}
                    to={menuItem.path}
                    colorMode={colorMode}
                    menuItem={menuItem}
                    clearSearch={clearSearch}
                    handleClick={handleClick}
                  />
                );
              }
            )}
          </ul>
          <h4 style={menuListTitleNoTopMargin}>Quick Links</h4>
          <ul style={taskManagementItemsWrapper}>
            <ExternalLinkComponent
              menuItem={externalNavigationMenuItems.keyContacts}
              colorMode={colorMode}
              handleClick={handleClick}
              clearSearch={clearSearch}
              googleAnalyticsEvent={
                externalNavigationMenuItems.keyContacts.googleAnalyticsEvent
              }
            />
          </ul>
        </div>
        <div style={menuListVersion}>
          <span>{`version: ${packageJSON.version}`}</span>
        </div>
      </div>
    </>
  );
};

export default TaskManagementNavigation;
