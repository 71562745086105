export const FormStyles = (headerComponentHeight: string) => {
    return {
        boxStretch: {
            height: `calc(100% - ${headerComponentHeight}px - 15px)`,
            [`@media (maxWidth:599.95px)`]: {
                height: `calc(100% - ${headerComponentHeight}px - 40px)`,
            },
        },
    }

}