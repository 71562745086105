// import {theme} from "../../../assets/siteLineTheme";

import { theme } from '../../../assets/siteLineTheme';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

export type BaseButtonStylesParams = {
  isActive: boolean;
  activeBackgroundColor: string;
  passiveBackgroundColor: string;
  activeTextColor: string;
  passiveTextColor: string;
  fontSize: string | undefined;
  margin: string | undefined;
  padding: string | undefined;
  borderRadius: string | undefined;
  borderStyle: string | undefined;
  borderWidth: string | undefined;
  borderColor: string | undefined;
  isSelected: boolean | undefined;
  changeColorWhenSelected: boolean | undefined;
  colorMode: ColorModeTypeEnum | undefined;
};

export const baseButtonStyles = (params: BaseButtonStylesParams): any => {
  const {
    isActive,
    activeBackgroundColor,
    passiveBackgroundColor,
    activeTextColor,
    passiveTextColor,
    fontSize,
    margin,
    padding,
    borderRadius,
    borderWidth,
    borderStyle,
    borderColor,
    isSelected,
    changeColorWhenSelected,
    colorMode,
  } = params;
  /* istanbul ignore next */
  return {
    disabledButton: {
      pointerEvent: 'none',
      // cursor: 'none',
      backgroundColor:
        colorMode !== undefined
          ? theme.newColorPalette.backgroundLightWhite[colorMode]
          : '',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      fontSize: fontSize !== undefined ? fontSize : '.875rem',
      fontWeight: 500,
      lineHeight: 1.75,
      borderRadius: borderRadius !== undefined ? borderRadius : '4px',
      borderWidth: borderWidth !== undefined ? borderWidth : '0',
      borderStyle: borderStyle !== undefined ? borderStyle : 'solid',
      borderColor:
        colorMode !== undefined
          ? theme.newColorPalette.backgroundDarkBlack[colorMode]
          : '',
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
      padding: padding !== undefined ? padding : '6px 8px',
      width: 'fit-content',
      height: 'fit-content',
      margin,
    },
    baseButton: {
      backgroundColor: isActive
        ? activeBackgroundColor
        : changeColorWhenSelected
        ? activeBackgroundColor
        : passiveBackgroundColor,
      color: isActive ? activeTextColor : passiveTextColor,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      fontSize: fontSize !== undefined ? fontSize : '.875rem',
      fontWeight: 500,
      lineHeight: 1.75,
      borderRadius: borderRadius !== undefined ? borderRadius : '4px',
      borderWidth: borderWidth !== undefined ? borderWidth : '0',
      borderStyle: borderStyle !== undefined ? borderStyle : undefined,
      borderColor: borderColor !== undefined ? borderColor : undefined,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
      padding: padding !== undefined ? padding : '6px 8px',
      cursor: 'pointer',
      width: 'fit-content',
      height: 'fit-content',
      margin,
      transform: isSelected ? 'scale(1.1)' : 'scale(1)',
      zIndex: isSelected ? '2' : undefined,
    },
  };
};
