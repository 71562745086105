import { NewMerchMenuObjPDFType } from '../../api/apiDigitalMerchandising';

export type NavigationObj = {
  id: string;
  code: string;
  menu_path: string;
  title: string;
  url: string | null;
  parent_code: string | null;
  level: number;
  row_number: number;
  link_type: DigitalMerchandisingLinkTypeEnum | null;
  is_active?: boolean;
  sort_number: number;
  pdf_file_information?: NewMerchMenuObjPDFType;
};

/* eslint-disable */
export enum DigitalMerchandisingLinkTypeEnum {
  Flipping = 'Flipping',
  GSite = 'GSite',
  Pdf = 'Pdf',
}

export const demoDataArray: NavigationObj[] = [
  {
    id: '1',
    code: '1',
    menu_path: 'Merchandising',
    title: 'Merchandising',
    url: null,
    parent_code: null,
    level: 0,
    row_number: 1,
    link_type: null,
    sort_number: 1,
    is_active: true,
  },

  {
    id: '13',
    code: '13',
    menu_path: 'Merchandising/Accessories',
    title: 'Accessories',
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 13,
    link_type: null,
    sort_number: 2,
  },
  {
    id: '14',
    code: '14',
    menu_path: 'Merchandising/Bath & Bedding',
    title: 'Bath & Bedding',
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 14,
    link_type: null,
    sort_number: 3,
  },
  {
    id: '15',
    code: '15',
    menu_path: 'Merchandising/Electronics',
    title: 'Electronics',
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 15,
    link_type: null,
    sort_number: 4,
  },
  {
    id: '16',
    code: '16',
    menu_path: "Merchandising/Discover @ Kohl's / Impulse",
    title: `Discover @ Kohl's / Impulse`,
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 16,
    link_type: null,
    sort_number: 5,
  },
  {
    id: '17',
    code: '17',
    menu_path: 'Merchandising/Home Decor & Seasonal',
    title: 'Home Decor & Seasonal',
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 17,
    link_type: null,
    sort_number: 6,
  },
  {
    id: '18',
    code: '18',
    menu_path: 'Merchandising/Housewares & Luggage',
    title: 'Housewares & Luggage',
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 18,
    link_type: null,
    sort_number: 7,
  },
  {
    id: '19',
    code: '19',
    menu_path: 'Merchandising/Intimates / Sleepwear',
    title: 'Intimates / Sleepwear',
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 19,
    link_type: null,
    sort_number: 7,
  },
  {
    id: '20',
    code: '20',
    menu_path: 'Merchandising/Jewelry',
    title: 'Jewelry',
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 20,
    link_type: null,
    sort_number: 8,
  },
  {
    id: '21',
    code: '21',
    menu_path: 'Merchandising/Kids & Toys',
    title: 'Kids & Toys',
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 21,
    link_type: null,
    sort_number: 9,
  },
  {
    id: '22',
    code: '22',
    menu_path: "Merchandising/Men's and Young Men's",
    title: "Men's and Young Men's",
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 22,
    link_type: null,
    sort_number: 10,
  },
  {
    id: '23',
    code: '23',
    menu_path: 'Merchandising/Shoes',
    title: 'Shoes',
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 23,
    link_type: null,
    sort_number: 11,
  },
  {
    id: '24',
    code: '24',
    menu_path: 'Merchandising/Storewide',
    title: 'Storewide',
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 24,
    link_type: null,
    sort_number: 12,
  },
  {
    id: '25',
    code: '25',
    menu_path: "Merchandising/Sephora @ Kohl's",
    title: "Sephora @ Kohl's",
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 25,
    link_type: null,
    sort_number: 13,
  },
  {
    id: '4',
    code: '4',
    menu_path: "Merchandising/Women's & Young Women's",
    title: "Women's & Young Women's",
    url: null,
    parent_code: '1',
    level: 1,
    row_number: 2,
    link_type: null,
    sort_number: 14,
    is_active: true,
  },
  {
    id: '9',
    code: '9',
    menu_path:
      "Merchandising/Women's & Young Women's/Women's Oct Wk 3 - Dec Wk 5",
    title: "Women's Oct Wk 3 - Dec Wk 5",
    url: 'https://online.flippingbook.com/view/529376201/',
    parent_code: '4',
    level: 2,
    row_number: 6,
    link_type: DigitalMerchandisingLinkTypeEnum.Flipping,
    sort_number: 15,
  },
  {
    id: '10',
    code: '10',
    menu_path:
      "Merchandising/Women's & Young Women's/Young Women's Oct Wk 3 - Dec Wk 5",
    title: "Young Women's Oct Wk 3 - Dec Wk 5",
    url: 'https://online.flippingbook.com/view/39152679/',
    parent_code: '4',
    level: 2,
    row_number: 7,
    link_type: DigitalMerchandisingLinkTypeEnum.Flipping,
    sort_number: 16,
  },
  {
    id: '3',
    code: '3',
    menu_path: 'Mannequins',
    title: 'Mannequins',
    url: null,
    parent_code: null,
    level: 0,
    row_number: 10,
    link_type: null,
    sort_number: 17,
  },
  {
    id: '11',
    code: '11',
    menu_path: 'Mannequins/Jan Wk 1 - Jan Wk 4 Digital Mannequin Direction',
    title: 'Jan Wk 1 - Jan Wk 4 Digital Mannequin Direction',
    url: 'https://sites.google.com/kohls.com/mannequinsjan23/home',
    parent_code: '3',
    level: 1,
    row_number: 11,
    link_type: DigitalMerchandisingLinkTypeEnum.GSite,
    sort_number: 18,
  },
  {
    id: '12',
    code: '12',
    menu_path: 'Mannequins/Feb Wk 1 - Feb Wk 4 Digital Mannequin Direction',
    title: 'Feb Wk 1 - Feb Wk 4 Digital Mannequin Direction',
    url: 'https://sites.google.com/kohls.com/mannequinsjan23/home', // todo - update with actual
    parent_code: '3',
    level: 1,
    row_number: 12,
    link_type: DigitalMerchandisingLinkTypeEnum.Pdf,
    sort_number: 19,
  },
];
