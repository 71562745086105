import { useQuery } from '@tanstack/react-query';
import { getTaskCompletionData } from '../api/apiTaskCompletionData';

export const useFetchTaskCompletionData = (
  completionDashboardQueryString: string,
  triggerTime: Date
) => {
  const { data, error, isLoading } = useQuery(
    ['taskCompletionData', triggerTime.getTime()],
    () => getTaskCompletionData(completionDashboardQueryString)
  );
  return { data, error, isLoading };
};
