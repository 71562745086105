import { ViewOptionsProps } from './CalendarToolbar';

export const viewOptions: Array<ViewOptionsProps> = [
  {
    key: 'Day',
    value: 'day',
    clickHandler: () => null,
  },
  {
    key: 'Week',
    value: 'week',
    clickHandler: () => null,
  },
  {
    key: 'Month',
    value: 'month',
    clickHandler: () => null,
  },
];
