import { CalendarEvent } from '../../types/Calendar/ICalendarIndex';
import { tableCellStyles } from '../../Table/TableCell/tableCellStyles';
import TableRowSwitch from '../../Table/TableRowSwitch/TableRowSwitch';
import {TableRowTypeEnum} from "../../../utils/enum/TableRowTypeEnum";

export type TaskListViewContentProps = {
  contentData: CalendarEvent | any;
  type: TableRowTypeEnum;
  callBack?: Function | undefined;
  resetList?: Function | undefined;
};

const TaskListViewContent = (props: TaskListViewContentProps) => {
  const { contentData, type, callBack, resetList } = props;

  return (
    <div style={tableCellStyles({}).rowContainer}>
      <TableRowSwitch
        contentData={contentData}
        tableRowType={type}
        callBack={callBack}
        resetList={resetList}
      />
    </div>
  );
};

export default TaskListViewContent;
