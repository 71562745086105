import { create, SetState } from 'zustand';
import { format, startOfWeek, endOfWeek } from 'date-fns';

/* eslint-disable */
export enum DateRangeTypeEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export type DateSelectionForDisplayStoreProps = {
  dateRangeType: DateRangeTypeEnum;
  selectedDisplayDate: Date;
  dateLabel: string;
};

export const dateLabelGenerator = (params: {
  dateRangeTypeEnum: DateRangeTypeEnum;
  selectedDisplayDate: Date;
}) => {
  const { dateRangeTypeEnum, selectedDisplayDate } = params;

  if (dateRangeTypeEnum === DateRangeTypeEnum.DAY) {
    const monthName = format(new Date(selectedDisplayDate), 'MMM');
    const day = format(new Date(selectedDisplayDate), 'd');
    return monthName + ' ' + day;
  } else {
    const start = startOfWeek(new Date(selectedDisplayDate));
    const end = endOfWeek(new Date(selectedDisplayDate));

    const startMon = format(new Date(start), 'MMM'); // Month Feb
    const endMon = format(new Date(end), 'MMM');
    const startDay = format(new Date(start), 'd');
    const endDay = format(new Date(end), 'd');

    if (startMon === endMon) {
      return `${startMon} ${startDay} - ${endDay}`;
    } else {
      return `${startMon} ${startDay} - ${endMon} ${endDay}`;
    }
  }
};

export const defaultDateSelectionForDisplayStoreProps: DateSelectionForDisplayStoreProps =
  {
    dateRangeType: DateRangeTypeEnum.WEEK,
    selectedDisplayDate: new Date(Date.now()),
    dateLabel: dateLabelGenerator({
      dateRangeTypeEnum: DateRangeTypeEnum.WEEK,
      selectedDisplayDate: new Date(Date.now()),
    }),
  };

const dateSelectionForDisplayStore = create((set: SetState<any>) => ({
  dateSelectionForDisplayProps: { ...defaultDateSelectionForDisplayStoreProps },
  setDateSelectionForDisplayProps: (
    dateSelectionForDisplayProps: DateSelectionForDisplayStoreProps
  ) => {
    set(() => ({ dateSelectionForDisplayProps }));
  },
}));

export default dateSelectionForDisplayStore;
