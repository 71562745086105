import { env } from '../envConfig';
import { getDataWithOktaTokens } from './apiUtils';
import axios, { AxiosResponse } from 'axios';
import { apiRequestConfig } from './apiV1Task';
import { DigitalMerchandisingLinkTypeEnum } from '../utils/testData/mockHierarchyData';

export const getDigitalMerchandisingData = async () => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/merch`;
    return getDataWithOktaTokens(url);
  } catch (error) {
    return null;
  }
};

export const setMerchRecentlyViewed = async (merchandising_id: string) => {
  try {
    const response: AxiosResponse = await axios.put(
      `${env.REACT_APP_BACKEND_URL}/api/v1/merch/recentlyViewed`,
      {
        merchandising_id,
      },
      await apiRequestConfig()
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getMerchRecentlyViewed = async () => {
  try {
    const url = `${env.REACT_APP_BACKEND_URL}/api/v1/merch/recentlyViewed`;
    return getDataWithOktaTokens(url);
  } catch (error) {
    return null;
  }
};

export type NewMerchMenuObjPDFType = {
  attachments_id: string;
  name: string;
  size: number;
  type: string;
};

export type NewMerchMenuObjType = {
  code: string;
  title: string;
  parent_code: string | null;
  level: number;
  url: string | null;
  link_type: DigitalMerchandisingLinkTypeEnum | null;
  is_active: boolean;
  sort_number: number;
  pdf_file_information?: NewMerchMenuObjPDFType;
};

export const createOrUpdateNewMerchMenu = async (
  newMerchMenuObj: NewMerchMenuObjType
) => {
  try {
    const response: AxiosResponse = await axios.put(
      `${env.REACT_APP_BACKEND_URL}/api/v1/merch`,
      {
        ...newMerchMenuObj,
      },
      await apiRequestConfig()
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const deleteMerchMenu = async (merchMenuCode: string) => {
  try {
    const response: AxiosResponse = await axios.delete(
      `${env.REACT_APP_BACKEND_URL}/api/v1/merch/${merchMenuCode}`,
      await apiRequestConfig()
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const updateMerchMenuFileInfo = async (merchMenuCode: string) => {
  try {
    const response: AxiosResponse = await axios.put(
      `${env.REACT_APP_BACKEND_URL}/api/v1/merch/${merchMenuCode}/fileInfo`,
      {},
      await apiRequestConfig()
    );
    return response.data;
  } catch (error) {
    return null;
  }
};
