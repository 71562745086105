import { theme } from 'src/assets/siteLineTheme';

export const viewTypeStyles = () => {
  return {
    viewTypeContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
    },
    viewTypeTitle: {
      fontSize: '1.1em',
      fontWeight: '700',
      margin: '0.2em 0',
    },
    viewTypeRadioContainer: {
      display: 'flex',
    },
    viewTypeRadio: {
      display: 'flex',
      alignItems: 'center',
    },
    viewTypeText: {
      fontSize: '0.9em',
    },
    viewTypeInformText: {
      margin: '0.2em 0 0 0.5em',
      fontSize: '0.5em',
      color: theme.colorPalette.lighterGrey,
    },
  };
};
