import React, { useEffect, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { SelectOptionType } from '../../../utils/Data/dropboxDataList';
import { multiDropdownSelectorStyles } from './multiDropdownSelectorStyles';

export type MultiDropdownSelectorProps = {
  dataOption: Array<SelectOptionType>;
  placeholderText: string;
  onChange: Function;
  targetObjField: string;
  labelText?: string;
  isDisabled?: boolean;
  resetValue?: boolean;
};

const MultiDropdownSelector: React.FC<MultiDropdownSelectorProps> = (props) => {
  const {
    dataOption,
    placeholderText,
    onChange,
    targetObjField,
    labelText,
    isDisabled,
    resetValue,
  } = props;
  const [value, setValue] = useState<MultiValue<SelectOptionType>>([]);

  const multiDropdownSelectorClasses = multiDropdownSelectorStyles();

  const handleChange = (newSelections: MultiValue<SelectOptionType>) => {
    setValue(newSelections);
    onChange(newSelections, targetObjField);
  };

  useEffect(() => {
    if (resetValue) {
      setValue([]);
      onChange([], targetObjField);
    }
  }, [resetValue]);

  return (
    <div
      aria-label={'MultiDropdownSelector Component'}
      style={multiDropdownSelectorClasses.multiDropboxContainer}
    >
      <div>
        {labelText && (
          <div style={multiDropdownSelectorClasses.multiDropboxTextContainer}>
            {labelText}
          </div>
        )}
        <Select
          aria-label={'MultiDropdownSelector DropBox'}
          value={value}
          onChange={handleChange}
          isMulti
          options={dataOption}
          placeholder={placeholderText}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default MultiDropdownSelector;
