import { theme } from '../../assets/siteLineTheme';

export const notificationWithRedCircleStyles = (colorMode: string) => {
  return {
    notifContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    redCircle: {
      display: 'flex',
      height: '0.55em',
      width: '0.55em',
      borderRadius: '0.55em',
      backgroundColor:
        theme.newColorPalette.notificationBellRedCircle[colorMode],
      border: `1.5px solid ${theme.newColorPalette.notificationBellRedCircleOutline[colorMode]}`,
      position: 'absolute' as 'absolute',
      zIndex: 12,
    },
  };
};
