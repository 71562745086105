import { theme } from '../../../assets/siteLineTheme';

export const generalSectionStyles = () => {
  /* istanbul ignore next */
  return {
    paper: {
      padding: '16px',
      margin: '0 1em 1em 1em',
      background: theme.colorPalette.plainWhite,
      width: '100%',
      display: 'flex',
      flexDirection: 'column' as 'column',
    },
    sectionTitle: {
      fontSize: '.85em',
      color: 'rgba(0, 0, 0, 0.54)',
      padding: '6px 0px',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    accordionContainer: {
      marginBottom: '0.3em',
    },
  };
};
