import { create, SetState } from 'zustand';

export type CommentsModalContentStoreState = {
  task_id: string;
  location_type: 'district' | 'region' | 'territory';
  location_number: string;
  location_name: string;
};

const defaultCommentsModalContentState: CommentsModalContentStoreState = {
  task_id: '',
  location_type: 'district',
  location_number: '',
  location_name: '',
};

const commentsModalContentStore = create((set: SetState<any>) => ({
  commentsModalContentStoreProps: { ...defaultCommentsModalContentState },

  setCommentsModalContent: (
    commentsModalContentStoreProps: CommentsModalContentStoreState
  ) => {
    set(() => ({ commentsModalContentStoreProps }));
  },
}));

export default commentsModalContentStore;
