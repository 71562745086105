import {theme} from "../../../assets/siteLineTheme";
import {ColorModeTypeEnum} from "../../../stores/userPreferenceStore";
import muiTheme from "../../../utils/mui/muiTheme";

/** Box **/
export const BoxStyles = (colorMode: ColorModeTypeEnum) => {
    return {
        drawerBackgroundBox: {
            zIndex: -1,
            height: `33vh`,
            backgroundColor: theme.newColorPalette.backgroundDarkWhite[colorMode],
            width: '100%',
            position: 'absolute',
        },
        drawerStyledBackgroundBox: {
            zIndex: -1,
            height: `33vh`,
            width: '100%',
            position: 'absolute',
            left: 0,
        },
        contentContainer: {
            width: '100%',
            flex: 1,
            overflowY: 'auto',
        },
        filterSearchBox: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        filterDrawerContentContainer: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
        },
        feedBackBox: {
            position: 'fixed',
            top: '50%',
            right: '-2.5em',
            width: '115px',
            height: '0px',
            textAlign: 'right',
            zIndex: 12,
            marginTop: '-15px',
        },
        calendarToolBarComponent: {
            display: 'flex',
            flexDirection: 'column',
        },
        calendarToolBarViewOptionsUl: {
            display: 'flex',
            flexDirection: 'row',
            listStyle: 'none',
            paddingLeft: '1rem',
            alignItems: 'center',
            [muiTheme.breakpoints.down('sm')]: {
                padding: '0rem',
                flexDirection: 'column',
                justifyContent: 'center',
            },
        },
        calendarHalfFullMobile: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [muiTheme.breakpoints.down('sm')]: {
                width: '100%',
                margin: '.25rem .5rem',
            },
        },
        calendarToolBarNavigator: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            [muiTheme.breakpoints.up('md')]: {
                width: '20vw',
            },
        },
        calendarRightSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            minWidth: '33%',
            [muiTheme.breakpoints.between('xs', 'sm')]: {
                width: '100%',
                alignItems: 'center',
                flexDirection: 'column-reverse',
            },
            justifyContent: 'space-between',
            paddingBottom: '.75em',
        }
    }

}