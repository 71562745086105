import { create, SetState } from 'zustand';

export type ConfirmModalStoreState = {
  message: string;
  confirmButtonText: string;
  confirmOnClickAction: Function;
  task_id?: string | undefined;
};

export const defaultConfirmModalState: ConfirmModalStoreState = {
  message: '',
  confirmButtonText: '',
  task_id: undefined,
  confirmOnClickAction: () => null,
};

const confirmModalStore = create((set: SetState<any>) => ({
  confirmModalStoreProps: { ...defaultConfirmModalState },

  setConfirmModal: (confirmModalStoreProps: ConfirmModalStoreState) => {
    set(() => ({ confirmModalStoreProps }));
  },
}));

export default confirmModalStore;
