import React from 'react';
import { NavigationObj } from '../../utils/testData/mockHierarchyData';
import BoxArray, { BoxContentTypeEnum } from '../BoxArray/BoxArray';
import { breadcrumbNavigationBarStyles } from './breadcrumbNavigationBarStyles';
import { IconContext } from 'react-icons';
import IconSelector from '../Chip/IconSelector';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';

export type BreadcrumbNavigationBarProps = {
  navigationObj: NavigationObj;
  onClick: Function;
  isRecentlyViewed?: boolean | undefined;
};

export const breadcrumbNavigationPathParser = (path: string) => {
  const splitPathStringArray = path.split('/');

  const numberOfIndexesInArray = splitPathStringArray.length;
  const returnedPathArray = [];

  let i = 0;

  for (i; i < numberOfIndexesInArray; i++) {
    let dynamicPath = '';

    for (let p = 0; p <= i; p++) {
      dynamicPath += splitPathStringArray[p];
      if (p !== i) dynamicPath += '/';
    }

    const pathObj = {
      path: dynamicPath,
      title:
        splitPathStringArray[i].charAt(0).toUpperCase() +
        splitPathStringArray[i].substring(1),
    };

    returnedPathArray.push(pathObj);
  }
  return returnedPathArray;
};

const BreadcrumbNavigationBar: React.FC<BreadcrumbNavigationBarProps> = (
  props
) => {
  const { navigationObj, onClick } = props;

  const breadcrumbArrayStrings = breadcrumbNavigationPathParser(
    navigationObj.menu_path
  );

  const backObj = breadcrumbArrayStrings[breadcrumbArrayStrings.length - 2];
  const breadcrumbNavigationBarClasses = breadcrumbNavigationBarStyles();

  return (
    <div style={breadcrumbNavigationBarClasses.barContainer}>
      <div
        onClick={() => onClick(backObj.path)}
        style={breadcrumbNavigationBarClasses.iconBackground}
        aria-label={'Go Back One Step Button'}
      >
        <IconContext.Provider value={breadcrumbNavigationBarClasses.icon}>
          {IconSelector(IconEnum.Previous)}
        </IconContext.Provider>
      </div>
      <BoxArray
        dataArray={breadcrumbArrayStrings}
        boxHeight={'fit-content'}
        boxWidth={`fit-content`}
        boxContentType={BoxContentTypeEnum.BreadcrumbContent}
        onClick={onClick}
        justifyContent={'flex-start'}
      />
    </div>
  );
};

export default BreadcrumbNavigationBar;
