import React from 'react';
import { navigationBoxContentStyles } from './navigationBoxContentStyles';
import { CalendarEvent } from '../../types/Calendar/ICalendarIndex';
import { NavigationObj } from '../../../utils/testData/mockHierarchyData';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from '../../../stores/userPreferenceStore';

export type NavigationBoxContentProps = {
  contentData: NavigationObj | CalendarEvent | any;
  onClick: Function | undefined;
  boxHeight: string | undefined;
  isSelected: boolean | undefined;
  callBack: Function | undefined;
  fromDirectUrl?: { fromDirectUrl: boolean; url: string } | undefined;
};

const NavigationBoxContent: React.FC<NavigationBoxContentProps> = (props) => {
  const { contentData, onClick, isSelected } = props;

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const navigationBoxContentClasses = navigationBoxContentStyles({
    data: contentData,
    image: contentData.image,
    isSelected,
    isInactive: !contentData.is_active,
    colorMode: userPreference.colorMode,
  });

  const hId = contentData.url && contentData.url.split('/')[4];

  const handleOnClick = (navigationObj: string) => {
    if (onClick !== undefined) {
      onClick(navigationObj);
    }
  };

  return (
    <div
      style={navigationBoxContentClasses.navigationBoxContentContainerOutline}
      className={'navigationBoxContentContainerOutline'}
    >
      {
        <div
          className={'navigationBoxContentContainer'}
          aria-label={'navigationBoxContentContainer'}
          style={navigationBoxContentClasses.navigationBoxContentContainer}
          onClick={() => handleOnClick(contentData)}
        >
          <div
            className={'navigationBoxContentTextContainer'}
            style={
              navigationBoxContentClasses.navigationBoxContentTextContainer
            }
          >
            {decodeURIComponent(contentData.title)}
            {(hId === null || hId.length === 0) && !contentData.is_active && (
              <div
                style={{
                  fontSize: '.85em',
                  marginTop: '.65em',
                  color: '#5e5e5e',
                }}
              >
                Coming Soon
              </div>
            )}
          </div>
        </div>
      }
    </div>
  );
};

export default NavigationBoxContent;
