import { theme } from '../../../assets/siteLineTheme';

export const gSiteNavigationContentStyles = (boxHeight: string | undefined) => {
  return {
    contentContainer: {
      display: 'flex',
      width: '92.5%',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column' as 'column',
      height: boxHeight !== undefined ? boxHeight : 'fit-content',
      textAlign: 'center' as 'center',
      cursor: 'pointer',
      border: `2px solid ${theme.colorPalette.textDark}`,
      borderRadius: '.5em',
      boxShadow: `3px 2px ${theme.colorPalette.textDark}`,
      margin: '0 auto',
    },
    iconBackground: {
      borderRadius: '100%',
      backgroundColor: theme.colorPalette.textDark,
      opacity: 0.8,
      padding: '.5em',
      margin: '.75em',
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      color: theme.colorPalette.plainWhite,
      size: '2em',
    },
    titleText: {
      margin: '.5em',
      fontSize: '.85em',
    },
  };
};
