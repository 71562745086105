import { styled } from "@mui/material/styles";
import muiTheme from "../../../utils/mui/muiTheme";
import * as SitelineTheme from "../../../assets/siteLineTheme";
import {ColorModeTypeEnum} from "../../../stores/userPreferenceStore";

/** DIV **/
export const RootDiv = styled("div")<{ colorMode: ColorModeTypeEnum }>(({ theme, colorMode }) => ({
    flexGrow: 1,
    background: SitelineTheme.theme.newColorPalette.backgroundLightWhite[colorMode],
    zIndex: 1,
    padding: `0 ${muiTheme.spacing(2)}px`,
    paddingTop: 0,
    position: 'relative',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
}));

export const DrawerHeaderContainerDiv = styled("div")(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0px',
    width: '100%',
}));

export const DrawerHeaderSectionDiv = styled("div")(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '1.5em',
    padding: '.25em 2em .25em 2em',
    marginRight: '48px',
    width: '100%',
}));

export const BoxStretchDiv = styled("div")<{ headerComponentHeight: string }>(({ theme, headerComponentHeight }) => ({
    height: `calc(100% - ${headerComponentHeight}px - 15px)`,
    [muiTheme.breakpoints.between('xs', 'sm')]: {
        height: `calc(100% - ${headerComponentHeight}px - 40px)`,
    },
}));

export const TextEditorDiv = styled("div")<{ formMode: string | undefined }>(({ theme, formMode }) => ({
    height: 'auto',
    color: 'black',
    backgroundColor: 'white',
    border: formMode === 'view' ? 'none' : '1px solid #ccc',
    [muiTheme.breakpoints.between('xs', 'md')]: {
        overflowY: 'auto',
    },
}));

export const TaskButtonsGridDiv = styled("div")(({ theme }) => ({
    height: 'fit-content',
    marginTop: muiTheme.spacing(1),
    '& > *': {
        marginTop: muiTheme.spacing(1),
    },
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '10px',
    margin: '1em',
    width: '93%',
}));


