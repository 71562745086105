import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';
import { theme } from '../../../assets/siteLineTheme';

export const navigationComponentStyles = (colorMode: ColorModeTypeEnum) => {
  /* istanbul ignore next */
  return {
    leftDrawerCloseIcon: {
      color: theme.newColorPalette.plainWhiteBlack[colorMode],
      alignItem: 'flex-end',
      margin: '0px 0px 0px auto',
    },
    sitelineLogoIcon: {
      // color: 'black',
      height: 40,
      width: 40,
    },
    taskManagementDrawerToolBar: {
      background: theme.newColorPalette.toolbarBackgroundWhite[colorMode],
    },
    taskManagementItemsWrapper: {
      listStyle: 'none',
    },
    menuListTitle: {
      marginBottom: '0px',
      marginLeft: '1.5em',
      fontWeight: 100,
      color: 'rgba(0,0,0,.54)',
    },
    menuListTitleNoTopMargin: {
      marginBottom: '0px',
      marginTop: '0px',
      marginLeft: '1.5em',
      fontWeight: 100,
      color: 'rgba(0,0,0,.54)',
    },
    menuListVersionWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column' as 'column',
      justifyContent: 'space-between',
    },
    menuListVersion: {
      display: 'flex',
      position: 'relative' as 'relative',
      bottom: '13px',
      right: '13px',
      zIndex: 1000,
      justifyContent: 'flex-end',
    },
  };
};
