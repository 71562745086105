import { scaleBand, scaleLinear } from '@visx/scale';
import { histogramMargin } from '../components/Charts/ChartControllers/CompletionDataHistogramController';
import {
  CompletionDashboardState,
  GroupByEnum,
} from '../stores/taskCompletionDashboardStore';

export const histogramMarginCalculator = (
  visHeight: number,
  visWidth: number
) => {
  return {
    top: visHeight * 0.075,
    bottom: visHeight * 0.175,
    left: visWidth * 0.1,
    right: visWidth * 0.075,
  };
};

export const xMaxCalculator = (width: number, margin: histogramMargin) => {
  return Math.max(width - margin.left - margin.right, 0);
};
export const yMaxCalculator = (height: number, margin: histogramMargin) => {
  return height - margin.top - margin.bottom;
};

export const totalNumberForType = (data: any[]) => {
  return data.reduce((totals: number[], currentDataIndex: any) => {
    totals.push(Number(currentDataIndex.total));
    return totals;
  }, [] as number[]);
};

// accessors
export const xAccessorCalculator = (data: any, groupBy: string) => {
  return data.map((d: any) => {
    return d[groupBy];
  });
};
// scales
export const xScaleCalculatorVertical = (data: any[], groupBy: string) => {
  return scaleBand<string>({
    domain: xAccessorCalculator(data, groupBy),
    padding: 0.2,
  });
};
export const xScaleCalculatorHorizontal = (data: any[]) => {
  return scaleLinear<number>({
    domain: [0, Math.max(...totalNumberForType(data))],
    nice: true,
  });
};

export const yScaleCalculatorHorizontal = (data: any[], groupBy: string) => {
  return scaleBand<string>({
    domain: xAccessorCalculator(data, groupBy),
    padding: 0.2,
  });
};

export const yScaleCalculatorVertical = (data: any[]) => {
  return scaleLinear<number>({
    domain: [0, Math.max(...totalNumberForType(data))],
    nice: true,
  });
};

export const yAxisScaleCalculator = (data: any[]) => {
  return scaleLinear<number>({
    domain: [0, Math.max(...totalNumberForType(data))],
    nice: true,
  });
};

export const calculateYPosition = (
  yMax: any,
  yScale: any,
  d: any,
  currCompletionStatus: string
) => {
  if (currCompletionStatus === 'overdue') {
    if (
      d.completed !== undefined &&
      d.incompleted !== undefined &&
      d.overdue !== undefined
    ) {
      return (
        yMax -
        yScale(Number(d.overdue)) -
        yScale(Number(d.completed)) -
        yScale(Number(d.incompleted))
      );
    } else if (d.incompleted !== undefined && d.completed === undefined) {
      return yMax - yScale(Number(d.overdue)) - yScale(Number(d.incompleted));
    } else if (d.completed !== undefined && d.incompleted === undefined) {
      return yMax - yScale(Number(d.overdue)) - yScale(Number(d.completed));
    } else {
      return yMax - yScale(Number(d.overdue));
    }
  } else if (currCompletionStatus === 'incompleted') {
    if (d.completed !== undefined && d.incompleted !== undefined) {
      return yMax - yScale(Number(d.completed)) - yScale(Number(d.incompleted));
    } else {
      return yMax - yScale(Number(d.incompleted));
    }
  } else {
    return 0;
  }
};
export const calculateXPosition = (
  xMax: any,
  xScale: any,
  d: any,
  currCompletionStatus: string
) => {
  if (currCompletionStatus === 'overdue') {
    if (
      d !== undefined &&
      d.completed !== undefined &&
      d.incompleted !== undefined &&
      d.overdue !== undefined
    ) {
      return xScale(Number(d.completed)) + xScale(Number(d.incompleted));
    } else if (
      d !== undefined &&
      d.incompleted !== undefined &&
      d.completed === undefined
    ) {
      // overdue and completed
      return xScale(Number(d.completed));
      // overdue and incomplete
    } else if (
      d !== undefined &&
      d.completed !== undefined &&
      d.incompleted === undefined
    ) {
      return xScale(Number(d.incompleted));
    } else {
      // only overdue
      return 0;
    }
    // incompleted
  } else if (currCompletionStatus === 'incompleted') {
    // incompleted and completed
    if (
      d !== undefined &&
      d.completed !== undefined &&
      d.incompleted !== undefined
    ) {
      return xScale(Number(d.completed));
      // only incompleted
    } else {
      return 0;
    }
  } else {
    // completed
    return 0;
  }
};

export const axisBottomTickLabelProps = {
  textAnchor: 'middle' as const,
  fontFamily: 'Roboto',
  fontSize: 10,
  fill: 'black',
};

export const axisLeftTickLabelProps = (groupBy?: string) => {
  return {
    dx:
      window.innerWidth > 420
        ? '-0.35em'
        : groupBy === 'TaskType'
        ? '1em'
        : '-0.75em',
    dy: groupBy === 'TaskType' ? '-0.25em' : '0.25em',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 'bold',
    textAnchor: groupBy === 'TaskType' ? ('start' as const) : ('end' as const),
    fill: 'black',
  };
};

export const axisLabelsProps = {
  fontFamily: 'Roboto',
  fontSize: 15,
  textAnchor: 'middle' as const,
};

export const histogramTitleStringBuilder = (
  completionDashboardState: CompletionDashboardState
) => {
  const { groupBy, storeNumber, districtNumber, territoryNumber } =
    completionDashboardState;

  let locationNumberString = '';
  if (storeNumber !== undefined) {
    locationNumberString = `for Store ${storeNumber} `;
    if (groupBy === GroupByEnum.Store) {
      return `Completion Status ${locationNumberString}`;
    }
  } else if (districtNumber !== undefined) {
    locationNumberString = `for District ${districtNumber} `;
    if (groupBy === GroupByEnum.District) {
      return `Completion Status ${locationNumberString}`;
    }
  } else if (territoryNumber !== undefined) {
    locationNumberString = `for Territory ${territoryNumber} `;
    if (groupBy === GroupByEnum.Territory) {
      return `Completion Status ${locationNumberString}`;
    }
  }

  let groupByString = groupBy as string;
  if (groupBy === GroupByEnum.TaskType) {
    groupByString = 'Task Type';
  }

  return `Completion Status ${locationNumberString}by ${groupByString}`;
};

export const bottomAxisLabelBuilder = (groupBy: GroupByEnum) => {
  if (groupBy === GroupByEnum.TaskType) {
    return 'Task Type';
  } else if (groupBy === GroupByEnum.Store) {
    return 'Store Number';
  } else if (groupBy === GroupByEnum.Territory) {
    return 'Territory Number';
  } else if (groupBy === GroupByEnum.District) {
    return 'District Number';
  } else {
    return '';
  }
};
