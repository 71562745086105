import { pageNotFoundStyles } from './styles/pageNotFoundStyles';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from '../../stores/userPreferenceStore';
import zoidberg from '../../assets/images/zoidberg-404.png';

const PageNotFound = () => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const {
    errorPageGenericTextStyle,
    errorPageHeaderText,
    errorPageBodyText,
    errorPageHeader,
  } = pageNotFoundStyles(userPreference.colorMode);

  return (
    <div style={errorPageGenericTextStyle}>
      <div style={errorPageHeader}>
        <div style={errorPageHeaderText}>Oops, we goofed!</div>
        <img style={{ width: 300 }} src={zoidberg} alt={'Zoidberg'} />
      </div>
      <div style={errorPageBodyText}>
        We have recorded the issue and are working diligently to fix things
        behind the scenes.
      </div>
    </div>
  );
};

export default PageNotFound;
