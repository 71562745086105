import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { TaskStatus } from '../../models/TaskStatus';
import {
  getEditNotesForTaskByTaskId,
  getTaskDescriptionByTaskId,
  getTaskLevelDetails,
  getTaskStoreLevelDetails,
  updateTaskStatus,
} from '../../api/apiV1Task';
import { TaskApiResponse } from '../../models/apiV1TaskResponses';
import { theme } from '../../assets/siteLineTheme';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from 'src/utils/analytics';
import TableSortIconButton from '../Buttons/TableSortIconButton';
import Chip from '../Chip/Chip';
import { ChipVariantEnum, IconEnum } from '../Chip/chipUtils/ChipEnums';
import {
  chipBackgroundColorPicker,
  chipTextColorPicker,
} from '../Chip/chipUtils/chipColorPickers';
import drawerPropsStore from '../../stores/drawerPropsStore';
import { DrawerContentType } from '../../stores/models/DrawerProps';
import { fetchRegionalTaskCompletionStatus } from 'src/api/apiUtils';
import { IconButton } from '../Buttons/IconButton';
import { reviewAndApprovalTabelStyles } from './reviewAndApprovalTabelStyles';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';
import PageNavigation from '../PageNavigation/PageNavigation';
import { DirectionEnum } from '../../utils/enum/DirectionEnum';
import { useHistory } from 'react-router-dom';

const useStyles = (colorMode: ColorModeTypeEnum) => {
  return {
    table: {
      minWidth: 650,
    },
    approveButton: {
      backgroundColor: theme.newColorPalette.feedbackWhite[colorMode],
      color: theme.newColorPalette.plainWhiteBlack[colorMode],
      margin: '3px',
    },
    returnTaskButton: {
      backgroundColor: theme.colorPalette.textDark,
      transform: 'scaleY(-1)',
      margin: '3px',
    },
    returnButtonIcon: {
      color: 'white',
    },
    taskTypeWrapper: {
      backgroundColor: 'orange',
      borderRadius: '4px',
    },
    tableHeaderCellContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    iconButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '1em',
    },
    pagination: {
      '& .MuiTablePagination-spacer': {
        display: 'none',
      },
      '& .MuiTablePagination-toolbar': {
        justifyContent: 'center',
      },
    },
  }

}

export type ReviewAndApprovalTableProps = {
  events: TaskApiResponse[];
  eventFilterStatus: TaskStatus;
  colorMode: ColorModeTypeEnum;
  refreshTasksOnTable: Function;
  rowNumber: number;
};

const ReviewAndApprovalTable: React.FC<ReviewAndApprovalTableProps> = (
  props
) => {
  const {
    events,
    eventFilterStatus,
    colorMode,
    refreshTasksOnTable,
    rowNumber,
  } = props;

  const classes = useStyles(colorMode);
  const [page, setPage] = useState(1);
  const [startRowPage, setStartRowPage] = useState<number>(0);
  const [endRowPage, setEndRowPage] = useState<number>(rowNumber);
  const [tableColumnSortDirection, setTableColumnSortDirection] = useState({
    taskTitle: '',
    taskType: '',
    endDate: 'desc',
    authorName: '',
  });

  const { drawerProps, setDrawerProps, resetDrawerProps } = drawerPropsStore(
    (state: {
      drawerProps: any;
      setDrawerProps: any;
      resetDrawerProps: any;
    }) => ({
      drawerProps: state.drawerProps,
      setDrawerProps: state.setDrawerProps,
      resetDrawerProps: state.resetDrawerProps,
    })
  );

  const emptyColumnSortDirection = {
    taskTitle: '',
    taskType: '',
    endDate: '',
    authorName: '',
  };

  const handlerChangePage = (
    type: DirectionEnum,
    curPage: number,
    pageRow: number
  ) => {
    if (type === DirectionEnum.Forward) {
      setPage(curPage);
      setStartRowPage(startRowPage + pageRow);
      setEndRowPage(endRowPage + pageRow);
    } else {
      setPage(curPage);
      setStartRowPage(startRowPage - pageRow);
      setEndRowPage(endRowPage - pageRow);
    }
  };

  const handleUpdateTaskStatus = async (
    taskStatus: TaskStatus,
    taskId?: string
  ) => {
    await updateTaskStatus(taskStatus, taskId);
    refreshTasksOnTable();
    resetDrawerProps();
  };

  const getAuthorDetails = (row: TaskApiResponse) => {
    const authorDetails: { name: string; empID: string } =
      row.author_details as { name: string; empID: string };
    return authorDetails;
  };

  const sortEventData = () => {
    tableColumnSortDirection.authorName !== '' && // checks if author name field is empty
      (tableColumnSortDirection.authorName === 'desc' // checks if author name field is descending
        ? events.sort(
            (
              a,
              b // array.sort two elements by comparing them
            ) =>
              getAuthorDetails(a).name.split(' ')[1] > // taking last name of first element and comparing to 2nd element
              getAuthorDetails(b).name.split(' ')[1]
                ? 1 // move left
                : getAuthorDetails(b).name.split(' ')[1] >
                  getAuthorDetails(a).name.split(' ')[1]
                ? -1 // move right
                : 0 // if equal, don't move in the array
          )
        : events.sort((a, b) =>
            getAuthorDetails(b).name.split(' ')[1] >
            getAuthorDetails(a).name.split(' ')[1]
              ? 1
              : getAuthorDetails(a).name.split(' ')[1] >
                getAuthorDetails(b).name.split(' ')[1]
              ? -1
              : 0
          ));

    if (tableColumnSortDirection.endDate === 'desc') {
      events.sort((a, b) => {
        return new Date(b.end_date).getTime() - new Date(a.end_date).getTime();
      });
    }

    if (tableColumnSortDirection.taskTitle === 'desc') {
      events.sort((a, b) =>
        a.title > b.title ? 1 : b.title > a.title ? -1 : 0
      );
    }

    if (tableColumnSortDirection.taskType === 'desc') {
      events.sort((a, b) =>
        a.task_type > b.task_type ? 1 : b.task_type > a.task_type ? -1 : 0
      );
    }

    if (tableColumnSortDirection.taskTitle === 'asc') {
      events.sort((a, b) =>
        b.title > a.title ? 1 : a.title > b.title ? -1 : 0
      );
    }

    if (tableColumnSortDirection.taskType === 'asc') {
      events.sort((a, b) =>
        b.task_type > a.task_type ? 1 : a.task_type > b.task_type ? -1 : 0
      );
    }

    if (tableColumnSortDirection.endDate === 'asc') {
      events.sort((a, b) => {
        return new Date(a.end_date).getTime() - new Date(b.end_date).getTime();
      });
    }
  };

  sortEventData();

  const handleTaskClick = async (task: TaskApiResponse) => {
    try {
      sendAnalyticsEvent({
        ...analyticsEventConstants.VIEW_TASK,
        state: task.title,
      });
      const taskLevelDetails = await getTaskLevelDetails(task?.id);
      const taskStoreLevelDetails = await getTaskStoreLevelDetails(task?.id);
      const taskDescription = await getTaskDescriptionByTaskId(task?.id);
      const taskCompletionStatus = await fetchRegionalTaskCompletionStatus(
        task?.id
      );
      let edit_notes = [];
      if (task?.id !== undefined) {
        edit_notes = await getEditNotesForTaskByTaskId(task.id);
      }

      const taskEvent = {
        task_description: taskDescription,
        ...taskStoreLevelDetails,
        ...taskLevelDetails,
        ...taskCompletionStatus,
        distro_file_information: taskLevelDetails?.distro_file_information
          ? [taskLevelDetails?.distro_file_information]
          : [],
        store_number: taskLevelDetails?.distro_file_information
          ? undefined
          : taskStoreLevelDetails.store_number,
        edit_notes,
      };

      setDrawerProps({
        isDrawerOpen: true,
        drawerContentType: DrawerContentType.TaskForm,
        drawerContentProps: {
          ...drawerProps.drawerContentProps,
          formMode: 'approve',
          viewEvent: taskEvent,
        },
      });
    } catch (error) {
      const history = useHistory();
      console.error(error);
      history.replace('/error');
    }
  };

  const handleColumnSortClick = (selectedCol: string) => {
    let sortDirection = '';
    if (
      tableColumnSortDirection[selectedCol] !== '' &&
      tableColumnSortDirection[selectedCol] === 'desc'
    ) {
      sortDirection = 'asc';
    } else {
      sortDirection = 'desc';
    }

    sortEventData();

    setTableColumnSortDirection({
      ...emptyColumnSortDirection,
      [selectedCol]: sortDirection,
    });
  };

  useEffect(() => {
    setPage(1);
  }, [eventFilterStatus]);

  const reviewAndApprovalTableClasses = reviewAndApprovalTabelStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <div style={classes.tableHeaderCellContainer}>
                  <div>Task Title</div>
                  <div
                    onClick={() => handleColumnSortClick('taskTitle')}
                    style={classes.iconButtonContainer}
                  >
                    <TableSortIconButton
                      applicableColumn={'taskTitle'}
                      tableColumnSortDirection={tableColumnSortDirection}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <div style={classes.tableHeaderCellContainer}>
                  <div>Task Type</div>
                  <div
                    onClick={() => handleColumnSortClick('taskType')}
                    style={classes.iconButtonContainer}
                  >
                    <TableSortIconButton
                      applicableColumn={'taskType'}
                      tableColumnSortDirection={tableColumnSortDirection}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell align="left">Store(s)</TableCell>
              <TableCell>
                <div style={classes.tableHeaderCellContainer}>
                  <div>End Date</div>
                  <div
                    onClick={() => handleColumnSortClick('endDate')}
                    style={classes.iconButtonContainer}
                  >
                    <TableSortIconButton
                      applicableColumn={'endDate'}
                      tableColumnSortDirection={tableColumnSortDirection}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell align="left">Labor Hours</TableCell>
              <TableCell>
                <div style={classes.tableHeaderCellContainer}>
                  <div>Author Name</div>
                  <div
                    onClick={() => handleColumnSortClick('authorName')}
                    style={classes.iconButtonContainer}
                  >
                    <TableSortIconButton
                      applicableColumn={'authorName'}
                      tableColumnSortDirection={tableColumnSortDirection}
                      aria-label="sort by authorName"
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events
              // .slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage)
              .slice(startRowPage, endRowPage)
              .map((row: TaskApiResponse, index) => (
                <TableRow
                  hover={true}
                  aria-label={`Table Row ${index}`}
                  key={index}
                  sx={{cursor: 'pointer',}}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => handleTaskClick(row)}
                  >
                    {row.title}
                  </TableCell>
                  <TableCell align="left" onClick={() => handleTaskClick(row)}>
                    <Chip
                      variant={ChipVariantEnum.Filled}
                      chipBackgroundColor={chipBackgroundColorPicker(
                        colorMode,
                        row.task_type,
                        undefined
                      )}
                      chipTextColor={chipTextColorPicker(
                        colorMode,
                        row.task_type,
                        undefined
                      )}
                    >
                      {row.task_type}
                    </Chip>
                  </TableCell>
                  <TableCell align="left" onClick={() => handleTaskClick(row)}>
                    {row.distro_file_information
                      ? `Multiple (${row.number_of_stores})`
                      : row.store_number}
                  </TableCell>
                  <TableCell align="left" onClick={() => handleTaskClick(row)}>
                    {format(new Date(row.end_date), 'yyyy-MM-dd')}
                  </TableCell>
                  <TableCell align="left" onClick={() => handleTaskClick(row)}>
                    {row.average_labor_hours
                      ? row.distro_file_information
                        ? `${row.average_labor_hours} (avg)`
                        : row.average_labor_hours
                      : 0}
                  </TableCell>
                  <TableCell align="left" onClick={() => handleTaskClick(row)}>
                    {row.author_details
                      ? `${getAuthorDetails(row).name} (${
                          getAuthorDetails(row).empID
                        })`
                      : 'loading'}
                  </TableCell>

                  <TableCell align={'center'}>
                    <div style={reviewAndApprovalTableClasses.iconContainer}>
                      {eventFilterStatus === TaskStatus.Approved ? null : (
                        <IconButton
                          label={'approve task button'}
                          icon={IconEnum.Check}
                          onClick={() => {
                            sendAnalyticsEvent(
                              analyticsEventConstants.APPROVE_TASK
                            );
                            handleUpdateTaskStatus(TaskStatus.Approved, row.id);
                          }}
                          backgroundColor={
                            theme.newColorPalette.completionStatus
                              .completedBlack[colorMode]
                          }
                          color={theme.colorPalette.plainWhite}
                          size={'1.25em'}
                          margin={'0 0.75em 0 0'}
                          padding={'0.5em 1em 0.5em 1em'}
                          borderRadius={'10%'}
                        />
                      )}

                      {eventFilterStatus === TaskStatus.Returned ? null : (
                        <IconButton
                          label={'return task button'}
                          icon={IconEnum.ReturnArrow}
                          onClick={() => {
                            sendAnalyticsEvent(
                              analyticsEventConstants.RETURN_TASK
                            );
                            handleUpdateTaskStatus(TaskStatus.Returned, row.id);
                          }}
                          backgroundColor={
                            theme.newColorPalette.backgroundDarkBlack[colorMode]
                          }
                          color={theme.colorPalette.plainWhite}
                          size={'1.25em'}
                          margin={'0 0.75em 0 0'}
                          padding={'0.5em 1em 0.5em 1em'}
                          borderRadius={'10%'}
                        />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PageNavigation
        curPage={page}
        dataCount={events ? events.length : 0}
        onChangePage={handlerChangePage}
      />
    </>
  );
};

export default ReviewAndApprovalTable;
