import React, { ReactNode } from 'react';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import { Box, Typography } from '@mui/material';
import { ChipVariantEnum, IconEnum } from './chipUtils/ChipEnums';
import {ChipStyles} from "../Forms/styles/chipStyles";

export type ChipProps = {
  children: ReactNode;
  chipBackgroundColor?: string;
  chipTextColor?: string;
  iconEnum?: IconEnum;
  variant: ChipVariantEnum;
};

const Chip: React.FC<ChipProps> = (props) => {
  const { children, variant, chipBackgroundColor, chipTextColor } = props;
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  return (
    <Box
        sx={{...ChipStyles(userPreference.colorMode, chipBackgroundColor, chipTextColor).generalChipStyles
            , ...(variant === ChipVariantEnum.Outlined && ChipStyles(userPreference.colorMode, chipBackgroundColor, chipTextColor).outlinedChip)
            , ...(variant === ChipVariantEnum.Filled && ChipStyles(userPreference.colorMode, chipBackgroundColor, chipTextColor).filledChip)
        }}
    >
      <Typography style={ChipStyles(userPreference.colorMode, chipBackgroundColor, chipTextColor).chipText}>{children}</Typography>
    </Box>
  );
};

export default Chip;
