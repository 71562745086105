export const pageNavigationStyles = () => {
  return {
    pageNavButtonsContainer: {
      display: 'flex',
      flexDirection: 'row-reverse' as 'row-reverse',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  };
};
