import { theme } from '../../../assets/siteLineTheme';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

export const easyPrintCalendarStyles = (colorMode: ColorModeTypeEnum) => {
    return {
        calendarContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        calendarDatesContainer: {
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
        dateLabelRow: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
        },
        dateLabelContainer: {
            minWidth: '13.5%',
            width: '13.5',
            border: `1px solid ${theme.newColorPalette.zoidbergBlack[colorMode]}`,
            display: 'flex',
            justifyContent: 'center',
        },
        dateLabelText: {
            fontSize: '1em',
        },
        dateContainer: {
            minWidth: '13.5%',
            width: '13.5%',
            border: `1px solid ${theme.newColorPalette.zoidbergLightBlack[colorMode]}`,
            minHeight: '100px',
        },
        taskTitleText: {
            padding: '0px',
            margin: '0px',
            lineHeight: 1,
            width: '85%',
        },
        taskCheckBox: {
            width: '1em',
            height: '1em',
            border: '1px solid black',
            borderRadius: '3px',
            margin: '0 .25em',
        },
        taskTitleContainer: {
            padding: '.25em',
            borderBottom: `1px dashed ${theme.colorPalette.dashedSeparator}`,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        dateNumberContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'fit-content',
            minWidth: '1.25em',
            minHeight: '1.25em',
            padding: '.1em',
            borderRadius: '50%',
            border: `2px solid ${theme.colorPalette.plainBlack}`,
            marginTop: '.1em',
            marginLeft: '.1em',
        },
        dateNumberText: {
            fontSize: '.75em',
            fontWeight: 700,
        },
    }
}
