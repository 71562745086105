import React, { useCallback, useEffect, useState } from 'react';
import commentsModalContentStore, {
  CommentsModalContentStoreState,
} from '../../../stores/commentsModalContentStore';
import baseModalStore, {
  baseModalContentStoreState,
} from '../../../stores/baseModalStore';
import {
  CreateCommentValues,
  getStoreLevelCommentsByRegionAndTaskID,
} from '../../../api/apiV1Task';
import { commentsModalContentStyles } from './commentsModalContentStyles';
import Accordion, { AccordionContentEnum } from '../../Accordion/Accordion';
import innerBaseModalStore, {
  innerBaseModalContentStoreState,
} from '../../../stores/innerBaseModalStore';
import { useHistory } from 'react-router-dom';

export type StoreNumberObj = {
  location_name: string;
  location_number: number;
  comments: CreateCommentValues[];
};

const CommentsModalContent: React.FC = () => {
  const { commentsModalContentStoreProps } = commentsModalContentStore(
    (state: {
      commentsModalContentStoreProps: CommentsModalContentStoreState;
    }) => ({
      commentsModalContentStoreProps: state.commentsModalContentStoreProps,
    })
  );

  const { isActive } = baseModalStore(
    (state: { baseModalContentStoreProps: baseModalContentStoreState }) => ({
      isActive: state.baseModalContentStoreProps.isActive,
    })
  );

  const { state } = innerBaseModalStore(
    (state: {
      innerBaseModalContentStoreProps: innerBaseModalContentStoreState;
    }) => ({
      state: state.innerBaseModalContentStoreProps,
    })
  );

  const { task_id, location_type, location_number, location_name} =
    commentsModalContentStoreProps;

  const [storeNameList, setStoreNameList] = useState([]);

  const callGetStoreLocationAPI = useCallback(async () => {
    try {
      const getStoreNameList = await getStoreLevelCommentsByRegionAndTaskID(
        parseInt(location_number),
        task_id,
        location_type,
        'Only'
      );

      if (JSON.stringify(storeNameList) !== JSON.stringify(getStoreNameList)) {
        setStoreNameList(getStoreNameList);
      }
    } catch (error) {
      const history = useHistory();
      console.error(error);
      history.replace('/error');
    }
  }, [storeNameList]);

  useEffect(() => {
    if (state.isActive === false) {
      callGetStoreLocationAPI();
    }
  }, [state.isActive]);

  const windowDimensions = {
    height: window.innerHeight,
  };

  const commentsModalContentClasses = commentsModalContentStyles({
    height: windowDimensions.height,
  });

  return (
    <>
      {isActive && (
        <>
          <div style={commentsModalContentClasses.commentModalTitleContainer}>
            Comments for {location_type.charAt(0).toUpperCase() + location_type.slice(1)} {location_number} - {location_name}
          </div>
          <div style={commentsModalContentClasses.commentModalContentContainer}>
            {storeNameList.map((store: StoreNumberObj) => {
              return (
                <div
                  style={commentsModalContentClasses.commentRowContainer}
                  key={store.location_number}
                >
                  <div
                    style={
                      commentsModalContentClasses.commentAccordionContainer
                    }
                  >
                    <Accordion
                      title={`${store.location_number} - ${store.location_name} (${store.comments.length})`}
                      accordionContentEnum={AccordionContentEnum.Comments}
                      data={store.comments}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default CommentsModalContent;
