import React from 'react';
import { env } from '../envConfig';

const MobileMetrics: React.FC = () => {
  return (
    <div style={{ margin: '10px auto', width: '98%', height: '90%' }}>
      <iframe
        id="mobile-metrics"
        title="MobileMetrics"
        width="100%"
        height="100%"
        frameBorder="0"
        src={env.REACT_APP_QLIK_METRICS_URL}
      />
    </div>
  );
};

export default MobileMetrics;
