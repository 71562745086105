export const taskTableRowStyles = () => {
  return {
    titleRow: {
      cellWidth: 'calc(40% - 10px)',
    },
    completionStatusRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    mobileCompletionStatusRow: {
      margin: '0px auto',
      width: 'fit-content',
    },
  };
};
