import { theme } from '../../../assets/siteLineTheme';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

export type colorToggleStylesParams = {
  colorMode: ColorModeTypeEnum;
};

export const colorToggleStyles = (params: colorToggleStylesParams) => {
  const { colorMode } = params;
  /* istanbul ignore next */
  return {
    colorToggleContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
    },
    colorToggleTitle: {
      fontSize: '1.1em',
      fontWeight: '700',
      marginBottom: '0.2em',
      color: theme.newColorPalette.zoidbergBlack[colorMode],
    },
    colorToggleRadioContainer: {
      display: 'flex',
    },
    colorToggleRadio: {
      display: 'flex',
      alignItems: 'center',
    },
    colorToggleCircle: {
      Zoidberg: {
        borderWidth: '0.7em',
        borderRightColor: theme.colorPalette.zoidberg,
        borderTopColor: theme.colorPalette.zoidberg,
        borderBottomColor: theme.colorPalette.backgroundDark,
        borderLeftColor: theme.colorPalette.backgroundDark,
        borderStyle: 'solid',
        borderRadius: '50%',
      },
      BlackWhite: {
        borderWidth: '0.7em',
        borderRightColor: theme.colorPalette.plainBlack,
        borderTopColor: theme.colorPalette.plainBlack,
        borderBottomColor: theme.colorPalette.veryLightGrey,
        borderLeftColor: theme.colorPalette.veryLightGrey,
        borderStyle: 'solid',
        borderRadius: '50%',
      },
      Teal: {
        borderWidth: '0.7em',
        borderRightColor: theme.newColorPalette.zoidbergBlack.Teal,
        borderTopColor: theme.newColorPalette.zoidbergBlack.Teal,
        borderBottomColor: theme.colorPalette.backgroundDark,
        borderLeftColor: theme.colorPalette.backgroundDark,
        borderStyle: 'solid',
        borderRadius: '50%',
      },
    },
    colorToggleRadioText: {
      marginLeft: '0.2em',
      marginRight: '0.2em',
      fontSize: '0.9em',
    },
    colorToggleRadioButton: {
      accentColor: theme.newColorPalette.zoidbergBlack[colorMode],
      cursor: 'pointer',
    },
  };
};
