import Papa from 'papaparse';
import { downloadCsv } from './csvValidator';
import { analyticsEventConstants, sendAnalyticsEvent } from './analytics';
import { getTaskViewedReadReceiptsById } from '../api/apiV1Task';

export const exportTaskViewedReadReceiptsCsv = async (
  task_id: string,
  title: string
) => {
  const allStoreCompletionList = await getTaskViewedReadReceiptsById(task_id);

  if (allStoreCompletionList !== undefined) {
    const csv = Papa.unparse(allStoreCompletionList);
    downloadCsv(csv, title);
    sendAnalyticsEvent({
      ...analyticsEventConstants.TASK_READ_RECEIPT,
      state: task_id,
    });
  }
};
