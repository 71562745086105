export const toggleStyles = () => {
  return {
    toggleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0.1em 0 0.1em 0.75em',
      width: window.innerWidth > 420 ? '65%' : '100%',
    },
    toggleLabelContainer: {
      width: 'fit-content',
      marginRight: '0.1em',
      minWidth: window.innerWidth > 420 ? '170px' : undefined,
    },
    toggleLabelText: {
      fontSize: '1em',
    },
  };
};
