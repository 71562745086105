import { ChangeEvent } from 'react';
import {
  DigitalMerchandisingLinkTypeEnum,
  NavigationObj,
} from '../../../utils/testData/mockHierarchyData';
import { DigitalMerchandisingObj } from '../DigitalMerchandisingCreate';

export type HandlerChangeParams = {
  e: ChangeEvent<HTMLInputElement>;
  setValue: Function;
  digitalObj: DigitalMerchandisingObj;
  type: 'title' | 'url' | 'is_active' | 'link_type';
};

export const handlerChange = (params: HandlerChangeParams) => {
  const { e, setValue, digitalObj, type } = params;
  if (type === 'is_active') {
    digitalObj.data.find((menu: NavigationObj) => {
      if (menu.code === digitalObj.currentSelectedId) {
        menu.is_active = !e;
        return true;
      }
      return false;
    });
    setValue({ ...digitalObj, isActive: !e });
  } else {
    const inputValue = e.target.value;

    if (type === 'url') {
      digitalObj.data.find((menu: NavigationObj) => {
        if (menu.code === digitalObj.currentSelectedId) {
          menu.url = e.target.value;
          menu.link_type =
            e.target.value !== '' && menu.link_type === undefined
              ? DigitalMerchandisingLinkTypeEnum.Flipping
              : e.target.value === '' || e.target.value === undefined
              ? null
              : menu.link_type;
          return true;
        }
        return false;
      });
      setValue({ ...digitalObj, urlValue: inputValue });
    } else if (type === 'link_type') {
      digitalObj.data.find((menu: NavigationObj) => {
        if (menu.code === digitalObj.currentSelectedId) {
          // if (e.target.value === 'Pdf') {
          //   menu.url = '';
          // }
          menu.link_type = DigitalMerchandisingLinkTypeEnum[e.target.value];
          return true;
        }
        return false;
      });
      setValue({ ...digitalObj, linkType: inputValue });
    } else if (type === 'title') {
      digitalObj.data.find((menu: NavigationObj) => {
        if (menu.code === digitalObj.currentSelectedId) {
          menu.title = inputValue;

          const menuLevel = menu.level;
          const menuPath = menu.menu_path.split('/', menuLevel);
          let menuSlashPosition = 0;
          if (menuPath.length > 0) {
            menuSlashPosition =
              menu.menu_path.indexOf(menuPath[menuPath.length - 1]) +
              menuPath[menuPath.length - 1].length;
            menu.menu_path =
              menu.menu_path.slice(0, menuSlashPosition) + '/' + inputValue;
          } else {
            menu.menu_path = inputValue;
          }
          return true;
        }
        return false;
      });
      setValue({ ...digitalObj, nameValue: inputValue });
    }
  }
  return null;
};
