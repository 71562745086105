import React from 'react';
import BoxArray, { BoxContentTypeEnum } from '../BoxArray/BoxArray';
import TableHeader from './TableHeader';
import { tableCellStyles } from './TableCell/tableCellStyles';
import { CalendarEvent } from '../types/Calendar/ICalendarIndex';

export type TableProps = {
  data: Array<any> | Array<CalendarEvent>;
  columnHeadersArray: Array<TableCellObj>;
  boxContentType: BoxContentTypeEnum;
  optionalState?: any;
  optionalFunction?: Function;
};

export type TableCellObj = {
  content: string;
  cellWidth: string;
  column: string;
  justifyContent?: string | undefined;
  onClick?: Function;
  optionalState?: any;
};

const Table: React.FC<TableProps> = (props) => {
  const {
    data,
    columnHeadersArray,
    optionalState,
    boxContentType,
    optionalFunction,
  } = props;
  return (
    <div style={tableCellStyles({}).tableContainer}>
      {window.innerWidth > 420 && (
        <TableHeader
          columnHeadersArray={columnHeadersArray}
          optionalState={optionalState}
        />
      )}
      <BoxArray
        boxContentType={boxContentType}
        dataArray={data}
        boxHeight={'100%'}
        boxWidth={'100%'}
        callBack={optionalFunction}
      />
    </div>
  );
};

export default Table;
