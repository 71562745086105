import React from 'react';
import { NavigationObj } from '../../../utils/testData/mockHierarchyData';
import { CalendarEvent } from '../../types/Calendar/ICalendarIndex';
import { gSiteNavigationContentStyles } from './gSiteNavigationBoxContentStyles';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../utils/analytics';

export type GSiteNavigationBoxContentProps = {
  contentData: NavigationObj | CalendarEvent | any;
  onClick: Function | undefined;
  boxHeight: string | undefined;
};

const GSiteNavigationBoxContent: React.FC<GSiteNavigationBoxContentProps> = (
  props
) => {
  const { contentData, onClick, boxHeight } = props;
  const gSiteNavigationBoxContentClasses =
    gSiteNavigationContentStyles(boxHeight);

  const clickHandler = (contentData: NavigationObj | CalendarEvent | any) => {
    if (onClick !== undefined) {
      onClick(contentData);
      sendAnalyticsEvent(analyticsEventConstants.MERCHANDISING_GOOGLESITE);
    }
  };

  return (
    <div
      aria-label={`Google Site link to ${contentData.title}`}
      onClick={() => (onClick !== undefined ? clickHandler(contentData) : null)}
      style={gSiteNavigationBoxContentClasses.contentContainer}
    >
      <div style={gSiteNavigationBoxContentClasses.titleText}>
        {decodeURIComponent(contentData.title)}
      </div>
    </div>
  );
};

export default GSiteNavigationBoxContent;
