import React from 'react';
import { animated, useTransition } from '@react-spring/web';
import { IconButton } from '../Buttons/IconButton';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import baseModalStore from '../../stores/baseModalStore';
import { theme } from '../../assets/siteLineTheme';
import { DialogModalSwitchEnum } from './DialogModalSwitch';
import innerBaseModalStore, {
  innerBaseModalContentStoreState,
} from '../../stores/innerBaseModalStore';

export type ModalContentProps = {
  isActive: boolean;
  children: React.ReactNode;
  width?: number | undefined;
  height?: number | undefined;
  isInnerModal?: boolean;
  dialogModalEnum?: DialogModalSwitchEnum | undefined;
  top?: number | undefined;
  right?: number | undefined;
};

const ModalContent: React.FC<ModalContentProps> = (props) => {
  const {
    isActive,
    children,
    width,
    height,
    isInnerModal,
    dialogModalEnum,
    top,
    right,
  } = props;
  const windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
    heightWithScroll: window.scrollY,
  };

  /* istanbul ignore next */
  const modalTransition = useTransition(isActive, {
    from: {
      width: `${windowDimensions.width * 0}px`,
      height: `${windowDimensions.height * 0}px`,
      backgroundColor: 'white',
      zIndex: isInnerModal ? '10003' : '10001',
      borderRadius: '1em',
      margin: 'auto',
      top: top !== undefined ? `${top}px` : undefined,
      right: right !== undefined ? `${right}px` : undefined,
      translateY:
        top !== undefined
          ? undefined
          : `${
              windowDimensions.height * 0.5 + windowDimensions.heightWithScroll
            }px`,
      position: 'absolute' as 'absolute',
    },
    enter: {
      width:
        width !== undefined
          ? `${windowDimensions.width * width}px`
          : `${windowDimensions.width * 0.8}px`,
      height:
        height !== undefined
          ? `${windowDimensions.height * height}px`
          : `${windowDimensions.height * 0.8}px`,
      top: top !== undefined ? `${top}px` : undefined,
      right: right !== undefined ? `${right}px` : undefined,
      translateY:
        top !== undefined
          ? undefined
          : `${
              windowDimensions.height * 0.5 + windowDimensions.heightWithScroll
            }px`,
      backgroundColor: 'white',
      zIndex: isInnerModal ? '10003' : '10001',
      borderRadius: '1em',
      margin: 'auto',
      position: 'absolute' as 'absolute',
      padding: '1.7em',
    },
    leave: {
      width: `${windowDimensions.width * 0}px`,
      height: `${windowDimensions.height * 0}px`,
      top: top !== undefined ? `${top}px` : undefined,
      right: right !== undefined ? `${right}px` : undefined,
      translateY:
        top !== undefined
          ? undefined
          : `${
              windowDimensions.height * 0.5 + windowDimensions.heightWithScroll
            }px`,
      backgroundColor: 'white',
      zIndex: isInnerModal ? '10003' : '10001',
      borderRadius: '1em',
      margin: 'auto',
      position: 'absolute' as 'absolute',
      padding: '0em',
    },
  });

  const { resetBaseModalContent } = baseModalStore(
    (state: { resetBaseModalContent: Function }) => ({
      resetBaseModalContent: state.resetBaseModalContent,
    })
  );

  const { innerBaseModalContentStoreProps } = innerBaseModalStore(
    (state: {
      innerBaseModalContentStoreProps: innerBaseModalContentStoreState;
    }) => ({
      innerBaseModalContentStoreProps: state.innerBaseModalContentStoreProps,
    })
  );

  const { dialogModalSwitchEnum } = innerBaseModalContentStoreProps;

  return (
    <>
      {modalTransition(
        (styles, item) =>
          item && (
            <animated.div style={{ ...styles, overflow: 'auto' }}>
              {isActive &&
                (dialogModalEnum === DialogModalSwitchEnum.StoreComment ||
                  dialogModalSwitchEnum ===
                    DialogModalSwitchEnum.StoreComment ||
                  dialogModalEnum === DialogModalSwitchEnum.ActivityHistory ||
                  dialogModalSwitchEnum ===
                    DialogModalSwitchEnum.ActivityHistory) && (
                  <IconButton
                    label={'Exit Modal'}
                    icon={IconEnum.Exit}
                    onClick={resetBaseModalContent}
                    color={theme.colorPalette.lightGrey}
                    margin={'0 0 0 auto'}
                  />
                )}
              {children}
            </animated.div>
          )
      )}
    </>
  );
};

export default ModalContent;
