import { List, ListItem, Typography } from '@mui/material';
import { CompletionStatusProps } from '../types/Calendar/ICalendarIndex';
import { TaskStoreCompletionStatus } from '../../models/TaskStoreCompletionStatus';
import { format } from 'date-fns';
import { theme } from '../../assets/siteLineTheme';
import { StoreLocationInformationProps } from '../../models/StoreLocationInformation';
import { isDateBeforeToday } from '../../utils/dateUtils';
import React from 'react';

const useStyles = (isPrintView?: boolean) => {
    return {
      accordionRoot: {
        paddingLeft: '1em',
      },
      accordionDetails: {
        paddingBottom: '.5em',
        paddingTop: '0em',

        display: 'flex',
        flexDirection: 'column',
      },
      accordionDetailsList: {
        padding: '0px',
        maxHeight: isPrintView ? 'fit-content' : '400px',
        overflowY: isPrintView ? 'hidden' : 'scroll',
      },
      accordionListItem: {
        fontSize: '.85em',
        paddingBottom: '0em',
        paddingTop: '0em',
      },
      accordionDetailsTitle: {
        fontSize: '.85em',
      },
      accordionSummaryText: {
        margin: 'auto 0px',
      },
      accordionSummaryRoot: {
        height: 'fit-content',
      },
      completionStatusTextSpan: {
        color: theme.colorPalette.completionStatus.completed,
        fontStyle: 'italic',
      },
      overDueStatusRestSpan: {
        color: theme.colorPalette.completionStatus.overdue,
        fontStyle: 'italic',
      },
      scheduledDateTextSpan: {
        color: theme.colorPalette.completionStatus.scheduledDate,
        fontStyle: 'italic',
      },
    }
}

export type DistrictStoreNumberAccordionProps = {
  isPrintView?: boolean;
  completionStatuses?: CompletionStatusProps[];
  storeNumbersAndNamesForDistrict: StoreLocationInformationProps[];
};

const DistrictStoreNumberAccordion: React.FC<
  DistrictStoreNumberAccordionProps
> = (props) => {
  const { completionStatuses, storeNumbersAndNamesForDistrict } = props;

  const classes = props.isPrintView
    ? useStyles(props.isPrintView)
    : useStyles();

  const storeInfoObjs = storeNumbersAndNamesForDistrict.map((info: any) => {
    const storeCompletionInfoObj: {
      location_number: string;
      location_name: string;
      completion_status: CompletionStatusProps | undefined;
    } = {
      location_number: info.location_number,
      location_name: info.location_name,
      completion_status:
        completionStatuses &&
        completionStatuses.find(
          (status) => info.location_number === parseInt(status.store_number)
        ),
    };
    return storeCompletionInfoObj;
  });

  return (
    <List sx={classes.accordionDetailsList}>
      {storeInfoObjs.map(
        (storeInfo: {
          location_number: string;
          location_name: string;
          completion_status: CompletionStatusProps | undefined;
        }) => {
          return (
            <ListItem
              key={storeInfo.location_number}
              sx={classes.accordionListItem}
            >
              <Typography>
                {`${storeInfo.location_number} - ${storeInfo.location_name}`}

                {/* if you have scheduled date, we show them. */}
                {storeInfo.completion_status &&
                  storeInfo.completion_status.store_scheduled_start_date &&
                  storeInfo.completion_status.store_scheduled_end_date && (
                    <span style={classes.scheduledDateTextSpan}>
                      {` - Scheduled - ${format(
                        new Date(
                          storeInfo.completion_status.store_scheduled_start_date
                        ),
                        'MM/dd/yy'
                      )}`}
                      {storeInfo.completion_status
                        .store_scheduled_start_date !==
                      storeInfo.completion_status.store_scheduled_end_date
                        ? ` ~ ${format(
                            new Date(
                              storeInfo.completion_status.store_scheduled_end_date
                            ),
                            'MM/dd/yy'
                          )}`
                        : ``}
                    </span>
                  )}

                {storeInfo.completion_status &&
                  storeInfo.completion_status.completion_status ===
                    TaskStoreCompletionStatus.Completed && (
                    <span style={classes.completionStatusTextSpan}>{` - ${
                      storeInfo.completion_status.completion_status
                    } - ${format(
                      new Date(storeInfo.completion_status.date_last_modified),
                      'MM/dd/yy'
                    )}`}</span>
                  )}

                {storeInfo.completion_status &&
                  storeInfo.completion_status.completion_status ===
                    TaskStoreCompletionStatus.Incomplete &&
                  isDateBeforeToday(
                    storeInfo.completion_status.store_scheduled_end_date
                      ? storeInfo.completion_status.store_scheduled_end_date
                      : storeInfo.completion_status.end_date
                  ) && (
                    <span style={classes.overDueStatusRestSpan}>
                      {` - Overdue`}
                    </span>
                  )}
              </Typography>
            </ListItem>
          );
        }
      )}
    </List>
  );
};

export default DistrictStoreNumberAccordion;
