import React, { useContext } from 'react';
import { TaskCompletionListParams } from '../../Forms/TaskCompletionDashboard/TaskCompletionDashboardDataList';
import TableCell from '../TableCell/TableCell';
import { taskCompletionDashboardTableRowStyles } from './styles/taskCompletionDashboardTableRowStyles';
import { groups } from '../../../context/constants';
import { IconButton } from '../../Buttons/IconButton';
import { IconEnum } from '../../Chip/chipUtils/ChipEnums';
import { theme } from '../../../assets/siteLineTheme';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from '../../../stores/userPreferenceStore';
import { AppContext } from '../../../context/AppContext';
import useFetchUserGroup from '../../../hooks/fetchUserGroup';
import drawerPropsStore from '../../../stores/drawerPropsStore';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../utils/analytics';
import { subDays } from 'date-fns';
import {
  getTaskDescriptionByTaskId,
  getTaskLevelDetails,
  getTaskStoreCompletionStatus,
  getTaskStoreLevelDetails,
} from '../../../api/apiV1Task';
import { copyAttachmentsToNewGCPBucket } from '../../../api/apiFile';
import { fetchRegionalTaskCompletionStatus } from '../../../api/apiUtils';
import { CalendarEvent } from '../../types/Calendar/ICalendarIndex';
import { DrawerContentType } from '../../../stores/models/DrawerProps';
import { v4 as uuidV4 } from 'uuid';
import taskCompletionDashboardStore, {
  CompletionDashboardState,
} from '../../../stores/taskCompletionDashboardStore';
import appliedFiltersStore from '../../../stores/appliedFiltersStore';

type TaskCompletionDashboardTableRowProps = {
  contentData: TaskCompletionListParams;
  callBack: Function | undefined;
};

const TaskCompletionDashboardTableRow: React.FC<
  TaskCompletionDashboardTableRowProps
> = (props) => {
  const { contentData, callBack } = props;

  const taskCompletionDashboardTableRowClasses =
    taskCompletionDashboardTableRowStyles();

  const { appState } = useContext<any>(AppContext);
  const { userInfo } = appState;
  const userOktaGroup = useFetchUserGroup(userInfo);

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const { drawerProps, setDrawerProps } = drawerPropsStore(
    (state: { drawerProps: any; setDrawerProps: any }) => ({
      drawerProps: state.drawerProps,
      setDrawerProps: state.setDrawerProps,
    })
  );
  const { appliedFilters, setAppliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any; setAppliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
      setAppliedFilters: state.setAppliedFilters,
    })
  );
  const { completionDashboardState } = taskCompletionDashboardStore(
    (state: { completionDashboardState: CompletionDashboardState }) => ({
      completionDashboardState: state.completionDashboardState,
    })
  );

  const openEdit = () => {
    setDrawerProps({
      ...drawerProps,
      drawerContentProps: {
        ...drawerProps.drawerContentProps,
        formMode: 'edit',
      },
    });
  };

  const getNewAttachmentsIdAndCopyFilesInGCP = (viewEvent: CalendarEvent) => {
    const newViewEvent = { ...viewEvent, attachments_id: uuidV4() };
    const oldId = viewEvent.attachments_id;
    if (newViewEvent.attachment_information?.length !== 0) {
      copyAttachmentsToNewGCPBucket(
        oldId,
        newViewEvent.attachments_id,
        newViewEvent.attachment_information
      );
    }
    return newViewEvent;
  };

  const duplicateTaskHandler = () => {
    if (drawerProps.drawerContentProps.viewEvent) {
      const viewEventToSet = getNewAttachmentsIdAndCopyFilesInGCP(
        drawerProps.drawerContentProps.viewEvent
      ) as CalendarEvent;

      setDrawerProps({
        ...drawerProps,
        drawerContentProps: {
          ...drawerProps.drawerContentProps,
          formMode: 'create',
          viewEvent: viewEventToSet,
        },
      });
    }
  };

  const openView = (event: CalendarEvent, wasFiredFromCalendar?: boolean) => {
    setDrawerProps({
      ...drawerProps,
      drawerContentType: DrawerContentType.TaskForm,
      isDrawerOpen: true,
      drawerContentProps: {
        openEdit,
        formMode: 'view',
        viewEvent: event,
        duplicateTaskHandler,
      },
    });
  };

  const setAppliedFiltersWithCompletionDashboardState = () => {
    if (completionDashboardState.storeNumber !== undefined) {
      setAppliedFilters({
        ...appliedFilters,
        store_number: completionDashboardState.storeNumber,
      });
    } else if (completionDashboardState.districtNumber !== undefined) {
      setAppliedFilters({
        ...appliedFilters,
        district_number: completionDashboardState.districtNumber,
      });
    } else if (completionDashboardState.territoryNumber !== undefined) {
      setAppliedFilters({
        ...appliedFilters,
        territory_number: completionDashboardState.territoryNumber,
      });
    }
  };

  const findFullEventToOpen = async (
    selectedEvent: any,
    wasFiredFromCalendar?: boolean
  ) => {
    const { id, uc_event, end_date, title } = selectedEvent;
    /* istanbul ignore next */
    sendAnalyticsEvent({ ...analyticsEventConstants.VIEW_TASK, state: title });

    if (uc_event) {
      openView(
        { ...selectedEvent, end_date: subDays(new Date(end_date), 1) },
        wasFiredFromCalendar
      );
      return;
    }

    const taskLevelDetails = await getTaskLevelDetails(id);
    taskLevelDetails.distro_file_information =
      taskLevelDetails?.distro_file_information
        ? [taskLevelDetails?.distro_file_information]
        : [];

    const isDistroEvent = taskLevelDetails?.distro_file_information?.length;

    const storeLevelDetailsPromise = completionDashboardState.storeNumber
      ? getTaskStoreLevelDetails(id, completionDashboardState.storeNumber)
      : !isDistroEvent
      ? getTaskStoreLevelDetails(id)
      : {};

    let taskDescriptionByTaskId = await getTaskDescriptionByTaskId(id);
    taskDescriptionByTaskId = taskDescriptionByTaskId.ops
      ? { task_description: taskDescriptionByTaskId }
      : taskDescriptionByTaskId;
    const storeLevelDetails = await storeLevelDetailsPromise;

    setAppliedFiltersWithCompletionDashboardState();

    const regionalTaskStoreCompletionStatus =
      await fetchRegionalTaskCompletionStatus(id);

    let eventObj = {
      ...selectedEvent,
      ...taskLevelDetails,
      ...taskDescriptionByTaskId,
      ...storeLevelDetails,
      ...regionalTaskStoreCompletionStatus,
    };

    const taskStoreCompletionStatus =
      completionDashboardState.storeNumber &&
      completionDashboardState.storeNumber !== 'All' &&
      eventObj.require_completion
        ? await getTaskStoreCompletionStatus(
            id,
            completionDashboardState.storeNumber
          )
        : {};

    eventObj = {
      ...eventObj,
      ...taskStoreCompletionStatus,
    };
    openView({ ...eventObj }, wasFiredFromCalendar);
  };

  return (
    <div
      style={taskCompletionDashboardTableRowClasses.rowContainer}
      onClick={() => findFullEventToOpen(contentData)}
    >
      <TableCell styleProps={taskCompletionDashboardTableRowClasses.titleCell}>
        {contentData.title}
      </TableCell>

      <TableCell
        styleProps={taskCompletionDashboardTableRowClasses.storeNumberCell}
      >
        <div>Multiple ({contentData.store_number_count})</div>
      </TableCell>
      <TableCell
        styleProps={taskCompletionDashboardTableRowClasses.endDateCell}
      >
        {contentData.end_date.toString().split('T')[0]}
      </TableCell>
      <TableCell styleProps={taskCompletionDashboardTableRowClasses.exportCell}>
        {(userOktaGroup === groups.JAN ||
          userOktaGroup === groups.SUPER_GABE ||
          userOktaGroup === groups.GABE) &&
          window.innerWidth > 420 && (
            <IconButton
              icon={IconEnum.Download}
              onClick={() =>
                callBack !== undefined
                  ? callBack(contentData.id, contentData.title + '_completion')
                  : null
              }
              label={'Download Completion List'}
              margin={'0 0.2em'}
              color={
                theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
              }
            />
          )}
      </TableCell>
    </div>
  );
};

export default TaskCompletionDashboardTableRow;
