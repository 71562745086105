import {pageNotFoundStyles} from './styles/pageNotFoundStyles';
import userPreferenceStore, {ColorModeTypeEnum, UserPreferenceStoreState,} from '../../stores/userPreferenceStore';
import zoidberg from '../../assets/images/zoidberg-404.png';
import {IconEnum} from "../Chip/chipUtils/ChipEnums";
import {theme} from "../../assets/siteLineTheme";
import IconButtonWithLabel from "../Buttons/IconButtonWithLabel";

const goBackButtonStyles = (colorMode: ColorModeTypeEnum) => {
    return {
        cursor: 'pointer',
        fontSize: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '0.35em',
        marginTop: '0.5em',
        backgroundColor: theme.newColorPalette.backgroundAddTaskIconDarkBlack[colorMode],
        color: theme.colorPalette.light,
        width: '228px',
        height: '47px',
        borderRadius: '24px',
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    }
}

const PageAPIError = () => {
    const {userPreference} = userPreferenceStore(
        (state: { userPreference: UserPreferenceStoreState }) => ({
            userPreference: state.userPreference,
        })
    );
    const {
        errorPageGenericTextStyle,
        errorPageHeaderText,
        errorPageBodyText,
        errorPageHeader,
    } = pageNotFoundStyles(userPreference.colorMode);

    const goBackButtonClasses = goBackButtonStyles(userPreference.colorMode);

    return (
        <div style={errorPageGenericTextStyle}>
            <div style={errorPageHeader}>
                <div style={errorPageHeaderText}>Uh oh, something went wrong.</div>
                <img style={{width: 300}} src={zoidberg} alt={'Zoidberg'}/>
            </div>
            <div style={errorPageBodyText}>Please try again.</div>
            <IconButtonWithLabel
                icon={IconEnum.BackArrow}
                label={'Return to Homepage'}
                buttonLabel={'RETURN TO HOMEPAGE'}
                onClick={() => {
                    window.location.href = '/'
                }}
                backgroundColor={
                    theme.newColorPalette.backgroundAddTaskIconDarkBlack[
                        userPreference.colorMode
                        ]
                }
                color={theme.colorPalette.plainWhite}
                passedStyle={goBackButtonClasses}
            />
        </div>
    );
};

export default PageAPIError;
