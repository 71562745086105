import { theme } from '../../assets/siteLineTheme';

export type BoxStylesParams = {
  textColor?: string | undefined;
  backgroundColor?: string | undefined;
  padding?: string | undefined;
  margin?: string | undefined;
  borderRadius?: string | undefined;
};

export const boxStyles = (params: BoxStylesParams) => {
  const { textColor, backgroundColor, margin, padding, borderRadius } = params;
  /* istanbul ignore */
  return {
    box: {
      color:
        textColor !== undefined ? textColor : theme.colorPalette.plainBlack,
      backgroundColor:
        backgroundColor !== undefined ? backgroundColor : '#F5F5F5',
      width: '100%',
      height: '100%',
      borderRadius,
      padding,
      margin,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
};
