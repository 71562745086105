import { Box, Typography } from '@mui/material';
import { easyPrintCalendarStyles } from './utils/easyPrintCalendarStyles';
import { monthDayYear } from '../../utils/taskDataFormatter';
import React from 'react';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

export type TaskTitleListProps = {
  taskArray: Array<any>;
};

const TaskTitleList: React.FC<TaskTitleListProps> = (props) => {
  const { taskArray } = props;
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const easyPrintCalendarClasses = easyPrintCalendarStyles(
    userPreference.colorMode
  );

  return (
    <>
      {taskArray.map((task: any) => {
        const displayString = `${task.title} - ${monthDayYear(task.end_date)}`;
        return (
          <Box
            key={task.id}
            sx={easyPrintCalendarClasses.taskTitleContainer}
          >
            <Box sx={easyPrintCalendarClasses.taskCheckBox} />
            <Typography sx={easyPrintCalendarClasses.taskTitleText}>
              {displayString}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

export default TaskTitleList;
