export const listViewWeekDayContentStyles = () => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column' as 'column',
      width: window.innerWidth > 420 ? '1.5em' : '0.55em',
      height: window.innerWidth > 420 ? '1.5em' : '0.55em',
      padding: '0.7em',
    },
    dayLabel: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center' as 'center',
      justifyContent: 'center',
      flexDirection: 'column' as 'column',
      lineHeight: '1.3em',
      fontSize: window.innerWidth > 420 ? '1.2em' : '1em',
    },
  };
};
