import React from 'react';
import { letterIconStyle } from './letterIconStyle';

export type LetterIconProps = {
  text: string;
  backgroundColor?: string;
  onClickFunc?: Function;
};

const LetterIcon: React.FC<LetterIconProps> = (props) => {
  const { text, backgroundColor, onClickFunc } = props;
  const letterIconClasses = letterIconStyle({ backgroundColor });

  return (
    <div
      style={letterIconClasses.letterIconContainer}
      onClick={() => (onClickFunc !== undefined ? onClickFunc() : null)}
    >
      <div style={letterIconClasses.text}>{text}</div>
    </div>
  );
};

export default LetterIcon;
