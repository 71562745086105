import EasyPrintCalendar from '../components/EasyPrintCalendar/EasyPrintCalendar';
import React, { useEffect, useState } from 'react';
import { easyPrintDataSculptor } from '../utils/taskDataFormatter';
import formattedTaskDataStore from '../stores/formattedTaskDataStore';
import {
  appliedFilterQueryStringBuilder,
  FromViewType,
} from '../utils/appliedFilterQueryStringBuilder';
import appliedFiltersStore from '../stores/appliedFiltersStore';
import EasyPrintNavigationRow from '../components/EasyPrintCalendar/EasyPrintNavigationRow';
import { Typography } from '@mui/material';
import { ChangeMonthDirectionEnum } from '../components/EasyPrintCalendar/utils/easyPrintCalendarConstants';
import { getTasksWithAppliedFilters } from '../api/apiV1Task';
import { TaskStoreCompletionStatus } from '../models/TaskStoreCompletionStatus';
import { TaskStatus } from '../models/TaskStatus';
import { useHistory } from 'react-router-dom';
import {theme} from "../assets/siteLineTheme";

const EasyPrintScreen: React.FC = () => {
  const { setReadyForEasyPrintMonthData, readyForEasyPrintMonthData } =
    formattedTaskDataStore(
      (state: {
        setReadyForEasyPrintMonthData: any;
        readyForEasyPrintMonthData: any;
      }) => ({
        setReadyForEasyPrintMonthData: state.setReadyForEasyPrintMonthData,
        readyForEasyPrintMonthData: state.readyForEasyPrintMonthData,
      })
    );

  const { appliedFilters, setAppliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any; setAppliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
      setAppliedFilters: state.setAppliedFilters,
    })
  );

  const history = useHistory();

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const changeMonth = async (direction: string) => {
    if (selectedMonth === 11 && direction === ChangeMonthDirectionEnum.Next) {
      setSelectedMonth(0);
      setSelectedYear(selectedYear + 1);
    } else if (
      selectedMonth === 0 &&
      direction === ChangeMonthDirectionEnum.Prev
    ) {
      setSelectedMonth(11);
      setSelectedYear(selectedYear - 1);
    } else {
      direction === ChangeMonthDirectionEnum.Next
        ? setSelectedMonth(selectedMonth + 1)
        : setSelectedMonth(selectedMonth - 1);
    }
  };

  useEffect(() => {
    if (appliedFilters.store_number && !appliedFilters.completion_status) {
      setAppliedFilters({
        ...appliedFilters,
        completion_status: TaskStoreCompletionStatus.Incomplete,
        task_status: TaskStatus.Approved,
      });
    }
  }, [appliedFilters]);

  const fetchAllTasksWithAppliedFilters = async () => {
    await getTasksWithAppliedFilters(
      appliedFilterQueryStringBuilder(appliedFilters, FromViewType.EasyPrint)
    )
      .then((response) => {
        setReadyForEasyPrintMonthData(
          easyPrintDataSculptor(response, selectedMonth, selectedYear)
        );
      })
      .catch((err) => {
        console.error(err);
        history.replace('/error');
      });
  };

  useEffect(() => {
    fetchAllTasksWithAppliedFilters();
  }, [selectedMonth, appliedFilters]);

  // todo - get a better loading thing
  return (
    <div style={{
      backgroundColor: theme.colorPalette.plainWhite,
      minHeight: '100vh',
      width: '100%',
      position: 'absolute',
    }}>
      <EasyPrintNavigationRow
        changeMonth={changeMonth}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
      />
      {readyForEasyPrintMonthData.length > 0 ? (
        <EasyPrintCalendar />
      ) : (
        <Typography>Loading</Typography>
      )}
    </div>
  );
};

export default EasyPrintScreen;
