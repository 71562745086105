import React, { useEffect, useState } from 'react';
import BaseButton from '../Buttons/BaseButton';
import MultiDropdownSelector from '../Forms/InputComponents/MultiDropdownSelector';
import {
  CompletionStatusList,
  GroupByList,
  SelectOptionType,
  TaskTypeList,
} from '../../utils/Data/dropboxDataList';
import { MultiValue, SingleValue } from 'react-select';
import taskCompletionDashboardStore, {
  AreaScopeEnum,
  CompletionDashboardState,
} from '../../stores/taskCompletionDashboardStore';
import DateSelector from '../Forms/InputComponents/DateSelector';
import { stringBuilderUtils } from '../../utils/stringBuilderUtils';
import SingleDropdownSelector from '../Forms/InputComponents/SingleDropdownSelector';
import { formatStoreNumber } from '../Drawers/FilterDrawer/FilterDrawer';
import { formattedFilterLocationList } from '../../utils/formattedFilterLocationList';
import { theme } from '../../assets/siteLineTheme';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import appliedFiltersStore from '../../stores/appliedFiltersStore';

import { TaskStatus } from '../../models/TaskStatus';
import { groups } from '../../context/constants';
import { AppStateObj } from '../../dataWrappers/TaskCompletionDashboardDataWrapper';
import useFetchUserGroup from '../../hooks/fetchUserGroup';
import { taskCompletionDataToolbarStyles } from './styles/taskCompletionDataToolbarStyles';
import { IconButton } from '../Buttons/IconButton';
import IconButtonWithLabel from '../Buttons/IconButtonWithLabel';
import { AnimatedComponent } from '../animatedComponents/AnimatedComponent';
import { availableSpringParams } from '../animatedComponents/springStylesConstructor';
import { SpringTypeEnum } from '../animatedComponents/AnimatedComponentTypes';

export type TaskCompletionDataToolbarProps = {
  generateChartHandler: Function;
  handleDownloadReportingCSV: Function;
  appStateObj: AppStateObj;
};

export type TaskCompletionDataToolbarState = {
  filteredStoreInformationList: any;
  isNewDataSelections: boolean;
  completionDashboardStateSnapshot: CompletionDashboardState;
  isResetFilter: boolean;
};

const TaskCompletionDataToolbar: React.FC<TaskCompletionDataToolbarProps> = (
  props
) => {
  const { generateChartHandler, handleDownloadReportingCSV, appStateObj } = props;

  const {
    completionDashboardState,
    setCompletionDashboardState,
    resetCompletionDashboardState,
  } = taskCompletionDashboardStore(
    (state: {
      completionDashboardState: CompletionDashboardState;
      setCompletionDashboardState: Function;
      resetCompletionDashboardState: Function;
    }) => ({
      completionDashboardState: state.completionDashboardState,
      setCompletionDashboardState: state.setCompletionDashboardState,
      resetCompletionDashboardState: state.resetCompletionDashboardState,
    })
  );

  const { appliedFilters, setAppliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any; setAppliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
      setAppliedFilters: state.setAppliedFilters,
    })
  );

  const userOktaGroup = useFetchUserGroup(appStateObj.userInfo);

  const [taskCompletionDataToolbarObj, setTaskCompletionDataToolbarObj] =
    React.useState<TaskCompletionDataToolbarState>({
      filteredStoreInformationList: appStateObj.storeLocationInformation,
      isNewDataSelections: false,
      completionDashboardStateSnapshot: completionDashboardState,
      isResetFilter: false,
    });

  const [filteredStoreInformation, setFilteredStoreInformation] = useState(
    appStateObj.storeLocationInformation
  );

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const taskCompletionDataToolbarClasses = taskCompletionDataToolbarStyles();

  useEffect(() => {
    getFilteredStoreInformation();
  }, [
    appStateObj.storeLocationInformation,
    completionDashboardState.territoryNumber,
    completionDashboardState.districtNumber,
    completionDashboardState.storeNumber,
  ]);

  // stays here
  const getFilteredStoreInformation = () => {
    let filteredStoreInformation = appStateObj.storeLocationInformation;

    if (filteredStoreInformation && completionDashboardState.territoryNumber) {
      filteredStoreInformation = filteredStoreInformation.filter(
        (store: any) =>
          store.territory_number ===
          parseInt(completionDashboardState.territoryNumber as string)
      );
    }

    if (filteredStoreInformation && completionDashboardState.districtNumber) {
      filteredStoreInformation = filteredStoreInformation.filter(
        (store: any) =>
          store.district_number ===
          parseInt(completionDashboardState.districtNumber as string)
      );
    }

    if (filteredStoreInformation && completionDashboardState.storeNumber) {
      filteredStoreInformation = filteredStoreInformation.filter(
        (store: any) =>
          formatStoreNumber(store?.location_number?.toString()) ===
          formatStoreNumber(completionDashboardState.storeNumber as string)
      );
    }

    setFilteredStoreInformation(filteredStoreInformation);
  };

  const getLocationList = (scope: AreaScopeEnum) => {
    const filteringLocationList = formattedFilterLocationList(
      scope,
      filteredStoreInformation
    );
    const returnFilteringLocationList: SelectOptionType[] = [];
    filteringLocationList.map((store: string) => {
      const formatObj = { value: store.split(' - ')[0], label: store };
      return returnFilteringLocationList.push(formatObj);
    });
    return returnFilteringLocationList;
  };

  // move to global storeUtil
  // refactor to select which store and which value should be set
  // todo - pass in getter and setter for store to make more generic
  const onChangeSingleSelector = (
    newSelection: SingleValue<SelectOptionType>,
    targetFieldKey: string
  ) => {
    setTaskCompletionDataToolbarObj({
      ...taskCompletionDataToolbarObj,
      isResetFilter: false,
    });
    setCompletionDashboardState({
      ...completionDashboardState,
      [targetFieldKey]: newSelection ? newSelection.value : undefined,
    });
  };
  // move to global storeUtil
  // refactor to select which store and which value should be set
  // todo - pass in getter and setter for store to make more generic
  const onChangeMultiDropbox = (
    newSelections: MultiValue<SelectOptionType>,
    targetFieldKey: string
  ) => {
    setTaskCompletionDataToolbarObj({
      ...taskCompletionDataToolbarObj,
      isResetFilter: false,
    });
    const stringBuilder = stringBuilderUtils(newSelections, targetFieldKey);
    setCompletionDashboardState({
      ...completionDashboardState,
      [targetFieldKey]: stringBuilder,
    });
  };
  // move to global storeUtil
  // refactor to select which store and which value should be set
  // todo - pass in getter and setter for store to make more generic
  const onChangeDateSelector = (
    e: React.ChangeEvent<HTMLInputElement>,
    targetFieldKey: string
  ) => {
    setTaskCompletionDataToolbarObj({
      ...taskCompletionDataToolbarObj,
      isResetFilter: false,
    });
    setCompletionDashboardState({
      ...completionDashboardState,
      [targetFieldKey]: e.target.value,
    });
  };

  const compareWithSnapshot = () => {
    if (
      taskCompletionDataToolbarObj.completionDashboardStateSnapshot !==
      completionDashboardState
    ) {
      setTaskCompletionDataToolbarObj({
        ...taskCompletionDataToolbarObj,
        isNewDataSelections: true,
        completionDashboardStateSnapshot: completionDashboardState,
      });
    } else {
      setTaskCompletionDataToolbarObj({
        ...taskCompletionDataToolbarObj,
        isNewDataSelections: false,
      });
    }
  };

  // if the data has changed, turn it true and match up the data
  useEffect(() => {
    compareWithSnapshot();
  }, [completionDashboardState]);

  const generateChartHandlerWithSnapshot = () => {
    generateChartHandler();
    compareWithSnapshot();
  };

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const resetFilter = () => {
    resetCompletionDashboardState();
    setTaskCompletionDataToolbarObj({
      ...taskCompletionDataToolbarObj,
      isResetFilter: true,
    });
    if (userOktaGroup === groups.JAN) {
      if (
        appliedFilters.store_number ||
        appliedFilters.district_number ||
        appliedFilters.territory_number
      ) {
        setAppliedFilters({
          ...appliedFilters,
          task_status: TaskStatus.Approved,
        });
      } else {
        setAppliedFilters({
          ...appliedFilters,
          territory_number: appStateObj.userInfo.territory_number,
          district_number: appStateObj.userInfo.district_number,
          task_status: TaskStatus.Approved,
        });
      }
    } else if (
      userOktaGroup === groups.MICHAEL ||
      userOktaGroup === groups.STANLEY
    ) {
      setAppliedFilters({
        ...appliedFilters,
        store_number: appStateObj.userInfo.locationnumber,
        task_status: TaskStatus.Approved,
      });
    } else {
      setAppliedFilters({
        ...appliedFilters,
        territory_number: undefined,
        district_number: undefined,
        store_number: undefined,
      });
    }
  };

  const handleExpandSection = () => {
    setIsCollapsed(!isCollapsed);
  };
  const springsParam: Array<availableSpringParams> = [
    {
      springType: SpringTypeEnum.RotateSpring,
      activeRotateZ: 180,
      passiveRotateZ: 0,
    },
  ];

  return (
    <div style={taskCompletionDataToolbarClasses.toolBarTitleContainer}>
      <div style={taskCompletionDataToolbarClasses.titleContainer}>
        <div style={taskCompletionDataToolbarClasses.titleText}>
          Chart Filters
        </div>
        {window.innerWidth > 420 ? (
          <></>
        ) : (
          <AnimatedComponent
            isPassive={isCollapsed}
            springs={springsParam}
            label={'accordionHeaderAnimation'}
            loop={false}
          >
            <IconButton
              label={'expand isRecent button'}
              icon={IconEnum.DropDownArrow}
              margin={'0 0.2em'}
              onClick={handleExpandSection}
            />
          </AnimatedComponent>
        )}
      </div>
      {isCollapsed ? (
        <></>
      ) : (
        <div
          style={taskCompletionDataToolbarClasses.toolbarContainer}
          aria-label={'Task Completion Dashboard Toolbar'}
        >
          {false &&
              <>
                <SingleDropdownSelector
                    dataOption={GroupByList}
                    placeholderText={'Sorting Value...'}
                    onChange={onChangeSingleSelector}
                    targetObjField={'groupBy'}
                    labelText={'Sort By'}
                    redLabelText={'*'}
                    defaultValue={GroupByList[0]}
                    resetValue={taskCompletionDataToolbarObj.isResetFilter}
                />
                <SingleDropdownSelector
                    dataOption={getLocationList(AreaScopeEnum.Territory)}
                    placeholderText={'Territory Number...'}
                    onChange={onChangeSingleSelector}
                    targetObjField={'territoryNumber'}
                    labelText={'Territory Number'}
                    isDisabled={completionDashboardState.groupBy === undefined}
                    resetValue={taskCompletionDataToolbarObj.isResetFilter}
                />
              </>

          }
          <SingleDropdownSelector
            dataOption={getLocationList(AreaScopeEnum.District)}
            placeholderText={'District Number...'}
            onChange={onChangeSingleSelector}
            targetObjField={'districtNumber'}
            labelText={'District Number'}
            isDisabled={completionDashboardState.groupBy === undefined}
            resetValue={taskCompletionDataToolbarObj.isResetFilter}
          />
          <SingleDropdownSelector
            dataOption={getLocationList(AreaScopeEnum.Store)}
            placeholderText={'Store Number...'}
            onChange={onChangeSingleSelector}
            targetObjField={'storeNumber'}
            labelText={'Store Number'}
            isDisabled={
              completionDashboardState.groupBy === undefined ||
              userOktaGroup === groups.MICHAEL
            }
            resetValue={taskCompletionDataToolbarObj.isResetFilter}
            defaultValue={appStateObj.defaultStoreNumber}
          />
          <MultiDropdownSelector
            dataOption={TaskTypeList}
            placeholderText={'Task Type...'}
            onChange={onChangeMultiDropbox}
            targetObjField={'task_type'}
            labelText={'Task Type'}
            isDisabled={completionDashboardState.groupBy === undefined}
            resetValue={taskCompletionDataToolbarObj.isResetFilter}
          />
          <MultiDropdownSelector
            dataOption={CompletionStatusList}
            placeholderText={'Completion Status...'}
            onChange={onChangeMultiDropbox}
            targetObjField={'completion_status'}
            labelText={'Completion Status'}
            isDisabled={completionDashboardState.groupBy === undefined}
            resetValue={taskCompletionDataToolbarObj.isResetFilter}
          />
          <div style={taskCompletionDataToolbarClasses.dateSelectorsContainer}>
            <DateSelector
              dataValue={completionDashboardState.minQueryDate}
              dataKey={'minQueryDate'}
              onChange={onChangeDateSelector}
              ariaLabelText={'Minimum Query Date Selector'}
              lableText={'Start Date'}
              disabled={completionDashboardState.groupBy === undefined}
              width={'12em'}
              height={'2em'}
            />
            <DateSelector
              dataValue={completionDashboardState.maxQueryDate}
              dataKey={'maxQueryDate'}
              onChange={onChangeDateSelector}
              ariaLabelText={'Maximum Query Date Selector'}
              lableText={'End Date'}
              disabled={completionDashboardState.groupBy === undefined}
              width={'12em'}
              height={'2em'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: 'fit-content',
              margin: '.7em 0em',
              flexDirection: window.innerWidth > 420 ? 'column' : 'row',
            }}
          >
            <BaseButton
              borderRadius={'.25em'}
              onClick={generateChartHandlerWithSnapshot}
              activeBackgroundColor={
                theme.newColorPalette.zoidbergWhite[userPreference.colorMode]
              }
              passiveBackgroundColor={
                theme.newColorPalette.zoidbergWhite[userPreference.colorMode]
              }
              activeTextColor={
                theme.newColorPalette.plainWhiteBlack[userPreference.colorMode]
              }
              passiveTextColor={
                theme.newColorPalette.plainWhiteBlack[userPreference.colorMode]
              }
              label={'Generate Chart Button'}
              isDisabled={
                !taskCompletionDataToolbarObj.isNewDataSelections ||
                completionDashboardState.groupBy === undefined
              }
              isSelected={
                !taskCompletionDataToolbarObj.isNewDataSelections ||
                completionDashboardState.groupBy === undefined
              }
              colorMode={userPreference.colorMode}
              margin={window.innerWidth > 420 ? '0em 0 0.5em 0' : '0 0.5em'}
            >
              Apply Filters
            </BaseButton>
            {userOktaGroup !== groups.MICHAEL && (
              <BaseButton
                borderRadius={'.25em'}
                onClick={resetFilter}
                activeBackgroundColor={
                  theme.newColorPalette.zoidbergWhite[userPreference.colorMode]
                }
                passiveBackgroundColor={
                  theme.newColorPalette.plainGreyBlack[userPreference.colorMode]
                }
                activeTextColor={
                  theme.newColorPalette.plainWhiteBlack[
                    userPreference.colorMode
                  ]
                }
                passiveTextColor={
                  theme.newColorPalette.plainWhiteBlack[
                    userPreference.colorMode
                  ]
                }
                label={'Reset Filters'}
                colorMode={userPreference.colorMode}
                margin={window.innerWidth > 420 ? '0' : '0 0.5em'}
              >
                Reset Filters
              </BaseButton>
            )}
            <IconButtonWithLabel
                label={'Download Report'}
                icon={IconEnum.Download}
                onClick={() => handleDownloadReportingCSV()}
                color={
                  taskCompletionDataToolbarObj.isNewDataSelections ? 'rgba(16, 16, 16, 0.3)'
                      : (completionDashboardState.districtNumber === undefined && completionDashboardState.storeNumber === undefined) ? 'rgba(16, 16, 16, 0.3)' : theme.newColorPalette.plainWhiteBlack[userPreference.colorMode]
                }
                textColor={
                  taskCompletionDataToolbarObj.isNewDataSelections ? 'rgba(16, 16, 16, 0.3)'
                      : (completionDashboardState.districtNumber === undefined && completionDashboardState.storeNumber === undefined) ? 'rgba(16, 16, 16, 0.3)' : theme.newColorPalette.plainWhiteBlack[userPreference.colorMode]
                }
                buttonLabel={'Download Report'}
                backgroundColor={
                  theme.newColorPalette.zoidbergWhite[userPreference.colorMode]
                }
                margin={window.innerWidth > 420 ? '0.5em 0' : '0 0.5em'}
                size={window.innerWidth > 420 ? '2em' : '3.75em'}
                borderRadius={'0.25em'}
                isDisabled={taskCompletionDataToolbarObj.isNewDataSelections ? true : (completionDashboardState.districtNumber === undefined && completionDashboardState.storeNumber === undefined)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskCompletionDataToolbar;
