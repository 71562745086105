import { CalendarEvent } from '../components/types/Calendar/ICalendarIndex';
import { TaskStatus } from '../models/TaskStatus';
import {
  addDays,
  endOfWeek,
  format,
  formatDistance,
  startOfWeek,
} from 'date-fns';
import { DateRangeTypeEnum } from '../stores/dateSelectionForDisplayStore';

export const convertDateToUTC = (date: string) => {
  const unConvertedDate = new Date(date);

  return new Date(
    unConvertedDate.getUTCFullYear(),
    unConvertedDate.getUTCMonth(),
    unConvertedDate.getUTCDate()
  );
};

export const removeTime = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const isDateBeforeToday = (checkedDate: Date) => {
  const currentDate = removeTime(new Date());
  const taskEndDate = removeTime(new Date(checkedDate));
  return taskEndDate < currentDate;
};

export const modifyStartDateAndEndDateToUTCForDisplayInCalendar = (
  events: Array<CalendarEvent>
) => {
  const modifyEventsArray: Array<CalendarEvent> = events.map(
    (event: CalendarEvent) => {
      if (event.id) {
        const utcStartDate = new Date(
          event.start_date.getUTCFullYear(),
          event.start_date.getUTCMonth(),
          event.start_date.getUTCDate()
        );
        const utcEndDate = new Date(
          event.end_date.getUTCFullYear(),
          event.end_date.getUTCMonth(),
          event.end_date.getUTCDate() + 1
        );
        let utcStoreScheduleStartDate;

        if (
          event.store_scheduled_start_date !== null &&
          event.store_scheduled_start_date !== undefined
        ) {
          utcStoreScheduleStartDate = new Date(
            event.store_scheduled_start_date.getUTCFullYear(),
            event.store_scheduled_start_date.getUTCMonth(),
            event.store_scheduled_start_date.getUTCDate()
          );
        }
        let utcStoreScheduleEndDate;

        if (
          event.store_scheduled_end_date !== null &&
          event.store_scheduled_end_date !== undefined
        ) {
          utcStoreScheduleEndDate = new Date(
            event.store_scheduled_end_date.getUTCFullYear(),
            event.store_scheduled_end_date.getUTCMonth(),
            event.store_scheduled_end_date.getUTCDate() + 1
          );
        }
        return {
          ...event,
          start_date: utcStartDate,
          end_date: utcEndDate,
          display_start_date:
            utcStoreScheduleStartDate !== undefined
              ? utcStoreScheduleStartDate
              : utcStartDate,
          display_end_date:
            utcStoreScheduleEndDate !== undefined
              ? utcStoreScheduleEndDate
              : utcEndDate,
        };
      } else {
        const endDateAsDate = new Date(event.end_date);
        const utcFormattedEndDate = new Date(
          endDateAsDate.getUTCFullYear(),
          endDateAsDate.getUTCMonth(),
          endDateAsDate.getUTCDate() + 1
        );

        return {
          ...event,
          start_date: new Date(event.start_date),
          end_date: utcFormattedEndDate,
          task_type: 'Marketing Promotions',
          task_status: TaskStatus.Approved,
          uc_event: true,
          display_start_date: new Date(event.start_date),
          display_end_date: utcFormattedEndDate,
        };
      }
    }
  );
  return modifyEventsArray;
};

export const formatDateForDisplay = (
  inputDate: string,
  returnType?: string
) => {
  const formattedDate = new Date(inputDate);
  const currentDate = new Date();

  const localFormattedDate = formattedDate.toString();
  const localCurrentDate = currentDate.toString();

  const convertLocalFormattedDate = new Date(localFormattedDate);
  const convertLocalCurrentDate = new Date(localCurrentDate);

  const convertLocalFormattedDateDay = convertLocalFormattedDate.getDate();
  const convertLocalCurrentDateDay = convertLocalCurrentDate.getDate();

  const formattedDateForDisplay = formatDistance(
    convertLocalCurrentDate,
    convertLocalFormattedDate
  );

  // 1. Day same
  if (
    convertLocalFormattedDateDay === convertLocalCurrentDateDay &&
    (formattedDateForDisplay.includes('minute') ||
      formattedDateForDisplay.includes('hour'))
  ) {
    const removeAbout = formattedDateForDisplay
      .replace('about', '')
      .replace('less than a', '1');
    if (returnType !== undefined && returnType === 'History') {
      return removeAbout.replace('minute', 'min') + ' ago';
    } else {
      return removeAbout + ' ago';
    }
  }
  // 2. Day differ but 20 hours ago -> 1 day ago
  else if (
    !formattedDateForDisplay.includes('day') &&
    !formattedDateForDisplay.includes('month') &&
    !formattedDateForDisplay.includes('year') &&
    (formattedDateForDisplay.includes('minute') ||
      formattedDateForDisplay.includes('hour'))
  ) {
    if (returnType !== undefined && returnType === 'History') {
      return formatDateForDisplayWithMonthDayYear(inputDate);
    } else {
      return '1 day ago';
    }
  } else {
    if (returnType !== undefined && returnType === 'History') {
      return formatDateForDisplayWithMonthDayYear(inputDate);
    } else {
      return formattedDateForDisplay + ' ago';
    }
  }
};

export const formatDateForDisplayWithMonthDayYear = (inputDate: string) => {
  const formattedDate = new Date(inputDate);

  const month = formattedDate.getUTCMonth() + 1;
  const day = formattedDate.getUTCDate();
  const year = formattedDate.getUTCFullYear().toString();
  const sliceYear = year.slice(-2);

  return month + '/' + day + '/' + sliceYear;
};

export const getDaysOfWeekArray = (inputDate: Date) => {
  const start = startOfWeek(new Date(inputDate));

  const shortWeekDaysArray = Array.from(Array(7)).map((e, i) => {
    const dayName = format(addDays(start, i), 'E');
    const dayNumber = format(addDays(start, i), 'd');
    const month = format(addDays(start, i), 'M');
    const year = format(addDays(start, i), 'yyyy');
    return {
      day: dayName,
      date: dayNumber,
      month: parseInt(month) - 1,
      year,
    };
  });
  return shortWeekDaysArray;
};

export const getMaxMinDates = (currentDay: Date, view: DateRangeTypeEnum) => {
  const start =
    view === DateRangeTypeEnum.DAY
      ? removeTime(currentDay)
      : startOfWeek(new Date(currentDay));
  const end =
    view === DateRangeTypeEnum.DAY ? start : endOfWeek(new Date(currentDay));
  return {
    min_selected_date: new Date(start),
    max_selected_date: new Date(end),
  };
};
