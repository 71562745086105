import {
  apiRequestConfig,
  getRegionalTaskStoreCompletionStatus,
} from './apiV1Task';
import appliedFiltersStore from './../stores/appliedFiltersStore';
import axios, { AxiosResponse } from 'axios';

export const fetchRegionalTaskCompletionStatus = async (
  id: string | undefined
) => {
  if (!id) {
    return {};
  }
  const appliedFilters = appliedFiltersStore.getState().appliedFilters;
  let regionalTaskStoreCompletionStatusPromise = {};

  if (appliedFilters.district_number && !appliedFilters.store_number) {
    regionalTaskStoreCompletionStatusPromise =
      getRegionalTaskStoreCompletionStatus(id, {
        district_number: appliedFilters.district_number,
      });
  } else if (appliedFilters.region_number && !appliedFilters.store_number) {
    regionalTaskStoreCompletionStatusPromise =
        getRegionalTaskStoreCompletionStatus(id, {
          region_number: appliedFilters.region_number,
        });
  } else if (appliedFilters.territory_number && !appliedFilters.store_number) {
    regionalTaskStoreCompletionStatusPromise =
      getRegionalTaskStoreCompletionStatus(id, {
        territory_number: appliedFilters.territory_number,
      });
  }
  return regionalTaskStoreCompletionStatusPromise;
};

export const getDataWithOktaTokens = async (url: string, passedValue?: any) => {
  const whitelist = [url]
  if(whitelist.includes(url)) {
    const response: AxiosResponse = await axios.get(url, {
      params: {
        ...passedValue,
      },
      ...await apiRequestConfig(),
    });
    return response.data;
  } else {
    console.log('You dont have permission to GET from that url')
  }
};
