import { theme } from '../../assets/siteLineTheme';
import userPreferenceStore, {
  ColorModeTypeEnum,
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import React from 'react';
import BaseButton from './BaseButton';
import { RoundedButtonProps } from '../Toolbar/CalendarToolbar';

const RoundedButton: React.FC<RoundedButtonProps> = (props) => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const { displayText, clickHandler, labelText } = props;

  const backgroundColor =
    userPreference.colorMode !== ColorModeTypeEnum.BlackWhite
      ? theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
      : 'undefined';
  const color = theme.newColorPalette.plainWhiteBlack[userPreference.colorMode];

  return (
    <BaseButton
      onClick={clickHandler}
      activeBackgroundColor={backgroundColor}
      passiveBackgroundColor={backgroundColor}
      activeTextColor={color}
      passiveTextColor={color}
      label={labelText}
    >
      {displayText}
    </BaseButton>
  );
};
export default RoundedButton;
