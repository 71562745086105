import { theme } from '../../../../assets/siteLineTheme';

export const taskCompletionDashboardTableRowStyles = () => {
  return {
    rowContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderBottom: `0.75px solid ${theme.colorPalette.taskListViewBorder}`,
      borderTop: `0.75px solid ${theme.colorPalette.taskListViewBorder}`,
      height: '100%',
      cursor: 'pointer',
    },
    titleCell: {
      cellWidth: window.innerWidth > 420 ? '50%' : '55%',
    },
    endDateCell: {
      cellWidth: window.innerWidth > 420 ? '20%' : '25%',
      justifyContent: 'center',
    },
    storeNumberCell: {
      cellWidth: '20%',
      justifyContent: 'center',
    },
    exportCell: {
      cellWidth: '10%',
      justifyContent: 'center',
    },
  };
};
