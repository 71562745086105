import { useQuery } from '@tanstack/react-query';
import {
  getDigitalMerchandisingData,
  getMerchRecentlyViewed,
} from '../api/apiDigitalMerchandising';

export const useFetchDigitalMerchandisingData = (triggerTime: Date) => {
  const { data, error, isLoading, isSuccess } = useQuery(
    ['digitalMerchandisingData', triggerTime],
    () => getDigitalMerchandisingData()
  );
  return { data, error, isLoading, isSuccess };
};

export const useFetchDigitalMerchandisingRecentlyViewed = (
  triggerTime: Date
) => {
  const { data, error, isLoading } = useQuery(
    ['digitalMerchandisingRecentlyViewedData', triggerTime],
    () => getMerchRecentlyViewed()
  );
  return { data, error, isLoading };
};
