import React, {useContext, useEffect, useState} from 'react';
import {useFetchDigitalMerchandisingData} from '../hooks/useFetchDigitalMerchandisingData';
import BoxArray, {BoxContentTypeEnum} from '../components/BoxArray/BoxArray';
import BreadcrumbNavigationBar from '../components/BreadcrumbNavigationBar/BreadcrumbNavigationBar';
import {digitalMerchandisingScreenStyles} from '../screens/styles/digitalMerchandisingScreenStyles';
import {DigitalMerchandisingLinkTypeEnum, NavigationObj,} from '../utils/testData/mockHierarchyData';
import {IconButton} from '../components/Buttons/IconButton';
import {AnimatedComponent} from '../components/animatedComponents/AnimatedComponent';
import {availableSpringParams} from '../components/animatedComponents/springStylesConstructor';
import {SpringTypeEnum} from '../components/animatedComponents/AnimatedComponentTypes';
import {AppContext} from '../context/AppContext';
import {analyticsEventConstants, sendAnalyticsEvent,} from '../utils/analytics';
import FeedbackButton from '../components/Buttons/FeedbackButton';
import DigitalMerchandisingCreate from '../components/Forms/DigitalMerchandisingCreate';
import {IconEnum} from '../components/Chip/chipUtils/ChipEnums';
import IconButtonWithLabel from '../components/Buttons/IconButtonWithLabel';
import {handlerChange} from '../components/Forms/formUtilities/handlerChange';
import {handlerButtonClick} from '../components/Forms/formUtilities/handlerButtonClick';
import {handlerMenuClick} from '../components/Forms/formUtilities/handlerMenuClick';
import userPreferenceStore, {UserPreferenceStoreState,} from 'src/stores/userPreferenceStore';
import {theme} from '../assets/siteLineTheme';
import {getMerchRecentlyViewed} from '../api/apiDigitalMerchandising';
import fireUseQueryStore from '../stores/fireUseQueryStore';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {groups} from '../context/constants';
import ProtectedEmbedContainer from '../components/ProtectedEmbedContainer/ProtectedEmbedContainer';
import useFetchUserGroup from '../hooks/fetchUserGroup';
import ViewPDF from '../components/ViewAttachments/ViewPDF';
import {generateSignedUrl} from '../api/apiFile';
import {parseFilesUploadType} from '../utils/fileUploadUtils/parseFilesUploadType';

const DigitalMerchandisingDataWrapper: React.FC = () => {
  const { appState } = useContext<any>(AppContext);
  const { screens, userInfo } = appState;
  const userOktaGroup = useFetchUserGroup(userInfo);

  const digitalMerchandisingScreenClasses = digitalMerchandisingScreenStyles();

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const { fireUseQueryStoreProps } = fireUseQueryStore(
    (state: { fireUseQueryStoreProps: any }) => ({
      fireUseQueryStoreProps: state.fireUseQueryStoreProps,
    })
  );

  const { data, isLoading, isSuccess, error } =
    useFetchDigitalMerchandisingData(fireUseQueryStoreProps.triggerTime);

  const { subPath } = useParams<{ subPath: string }>();
  const history = useHistory();
  const { pathname } = useLocation();

  const setHistory = (pathname: string) => {
    history.push({
      pathname,
      state: {
        // location state
        update: true,
      },
    });
  };

  const demoDataTopLevel =
    data &&
    data.filter((data: NavigationObj) => {
      return data.parent_code === null;
    });

  const [navigationParentObj, setNavigationParentObj] = useState<
    NavigationObj | undefined
  >(undefined);
  const [childrenArray, setChildrenArray] = useState<Array<NavigationObj>>([]);
  const [recentlyViewedArray, setRecentlyViewedArray] = useState<
    Array<NavigationObj>
  >([]);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const clearNavigationArray = () => {
    setChildrenArray([]);
    setNavigationParentObj(undefined);
  };

  const getChildrenArray = (navigationObj: NavigationObj) => {
    const filteredChildrenArray =
      data &&
      data.filter((data: NavigationObj) => {
        return data.parent_code === navigationObj.code;
      });
    setChildrenArray(filteredChildrenArray);
  };

  const getRecentlyViewedArray = async () => {
    const dataReturn = await getMerchRecentlyViewed();
    setRecentlyViewedArray(dataReturn);
  };

  useEffect(() => {
    getRecentlyViewedArray();
  }, [isLoading]);

  const handleDigitalMerchItemClicked = (
    name: string,
    event: { action: string; category: string; label: string }
  ) => {
    sendAnalyticsEvent({
      action: event.action,
      category: event.category,
      label: event.label,
      state: decodeURIComponent(name),
    });
  };

  const handleSetNavigationDisplay = (navigationObj: NavigationObj) => {
    if (
      navigationObj.link_type === DigitalMerchandisingLinkTypeEnum.GSite &&
      navigationObj.url !== null
    ) {
      window.open(navigationObj.url, '_self');
      handleDigitalMerchItemClicked(
        navigationObj.title,
        analyticsEventConstants.MERCHANDISING_GOOGLESITE
      );
    } else {
      if (!navigationObj.parent_code) {
        setHistory('/digital-merchandising/' + navigationObj.title);
      } else {
        if (pathname.substring(pathname.length - 1) !== '/') {
          setHistory(pathname + '/' + navigationObj.title);
          if (
            navigationObj.link_type === DigitalMerchandisingLinkTypeEnum.Pdf
          ) {
            handleDigitalMerchItemClicked(
              navigationObj.title,
              analyticsEventConstants.MERCHANDISING_PDF
            );
          } else if (
            navigationObj.link_type ===
            DigitalMerchandisingLinkTypeEnum.Flipping
          ) {
            handleDigitalMerchItemClicked(
              navigationObj.title,
              analyticsEventConstants.MERCHANDISING_FLIPPINGBOOK
            );
          }
        } else {
          setHistory(pathname + navigationObj.title);
        }
      }
    }
  };

  const [fromUrl, setFromUrl] = useState<{
    fromDirectUrl: boolean;
    url: string;
  }>({ fromDirectUrl: false, url: '' });
  useEffect(() => {
    if (isSuccess) {
      if (subPath) {
        const fromUrlObj =
          data &&
          data.find((data: NavigationObj) => {
            if (data.menu_path && subPath) {
              return (
                decodeURIComponent(data.menu_path) ===
                decodeURIComponent(subPath)
              );
            } else {
              return false;
            }
          });

        if (fromUrlObj && fromUrlObj.url) {
          setNavigationParentObj(fromUrlObj);
          getChildrenArray(fromUrlObj);
          setFromUrl({ fromDirectUrl: true, url: fromUrlObj.url });
        } else if (fromUrlObj) {
          setNavigationParentObj(fromUrlObj);
          getChildrenArray(fromUrlObj);
          setFromUrl({ fromDirectUrl: false, url: '' });
        } else {
          // if you couldn't find because of modified menus, it should be main
          setHistory('/digital-merchandising');
          getChildrenArray(data && demoDataTopLevel[0]);
        }
      } else {
        getChildrenArray(data && demoDataTopLevel[0]);
        setFromUrl({ fromDirectUrl: false, url: '' });
      }
    }
  }, [isSuccess, subPath]);

  const handleSetNavigationDisplayWithBreadcrumb = (
    navigationPathString: string
  ) => {
    const pathPatchNavigationObj = data.find(
      (data: NavigationObj) => data.menu_path === navigationPathString
    );

    if (pathPatchNavigationObj !== undefined) {
      setHistory('/digital-merchandising/' + pathPatchNavigationObj.menu_path);
    }
  };

  const determineIsSelectedMainMenu = (navigationObj: NavigationObj) => {
    return navigationObj.menu_path === navigationParentObj?.menu_path;
  };

  const handleExpandSection = () => {
    setIsCollapsed(!isCollapsed);
  };
  const springsParam: Array<availableSpringParams> = [
    {
      springType: SpringTypeEnum.RotateSpring,
      activeRotateZ: 180,
      passiveRotateZ: 0,
    },
  ];

  useEffect(() => {
    if (screens) {
      sendAnalyticsEvent({
        ...analyticsEventConstants.NAVIGATION_MERCHANDISING_VIEW,
        state: 'Main Page',
      });
    }
  }, [screens]);

  const [highestUserGroup, setHighestUserGroup] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (userOktaGroup !== undefined && userOktaGroup !== '') {
      setHighestUserGroup(userOktaGroup);
    }
  }, [userOktaGroup]);

  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [signedURL, setSignedURL] = useState('');

  useEffect(() => {
    const getFileSignedUrl = async (id: string, fileName: string) => {
      const { signedUrl } = await generateSignedUrl(fileName, 'read', id);
      setSignedURL(signedUrl);
    };

    navigationParentObj !== undefined && navigationParentObj.pdf_file_information?.attachments_id !== undefined &&
      getFileSignedUrl(
          navigationParentObj.pdf_file_information?.attachments_id,
        navigationParentObj.pdf_file_information?.name !== undefined
          ? navigationParentObj.pdf_file_information?.name
          : ''
      );
  }, [navigationParentObj]);

  useEffect(() => {
    if (error !== null) {
      history.replace('/error');
    }
  }, [error]);

  return (
    <>
      <FeedbackButton
        analyticsEvent={analyticsEventConstants.FEEDBACK_VIEW_CALENDAR}
      />
      <div aria-label={'DigitalMerchandisingDataWrapper'}>
        {isLoading && <div>Loading</div>}
        {data !== undefined && (
          <div
            className={'screenContainer'}
            style={digitalMerchandisingScreenClasses.screenContainer}
          >
            {!isCreate && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                {highestUserGroup !== undefined &&
                  highestUserGroup === groups.SUPER_GABE && (
                    <IconButtonWithLabel
                      label={'New Menu'}
                      icon={IconEnum.Check}
                      onClick={() => {
                        setHistory('/digital-merchandising');
                        setIsCreate(true);
                        sendAnalyticsEvent(
                          analyticsEventConstants.MERCHANDISING_OPEN_ADD_EDIT_MENU
                        );
                      }}
                      buttonLabel={'Add/Edit Menu'}
                      textColor={
                        theme.newColorPalette.plainBlackWhite[
                          userPreference.colorMode
                        ]
                      }
                      borderRadius={'1em'}
                      color={theme.colorPalette.plainWhite}
                      backgroundColor={
                        theme.newColorPalette.snackbar.successBlack[
                          userPreference.colorMode
                        ]
                      }
                    />
                  )}
              </div>
            )}

            {!isCreate && (
              <>
                <div>
                  {navigationParentObj === undefined ||
                  navigationParentObj.parent_code === null ? (
                    <>
                      {/* Recently Viewed */}
                      <div
                        className={'navSection'}
                        style={digitalMerchandisingScreenClasses.navSection}
                      >
                        <div
                          className={'navSectionTitle'}
                          style={
                            digitalMerchandisingScreenClasses.navSectionTitle
                          }
                        >
                          Recently Viewed
                          <AnimatedComponent
                            isPassive={isCollapsed}
                            springs={springsParam}
                            label={'accordionHeaderAnimation'}
                            loop={false}
                          >
                            <IconButton
                              label={'expand isRecent button'}
                              icon={IconEnum.DropDownArrow}
                              margin={'0 0.2em'}
                              onClick={handleExpandSection}
                              color={
                                theme.newColorPalette.snackbar.successBlack[
                                  userPreference.colorMode
                                ]
                              }
                            />
                          </AnimatedComponent>
                        </div>
                        {isCollapsed && (
                          <BoxArray
                            dataArray={recentlyViewedArray}
                            boxContentType={
                              BoxContentTypeEnum.RecentlyViewedBoxContent
                            }
                            boxHeight={
                              window.innerWidth > 420 ? '10vh' : '20vh'
                            }
                            boxWidth={'33%'}
                            onClick={handleSetNavigationDisplay}
                            justifyContent={'start'}
                            callBack={getRecentlyViewedArray}
                          />
                        )}
                      </div>
                      {/* Top level of nav data */}
                      <div
                        className={'navSection'}
                        style={digitalMerchandisingScreenClasses.navSection}
                      >
                        <div
                          className={'navSectionTitle'}
                          style={
                            digitalMerchandisingScreenClasses.navSectionTitle
                          }
                        >
                          Main Menu
                        </div>
                        <BoxArray
                          justifyContent={'flex-start'}
                          dataArray={demoDataTopLevel}
                          boxContentType={
                            BoxContentTypeEnum.NavigationBoxContent
                          }
                          boxHeight={window.innerWidth > 420 ? '15vh' : '10vh'}
                          boxWidth={`50%`}
                          onClick={handleSetNavigationDisplay}
                          determineIsSelected={determineIsSelectedMainMenu}
                          gap={'0.4em 0'}
                          callBack={getRecentlyViewedArray}
                        />
                      </div>
                    </>
                  ) : (
                    <BreadcrumbNavigationBar
                      navigationObj={navigationParentObj}
                      onClick={handleSetNavigationDisplayWithBreadcrumb}
                    />
                  )}
                  {/* todo - make section title dynamic based on hierarchy level */}
                  <div
                    className={'navSection'}
                    style={digitalMerchandisingScreenClasses.navSection}
                  >
                    {navigationParentObj !== undefined && (
                      <div
                        className={'navSectionTitle'}
                        style={
                          digitalMerchandisingScreenClasses.navSectionTitle
                        }
                      >
                        {navigationParentObj.menu_path === 'Merchandising' ? (
                          <div>Departments</div>
                        ) : (
                          <div>
                            {decodeURIComponent(navigationParentObj.title)}
                          </div>
                        )}
                      </div>
                    )}

                    {navigationParentObj !== undefined &&
                      (childrenArray.length === 0 ? (
                        navigationParentObj.link_type ===
                        DigitalMerchandisingLinkTypeEnum.Flipping ? (
                          <ProtectedEmbedContainer
                            contentData={navigationParentObj}
                            callback={getRecentlyViewedArray}
                          />
                        ) : navigationParentObj.link_type ===
                          DigitalMerchandisingLinkTypeEnum.Pdf ? (
                          <div
                            style={{
                              margin:
                                window.innerWidth > 420
                                  ? '-3.5em 0 0.25em 0'
                                  : '0 0 0.25em 0',
                              padding: '0 0 0.25em 0',
                            }}
                          >
                            {/* todo - pass parsed type or parse in ViewPDF */}
                            <ViewPDF
                              contentId={
                                navigationParentObj.pdf_file_information
                                  ?.attachments_id !== undefined
                                  ? navigationParentObj.pdf_file_information
                                      ?.attachments_id
                                  : ''
                              }
                              contentUrl={signedURL}
                              contentType={parseFilesUploadType(
                                navigationParentObj.pdf_file_information?.type
                                  ? navigationParentObj.pdf_file_information
                                      .type
                                  : 'pdf'
                              )}
                            />
                          </div>
                        ) : (
                          <div>No content to show.</div>
                        )
                      ) : (
                        <BoxArray
                          dataArray={childrenArray}
                          boxContentType={
                            BoxContentTypeEnum.NavigationBoxContent
                          }
                          boxHeight={window.innerWidth > 420 ? '15vh' : '10vh'}
                          boxWidth={window.innerWidth > 420 ? '20%' : '50%'}
                          onClick={handleSetNavigationDisplay}
                          justifyContent={'flex-start'}
                          gap={'.25em 0em'}
                          callBack={getRecentlyViewedArray}
                          fromDirectUrl={fromUrl}
                        />
                      ))}
                  </div>
                </div>
              </>
            )}
            {isCreate && (
              <div>
                <DigitalMerchandisingCreate
                  navigationObjList={data}
                  setIsCreate={setIsCreate}
                  handlerChange={handlerChange}
                  handlerButtonClick={handlerButtonClick}
                  handlerMenuClick={handlerMenuClick}
                  clearNavigationArrays={clearNavigationArray}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DigitalMerchandisingDataWrapper;
