import React from 'react';

import Comment, { CommentsDataType } from './CommentsAccordionContent/Comment';
import { theme } from '../../assets/siteLineTheme';
import { AccordionContentEnum } from './Accordion';
import EditNote from './EditNotesAccordionContent/EditNote';
import { EditNotesObj } from '../Drawers/TaskDrawerComponents/EditNotesSection/EditNotesSection';
import {animated, config, useChain, useSpring, useSpringRef, useTransition} from "@react-spring/web";

export type AccordionContentV2Props = {
  isCollapsed: boolean;
  dataArray: Array<CommentsDataType> | Array<EditNotesObj> | any[];
  onItemClick?: Function | undefined;
  accordionContentEnum: AccordionContentEnum;
};

const AccordionContent: React.FC<AccordionContentV2Props> = (props) => {
  const { isCollapsed, dataArray, onItemClick, accordionContentEnum } = props;
  const tempArray = dataArray !== undefined ? dataArray : [];

  const landingSpringApi = useSpringRef();
  const { ...rest } = useSpring({
    ref: landingSpringApi,
    config: config.stiff,
    from: {
      background: theme.colorPalette.plainWhite,
      width: '100%',
    },
    to: {
      width: '100%',
      height: 'fit-content',
      background: isCollapsed ? 'white' : theme.colorPalette.plainWhite,
    },
  });

  const cardTransitionApi = useSpringRef();
  const transition = useTransition(!isCollapsed ? dataArray : [], {
    ref: cardTransitionApi,
    trail: 250 / tempArray.length,
    from: {
      opacity: 0,
      scale: 0.9,
      width: '100%',
      height: 0,
    },
    enter: {
      opacity: 1,
      scale: 1,
      height: 'fit-content',
      width: '100%',
      x: 0,
    },
    leave: {
      opacity: 0,
      width: '100%',
      height: 0,
    },
  });

  useChain(
    isCollapsed
      ? [landingSpringApi, cardTransitionApi]
      : [cardTransitionApi, landingSpringApi],
    [
      0,
      isCollapsed ? 0.1 : 0.0, // controls delay in between animations
    ]
  );

  return (
    <>
      <animated.div style={{ ...rest, width: '100%' }}>
        {transition((style, element) => (
          <animated.div style={{ ...style }}>
            {accordionContentEnum === AccordionContentEnum.EditNotes && (
              <EditNote edit_note={element} />
            )}
            {accordionContentEnum === AccordionContentEnum.Comments && (
              <Comment comment={element} onItemClick={onItemClick} />
            )}
          </animated.div>
        ))}
      </animated.div>
    </>
  );
};

export default AccordionContent;
