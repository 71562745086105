import React from 'react';
import CommentsModalContent from './CommentsModalContent/CommentsModalContent';
import ConfirmModal from './ConfirmModal';
import EditNotesSubmissionModal from './EditNotesSubmissionModal/EditNotesSubmissionModal';
import UserPreferenceModal from './UserPreferenceModal/UserPreferenceModal';

/* eslint-disable */
export enum DialogModalSwitchEnum {
  Default = '',
  StoreComment = 'StoreComment',
  ConfirmDeleteComment = 'ConfirmDeleteComment',
  EditNotesSubmissionModal = 'EditNotesSubmissionModal',
  ActivityHistory = 'ActivityHistory',
  UserPreference = 'UserPreference',
}

export type DialogModalSwitchProps = {
  dialogModalEnum: DialogModalSwitchEnum | undefined;
};

const DialogModalSwitch: React.FC<DialogModalSwitchProps> = (props) => {
  const { dialogModalEnum } = props;

  switch (dialogModalEnum) {
    case DialogModalSwitchEnum.StoreComment:
      return <CommentsModalContent />;
    case DialogModalSwitchEnum.ConfirmDeleteComment:
      return <ConfirmModal />;
    case DialogModalSwitchEnum.EditNotesSubmissionModal:
      return <EditNotesSubmissionModal />;
    case DialogModalSwitchEnum.UserPreference:
      return <UserPreferenceModal />;
    default:
      return <div aria-label={'Default'}></div>;
  }
};

export default DialogModalSwitch;
