import { IconButton, IconButtonProps } from '../IconButton';

export type MapToIconButtonArrayProps = {
  dataForIconButtonArray: IconButtonProps[];
};

export const MapToIconButtonArray = (params: MapToIconButtonArrayProps) => {
  const IconButtonArray = params.dataForIconButtonArray.map(
    (iconButtonProps: IconButtonProps, index) => {
      return (
        <IconButton
          key={index}
          label={iconButtonProps.label}
          icon={iconButtonProps.icon}
          color={iconButtonProps.color}
          onClick={iconButtonProps.onClick}
        />
      );
    }
  );

  return IconButtonArray;
};
