import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import React from 'react';
import { linkComponentStyles } from './AppHeaderBarStyles/linkComponentStyles';
import { menuListProps } from '../TaskManagementNavigation/TaskManagementNavigation';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

export type InternalLinkComponentProps = {
  to: string;
  colorMode: ColorModeTypeEnum;
  menuItem: menuListProps;
  handleClick: Function;
  clearSearch: Function;
  googleAnalyticsEvent?: () => void;
};

export const InternalLinkComponent: React.FC<InternalLinkComponentProps> = (
  props
) => {
  const {
    to,
    colorMode,
    menuItem,
    handleClick,
    clearSearch,
    googleAnalyticsEvent,
  } = props;
  const {
    removeLinkStyles,
    taskManagementArrowIcon,
    taskManagementListItem,
    menuItemTitle,
  } = linkComponentStyles(colorMode);

  return (
    <Link to={to} style={removeLinkStyles}>
      {/* todo remove onClick? */}
      <li
        style={taskManagementListItem}
        onClick={() => {
          handleClick();
          clearSearch();
          googleAnalyticsEvent?.();
        }}
        value={menuItem.name}
        aria-label={menuItem.name}
      >
        <h3
          style={menuItemTitle}
          aria-label={`Navigation Link ${menuItem.name}`}
        >
          {menuItem.name}
        </h3>
        <ArrowForwardIosIcon style={taskManagementArrowIcon} />
      </li>
    </Link>
  );
};
