import { theme } from '../../assets/siteLineTheme';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

type toggleIconStylesProps = {
  colorMode: ColorModeTypeEnum;
  isActive: boolean;
  activeBarBackgroundColor: string;
  passiveBarBackgroundColor: string;
  activeCircleBackgroundColor: string;
  passiveCircleBackgroundColor: string;
  circleSize?: string | undefined;
  width?: string | undefined;
};

export const toggleIconStyles = (props: toggleIconStylesProps) => {
  const {
    colorMode,
    isActive,
    circleSize,
    activeBarBackgroundColor,
    passiveBarBackgroundColor,
    activeCircleBackgroundColor,
    passiveCircleBackgroundColor,
    width,
  } = props;

  return {
    toggleSwitchContainer: {
      minWidth: '40px',
      display: 'flex',
      width: width !== undefined ? width : '15%',
      height: '100%',
      marginRight: '0.5em',
    },
    toggleSwitchBar: {
      borderRadius: '0.65em',
      width: '90%',
      height: '1.25em',
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      backgroundColor:
        colorMode !== ColorModeTypeEnum.BlackWhite
          ? isActive
            ? activeBarBackgroundColor
            : passiveBarBackgroundColor
          : theme.colorPalette.drawerHeaderText,
      justifyContent: isActive ? 'flex-end' : 'flex-start',
      filter: isActive ? 'brightness(1.2)' : 'brightness(100%)',
    },
    toggleSwitchCircle: {
      backgroundColor:
        colorMode !== ColorModeTypeEnum.BlackWhite
          ? isActive
            ? activeCircleBackgroundColor
            : passiveCircleBackgroundColor
          : theme.colorPalette.plainBlack,
      width: circleSize !== undefined ? circleSize : '.8em',
      height: circleSize !== undefined ? circleSize : '.8em',
      borderRadius: '50%',
      margin: '0 .25em',
      filter: isActive ? 'brightness(.75)' : 'brightness(100%)',
    },
  };
};
