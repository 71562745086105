import { SelectionButtonProps } from '../types/ISelectionButton';
import SelectionButton from './SelectionButton';
import appliedFiltersStore from '../../stores/appliedFiltersStore';
import React from 'react';
import { ViewOptionsProps } from '../Toolbar/CalendarToolbar';
import RoundedButton from './RoundedButton';

/* eslint-disable */
export enum GroupedButtonTypeEnum {
  Rounded = 'Rounded',
  Block = 'Block',
}

export type GroupedSelectionButtonsOptions = {
  relevantFilter?: string;
};

export type GroupedSelectionButtonsProps = {
  buttonObjectArray: SelectionButtonProps[] | ViewOptionsProps[] | any[];
  buttonGroupLabel: string;
  groupedButtonTypeEnum: GroupedButtonTypeEnum;
  groupedSelectionButtonsOptions: GroupedSelectionButtonsOptions | undefined;
};

const GroupedSelectionButtons: React.FC<GroupedSelectionButtonsProps> = (
  props
) => {
  const {
    buttonObjectArray,
    buttonGroupLabel,
    groupedButtonTypeEnum,
    groupedSelectionButtonsOptions,
  } = props;
  const { appliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
    })
  );

  return (
    <div
      aria-label={buttonGroupLabel}
      style={{ display: 'flex', flexDirection: 'row' }}
    >
      {buttonObjectArray.map(
        (button: SelectionButtonProps | ViewOptionsProps | any, i: number) => {
          return (
            <div key={i}>
              {groupedButtonTypeEnum === GroupedButtonTypeEnum.Block &&
                groupedSelectionButtonsOptions?.relevantFilter !==
                  undefined && (
                  <SelectionButton
                    labelText={button.labelText}
                    clickHandler={button.clickHandler}
                    displayText={button.displayText}
                    isSelected={
                      button.appStateSelector !== undefined &&
                      appliedFilters[
                        groupedSelectionButtonsOptions.relevantFilter
                      ] === button.appStateSelector
                    }
                    key={i}
                    start={i === 0}
                    end={i === buttonObjectArray.length - 1}
                  />
                )}
              {groupedButtonTypeEnum === GroupedButtonTypeEnum.Rounded && (
                <RoundedButton
                  key={i}
                  // viewChangeHandler={button.viewChangeHandler}
                  clickHandler={button.clickHandler}
                  displayText={button.key}
                  labelText={button.value}
                />
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

export default GroupedSelectionButtons;
