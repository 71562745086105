export const commentCreateUpdateStyles = () => {
  /* istanbul ignore next */
  return {
    textArea: {
      fontFamily: 'Roboto-Light',
      minHeight: '2em',
      lineHeight: 'initial',
    },
    newCommentButtonsContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '.25em 0',
      justifyContent: 'right',
      marginLeft: '0.2em',
    },
  };
};
