export const taskListTableDataWrapperStyles = () => {
  return {
    taskTableStyles: {
      paddingBottom: '2em',
    },
    taskTableNavButtonsContainer: {
      display: 'flex',
      flexDirection: 'row-reverse' as 'row-reverse',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  };
};
