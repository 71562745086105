import React from 'react';
import TaskHistoryContent from './TaskHistoryContent';
import { activityHistoryModalStyles } from './activityHistoryModalStyles';

/* eslint-disable */
export enum TaskHistoryEnum {
  CREATE_TASK = 'Created',
  RETURN_TASK = 'Recalled',
  APPROVE_TASK = 'Published',
  TASK_MARKED_COMPLETE = 'Marked Complete',
  REOPEN_COMPLETED_TASK = 'Reopened',
  EDIT_TASK = 'Edited',
  ADD_COMMENT = 'Comment',
  SUBMIT_TASK = 'Submitted',
  STORE_SCHEDULED_DATE = 'Scheduled Date Selection',
  STORE_SCHEDULED_DATE_CHANGE = 'Scheduled Date Change',
  STORE_SCHEDULED_DATE_RESET = 'Scheduled Date Reset',
}

export type TaskHistoryObj = {
  id: string;
  emp_id: string;
  given_name: string;
  family_name: string;
  action_type: TaskHistoryEnum;
  action_time: string;
  content: string | undefined;
};

export type TaskHistoryModalProps = {
  task_id: string;
  taskHistoryData: Array<TaskHistoryObj>;
  updateParentState: Function;
};

const TaskHistoryModal: React.FC<TaskHistoryModalProps> = (props) => {
  const { task_id, taskHistoryData, updateParentState } = props;

  const windowDimensions = {
    height: window.innerHeight,
  };

  const activityHistoryModalClasses = activityHistoryModalStyles({
    height: windowDimensions.height,
  });

  return (
    <>
      <div style={activityHistoryModalClasses.activityHistoryContentContainer}>
        {taskHistoryData.map((history: TaskHistoryObj, index) => {
          return (
            <TaskHistoryContent
              key={index}
              task_id={task_id}
              activityHistory={history}
              updateParentState={updateParentState}
            />
          );
        })}
      </div>
    </>
  );
};

export default TaskHistoryModal;
