import { theme } from '../../../assets/siteLineTheme';

type tableCellStylesProps = {
  cellWidth?: string;
  justifyContent?: string;
  completionStatusColor?: string;
  showBorder?: boolean;
  cellHeight?: number;
  isMobile?: boolean;
};

export const tableCellStyles = (props: tableCellStylesProps) => {
  const {
    cellWidth,
    justifyContent,
    completionStatusColor,
    showBorder,
    cellHeight,
    isMobile,
  } = props;
  return {
    tableHeaderCell: {
      width: cellWidth,
      justifyContent,
      display: isMobile ? 'block' : 'flex',
      borderRadius: '0.5em',
      height: '100%',
    },
    rowContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderBottom: showBorder
        ? `0.75px solid ${theme.colorPalette.taskListViewBorder}`
        : undefined,
      borderTop: showBorder
        ? `0.75px solid ${theme.colorPalette.taskListViewBorder}`
        : undefined,
      height: '100%',
      cursor: 'pointer',
    },
    headerRowContainer: {
      width: 'calc(100% - 0.7em)',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 700,
      backgroundColor: theme.colorPalette.plainWhite,
      fontSize: '1.15em',
      paddingTop: '0.35em',
      paddingBottom: '0.35em',
      position: 'relative' as 'relative',
      paddingLeft: '0.4em',
      borderRadius: '0.5em',
    },
    tableContainer: {
      width: '100%',
      height: '100%',
      maxHeight: '75vh',
      overflowY: 'scroll' as 'scroll',
      backgroundColor: theme.colorPalette.plainWhite,
      borderRadius: '0.5em',
    },
    taskCompletionStatusColorBar: {
      backgroundColor: completionStatusColor,
      width: '3px',
      height: cellHeight,
      marginRight: '5px',
      marginLeft: '2px',
    },
  };
};
