import { theme } from '../../../assets/siteLineTheme';

export const boxContentSwitchStyles = () => {
  return {
    breadcrumbForwardIcon: {
      color: theme.colorPalette.textDark,
      size: '1.5em',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
};
