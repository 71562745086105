import React, { useContext, useEffect, useState } from 'react';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format, getDay, parse, startOfWeek, subDays } from 'date-fns';
import * as enUS from 'date-fns/locale/en-US';
import './styles/calendar.css';
import {
  CalendarEvent,
  slotInfoProps,
  ViewType,
} from '../types/Calendar/ICalendarIndex';
import { CalendarToolbar } from '../Toolbar/CalendarToolbar';
import { theme } from '../../assets/siteLineTheme';
import { AppContext } from 'src/context/AppContext';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../utils/analytics';
import CalendarEventComponent from './CalendarEventComponent';
import userPreferenceStore, {
  ColorModeTypeEnum,
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import { DrawerContentType } from '../../stores/models/DrawerProps';
import drawerPropsStore from '../../stores/drawerPropsStore';
import { modifyStartDateAndEndDateToUTCForDisplayInCalendar } from '../../utils/dateUtils';
import { env } from 'src/envConfig';
import {taskbarHeight, useMobileBreakpoint} from './styles/calender.styles';

export type CalendarProps = {
  events: CalendarEvent[];
  findFullEventToOpen: Function;
};

export const Calendar: React.FC<CalendarProps> = (props) => {
  const { events, findFullEventToOpen } = props;
  const { appState } = useContext<any>(AppContext);
  const { userPermissions } = appState;
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const { drawerProps, setDrawerProps } = drawerPropsStore(
    (state: { drawerProps: any; setDrawerProps: any }) => ({
      drawerProps: state.drawerProps,
      setDrawerProps: state.setDrawerProps,
    })
  );
  const defaultView: string = env.REACT_APP_DEFAULT_VIEW || 'week';
  const [view, setView] = useState<ViewType>(defaultView as ViewType);
  const [yearView, setYearView] = useState<boolean>(false);
  const [rowOrderForceRerender, setRowOrderForceRerender] = useState(false);

  const root = document.documentElement;
  root.style.setProperty('--taskbar-height', taskbarHeight+'');
  root.style.setProperty('--white-space', useMobileBreakpoint() ? 'nowrap' : 'normal');
  root.style.setProperty('--calendar-today', theme.newColorPalette.CalendarTodayBackgroundWhite[userPreference.colorMode]);

  root.style.setProperty('--dark-grey', theme.newColorPalette.zoidbergDarkGrey[userPreference.colorMode]);
  root.style.setProperty('--black-white', theme.newColorPalette.plainBlackWhite[userPreference.colorMode]);
  root.style.setProperty('--zoidberd-black', theme.newColorPalette.zoidbergBlack[userPreference.colorMode]);
  root.style.setProperty('--black-white-colormode', userPreference.colorMode !== ColorModeTypeEnum.BlackWhite ? '' : theme.colorPalette.plainBlack);

  const onSelectEvent = async (selectedEvent: any) => {
    findFullEventToOpen(selectedEvent, true);
  };

  const onSelectSlot = async ({ start, end }: slotInfoProps) => {
    const yesterday = subDays(new Date(), 1);
    if (!userPermissions?.createNewTask) {
      return;
    }
    if (start <= yesterday) {
      window.alert("You cannot create a task that starts before today's date");
    } else {
      sendAnalyticsEvent(analyticsEventConstants.CREATE_TASK);

      setDrawerProps({
        drawerContentType: DrawerContentType.TaskForm,
        isDrawerOpen: true,
        drawerContentProps: {
          ...drawerProps.drawerContentProps,
          formMode: 'create',
          viewEvent: null,
          selectedSlot: {
            start_date: start,
            end_date: subDays(new Date(end), 1),
          },
        },
      });
    }
  };

  const getViewAction = (view: string) => {
    switch (view) {
      case 'day':
        return analyticsEventConstants.DAY_VIEW;
      case 'week':
        return analyticsEventConstants.WEEK_VIEW;
      default:
        return analyticsEventConstants.MONTH_VIEW;
    }
  };

  const viewChangeHandler = (obj: any): void => {
    setView(obj.value);
    sendAnalyticsEvent(getViewAction(obj.value));
    obj.value === 'year' ? setYearView(true) : setYearView(false);
  };

  const ToolbarComponent = (props: any) => {
    // const { label, onNavigate, view } = props;
    const { label, onNavigate, view, date } = props;

    const toolBarProps = {
      label,
      onNavigate,
      viewChangeHandler,
      view,
      yearView,
      setRowOrder,
      setRowOrderForceRerender,
      rowOrderForceRerender,
      date,
    };
    return <CalendarToolbar {...toolBarProps} />;
  };

  const CalendarEventComponentShell = (props: any) => {
    const calendarEventProps = {
      title: props.title,
      start: props.start,
      end: props.end,
      allDay: props.allDay,
      resource: props.resource,
      ...props,
    };
    return <CalendarEventComponent {...calendarEventProps} />;
  };

  const eventStyleGetter = (event: any) => {
    // this is an RGBA(x,x,x,x) value
    const currTaskTypeColor =
      theme.colorPalette.taskTypes.dark[event.task_type];
    const colorWithOpac = `${currTaskTypeColor.replace(', 1)', ', 0.1)')}`;
    let style = {};

    const backgroundGradient = `repeating-linear-gradient(
          -45deg,
          ${theme.colorPalette.taskTypes.light[event.task_type]},
          ${theme.colorPalette.taskTypes.light[event.task_type]} 20px,
          ${colorWithOpac} 20px,
          ${colorWithOpac} 25px
      )`;
    const backgroundGradientMonochrome = `repeating-linear-gradient(
          -45deg,
          ${theme.colorPalette.gradientBlack},
          ${theme.colorPalette.gradientBlack} 20px,
          ${theme.colorPalette.plainWhite} 20px,
          ${theme.colorPalette.plainWhite} 25px
      )`;

    switch (event.task_status) {
      case 'Submitted': {
        style = {
          background:
            userPreference.colorMode !== ColorModeTypeEnum.BlackWhite
              ? backgroundGradient
              : backgroundGradientMonochrome,
          color: theme.newColorPalette.textDarkBlack[userPreference.colorMode],
          border:
            userPreference.colorMode !== ColorModeTypeEnum.BlackWhite
              ? undefined
              : `1px solid ${theme.colorPalette.plainBlack}`,
        };
        break;
      }
      case 'Returned': {
        style = {
          border:
            userPreference.colorMode !== ColorModeTypeEnum.BlackWhite
              ? `solid 2px ${
                  theme.colorPalette.taskTypes.light[event.task_type]
                }`
              : `3px dashed ${theme.colorPalette.plainBlack}`,
          backgroundColor: 'white',
          color: theme.newColorPalette.textDarkBlack[userPreference.colorMode],
        };
        break;
      }

      default: {
        style = {
          backgroundColor:
            userPreference.colorMode !== ColorModeTypeEnum.BlackWhite
              ? theme.colorPalette.taskTypes.light[event.task_type]
              : theme.colorPalette.plainWhite,
          color: theme.newColorPalette.textDarkBlack[userPreference.colorMode],
          border:
            userPreference.colorMode !== ColorModeTypeEnum.BlackWhite
              ? undefined
              : `1px solid ${theme.colorPalette.plainBlack}`,
        };
        break;
      }
    }

    return {
      style: { ...style },
    };
  };

  useEffect(() => {
    sendAnalyticsEvent(getViewAction(defaultView));
  }, [appState]);

  interface HTMLCollectionBase {
    readonly length: number;
    item(index: number): Element | null;
    [index: number]: Element;
  }

  interface HTMLCollectionOf<T extends Element> extends HTMLCollectionBase {
    item(index: number): T | null;
    namedItem(name: string): T | null;
    [index: number]: T;
  }

  const setRowOrder = () => {
    const nodes = Array.from(
      document.getElementsByClassName(
        'rbc-row'
      ) as unknown as HTMLCollectionOf<HTMLElement>
    );
    nodes.forEach((node) => {
      const nodeRowSegment = node.querySelector(`[class="rbc-row-segment"]`);
      if (nodeRowSegment) {
        const firstTitle = node
          .querySelector(`[class="rbc-event-content"]`)
          ?.getAttribute('title');
        const firstEvent = events.find(
          (event: CalendarEvent) => event.title === firstTitle
        );
        if (firstEvent) {
          node.style.order = `${firstEvent.orderIndex}`;
        }
      }
    });
  };

  const onShowMore = (e: any) => {
    setTimeout(() => {
      const overlay = document.querySelector(
        `[class="rbc-overlay"]`
      ) as HTMLElement;
      if (overlay) {
        // overlay.setAttribute("style", "display:flex;flex-direction:column")
        overlay.style.display = 'flex';
        overlay.style.flexDirection = 'column';
        const nodes = Array.from(
          overlay?.getElementsByClassName(
            'rbc-event'
          ) as unknown as HTMLCollectionOf<HTMLElement>
        );
        nodes.forEach((node) => {
          const title = node
            .querySelector(`[class="rbc-event-content"]`)
            ?.getAttribute('title');
          const event = events.find(
            (event: CalendarEvent) => event.title === title
          );
          node.style.order = `${event && event.orderIndex}`;
          node.style.marginTop = '2px';
          node.style.padding = '0px';
          node.style.borderRadius = '24px';
          const taskBarContainer = Array.from(
            node?.getElementsByClassName(
              'task-bar-container'
            ) as unknown as HTMLCollectionOf<HTMLElement>
          );
          taskBarContainer[0].style.display = 'flex';
          const typeToken = Array.from(
            node?.getElementsByClassName(
              'type-token'
            ) as unknown as HTMLCollectionOf<HTMLElement>
          );
          typeToken[0].style.padding = '0px 5px';
          typeToken[0].style.borderRadius = '24px 0px 0px 24px';
          const taskTitle = Array.from(
            node?.getElementsByClassName(
              'task-bar-title'
            ) as unknown as HTMLCollectionOf<HTMLElement>
          );
          taskTitle[0].style.paddingLeft = '5px';
        });
      }
    }, 100);
  };

  const updatedCalendarEvents = events.map((event) => {
    const updatedStoreScheduledStartDate =
      event.store_scheduled_start_date === undefined
        ? event.start_date
        : event.store_scheduled_start_date;
    const updatedStoreScheduledEndDate =
      event.store_scheduled_end_date === undefined
        ? event.end_date
        : event.store_scheduled_end_date;

    return {
      ...event,
      display_start_date: updatedStoreScheduledStartDate,
      display_end_date: updatedStoreScheduledEndDate,
    };
  });

  useEffect(() => {
    if (view !== 'month') {
      setRowOrder();
    }
  });

  return (
    <div
      role="calendar-wrapper"
    >
      <BigCalendar
        elementProps={{ role: `calendar-component-${view}` }}
        selectable={true}
        localizer={dateFnsLocalizer({
          format,
          parse,
          startOfWeek,
          getDay,
          locales: {
            'en-US': enUS,
          },
        })}
        formats={{
          dayFormat: 'dd eee',
          dayHeaderFormat: 'cccc MMMM dd',
          weekdayFormat: 'ccc', // Configurable: Month View Weekday Label to show three 'ccc' characters || for full label pass 'cccc'
        }}
        events={modifyStartDateAndEndDateToUTCForDisplayInCalendar(
          updatedCalendarEvents
        )}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        startAccessor="display_start_date"
        endAccessor="display_end_date"
        view={view}
        onView={() => view}
        style={{
          minHeight: '800px',
          height: 'auto',
          width: '100%',
        }}
        components={{
          toolbar: ToolbarComponent,
          event: CalendarEventComponentShell,
        }}
        popup
        eventPropGetter={eventStyleGetter}
        onShowMore={onShowMore}
      />
    </div>
  );
};
