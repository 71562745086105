import { CalendarEvent } from '../../components/types/Calendar/ICalendarIndex';

/* istanbul ignore next */
export enum DrawerContentType {
  // eslint-disable-next-line no-unused-vars
  Filter = 'Filter',
  // eslint-disable-next-line no-unused-vars
  TaskForm = 'TaskForm',
  // eslint-disable-next-line no-unused-vars
  NewFeatures = 'NewFeatures',
}
/* istanbul ignore next */
export type FilterDrawerProps = {
  oktaGroup?: any;
};
/* istanbul ignore next */
export type TaskDrawerProps = {
  openEdit: Function;
  formMode: string;
  viewEvent: CalendarEvent | null | undefined;
  selectedSlot?: any;
  duplicateTaskHandler?: any;
};
/* istanbul ignore next */
export type NewFeatureProps = {
  newFeaturesList: Array<any>;
  viewedFeaturesList: Array<any>;
};
/* istanbul ignore next */
export type DrawerProps = {
  isDrawerOpen: boolean;
  drawerContentType: DrawerContentType | undefined;
  drawerContentProps:
    | FilterDrawerProps
    | TaskDrawerProps
    | NewFeatureProps
    | undefined;
};
