import { Button, Modal } from '@mui/material';
import { theme as siteLineTheme } from '../../../assets/siteLineTheme';
import React from 'react';
import ViewPDF from '../../ViewAttachments/ViewPDF';
import muiTheme from "../../../utils/mui/muiTheme";

export type ModalDisplayProps = {
  isOpen: boolean;
  contentId: string;
  contentUrl: string;
  contentType?: string;
  handleClose: Function;
};

/* istanbul ignore next */
const modalStyles = () => {
  return {
    backButton: {
      background: siteLineTheme.colorPalette.feedback,
      marginTop: '.5em',
      [muiTheme.breakpoints.down('xs')]: {
        padding: muiTheme.spacing(1, 1, 1),
        margin: '1em auto',
      },
    }
  }
}

const ContentPopOver: React.FC<ModalDisplayProps> = (props) => {
  const { isOpen, contentId, contentUrl, handleClose, contentType } = props;
  const modalClass = modalStyles();

  return (
    <>
      <Modal open={isOpen}>
        <div>
          <ViewPDF
            contentId={contentId}
            contentUrl={contentUrl}
            contentType={contentType}
          >
            <Button
              onClick={() => handleClose()}
              sx={modalClass.backButton}
            >
              BACK TO SITELINE
            </Button>
          </ViewPDF>
        </div>
      </Modal>
    </>
  );
};

export default ContentPopOver;
