import { useSpring } from '@react-spring/web';

export const textColorSpring = (
  isPassive: boolean,
  activeTextColor: string,
  passiveTextColor: string
): object => {
  return useSpring({
    to: {
      color: isPassive ? passiveTextColor : activeTextColor,
    },
    config: {
      duration: 500,
    },
    reverse: isPassive,
  });
};

export const loopedBackgroundColorSpring = (
  activeBackgroundColor: string,
  passiveBackgroundColor: string,
  loop: boolean | undefined
): object => {
  return useSpring({
    to: [
      { backgroundColor: activeBackgroundColor },
      { backgroundColor: passiveBackgroundColor },
    ],
    config: {
      duration: 750,
    },
    loop,
  });
};

export const backgroundColorSpring = (
  isPassive: boolean,
  activeBackgroundColor: string,
  passiveBackgroundColor: string
): object => {
  return useSpring({
    from: {
      backgroundColor: isPassive
        ? passiveBackgroundColor
        : activeBackgroundColor,
    },
    to: {
      backgroundColor: isPassive
        ? passiveBackgroundColor
        : activeBackgroundColor,
    },
    config: {
      duration: 500,
    },
    reverse: isPassive,
  });
};
