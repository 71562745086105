import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { theme } from 'src/assets/siteLineTheme';
import UpDownIcon from '../ReviewAndApprovalTable/UpDownIcon';
import userPreferenceStore, {
  ColorModeTypeEnum,
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import React from 'react';

// arrow should display red
// stays black in black/white mode
const useStyles = (colorMode: ColorModeTypeEnum) => {
  return {
    arrowIcon: {
      fontSize: 'xx-large',
      color: theme.newColorPalette.zoidbergBlack[colorMode],
    },
  }
}

export type TableColumnSortDirectionProps = {
  taskTitle: string;
  taskType: string;
  endDate: string;
  authorName: string;
};

export type TableSortIconButtonProps = {
  applicableColumn: string;
  tableColumnSortDirection: TableColumnSortDirectionProps;
};

const TableSortIconButton: React.FC<TableSortIconButtonProps> = (props) => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const { applicableColumn, tableColumnSortDirection } = props;
  const isTableSortedByThisColumn =
    tableColumnSortDirection[applicableColumn] !== '';
  const classes = useStyles(userPreference.colorMode);

  return (
    <>
      {isTableSortedByThisColumn ? (
        tableColumnSortDirection[applicableColumn] === 'asc' ? (
          <ArrowDropUp
            aria-label={`sort by ${applicableColumn} asc`}
            sx={classes.arrowIcon}
          />
        ) : (
          <ArrowDropDown
            aria-label={`sort by ${applicableColumn} desc`}
            sx={classes.arrowIcon}
          />
        )
      ) : (
        <UpDownIcon
          label={`sort by ${applicableColumn}`}
          size={25}
          color={theme.colorPalette.plainBlack}
        />
      )}
    </>
  );
};

export default TableSortIconButton;
