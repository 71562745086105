import { Box, Grid, Button } from '@mui/material';
import { TaskStatus } from '../../models/TaskStatus';
import { TaskStoreCompletionStatus } from '../../models/TaskStoreCompletionStatus';
import { groups as userGroups } from '../../context/constants';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../utils/analytics';
import { updateTaskStatus } from '../../api/apiV1Task';
import React, { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import useFetchUserGroup from '../../hooks/fetchUserGroup';
import appliedFiltersStore from '../../stores/appliedFiltersStore';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import { CalendarEvent } from '../types/Calendar/ICalendarIndex';
import { useFormikContext } from 'formik';
import { DialogModalSwitchEnum } from '../Modal/DialogModalSwitch';
import confirmModalStore from '../../stores/confirmModalStore';
import baseModalStore from '../../stores/baseModalStore';
import {GridStyles} from "./styles/gridStyles";
import {ButtonStyles} from "./styles/buttonStyles";

export type FormButtonsProps = {
  show: boolean;
  label: string;
};
export type TaskFormButtonsProps = {
  formButtons: {
    print: FormButtonsProps;
    submit: FormButtonsProps;
    edit: FormButtonsProps;
    cancelEdit: FormButtonsProps;
    deleteTask: FormButtonsProps;
    approve: FormButtonsProps;
    returnTask: FormButtonsProps;
    completionStatus: {
      show: boolean;
      complete: {
        label: string;
      };
      reopen: {
        label: string;
      };
    };
    duplicateTask: FormButtonsProps;
  };
  buttonActions: {
    handleReopenTask: Function;
    handleMarkTaskComplete: Function;
    handleDelete: Function;
    handlePrintClick: React.MouseEventHandler<HTMLButtonElement>;
    duplicateTaskHandler: Function;
    openEdit: React.MouseEventHandler<HTMLButtonElement>;
    resetToDefault: any;
  };
  buttonData: {
    viewEventAuthorEmployeeID: string;
    viewEvent: CalendarEvent;
    fileUploading: boolean;
  };
  taskInfo?: {
    taskId: string;
    formMode: String;
  };
};

const TaskFormButtons: React.FC<TaskFormButtonsProps> = (props) => {
  const { formButtons, buttonActions, buttonData, taskInfo } = props;
  const { appState } = useContext(AppContext);
  const { userInfo, userPermissions } = appState;
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const { appliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
    })
  );
  const userOktaGroup = useFetchUserGroup(userInfo);

  const {
    handleReopenTask,
    handleMarkTaskComplete,
    handleDelete,
    handlePrintClick,
    duplicateTaskHandler,
    openEdit,
    resetToDefault,
  } = buttonActions;
  const { viewEventAuthorEmployeeID, viewEvent, fileUploading } = buttonData;

  const {
    print,
    submit,
    edit,
    cancelEdit,
    deleteTask,
    approve,
    returnTask,
    completionStatus,
    duplicateTask,
  } = formButtons;

  const { setConfirmModal } = confirmModalStore(
    (state: { setConfirmModal: Function }) => ({
      setConfirmModal: state.setConfirmModal,
    })
  );

  const { setBaseModalContent } = baseModalStore(
    (state: { setBaseModalContent: Function }) => ({
      setBaseModalContent: state.setBaseModalContent,
    })
  );

  const windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const { submitForm } = useFormikContext();

  return (
    <Grid component={Box} item sx={GridStyles(userPreference.colorMode).basicGrid}>
      {print?.show && (
        <Button
          fullWidth
          sx={{...ButtonStyles(userPreference.colorMode).root, ...ButtonStyles(userPreference.colorMode).printButton}}
          data-testid="print-button"
          aria-label="Print Task"
          onClick={handlePrintClick}
        >
          {print?.label}{' '}
        </Button>
      )}
      {submit?.show && (
        <Button
          data-testid="submit-button"
          variant="outlined"
          // type="submit"
          // className={classes.approveButton}
          sx={{...ButtonStyles(userPreference.colorMode).root, ...ButtonStyles(userPreference.colorMode).approveButton}}
          disabled={fileUploading}
          onClick={() => {
            if (taskInfo && taskInfo.formMode === 'edit') {
              setBaseModalContent({
                isActive: true,
                width: windowDimensions.width > 420 ? 0.5 : 0.8,
                height: windowDimensions.width > 420 ? 0.15 : 0.18,
                dialogModalSwitchEnum:
                  DialogModalSwitchEnum.EditNotesSubmissionModal,
              });

              setConfirmModal({
                message:
                  "Please add Editor's Notes detailing the changes made to this task.",
                confirmButtonText: 'Submit',
                task_id: taskInfo && taskInfo.taskId,
                confirmOnClickAction: async () => submitForm(),
              });
            } else {
              submitForm();
            }
          }}
          fullWidth
        >
          {submit?.label}{' '}
        </Button>
      )}
      {viewEvent && (
        <>
          {edit?.show &&
            ((viewEvent.task_status === TaskStatus.Returned &&
              userPermissions?.editReturnedTask) ||
              (viewEvent.task_status === TaskStatus.Submitted &&
                userPermissions?.editSubmittedTask) ||
              (viewEvent.task_status === TaskStatus.Approved &&
                userPermissions?.editPublishedTask)) && (
              <Button
                variant="outlined"
                sx={{...ButtonStyles(userPreference.colorMode).root, ...ButtonStyles(userPreference.colorMode).editButton}}
                onClick={openEdit}
                aria-label="Edit"
              >
                {edit?.label}
              </Button>
            )}

          {completionStatus?.show &&
            appliedFilters.store_number &&
            userPermissions?.markTaskComplete &&
            viewEvent?.task_status === TaskStatus.Approved &&
            (viewEvent.completion_status ===
            TaskStoreCompletionStatus.Incomplete ? (
              <Button
                  sx={{...ButtonStyles(userPreference.colorMode).root, ...ButtonStyles(userPreference.colorMode).markCompleteButton}}
                aria-label="Mark Task Completed"
                onClick={() =>
                  handleMarkTaskComplete(
                    viewEvent.id,
                    appliedFilters.store_number
                  )
                }
              >
                {completionStatus.complete.label}
              </Button>
            ) : (
              userPermissions.markTaskComplete &&
              viewEvent.require_completion && (
                <Button
                    sx={{...ButtonStyles(userPreference.colorMode).root, ...ButtonStyles(userPreference.colorMode).editButton}}
                  aria-label="Reopen Completed Task"
                  onClick={() => handleReopenTask()}
                >
                  {completionStatus.reopen.label}
                </Button>
              )
            ))}

          {cancelEdit?.show && (
            <Button
              variant="outlined"
              sx={{...ButtonStyles(userPreference.colorMode).root, ...ButtonStyles(userPreference.colorMode).editButton}}
              onClick={resetToDefault}
            >
              {cancelEdit?.label}
            </Button>
          )}
          {deleteTask?.show &&
            ((viewEvent.task_status === TaskStatus.Submitted &&
              userPermissions?.editSubmittedTask) ||
              userPermissions?.deletePublishedTask ||
              (userOktaGroup === userGroups.GABE &&
                userInfo.empID === viewEventAuthorEmployeeID)) && (
              <Button
                  sx={{...ButtonStyles(userPreference.colorMode).root, ...ButtonStyles(userPreference.colorMode).returnButton}}
                variant="outlined"
                onClick={() => handleDelete(viewEvent.id)}
              >
                {deleteTask?.label}
              </Button>
            )}

          {approve?.show && (
            <Button
                sx={{...ButtonStyles(userPreference.colorMode).root, ...ButtonStyles(userPreference.colorMode).approveButton}}
              onClick={async () => {
                sendAnalyticsEvent(analyticsEventConstants.APPROVE_TASK);
                await updateTaskStatus(
                  TaskStatus.Approved,
                  // userInfo.empID,
                  viewEvent.id
                );
                resetToDefault();
              }}
            >
              {approve?.label}
            </Button>
          )}
          {returnTask?.show && (
            <Button
                sx={{...ButtonStyles(userPreference.colorMode).root, ...ButtonStyles(userPreference.colorMode).returnButton}}
              variant="outlined"
              onClick={async () => {
                sendAnalyticsEvent(analyticsEventConstants.RETURN_TASK);
                await updateTaskStatus(
                  TaskStatus.Returned,
                  viewEvent.id
                );
                resetToDefault();
              }}
            >
              {returnTask?.label}
            </Button>
          )}
          {duplicateTask?.show && userPermissions?.createNewTask && (
            <Button
                sx={{...ButtonStyles(userPreference.colorMode).root, ...ButtonStyles(userPreference.colorMode).duplicateButton}}
                aria-label="Duplicate Task Button"
              variant={'outlined'}
              onClick={async () => {
                sendAnalyticsEvent(analyticsEventConstants.DUPLICATE_TASK);
                await duplicateTaskHandler();
              }}
            >
              {duplicateTask?.label}
            </Button>
          )}
        </>
      )}
    </Grid>
  );
};

export default TaskFormButtons;
