import { easyPrintCalendarLabels } from './utils/easyPrintCalendarConstants';
import { Box, Typography } from '@mui/material';
import { easyPrintCalendarStyles } from './utils/easyPrintCalendarStyles';
import React from 'react';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

const EasyPrintCalendarDayLabels: React.FC = () => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const easyPrintCalendarClasses = easyPrintCalendarStyles(
    userPreference.colorMode
  );

  return (
    <>
      {easyPrintCalendarLabels.map(
        (data: { dateIndex: number; dateLabelString: string }) => {
          return (
            <Box
              key={data.dateIndex}
              sx={easyPrintCalendarClasses.dateLabelContainer}
            >
              <Typography sx={easyPrintCalendarClasses.dateLabelText}>
                {data.dateLabelString}
              </Typography>
            </Box>
          );
        }
      )}
    </>
  );
};

export default EasyPrintCalendarDayLabels;
