import React, { ReactNode } from 'react';
import { Field, ErrorMessage, FieldInputProps } from 'formik';
import {FormHelperText, MenuItem, TextField} from '@mui/material';
import { FormikSelectElementStylesWrapper } from './FormikStyles';
import { createStyles, makeStyles } from '@mui/styles';

export type FormikSelectItem = {
  label: string;
  value: string;
  abbreviation: string;
};
export type FormikSelectProps = {
  name: string;
  items: FormikSelectItem[];
  label: string;
  required?: boolean;
  disabled?: boolean;
};

interface MaterialUISelectFieldProps extends FieldInputProps<string> {
  errorString?: string;
  children: ReactNode;
  label: string;
  required?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiFormLabel-root.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.23)',
      },
      '& .MuiInputBase-root.MuiInput-underline:after': {
        borderBottomColor: 'rgba(0, 0, 0, 0.23)',
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'white',
      },
    },
  })
);

const MaterialUISelectField: React.FC<MaterialUISelectFieldProps> = (props) => {
  const {
    errorString,
    label,
    children,
    value,
    name,
    onChange,
    onBlur,
    // required,
    disabled,
  } = props;
  const id =
    'mui-component-select-' + label.toLocaleLowerCase().replace(' ', '_');
  const classes = useStyles();
  return (
    <>
      <TextField
        id={id}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        role={'select'}
        classes={{ root: classes.root }}
        fullWidth
        select
        variant={'outlined'}
        label={label}
      >
        {children}
      </TextField>
      <FormHelperText>{errorString}</FormHelperText>
    </>
  );
};

const FormikSelect: React.FC<FormikSelectProps> = ({
  name,
  items,
  label,
  required = false,
  disabled = false,
}) => {

  return (
    <FormikSelectElementStylesWrapper className="FormikSelect">
      <Field
        name={name}
        as={MaterialUISelectField}
        label={label}
        errorString={<ErrorMessage name={name} />}
        required={required}
        disabled={disabled}
        fullWidth
        select
        hover
      >
        {items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
        ))}
      </Field>
    </FormikSelectElementStylesWrapper>
  );
};

export default FormikSelect;
