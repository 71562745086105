import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React from 'react';

export type CircularProgressWithLabelProps = {
  CircularProgressProps?: any;
  value: number;
};

export const CircularProgressWithLabel: React.FC<
  CircularProgressWithLabelProps
> = (props) => {
  return (
    <Box position="relative" display="inline-flex" marginRight="3px">
      <CircularProgress variant="determinate" size={35} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          variantMapping={{ h5: 'h5' }}
          component="div"
          color="textPrimary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};
