import React from 'react';
import { generalSectionStyles } from '../generalSectionStyles';
import { Grid, Paper, Typography } from '@mui/material';
import Toggle from '../../../Toggle/Toggle';

export type SuperUserToggleFieldsSectionProps = {
  viewEvent: any;
};

const SuperUserToggleFieldsSection: React.FC<
  SuperUserToggleFieldsSectionProps
> = (props) => {
  const { viewEvent } = props;
  const {
    require_completion,
    schedule_date,
    funded,
    year_at_a_glance,
    leadership_calendar,
  } = viewEvent;
  const toggleArr = [
    { label: 'Requiring Completion?', boolean: require_completion },
    { label: 'Allowing Scheduled Dates?', boolean: schedule_date },
    { label: 'Is the Task Funded?', boolean: funded },
    { label: 'Leadership calendar?', boolean: leadership_calendar },
    { label: 'Year at a Glance Calendar?', boolean: year_at_a_glance },
  ];

  const generalSectionClasses = generalSectionStyles();

  return (
    <Grid container>
      <Paper elevation={4} sx={generalSectionClasses.paper}>
        <div style={generalSectionClasses.headerContainer}>
          <Typography sx={generalSectionClasses.sectionTitle}>
            Task Optional Settings
          </Typography>
        </div>
        {toggleArr.map((toggle, index) => {
          return (
            <Toggle
              label={toggle.label}
              isActive={toggle.boolean}
              key={index}
            />
          );
        })}
      </Paper>
    </Grid>
  );
};

export default SuperUserToggleFieldsSection;
