import './ViewPDF.css';
import { Box, IconButton, Paper, TextField } from '@mui/material';
import {Document, Page, pdfjs} from 'react-pdf';
import React, { useState } from 'react';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import muiTheme from "../../utils/mui/muiTheme";

export type ModalDisplayProps = {
  contentId: string;
  contentUrl: string;
  contentType?: string;
  children?: React.ReactNode;
};
// TODO: ?possibly change viewWithPerc if on mobile
const viewWidthPerc = 85;
/* istanbul ignore next */
const modalStyles = () => {
  return {
    paper: {
      height: '85vh',
      width: `${viewWidthPerc}%`,
      margin: '7.5vh auto',
      backgroundColor: muiTheme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: muiTheme.shadows[5],
      padding: muiTheme.spacing(2, 4, 3),
      [muiTheme.breakpoints.between('xs', 'sm')]: {
        height: '100vh',
        margin: 0,
        border: 'none',
        boxShadow: 'none',
        padding: 0,
        width: '100%',
      },
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'column',
    },
  }
}

const ViewPDF: React.FC<ModalDisplayProps> = (props) => {
  const { contentId, contentUrl, contentType, children } = props;
  const modalClass = modalStyles();
  const iFrameContentTypes = ['txt', 'mov', 'mp4', 'pdf'];

  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [magnification, setMagnification] = useState(1);

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  function onDocumentLoadSuccess(props: any) {
    setNumPages(props.numPages);
  }

  const calcWidth = () => {
    return (window.innerWidth * viewWidthPerc) / 100 + 20;
  };

  const pageChange = (direction: string) => {
    if (currentPage === 1 && direction === 'LEFT') {
      setCurrentPage(numPages);
    } else if (currentPage === numPages && direction === 'RIGHT') {
      setCurrentPage(1);
    } else if (direction === 'LEFT') {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleOnChange = (event: any) => {
    event.target.value > numPages
      ? setCurrentPage(numPages)
      : setCurrentPage(parseInt(event.target.value));
  };

  const magnificationChange = (zoomDirection: string) => {
    zoomDirection === 'ZoomIn' && setMagnification(magnification + 0.5);
    zoomDirection === 'ZoomOut' && setMagnification(magnification - 0.5);
  };

  return (
    <Paper sx={modalClass.paper}>
      {contentId === 'feedbackModalIframe' ||
      (contentType && iFrameContentTypes.includes(contentType)) ? (
        contentType === 'pdf' ? (
          window.innerWidth < 420 ? (
            <div>
              <Document
                file={contentUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                className={"pdfDocumentContainer"}
              >
                <Page
                  pageNumber={currentPage}
                  width={calcWidth()}
                  scale={magnification}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </Document>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                }}
              >
                <Box style={{ display: 'flex' }}>
                  <TextField
                    label="Page"
                    type="number"
                    value={currentPage}
                    onChange={(event) => handleOnChange(event)}
                    InputProps={{ inputProps: { min: 1, max: numPages } }}
                  />
                  <p style={{ marginTop: '20px' }}> of {numPages}</p>
                </Box>
                <Box>
                  <IconButton
                    aria-label={'Up Button'}
                    sx={{ border: '2px solid black', margin: '0px 5px' }}
                    size="small"
                  >
                    <KeyboardArrowLeft
                      onClick={() => pageChange('LEFT')}
                      // className={classes.navArrow}
                    />
                  </IconButton>
                  <IconButton
                    aria-label={'Down Button'}
                    sx={{ border: '2px solid black', margin: '0px 5px' }}
                    size="small"
                  >
                    <KeyboardArrowRight
                      onClick={() => pageChange('RIGHT')}
                      // className={classes.navArrow}
                    />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    aria-label={'Zoom Out'}
                    sx={{ border: '2px solid black', margin: '0px 5px' }}
                    size="small"
                  >
                    <ZoomOut
                      onClick={() => magnificationChange('ZoomOut')}
                      // className={classes.navArrow}
                    />
                  </IconButton>
                  <IconButton
                    aria-label={'Zoom In'}
                    sx={{ border: '2px solid black', margin: '0px 5px' }}
                    size="small"
                  >
                    <ZoomIn
                      onClick={() => magnificationChange('ZoomIn')}
                      // className={classes.navArrow}
                    />
                  </IconButton>
                  <div aria-label={'Magnification'}>
                    {`x ${Math.round(magnification * 100) / 100}`}
                  </div>
                </Box>
              </Box>
            </div>
          ) : (
            <iframe
              id={contentId}
              src={contentUrl}
              style={{
                width: '100%',
                height: '95%',
              }}
            />
          )
        ) : (
          <iframe
            aria-label={contentUrl}
            id={contentId}
            src={contentUrl}
            style={{
              width: '100%',
              height: '95%',
            }}
          />
        )
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '95%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            aria-label={contentUrl}
            id={contentId}
            src={contentUrl}
            style={{
              maxWidth: '100%',
              maxHeight: '80vh',
            }}
          />
        </Box>
      )}
      {children}
    </Paper>
  );
};

export default ViewPDF;
