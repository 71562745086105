import { theme } from 'src/assets/siteLineTheme';

export type commentsModalContentParams = {
  height: number;
};

export const commentsModalContentStyles = (
  params: commentsModalContentParams
) => {
  const { height } = params;
  /* istanbul ignore next */
  return {
    commentModalContentContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      flexDirection: 'column' as 'column',
      maxHeight: height > 750 ? `${height * 0.75}px` : `${height * 0.65}px`,
    },
    commentRowContainer: {
      display: 'flex',
      width: '99%',
      marginTop: '0.3em',
      alignItems: 'top',
    },
    commentAccordionContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column' as 'column',
      marginLeft: '0.4em',
    },
    commentModalTitleContainer: {
      fontSize: '1.5em',
      marginBottom: '0.2em',
      borderBottom: `1.5px solid ${theme.colorPalette.drawerHeaderText}`,
      width: 'fit-content',
    },
  };
};
