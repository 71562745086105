import { IconEnum } from '../../Chip/chipUtils/ChipEnums';
import { theme } from '../../../assets/siteLineTheme';
import { timeDirectionArrowClickHandler } from '../utilFunctions/timeDirectionArrowClickHandler';
import { MapToIconButtonArray } from './MapToIconButtonArray';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';
import { DirectionEnum } from 'src/utils/enum/DirectionEnum';

export const dateForwardBackIconButtonsData = (
  colorMode: ColorModeTypeEnum
) => {
  return [
    {
      label: 'navigate back in time button',
      icon: IconEnum.BackArrow,
      color: theme.newColorPalette.zoidbergBlack[colorMode],
      onClick: () => timeDirectionArrowClickHandler(DirectionEnum.Back),
    },
    {
      label: 'navigate forward in time button',
      icon: IconEnum.ForwardArrow,
      color: theme.newColorPalette.zoidbergBlack[colorMode],
      onClick: () => timeDirectionArrowClickHandler(DirectionEnum.Forward),
    },
  ];
};

export const DateForwardBackIconButtons = (colorMode: ColorModeTypeEnum) => {
  return MapToIconButtonArray({
    dataForIconButtonArray: dateForwardBackIconButtonsData(colorMode),
  });
};
