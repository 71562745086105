import {IconEnum} from './chipUtils/ChipEnums';
import {AccessTime, CheckCircleOutline, ErrorOutline, Print,} from '@mui/icons-material';
import TuneIcon from '@mui/icons-material/Tune';
import React from 'react';
import {AiFillPlusCircle} from 'react-icons/ai';
import {IoIosArrowDropdown, IoMdBookmarks, IoMdRefresh} from 'react-icons/io';
import {RiArrowGoBackLine, RiDeleteBinLine} from 'react-icons/ri';
import {CgWebsite} from 'react-icons/cg';
import {FaHistory, FaListUl, FaRegCalendarAlt, FaRegEdit, FaSort, FaSortDown, FaSortUp,} from 'react-icons/fa';
import {GoCommentDiscussion, GoSignOut} from 'react-icons/go';
import {BsCheckLg, BsPlusLg} from 'react-icons/bs';
import {BiHide, BiXCircle} from 'react-icons/bi';
import {IoNotificationsOutline} from 'react-icons/io5';
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineArchive,
  MdOutlinePictureAsPdf,
  MdOutlineHealthAndSafety,
} from 'react-icons/md';
import {FiDownload, FiMoreVertical, FiSearch} from 'react-icons/fi';
import {GiHamburgerMenu} from 'react-icons/gi';
import {HiShoppingBag} from 'react-icons/hi';
import userPreferenceStore, {UserPreferenceStoreState,} from 'src/stores/userPreferenceStore';
import NotificationWithRedCircle from '../Icons/NotificationWithRedCircle';
import {ImVideoCamera} from 'react-icons/im';
import {ChipStyles} from "../Forms/styles/chipStyles";

const IconSelector: React.FC<IconEnum> = (iconEnum) => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  /* istanbul ignore next */
  switch (iconEnum) {
    case IconEnum.AccessTime:
      return <AccessTime sx={ChipStyles(userPreference.colorMode).chipIcon} />;
    case IconEnum.CheckCircleOutline:
      return <CheckCircleOutline sx={ChipStyles(userPreference.colorMode).chipIcon} />;
    case IconEnum.Print:
      return <Print sx={ChipStyles(userPreference.colorMode).mediumIcon} />;
    case IconEnum.TuneIcon:
      return <TuneIcon sx={ChipStyles(userPreference.colorMode).mediumIcon} />;
    case IconEnum.ErrorOutline:
      return <ErrorOutline sx={ChipStyles(userPreference.colorMode).chipIcon} />;
    case IconEnum.PlusSign:
      return <AiFillPlusCircle />;
    case IconEnum.DropDownArrow:
      return <IoIosArrowDropdown />;
    case IconEnum.Delete:
      return <RiDeleteBinLine />;
    case IconEnum.Edit:
      return <FaRegEdit />;
    case IconEnum.ViewComments:
      return <GoCommentDiscussion />;
    case IconEnum.Check:
      return <BsCheckLg />;
    case IconEnum.Exit:
      return <BiXCircle />;
    case IconEnum.ActivityHistory:
      return <FaHistory />;
    case IconEnum.ReturnArrow:
      return <RiArrowGoBackLine />;
    case IconEnum.OutlinedPlus:
      return <BsPlusLg />;
    case IconEnum.Reset:
      return <IoMdRefresh />;
    case IconEnum.Notification:
      return <IoNotificationsOutline />;
    case IconEnum.NotificationWithRedCircle:
      return <NotificationWithRedCircle />;
    case IconEnum.Hide:
      return <BiHide />;
    case IconEnum.Next:
      return <MdKeyboardArrowRight />;
    case IconEnum.Previous:
      return <MdKeyboardArrowLeft />;
    case IconEnum.MoreVert:
      return <FiMoreVertical />;
    case IconEnum.Search:
      return <FiSearch />;
    case IconEnum.Menu:
      return <GiHamburgerMenu />;
    case IconEnum.SignOut:
      return <GoSignOut />;
    case IconEnum.Calendar:
      return <FaRegCalendarAlt />;
    case IconEnum.List:
      return <FaListUl />;
    case IconEnum.BackArrow:
      return <MdArrowBackIos />;
    case IconEnum.ForwardArrow:
      return <MdArrowForwardIos />;
    case IconEnum.Download:
      return <FiDownload />;
    case IconEnum.UpArrow:
      return <FaSortUp />;
    case IconEnum.DownArrow:
      return <FaSortDown />;
    case IconEnum.UpDownArrow:
      return <FaSort />;
    case IconEnum.Archive:
      return <MdOutlineArchive />;
    case IconEnum.Book:
      return <IoMdBookmarks />;
    case IconEnum.WebSite:
      return <CgWebsite />;
    case IconEnum.Merchandising:
      return <HiShoppingBag />;
    case IconEnum.Pdf:
      return <MdOutlinePictureAsPdf />;
    case IconEnum.Video:
      return <ImVideoCamera />;
    case IconEnum.Safety:
      return <MdOutlineHealthAndSafety />;
    default:
      return <></>;
  }
};

export default IconSelector;
