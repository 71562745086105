import { Box } from '@mui/material';
import { theme } from '../../assets/siteLineTheme';
import { eventItems } from '../Forms/TaskUtils';
import userPreferenceStore, {
  ColorModeTypeEnum,
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import React from 'react';
import { CalendarEvent } from '../types/Calendar/ICalendarIndex';

export type CalendarEventComponentProps = {
  event: CalendarEvent;
};

const CalendarEventComponent: React.FC<CalendarEventComponentProps> = (
  props
) => {
  const { task_type, title } = props.event;
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const tokenStyleGetter = (event: any) => {
    const allTokens = {
      backgroundColor: theme.colorPalette.plainBlack,
      // filter: 'brightness(.75)',
    };

    let style = {};

    switch (event.task_status) {
      case 'Submitted': {
        style = {
          ...allTokens,
          backgroundColor:
            userPreference.colorMode !== ColorModeTypeEnum.BlackWhite
              ? theme.colorPalette.taskTypes.dark[event.task_type]
              : theme.colorPalette.plainBlack,
          color: theme.colorPalette.taskTypes.getTextColor(
            event.task_type,
            userPreference.colorMode
          ),
        };
        break;
      }
      case 'Returned': {
        style = {
          ...allTokens,
          backgroundColor:
            userPreference.colorMode !== ColorModeTypeEnum.BlackWhite
              ? theme.colorPalette.taskTypes.dark[event.task_type]
              : theme.colorPalette.plainBlack,
          color: theme.colorPalette.taskTypes.getTextColor(
            event.task_type,
            userPreference.colorMode
          ),
        };
        break;
      }

      default: {
        style = {
          ...allTokens,
          backgroundColor:
            userPreference.colorMode !== ColorModeTypeEnum.BlackWhite
              ? theme.colorPalette.taskTypes.dark[event.task_type]
              : theme.colorPalette.plainBlack,
          color: theme.colorPalette.taskTypes.getTextColor(
            event.task_type,
            userPreference.colorMode
          ),
          borderRight:
            userPreference.colorMode !== ColorModeTypeEnum.BlackWhite
              ? undefined
              : `1px solid ${theme.colorPalette.plainBlack}`,
        };
        break;
      }
    }

    return style;
  };

  const eventItem = eventItems.find((item) => item.label === task_type);

  return (
    <Box className={'task-bar-container'}>
      <Box className={'type-token'} style={tokenStyleGetter(props.event)}>
        {eventItem?.abbreviation}
      </Box>
      <span className={'task-bar-title'}>{title}</span>
    </Box>
  );
};

export default CalendarEventComponent;
