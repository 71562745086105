import React from 'react';
import { EditNotesObj } from '../../Drawers/TaskDrawerComponents/EditNotesSection/EditNotesSection';
import { formatDateForDisplay } from '../../../utils/dateUtils';
import LetterIcon from '../../Icons/LetterIcon';
import { editNoteStyles } from './editNoteStyles';

export type EditNoteProps = {
  edit_note: EditNotesObj;
};

const EditNote: React.FC<EditNoteProps> = (props) => {
  const { edit_note } = props;
  const formattedDateForDisplay = formatDateForDisplay(
    `${edit_note.date_edited}`
  );
  const editNoteClasses = editNoteStyles();
  const firstInitial = `${edit_note.emp_first_name}`;
  const initial = firstInitial.substring(0, 1) + `${edit_note.emp_last_name}`;

  return (
    <div style={editNoteClasses.editNoteContainer}>
      <div style={editNoteClasses.iconContainer}>
        <LetterIcon text={initial} />
      </div>
      <div style={editNoteClasses.editNoteMainContainer}>
        <div style={editNoteClasses.editNoteHeaderContainer}>
          <div style={editNoteClasses.title}>
            {edit_note.emp_first_name} {edit_note.emp_last_name}
          </div>
          <div style={editNoteClasses.dateText}>{formattedDateForDisplay}</div>
        </div>

        <div style={editNoteClasses.editNoteTextContainer}>
          <div style={editNoteClasses.editNoteText}>{edit_note.notes}</div>
        </div>
      </div>
    </div>
  );
};

export default EditNote;
