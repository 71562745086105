import {theme} from "../../../assets/siteLineTheme";
import {ColorModeTypeEnum} from "../../../stores/userPreferenceStore";

/** Chip **/
export const ChipStyles = (colorMode: ColorModeTypeEnum, chipBackgroundColor?: string, chipTextColor?: string) => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'left',
            flexDirection: 'row' as 'row',
            flexGrow: 1,
            margin: '0 1em',
            flexWrap: 'wrap' as 'wrap',
            '& > *': {
                margin: '4px',
            },
        },
        filterCountChip: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: `1.5em`,
            color: theme.newColorPalette.textDarkBlack[colorMode],
            backgroundColor:
                colorMode !== ColorModeTypeEnum.BlackWhite
                    ? theme.newColorPalette.zoidbergBlack[colorMode]
                    : undefined,
            border:
                colorMode === ColorModeTypeEnum.BlackWhite
                    ? `1px solid ${theme.colorPalette.plainBlack}`
                    : undefined,
            width: '1.5em',
            height: '1.5em',
            padding: '5px',
        },
        generalChipStyles: {
            display: 'flex',
            width: 'fit-content',
            borderRadius: '20px',
            border:
                colorMode !== ColorModeTypeEnum.BlackWhite
                    ? undefined
                    : '1px solid rgba(0, 0, 0, 0.23)',
            padding: '4px 8px',
            margin: '4px 0 4px 10px',
            alignItems: 'center',
        },
        chipText: {
            fontSize: '0.8125rem',
            fontWeight: 400,
        },
        chipIcon: {
            fontSize: '1rem',
            marginLeft: '.2rem',
        },
        filledChip: {
            display: 'flex',
            width: 'fit-content',
            borderRadius: '20px',
            border:
                colorMode !== ColorModeTypeEnum.BlackWhite
                    ? undefined
                    : '1px solid rgba(0, 0, 0, 0.23)',
            padding: '4px 8px',
            margin: '4px 0 4px 10px',
            alignItems: 'center',
            backgroundColor: chipBackgroundColor && chipBackgroundColor,
            color: chipTextColor || theme.colorPalette.plainBlack,
        },
        outlinedChip: {
            backgroundColor: theme.colorPalette.plainWhite,
            color: theme.newColorPalette.textDarkBlack[colorMode],
        },
        mediumIcon: {
            fontSize: '20px',
        },
        typeChip: {
            padding: '2px 15px',
            borderRadius: 'calc((.85em + 10px)/2)',
            marginRight: 'auto',
        },
        typeLabel: {
            fontSize: '.9em',
            fontWeight: 500,
        },
        optionSubText: {
            fontSize: '.75em',
        },
    }
}