import { MultiValue } from 'react-select';
import { SelectOptionType } from './Data/dropboxDataList';

export const stringBuilderUtils = (
  array: MultiValue<SelectOptionType>,
  targetFieldKey: string
) => {
  let queryString = '';

  Object.keys(array).forEach((key: string) => {
    queryString += `&${targetFieldKey}=` + encodeURIComponent(array[key].value);
  });

  return queryString;
};
