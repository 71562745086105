import { create, SetState } from 'zustand';

const searchBarProps = {
  options: [{ title: 'Search for this page coming soon!', identifier: '404' }],
  clickHandler: (taskID: string) => {},
};
const searchBarPropsStore = create((set: SetState<any>) => ({
  searchBarProps: { ...searchBarProps },
  setSearchBarProps: (searchBarProps: {
    options: [];
    clickHandler: (taskID: string) => {};
  }) => {
    set(() => ({ searchBarProps }));
  },
}));

export default searchBarPropsStore;
