import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

const whiteFontColorEventTypes = new Set([
  'Merchandising',
  'Loss Prevention',
  'Operations',
  'Holiday',
  'Special Projects',
  'Surveys'
]);

export const bwTheme = {};

export const basicColorPalette = {
  Zoidberg: {
    primary: '#F2705B',
    medium: 'rgba(242, 112, 91, 0.50)',
    light: 'rgba(242, 112, 91, 0.25)',
  },
  Teal: {
    primary: '#52B9BE',
    medium: 'rgba(82,185,190,0.5)',
    light: 'rgba(82,185,190,0.25)',
  },
};

export const theme = {
  newColorPalette: {
    CalendarTodayBackgroundWhite: {
      Zoidberg: 'rgba(242, 111, 92, 0.05)',
      BlackWhite: '#ffffff',
      Teal: 'rgba(242, 111, 92, 0.05)',
    },
    zoidbergWhite: {
      Zoidberg: basicColorPalette.Zoidberg.primary,
      BlackWhite: '#ffffff',
      Teal: basicColorPalette.Teal.primary,
    },
    zoidbergGrey: {
      Zoidberg: basicColorPalette.Zoidberg.primary,
      BlackWhite: '#919191',
      Teal: basicColorPalette.Teal.primary,
    },
    zoidbergMediumGrey: {
      Zoidberg: basicColorPalette.Zoidberg.primary,
      BlackWhite: '#424242',
      Teal: basicColorPalette.Teal.primary,
    },
    zoidbergDarkGrey: {
      Zoidberg: basicColorPalette.Zoidberg.primary,
      BlackWhite: '#363636',
      Teal: basicColorPalette.Teal.primary,
    },
    zoidbergBlack: {
      Zoidberg: basicColorPalette.Zoidberg.primary,
      BlackWhite: '#000000',
      Teal: basicColorPalette.Teal.primary,
    },
    zoidbergMediumLighterGrey: {
      Zoidberg: basicColorPalette.Zoidberg.medium,
      BlackWhite: '#919191',
      Teal: basicColorPalette.Teal.medium,
    },
    zoidbergLightBlack: {
      Zoidberg: basicColorPalette.Zoidberg.light,
      BlackWhite: '#000000',
      Teal: basicColorPalette.Teal.light,
    },
    zoidbergLightLighterGrey: {
      Zoidberg: basicColorPalette.Zoidberg.light,
      BlackWhite: '#919191',
      Teal: basicColorPalette.Teal.light,
    },
    textDarkBlack: {
      Zoidberg: '#121212',
      BlackWhite: '#000000',
      Teal: '#121212',
    },
    textDarkWhite: {
      Zoidberg: '#121212',
      BlackWhite: '#ffffff',
      Teal: '#121212',
    },
    backgroundLightWhite: {
      Zoidberg: '#E5E5E5',
      BlackWhite: '#ffffff',
      Teal: '#E5E5E5',
    },
    backgroundDarkWhite: {
      Zoidberg: '#424242',
      BlackWhite: '#ffffff',
      Teal: '#424242',
    },
    backgroundDarkBlack: {
      Zoidberg: '#424242',
      BlackWhite: '#000000',
      Teal: '#424242',
    },
    backgroundAddTaskIconDarkBlack: {
      Zoidberg: '#008294',
      BlackWhite: '#121212',
      Teal: '#008294',
    },
    plainWhiteBlack: {
      Zoidberg: '#ffffff',
      BlackWhite: '#000000',
      Teal: '#ffffff',
    },
    plainWhiteGrey: {
      Zoidberg: '#ffffff',
      BlackWhite: '#4e4e4e',
      Teal: '#ffffff',
    },
    plainBlackWhite: {
      Zoidberg: '#000000',
      BlackWhite: '#ffffff',
      Teal: '#000000',
    },
    plainGreyBlack: {
      Zoidberg: '#4e4e4e',
      BlackWhite: '#000000',
      Teal: '#4e4e4e',
    },
    toolbarBackgroundWhite: {
      Zoidberg:
        'linear-gradient(90deg, rgba(243,124,104,1) 0%, rgba(255,177,0,1) 100%)',
      BlackWhite: '#ffffff',
      Teal: 'linear-gradient(90deg, rgba(104,227,243,1) 0%, rgba(173,236,228,1) 100%)',
    },
    feedbackBlack: {
      Zoidberg: '#1FE19B',
      BlackWhite: '#000000',
      Teal: '#1FE19B',
    },
    feedbackWhite: {
      Zoidberg: '#1FE19B',
      BlackWhite: '#ffffff',
      Teal: '#1FE19B',
    },
    notificationBellRedCircle: {
      Zoidberg: '#FFF380',
      BlackWhite: '#000000',
      Teal: '#FE3A42',
    },
    notificationBellRedCircleOutline: {
      Zoidberg: basicColorPalette.Zoidberg.primary,
      BlackWhite: '#000000',
      Teal: basicColorPalette.Teal.primary,
    },
    recentlyBackground: {
      Zoidberg: '#D81B60',
      BlackWhite: '#ffffff',
      Teal: '#D81B60',
    },
    pdfPage: {
      Zoidberg: '#cda0e5',
      BlackWhite: '#ffffff',
      Teal: '#cda0e5',
    },
    videoPage: {
      Zoidberg: '#962ef6',
      BlackWhite: '#ffffff',
      Teal: '#962ef6',
    },
    snackbar: {
      successBlack: {
        Zoidberg: '#3BBD9F',
        BlackWhite: '#000000',
        Teal: '#3BBD9F',
      },
      errorBlack: {
        Zoidberg: '#FE3A42',
        BlackWhite: '#000000',
        Teal: '#FE3A42',
      },
      loadingBlack: {
        Zoidberg: '#7c6fcc',
        BlackWhite: '#000000',
        Teal: '#7c6fcc',
      },
    },
    completionStatus: {
      completedBlack: {
        Zoidberg: '#43A047',
        BlackWhite: '#000000',
        Teal: '#43A047',
      },
      incompleteBlack: {
        Zoidberg: '#FFB300',
        BlackWhite: '#000000',
        Teal: '#FFB300',
      },
      overdueBlack: {
        Zoidberg: '#990000',
        BlackWhite: '#000000',
        Teal: '#990000',
      },
      scheduledDateBlack: {
        Zoidberg: '#14428b',
        BlackWhite: '#000000',
        Teal: '#14428b',
      },
    },
    buttons: {
      editBlack: {
        Zoidberg: '#4fc3f7',
        BlackWhite: '#000000',
        Teal: '#4fc3f7',
      },
      commentLightSubmitDarkGrey: {
        Zoidberg: 'rgba(59, 189, 159, .75)',
        BlackWhite: '#424242',
        Teal: 'rgba(59, 189, 159, .75)',
      },
      commentDarkSubmitBlack: {
        Zoidberg: 'rgba(59, 189, 159, 1)',
        BlackWhite: '#000000',
        Teal: 'rgba(59, 189, 159, 1)',
      },
      commentLightCancelDarkGrey: {
        Zoidberg: 'rgba(242, 112, 91, 0.75)',
        BlackWhite: '#424242',
        Teal: 'rgba(242, 112, 91, 0.75)',
      },
      commentDarkCancelBlack: {
        Zoidberg: 'rgba(242, 112, 91, 1)',
        BlackWhite: '#000000',
        Teal: 'rgba(242, 112, 91, 1)',
      },
      merchandisingDepartmentsMenuItems: {
        Zoidberg: 'rgba(59, 189, 159, 1)',
        BlackWhite: '#fff',
        Teal: 'rgba(59, 189, 159, 1)',
      },
    },
    completionStatusBarChart: {
      completed: {
        Zoidberg: '#27ae60',
        BlackWhite: '#797979',
        Teal: '#27ae60',
      },
      incomplete: {
        Zoidberg: '#f1c40f',
        BlackWhite: '#595959',
        Teal: '#f1c40f',
      },
      overdue: {
        Zoidberg: '#e74c3c',
        BlackWhite: '#3b3b3b',
        Teal: '#e74c3c',
      },
    },
  },
  colorPalette: {
    dark: '#121212',
    plainBlack: 'black',
    light: '#ffffff',
    textLight: '#ffffff',
    lightGrey: '#4e4e4e',
    lighterGrey: '#919191',
    veryLightGrey: '#d2d2d2',
    zoidberg: '#F2705B',
    zoidbergMedium: 'rgba(242, 112, 91, 0.50)',
    zoidbergLight: 'rgba(242, 112, 91, 0.25)',
    textDark: '#121212',
    drawerHeaderText: '#757575',
    colorModeWhite: '#d0cece',
    backgroundDark: '#424242',
    dashedSeparator: '#dfdfdf',
    plainWhite: 'white',
    feedback: '#1FE19B',
    taskListViewBorder: '#dddddd',
    gradientBlack: 'rgba(0,0,0,0.05)',
    calendarEl: {
      calHeaderToday: '#F2705B',
      calTodayBox: '#eaf6ff',
      calTodayBoxLightBW: '#ededed',
    },
    completionStatus: {
      completed: '#43A047',
      incomplete: '#FFB300',
      overdue: '#990000',
      scheduledDate: '#14428b',
    },
    snackbar: {
      success: '#3BBD9F',
      error: '#FE3A42',
      loading: '#7c6fcc',
    },
    easyCalendarPrintBorders: {
      pink: 'salmon',
      purple: 'rebeccaPurple',
    },
    activityHistory: {
      createTask: '#e6d747',
      addComment: 'black',
      approveTask: '#049BE5',
      returnTask: '#DF354A',
      editTask: '#F2705B',
      markedComplete: '#43A047',
      reopenTask: '#EC1ED7',
      submitTask: 'rgba(255, 240, 190, 0.7)',
      scheduledDate: 'rgba(17,10,89,0.6)',
    },
    taskTypes: {
      dark: {
        Sephora: 'rgba(120, 144, 156, 1)',
        Merchandising: 'rgba(0, 105, 92, 1)',
        'Pricing & Signing': 'rgba(240, 98, 146, 1)',
        Visual: 'rgba(161, 136, 127, 1)',
        Operations: 'rgba(171, 71, 188, 1)',
        'Loss Prevention': 'rgba(136, 14, 79, 1)',
        'Training & Recognition': 'rgba(79, 195, 247, 1)',
        'Marketing Promotions': 'rgba(185, 246, 202, 1)',
        Event: 'rgba(255, 235, 59, 1)',
        'Risk & Compliance': 'rgba(255, 112, 67, 1)',
        Holiday: 'rgba(211, 47, 47, 1)',
        'Special Projects': 'rgba(13, 71, 161, 1)',
        Surveys: 'rgba(74,67,117, 1)',
      },
      light: {
        Sephora: 'rgba(120, 144, 156, 0.2)',
        Merchandising: 'rgba(0, 105, 92, 0.2)',
        'Pricing & Signing': 'rgba(240, 98, 146, 0.2)',
        Visual: 'rgba(161, 136, 127, 0.2)',
        Operations: 'rgba(171, 71, 188, 0.2)',
        'Loss Prevention': 'rgba(136, 14, 79, 0.15)',
        'Training & Recognition': 'rgba(79, 195, 247, 0.2)',
        'Marketing Promotions': 'rgba(185, 246, 202, 0.4)',
        Event: 'rgba(255, 235, 59, 0.3)',
        'Risk & Compliance': 'rgba(255, 112, 67, 0.2)',
        Holiday: 'rgba(211, 47, 47, 0.2)',
        'Special Projects': 'rgba(13, 71, 161, 0.2)',
        Surveys: 'rgba(74,67,117, 0.2)',
      },
      getTextColor: (taskType: string, colorMode: ColorModeTypeEnum) => {
        return colorMode !== ColorModeTypeEnum.BlackWhite
          ? whiteFontColorEventTypes.has(taskType)
            ? theme.colorPalette.plainWhite
            : theme.colorPalette.plainBlack
          : theme.colorPalette.plainWhite;
      },
    },
    merchandisingDepartments: {
      Accessories: '#E53935',
      Beauty: '#D81B60',
      'Domestic / Luggage': '#8E24AA',
      'Housewares / Seasonal': '#5E35B1',
      'Intimates / Sleepwear': '#3949AB',
      Jewelry: '#1E88E5',
      'Young Womens': '#039BE5',
      'Kids / Toys': '#00ACC1',
      'Mens & Young Mens': '#00897B',
      Womens: '#43A047',
      Shoes: '#7CB342',
      Store: '#FBC02D',
      Graphics: '#4527A0',
      Mannequins: '#1565C0',
      Marketing: '#00695C',
    },
  },
};
