import { theme } from '../../../assets/siteLineTheme';
import { ColorModeTypeEnum } from '../../../stores/userPreferenceStore';

export const pageNotFoundStyles = (colorMode: ColorModeTypeEnum) => {
  // window height - app header bar height - some more to try and center the text more
  const pageHeight = window.innerHeight - 56 - 40;

  return {
    errorPageGenericTextStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column' as 'column',
      height: pageHeight,
      width: '100%',
      textAlign: 'center' as 'center',
    },
    errorPageHeaderText: {
      display: 'flex',
      alignItems: 'flex-end',
      fontSize: '1.75em',
      fontWeight: 'bold',
      color: `${theme.newColorPalette.zoidbergBlack[colorMode]}`,
      marginBottom: '.25em',
    },
    errorPageBodyText: {
      fontSize: '1.25em',
    },
    errorPageHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };
};
