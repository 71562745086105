import GroupedSelectionButtons, {
  GroupedButtonTypeEnum,
} from './GroupedSelectionButtons';
import { TaskStoreCompletionStatus } from '../../models/TaskStoreCompletionStatus';
import appliedFiltersStore from '../../stores/appliedFiltersStore';
import React from 'react';

const CalendarCompletionStatusButtonGroup: React.FC = () => {
  const { appliedFilters, setAppliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any; setAppliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
      setAppliedFilters: state.setAppliedFilters,
    })
  );

  const handleOnClick = (completion_status: TaskStoreCompletionStatus) => {
    setAppliedFilters({ ...appliedFilters, completion_status });
  };

  const buttonObjectArray = [
    {
      clickHandler: () => handleOnClick(TaskStoreCompletionStatus.Incomplete),
      displayText: 'To-Do',
      labelText: 'To-Do Filter Button',
      appStateSelector: TaskStoreCompletionStatus.Incomplete,
    },

    {
      clickHandler: () => handleOnClick(TaskStoreCompletionStatus.Completed),
      displayText: 'Completed',
      labelText: 'Completed Filter Button',
      appStateSelector: TaskStoreCompletionStatus.Completed,
    },
    {
      clickHandler: () => handleOnClick(TaskStoreCompletionStatus.Overdue),
      displayText: 'Overdue',
      labelText: 'Overdue Filter Button',
      appStateSelector: TaskStoreCompletionStatus.Overdue,
    },
    {
      clickHandler: () => handleOnClick(TaskStoreCompletionStatus.All),
      displayText: 'All',
      labelText: 'All Filter Button',
      appStateSelector: TaskStoreCompletionStatus.All,
    },
  ];
  return (
    <GroupedSelectionButtons
      buttonObjectArray={buttonObjectArray}
      buttonGroupLabel="Calendar Completion Status Filter Selector"
      groupedSelectionButtonsOptions={{ relevantFilter: 'completion_status' }}
      groupedButtonTypeEnum={GroupedButtonTypeEnum.Block}
    />
  );
};

export default CalendarCompletionStatusButtonGroup;
