import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import BaseButton from '../../Buttons/BaseButton';
import { theme } from '../../../assets/siteLineTheme';
import {
  createComment,
  CreateCommentValues,
  updateTaskCommentById,
} from '../../../api/apiV1Task';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../utils/analytics';
import appliedFiltersStore from '../../../stores/appliedFiltersStore';
import { AppContext } from '../../../context/AppContext';
import { commentCreateUpdateStyles } from './commentCreateUpdateStyles';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

export type CommentCreateUpdateProps = {
  existComment?: string | undefined;
  comment_id?: string | undefined;
  task_id: string;
  setIsCreatingComment: Dispatch<SetStateAction<boolean>>;
  updateParentState: Function | undefined;
};

const CommentCreateUpdate: React.FC<CommentCreateUpdateProps> = (props) => {
  const {
    existComment,
    comment_id,
    task_id,
    setIsCreatingComment,
    updateParentState,
  } = props;

  const [comment, setComment] = useState<string>(
    existComment !== undefined ? existComment : ''
  );
  const commentCreateUpdateClasses = commentCreateUpdateStyles();

  const { appState } = useContext(AppContext);
  const { userInfo } = appState;

  const { appliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
    })
  );

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setComment(event.target.value);
  };

  const handleNewCommentSubmit = async () => {
    const lastNameInitial = userInfo.family_name.substring(0, 1);
    const values: CreateCommentValues = {
      content: comment,
      emp_first_name: userInfo.given_name,
      emp_id: userInfo.empID,
      emp_last_name: lastNameInitial,
      store_number: appliedFilters.store_number,
      task_id,
    };
    sendAnalyticsEvent(analyticsEventConstants.ADD_COMMENT);

    await createComment(values);
    setIsCreatingComment(false);
    setComment('');
    if (updateParentState !== undefined) {
      await updateParentState(task_id, appliedFilters.store_number);
    }
  };

  const handleUpdateCommentSubmit = async () => {
    if (comment_id !== undefined) {
      await updateTaskCommentById(comment, comment_id);
    }
    setIsCreatingComment(false);
    setComment('');
    if (updateParentState !== undefined) {
      await updateParentState(task_id, appliedFilters.store_number);
    }
    sendAnalyticsEvent(analyticsEventConstants.EDIT_COMMENT);
  };

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  return (
    <>
      <textarea
        defaultValue={comment}
        placeholder="Add Comment..."
        onChange={(e) => handleChange(e)}
        style={commentCreateUpdateClasses.textArea}
        maxLength={255}
      />
      <div style={commentCreateUpdateClasses.newCommentButtonsContainer}>
        <BaseButton
          onClick={() => {
            setIsCreatingComment(false);
            setComment('');
          }}
          activeBackgroundColor={
            theme.newColorPalette.buttons.commentLightCancelDarkGrey[
              userPreference.colorMode
            ]
          }
          passiveBackgroundColor={
            theme.newColorPalette.buttons.commentDarkCancelBlack[
              userPreference.colorMode
            ]
          }
          activeTextColor={theme.colorPalette.plainWhite}
          passiveTextColor={theme.colorPalette.plainWhite}
          fontSize={'.65em'}
          label={'Cancel new comment'}
        >
          Cancel
        </BaseButton>
        <BaseButton
          onClick={() =>
            existComment !== undefined
              ? handleUpdateCommentSubmit()
              : handleNewCommentSubmit()
          }
          activeBackgroundColor={
            theme.newColorPalette.buttons.commentLightSubmitDarkGrey[
              userPreference.colorMode
            ]
          }
          passiveBackgroundColor={
            theme.newColorPalette.buttons.commentDarkSubmitBlack[
              userPreference.colorMode
            ]
          }
          activeTextColor={theme.colorPalette.plainWhite}
          passiveTextColor={theme.colorPalette.plainWhite}
          fontSize={'.65em'}
          margin={'0 0 0 .25em'}
          label={'Submit new comment'}
        >
          Submit
        </BaseButton>
      </div>
    </>
  );
};

export default CommentCreateUpdate;
