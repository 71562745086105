import React from 'react';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../../utils/analytics';
import { colorToggleStyles } from './colorToggleStyles';
import { createOrUpdateEmployeePreference } from '../../../api/apiEmployee';
import userPreferenceStore, {
  ColorModeTypeEnum,
  UserPreferenceStoreState,
} from '../../../stores/userPreferenceStore';

const ColorToggle: React.FC = () => {
  const { userPreference, setUserPreference } = userPreferenceStore(
    (state: {
      userPreference: UserPreferenceStoreState;
      setUserPreference: any;
    }) => ({
      userPreference: state.userPreference,
      setUserPreference: state.setUserPreference,
    })
  );

  const setToggle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUserPreference({
      ...userPreference,
      colorMode: ColorModeTypeEnum[value],
    });
    sendAnalyticsEvent(analyticsEventConstants.TOGGLE_COLOR_MODE);
    await createOrUpdateEmployeePreference({
      view_type: userPreference.viewType,
      view_row_number: userPreference.viewRowNumber,
      color_mode_type: ColorModeTypeEnum[value],
    });
  };

  const colorToggleClasses = colorToggleStyles({
    colorMode: userPreference.colorMode,
  });

  return (
    <>
      <div style={colorToggleClasses.colorToggleContainer}>
        <div>
          <div style={colorToggleClasses.colorToggleTitle}>{`Color Mode`}</div>
        </div>
        <div style={colorToggleClasses.colorToggleRadioContainer}>
          <div style={colorToggleClasses.colorToggleRadio}>
            <input
              type={'radio'}
              name={'colorMode'}
              value={ColorModeTypeEnum.Zoidberg}
              checked={userPreference.colorMode === ColorModeTypeEnum.Zoidberg}
              onChange={setToggle}
              style={colorToggleClasses.colorToggleRadioButton}
            />
            <div style={colorToggleClasses.colorToggleCircle.Zoidberg} />
            <div style={colorToggleClasses.colorToggleRadioText}> Default </div>
          </div>
          <div style={colorToggleClasses.colorToggleRadio}>
            <input
              type={'radio'}
              name={'colorMode'}
              value={ColorModeTypeEnum.BlackWhite}
              checked={
                userPreference.colorMode === ColorModeTypeEnum.BlackWhite
              }
              onChange={setToggle}
              style={colorToggleClasses.colorToggleRadioButton}
            />
            <div style={colorToggleClasses.colorToggleCircle.BlackWhite} />
            <div style={colorToggleClasses.colorToggleRadioText}> B/W </div>
          </div>
          <div style={colorToggleClasses.colorToggleRadio}>
            <input
              type={'radio'}
              name={'colorMode'}
              value={ColorModeTypeEnum.Teal}
              checked={userPreference.colorMode === ColorModeTypeEnum.Teal}
              onChange={setToggle}
              style={colorToggleClasses.colorToggleRadioButton}
            />
            <div style={colorToggleClasses.colorToggleCircle.Teal} />
            <div style={colorToggleClasses.colorToggleRadioText}> Teal </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ColorToggle;
