import { theme } from '../../../assets/siteLineTheme';

export type activityHistoryParams = {
  height: number;
};

export const activityHistoryModalStyles = (params: activityHistoryParams) => {
  const { height } = params;
  /* istanbul ignore next */
  return {
    activityHistoryContentContainer: {
      width: '100%',
      overflowY: 'auto' as 'auto',
      maxHeight: height > 750 ? `${height * 0.725}px` : `${height * 0.65}px`,
      marginTop: '.5em',
    },
    activityHistoryHeaderContainer: {
      fontSize: '1.5em',
      marginBottom: '0.2em',
      borderBottom: `1.5px solid ${theme.colorPalette.drawerHeaderText}`,
      width: 'fit-content',
    },
  };
};
