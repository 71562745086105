import React from 'react';
import { boxStyles } from './boxStyles';

export type BoxProps = {
  textColor?: string | undefined;
  backgroundColor?: string | undefined;
  padding?: string | undefined;
  margin?: string | undefined;
  borderRadius?: string | undefined;
  onClick?: Function;
  children?: React.ReactNode;
};

const Box: React.FC<BoxProps> = (props) => {
  const {
    textColor,
    backgroundColor,
    margin,
    padding,
    borderRadius,
    onClick,
    children,
  } = props;
  const boxClasses = boxStyles({
    textColor,
    backgroundColor,
    margin,
    padding,
    borderRadius,
  });

  const clickHandler = (e: any) => {
    if (onClick !== undefined) {
      return onClick(e);
    } else {
      return null;
    }
  };

  return (
    <div style={boxClasses.box} onClick={(e) => clickHandler(e)}>
      {children}
    </div>
  );
};

export default Box;
