import formattedTaskDataStore from '../../stores/formattedTaskDataStore';
import { easyPrintCalendarStyles } from './utils/easyPrintCalendarStyles';
import { Box } from '@mui/material';
import { day } from '../../utils/taskDataFormatter';
import EasyPrintCalendarDayLabels from './EasyPrintCalendarDayLabels';
import EmptyDateStartBoxes from './EmptyDateStartDateBoxes';
import EmptyEndDateBoxes from './EmptyEndDateBoxes';
import FilledDateBoxes from './FilledDateBoxes';
import React from 'react';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

const EasyPrintCalendar: React.FC = () => {
  // ignored below because functions are tested, styles don't need it, and store is working properly.
  /* istanbul ignore */
  const { readyForEasyPrintMonthData } = formattedTaskDataStore(
    (state: { readyForEasyPrintMonthData: any }) => ({
      readyForEasyPrintMonthData: state.readyForEasyPrintMonthData,
    })
  );

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const easyPrintCalendarClasses = easyPrintCalendarStyles(
    userPreference.colorMode
  );

  const firstDateIndex = day(readyForEasyPrintMonthData[0].rawDate);
  const lastDateIndex = day(
    readyForEasyPrintMonthData[readyForEasyPrintMonthData.length - 1].rawDate
  );

  return (
    <Box sx={easyPrintCalendarClasses.calendarContainer}>
      <Box sx={easyPrintCalendarClasses.dateLabelRow}>
        <EasyPrintCalendarDayLabels />
      </Box>
      <Box sx={easyPrintCalendarClasses.calendarDatesContainer}>
        <EmptyDateStartBoxes firstDateIndex={firstDateIndex} />
        <FilledDateBoxes />
        <EmptyEndDateBoxes lastDateIndex={lastDateIndex} />
      </Box>
    </Box>
  );
};

export default EasyPrintCalendar;
