import { useSpring } from '@react-spring/web';

export const rotateSpring = (
  isPassive: boolean,
  activeRotateZ: number,
  passiveRotateZ: number
): object => {
  return useSpring({
    to: {
      rotate: isPassive ? passiveRotateZ : activeRotateZ,
    },
    config: {
      duration: 500,
    },
    reverse: isPassive,
  });
};
