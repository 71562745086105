import { theme } from '../../../assets/siteLineTheme';

export const userPreferenceModalStyles = () => {
  return {
    userPreferenceContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column' as 'column',
      justifyContent: 'space-between',
    },
    logoutContainer: {
      marginTop: '0.8em',
      borderTop: `1.5px solid ${theme.colorPalette.drawerHeaderText}`,
      width: '100%',
    },
  };
};
