import React from 'react';
import { dateSelectorStyles } from './dateSelectorStyles';

export type DateSelectorProps = {
  dataValue: string;
  onChange: Function;
  dataKey: string;
  ariaLabelText: string;
  lableText?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  // defaultPosition: string;
};

const DateSelector: React.FC<DateSelectorProps> = (props) => {
  const {
    dataValue,
    onChange,
    dataKey,
    ariaLabelText,
    lableText,
    disabled,
    width,
    height,
  } = props;

  const dateSelectorClasses = dateSelectorStyles();

  return (
    <div
      aria-label={'DateSelector Component'}
      style={dateSelectorClasses.dateSelectorContainer}
    >
      {lableText && (
        <div style={dateSelectorClasses.dateSelectorTextContainer}>
          {lableText}
        </div>
      )}
      <input
        aria-label={ariaLabelText}
        type="date"
        value={dataValue}
        onChange={(e) => onChange(e, dataKey)}
        disabled={disabled}
        style={{ width, height }}
      />
    </div>
  );
};
export default DateSelector;
