import React, { useContext, useEffect, useState } from 'react';
import appliedFiltersStore from '../stores/appliedFiltersStore';
import {
  appliedFilterQueryStringBuilder,
  FromViewType,
} from '../utils/appliedFilterQueryStringBuilder';
import Table from '../components/Table/Table';
import { taskListTableDataWrapperStyles } from './styles/taskListTableDataWrapperStyles';
import { CalendarEvent } from '../components/types/Calendar/ICalendarIndex';
import { useFetchTaskAndPromotionsWithAppliedFilters } from '../hooks/useFetchTaskAndPromotionWithAppliedFilters';
import fireUseQueryStore from '../stores/fireUseQueryStore';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import {
  sortArray,
  sortCompletionStatus,
  columnSortDirection,
} from '../utils/sortUtils';
import CalendarToolbarV2 from '../components/Toolbar/CalendarToolbarV2';
import BaseButton from '../components/Buttons/BaseButton';
import GroupedSelectionButtonsV2 from '../components/Buttons/GroupedSelectionButtonsV2';
import ListViewWeekDaysContainer from '../components/BoxContent/ListViewWeekDayContent/ListViewWeekDaysContainer';
import { IntervalTimeRangeButtonsArray } from '../components/Buttons/IconButtonGroupElementArrays/IntervalTimeRangeButtons';
import { getSavedFilter } from '../api/apiEmployee';
import { groups } from '../context/constants';
import { TaskStatus } from '../models/TaskStatus';
import { AppContext } from '../context/AppContext';
import useFetchUserGroup from '../hooks/fetchUserGroup';
import PageNavigation from 'src/components/PageNavigation/PageNavigation';
import { DirectionEnum } from '../utils/enum/DirectionEnum';
import { BoxContentTypeEnum } from '../components/BoxArray/BoxArray';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../utils/analytics';
import { useHistory } from 'react-router-dom';

type TaskListTableDataWrapperProps = {
  fromCalendarScreen?: boolean;
  calendarEvents?: CalendarEvent[] | undefined;
};

const TaskListTableDataWrapper: React.FC<TaskListTableDataWrapperProps> = (
  props
) => {
  const { fromCalendarScreen, calendarEvents } = props;
  const [startRowPage, setStartRowPage] = useState<number>(0);
  const [endRowPage, setEndRowPage] = useState<number>(20);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [events, setEvents] = useState<CalendarEvent[]>(
    calendarEvents !== undefined
      ? sortArray(calendarEvents, 'end_date', 'desc')
      : []
  );
  const [slicedEvents, setSlicedEvents] = useState<CalendarEvent[]>([]);
  const [tableColumnSortDirection, setTableColumnSortDirection] = useState({
    title: '',
    task_type: '',
    end_date: 'desc',
    completion_status: '',
  });

  const { appState } = useContext<any>(AppContext);
  const { userInfo } = appState;
  const userOktaGroup = useFetchUserGroup(userInfo);

  const { appliedFilters, setAppliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any; setAppliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
      setAppliedFilters: state.setAppliedFilters,
    })
  );

  const { fireUseQueryStoreProps } = fireUseQueryStore(
    (state: { fireUseQueryStoreProps: any }) => ({
      fireUseQueryStoreProps: state.fireUseQueryStoreProps,
    })
  );

  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );

  const [queryString, setQueryString] = useState<string>(
    appliedFilterQueryStringBuilder(appliedFilters, FromViewType.List)
  );

  const { data, error } = useFetchTaskAndPromotionsWithAppliedFilters(
    queryString,
    fireUseQueryStoreProps.triggerTime
  );

  const history = useHistory();

  useEffect(() => {
    if (error !== null) {
      console.error(error);
      history.replace('/error');
    }
  }, [error]);

  const resetPageNumber = () => {
    setPageNumber(1);
  };

  const resetStartPageNumber = () => {
    setStartRowPage(0);
    setEndRowPage(userPreference.viewRowNumber);
  };

  useEffect(() => {
    setQueryString(
      appliedFilterQueryStringBuilder(appliedFilters, FromViewType.List)
    );
    if (!fromCalendarScreen) {
      data && setEvents(sortArray(data, 'end_date', 'desc'));
    } else {
      calendarEvents &&
        setEvents(sortArray(calendarEvents, 'end_date', 'desc'));
    }
    resetPageNumber();
    resetStartPageNumber();
  }, [data, fromCalendarScreen, appliedFilters, events]);

  useEffect(() => {
    if (userPreference.viewRowNumber !== undefined) {
      setEndRowPage(userPreference.viewRowNumber);
    }
  }, [userPreference.viewRowNumber]);

  const emptyColumnSortDirection = {
    title: '',
    task_type: '',
    end_date: '',
    completion_status: '',
  };

  const handleColumnSortClick = (selectedCol: string, endDate?: Date) => {
    const sortDirection = columnSortDirection(
      selectedCol,
      tableColumnSortDirection
    );

    selectedCol === 'completion_status'
      ? setEvents(sortCompletionStatus(data, selectedCol, sortDirection))
      : setEvents(sortArray(data, selectedCol, sortDirection));
    resetPageNumber();
    resetStartPageNumber();
    setSlicedEvents(data.slice(startRowPage, endRowPage));

    setTableColumnSortDirection({
      ...emptyColumnSortDirection,
      [selectedCol]: sortDirection,
    });
  };

  const sliceData = () => {
    data && setSlicedEvents(data.slice(startRowPage, endRowPage));
  };

  useEffect(() => {
    sliceData();
  }, [pageNumber, data?.length]);

  useEffect(() => {
    if (userOktaGroup) {
      const getFilterInformation = async () => {
        const getFilterInformList = await getSavedFilter();
        if (typeof getFilterInformList !== 'string') {
          setAppliedFilters({
            ...appliedFilters,
            ...getFilterInformList,
            territory_number:
              getFilterInformList.territory_number !== null
                ? getFilterInformList.territory_number
                : undefined,
            region_number:
                getFilterInformList.region_number !== null
                    ? getFilterInformList.region_number
                    : undefined,
            district_number:
              getFilterInformList.district_number !== null
                ? getFilterInformList.district_number
                : undefined,
            store_number:
              getFilterInformList.store_number !== null
                ? getFilterInformList.store_number
                : undefined,
            isSavedFilter: true,
          });
        } else {
          if (userOktaGroup === groups.JAN) {
            if (
              appliedFilters.store_number ||
              appliedFilters.district_number ||
              appliedFilters.region_number ||
              appliedFilters.territory_number
            ) {
              setAppliedFilters({
                ...appliedFilters,
                task_status: TaskStatus.Approved,
              });
            } else {
              setAppliedFilters({
                ...appliedFilters,
                territory_number: userInfo.territory_number,
                region_number: userInfo.region_number,
                district_number: userInfo.district_number,
                task_status: TaskStatus.Approved,
              });
            }
          } else if (
            userOktaGroup === groups.MICHAEL ||
            userOktaGroup === groups.STANLEY
          ) {
            setAppliedFilters({
              ...appliedFilters,
              store_number: userInfo.locationnumber,
              task_status: TaskStatus.Approved,
            });
          } else {
            setAppliedFilters({
              ...appliedFilters,
            });
          }
        }
      };
      getFilterInformation();
    }
  }, [userOktaGroup]);

  const columnHeadersArray = [
    {
      content: 'Title',
      column: 'title',
      cellWidth: '40%',
      justifyContent: undefined,
      onClick: () => handleColumnSortClick('title'),
    },
    {
      content: 'Type',
      column: 'task_type',
      cellWidth: '15%',
      justifyContent: 'center',
      onClick: () => handleColumnSortClick('task_type'),
    },
    {
      content: 'Store(s)',
      column: 'store_number_count',
      cellWidth: '15%',
      justifyContent: 'center',
      onClick: undefined,
    },
    {
      content: 'End Date',
      column: 'end_date',
      cellWidth: '20%',
      justifyContent: 'center',
      onClick: () => handleColumnSortClick('end_date'),
    },
    {
      content: 'Completion Status',
      column: 'completion_status',
      cellWidth: '15%',
      justifyContent: 'center',
      onClick:
        appliedFilters.store_number !== undefined
          ? () => handleColumnSortClick('completion_status')
          : undefined,
    },
  ];

  const columnHeadersArrayFiltered =
    appliedFilters.store_number === undefined
      ? columnHeadersArray
      : columnHeadersArray.filter((item: any) => {
          return item.column !== 'store_number_count';
        });

  const { taskTableStyles } = taskListTableDataWrapperStyles();

  const handlerChangePage = (
    type: DirectionEnum,
    curPage: number,
    pageRow: number
  ) => {
    if (type === DirectionEnum.Forward) {
      setPageNumber(curPage);
      setStartRowPage(startRowPage + pageRow);
      setEndRowPage(endRowPage + pageRow);
    } else {
      setPageNumber(curPage);
      setStartRowPage(startRowPage - pageRow);
      setEndRowPage(endRowPage - pageRow);
    }
  };

  useEffect(() => {
    if (userInfo.empID !== undefined) {
      sendAnalyticsEvent(analyticsEventConstants.NAVIGATION_LIST_VIEW);
    }
  }, [userInfo.empID]);

  return (
    <div style={taskTableStyles}>
      {!fromCalendarScreen && (
        <CalendarToolbarV2
          BaseButtonComponent={BaseButton}
          GroupedSelectionButtonsV2Component={GroupedSelectionButtonsV2}
          ListViewWeekDaysContainerComponent={ListViewWeekDaysContainer}
          getIntervalArray={IntervalTimeRangeButtonsArray}
        />
      )}
      {slicedEvents && (
        <Table
          data={slicedEvents}
          columnHeadersArray={columnHeadersArrayFiltered}
          boxContentType={BoxContentTypeEnum.TaskListViewContent}
          optionalState={tableColumnSortDirection}
        />
      )}
      <PageNavigation
        curPage={pageNumber}
        dataCount={events ? events.length : 0}
        onChangePage={handlerChangePage}
      />
    </div>
  );
};

export default TaskListTableDataWrapper;
