import { Box, Typography } from '@mui/material';
import { easyPrintCalendarStyles } from './utils/easyPrintCalendarStyles';
import formattedTaskDataStore from '../../stores/formattedTaskDataStore';
import TaskTitleList from './TaskTitleList';
import React from 'react';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

const FilledDateBoxes: React.FC = () => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const easyPrintCalendarClasses = easyPrintCalendarStyles(
    userPreference.colorMode
  );
  const { readyForEasyPrintMonthData } = formattedTaskDataStore(
    (state: { readyForEasyPrintMonthData: any }) => ({
      readyForEasyPrintMonthData: state.readyForEasyPrintMonthData,
    })
  );

  return (
    <>
      {readyForEasyPrintMonthData.map(
        (
          dateData: {
            rawDate: Date;
            formattedDate: string;
            taskArray: Array<any>;
          },
          index: number
        ) => {
          return (
            <Box
              key={dateData.formattedDate}
              sx={easyPrintCalendarClasses.dateContainer}
            >
              <Box sx={easyPrintCalendarClasses.dateNumberContainer}>
                <Typography sx={easyPrintCalendarClasses.dateNumberText}>
                  {index + 1}
                </Typography>
              </Box>
              {readyForEasyPrintMonthData[index].taskArray.length > 0 && (
                <TaskTitleList
                  taskArray={readyForEasyPrintMonthData[index].taskArray}
                />
              )}
            </Box>
          );
        }
      )}
    </>
  );
};

export default FilledDateBoxes;
