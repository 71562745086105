import React, { useState } from 'react';
import AccordionHeader from './AccordionHeader';
import AccordionContent from './AccordionContent';
import { CommentsDataType } from './CommentsAccordionContent/Comment';

export type AccordionProps = {
  title: string;
  data?: Array<CommentsDataType> | undefined | any;
  accordionContentEnum: AccordionContentEnum;
  onItemClick?: Function | undefined;
};

/* eslint-disable */
export enum AccordionContentEnum {
  Comments = 'Comments',
  EditNotes = 'EditNotes',
}

const Accordion: React.FC<AccordionProps> = (props) => {
  const { title, data, accordionContentEnum, onItemClick } = props;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <>
      <AccordionHeader
        title={title}
        accordionContentEnum={accordionContentEnum}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      <AccordionContent
        accordionContentEnum={accordionContentEnum}
        isCollapsed={isCollapsed}
        dataArray={data}
        onItemClick={onItemClick}
      />
    </>
  );
};

export default Accordion;
