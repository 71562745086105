import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import IconButtonWithLabel from '../../../Buttons/IconButtonWithLabel';
import { IconEnum } from '../../../Chip/chipUtils/ChipEnums';
import CommentCreateUpdate from '../../../Accordion/CommentsAccordionContent/CommentCreateUpdate';
import { generalSectionStyles } from '../generalSectionStyles';
import TaskHistoryModal from '../../../Modal/ActivityHistoryModal/TaskHistoryModal';
import { getActivityHistoryListByTaskId } from '../../../../api/apiActivityHistory';
import appliedFiltersStore from '../../../../stores/appliedFiltersStore';
import { theme } from '../../../../assets/siteLineTheme';

export type TaskHistorySectionProps = {
  task_id: string;
  refresh?: any;
};

export const TaskHistorySection: React.FC<TaskHistorySectionProps> = (
  props
) => {
  const { task_id, refresh } = props;
  const generalSectionClasses = generalSectionStyles();
  const [isCreatingComment, setIsCreatingComment] = useState<boolean>(false);
  const [localTaskHistory, setLocalTaskHistory] = useState<any>([]);
  const { appliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
    })
  );

  const store_number = appliedFilters.store_number;

  const handleOpenNewComment = () => {
    setIsCreatingComment(true);
  };

  const updateTaskHistory = useCallback(async () => {
    const getActivityHistoryList = await getActivityHistoryListByTaskId(
      task_id,
      store_number
    );
    if (getActivityHistoryList !== null) {
      setLocalTaskHistory(getActivityHistoryList);
    }
  }, [localTaskHistory]);

  useEffect(() => {
    updateTaskHistory();
  }, [refresh]);

  return (
    <Grid container>
      <Paper elevation={4} style={generalSectionClasses.paper}>
        <div style={generalSectionClasses.headerContainer}>
          <Typography style={generalSectionClasses.sectionTitle}>
            Task History
          </Typography>
          {!isCreatingComment && store_number !== undefined && (
            <IconButtonWithLabel
              label={'create comment button'}
              icon={IconEnum.PlusSign}
              onClick={handleOpenNewComment}
              buttonLabel={'Comment'}
              backgroundColor={theme.colorPalette.plainBlack}
              color={theme.colorPalette.plainWhite}
              borderRadius={'.25em'}
            />
          )}
        </div>
        {isCreatingComment && (
          <CommentCreateUpdate
            task_id={task_id}
            setIsCreatingComment={setIsCreatingComment}
            updateParentState={updateTaskHistory}
          />
        )}
        <TaskHistoryModal
          task_id={task_id}
          taskHistoryData={localTaskHistory}
          updateParentState={updateTaskHistory}
        />
      </Paper>
    </Grid>
  );
};

export default TaskHistorySection;
