import React, { useEffect } from 'react';
import { NavigationObj } from '../../utils/testData/mockHierarchyData';
import { CalendarEvent } from '../types/Calendar/ICalendarIndex';
import { setMerchRecentlyViewed } from '../../api/apiDigitalMerchandising';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../utils/analytics';

export type ProtectedEmbedContainerProps = {
  contentData: NavigationObj | CalendarEvent | any;
  callback: any;
};

const protectedEmbedStyles = () => {
  return {
    fullContainer: {
      display: 'flex',
      height: window.innerWidth >= 420 ? '75vh' : '80vh',
    },
  };
};

const navigationBoxContentStyles = () => {
  return {
    navigationBoxContentBookContainer: {
      height: '100%',
      width: '100%',
    },
  };
};

const ProtectedEmbedContainer: React.FC<ProtectedEmbedContainerProps> = (
  props
) => {
  const protectEmbedClasses = protectedEmbedStyles();
  const { contentData, callback } = props;
  const navigationBoxContentClasses = navigationBoxContentStyles();

  const hId = contentData.url && contentData.url.split('/')[4];

  const runEmbeddedScripts = () => {
    const script = document.createElement('script');
    /* todo - figure out why there is an EOL error */
    script.src = `https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=${hId}`;
    // script.src = `https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=529376201`;
    script.async = true;

    document.head.appendChild(script);
    document.head.removeChild(script);

    return (
      <div
        className={'navigationBoxContentBookContainer'}
        aria-label={'navigationBoxContentBookContainer'}
        style={navigationBoxContentClasses.navigationBoxContentBookContainer}
      >
        <a
          href={`${contentData.url}`}
          className="fbo-embed"
          data-fbo-id="09eaeaffad"
          data-fbo-ratio="3:2"
          data-fbo-lightbox="yes"
          data-fbo-width="100%"
          data-fbo-height="auto"
          data-fbo-version="1"
          style={{ maxWidth: '100%' }}
        />
      </div>
    );
  };

  const setAsRecentlyViewed = async () => {
    await setMerchRecentlyViewed(contentData.code);
    if (callback !== undefined) {
      callback();
    }
    sendAnalyticsEvent(analyticsEventConstants.MERCHANDISING_FLIPPINGBOOK);
  };

  useEffect(() => {
    setAsRecentlyViewed();
  }, []);

  return (
    <div className={'fullContainer'} style={protectEmbedClasses.fullContainer}>
      {runEmbeddedScripts()}
    </div>
  );
};

export default ProtectedEmbedContainer;
