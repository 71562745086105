import { theme } from '../../../assets/siteLineTheme';

type notificationTableRowStylesProps = {
  index: number;
};

export const notificationTableRowStyles = (
  props: notificationTableRowStylesProps
) => {
  const { index } = props;
  return {
    titleContainer: {
      width: '100%',
      minHeight: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `0.75px solid ${theme.colorPalette.taskListViewBorder}`,
      borderTop: `0.75px solid ${theme.colorPalette.taskListViewBorder}`,
      backgroundColor: index % 2 === 0 ? 'cornsilk' : 'floralwhite',
    },
    title: {
      maxWidth: 'calc(100% - 92px)',
      marginLeft: '1.5em',
      wordBreak: 'break-word' as 'break-word',
      padding: '0.4em 0',
    },
  };
};
