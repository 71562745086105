import { groups } from './constants';

export const permissionSetter = (userInfo: any) => {
  const { SUPER_GABE, GABE, JAN, MICHAEL, STANLEY } = groups;

  const permissions = {
    viewTaskCalendarPage: true,
    viewPublishedTasks: true,
    viewSubmittedTask: false,
    viewReturnedTask: false,
    editSubmittedTask: false,
    editReturnedTask: false,
    editPublishedTask: false,
    deleteSubmittedTask: false,
    deleteReturnedTask: false,
    deletePublishedTask: false,
    viewTaskReviewPage: false,
    createNewTask: false,
    filterByLocation: false,
    markTaskComplete: false,
  };

  const { SiteLineGroups } = userInfo;

  switch (true) {
    case SiteLineGroups?.includes(SUPER_GABE):
      return {
        ...permissions,
        viewSubmittedTask: true,
        viewReturnedTask: true,
        editSubmittedTask: true,
        editReturnedTask: true,
        editPublishedTask: true,
        deleteSubmittedTask: true,
        deleteReturnedTask: true,
        deletePublishedTask: true,
        viewTaskReviewPage: true,
        createNewTask: true,
        filterByLocation: true,
        markTaskComplete: true,
      };
    case SiteLineGroups?.includes(GABE):
      return {
        ...permissions,
        viewSubmittedTask: true,
        viewReturnedTask: true,
        editSubmittedTask: true,
        editReturnedTask: true,
        editPublishedTask: true,
        deleteSubmittedTask: true,
        deleteReturnedTask: true,
        deletePublishedTask: true,
        createNewTask: true,
        filterByLocation: true,
      };
    case SiteLineGroups?.includes(JAN):
      return {
        ...permissions,
        filterByLocation: true,
        markTaskComplete: true,
      };
    case SiteLineGroups?.includes(MICHAEL):
      return {
        ...permissions,
        markTaskComplete: true,
      };
    case SiteLineGroups?.includes(STANLEY):
      return { ...permissions };
    default:
      return { ...permissions };
  }
};
