import { create, SetState } from 'zustand';
import { DrawerProps } from './models/DrawerProps';

export const defaultDrawerProps: DrawerProps = {
  isDrawerOpen: false,
  drawerContentType: undefined,
  drawerContentProps: undefined,
};

const drawerPropsStore = create((set: SetState<any>) => ({
  drawerProps: { ...defaultDrawerProps },
  setDrawerProps: (drawerProps: DrawerProps) => {
    set(() => ({ drawerProps }));
  },
  resetDrawerProps: () => {
    set(() => ({ drawerProps: { ...defaultDrawerProps } }));
  },
}));

export default drawerPropsStore;
