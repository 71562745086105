import React from 'react';
import { ParentSize } from '@visx/responsive';
import StackedHistogram from '../StackedHistogram';
import { theme } from '../../../assets/siteLineTheme';
import {
  bottomAxisLabelBuilder,
  histogramMarginCalculator,
} from '../../../utils/stackedHistogramUtils';
import { GroupByEnum } from '../../../stores/taskCompletionDashboardStore';
import HorizontalStackedHistogram from '../HorizontalStackedHistogram';

export type histogramMargin = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};

export type CompletionDataHistogramControllerProps = {
  data: any;
  titleString: string;
  groupBy: GroupByEnum;
  onClickBar: Function;
};

const CompletionDataHistogramController: React.FC<
  CompletionDataHistogramControllerProps
> = (props) => {
  const { data, titleString, groupBy, onClickBar } = props;

  return (
    <div
      aria-label={'CompletionDataHistogramController'}
      style={{ width: '100%' }}
    >
      <div
        className="parent-container"
        style={{ height: '75vh', width: '100%' }}
      >
        <ParentSize className="graph-container" debounceTime={10}>
          {({ width: visWidth, height: visHeight }) => {
            return visWidth > 420 ? (
              <StackedHistogram
                data={data}
                width={visWidth}
                height={visHeight}
                barColors={theme.newColorPalette.completionStatusBarChart}
                squareSize={15}
                histogramMargin={histogramMarginCalculator(visHeight, visWidth)}
                groupBy={groupBy}
                histogramTitle={titleString}
                axisBottomLabel={bottomAxisLabelBuilder(groupBy)}
                axisLeftLabel={'Number of Tasks'}
                onClickBar={onClickBar}
              />
            ) : (
              <HorizontalStackedHistogram
                data={data}
                width={visWidth}
                height={visHeight}
                barColors={theme.newColorPalette.completionStatusBarChart}
                squareSize={15}
                histogramMargin={histogramMarginCalculator(visHeight, visWidth)}
                groupBy={groupBy}
                histogramTitle={titleString}
                axisBottomLabel={'Number of Tasks'}
                axisLeftLabel={bottomAxisLabelBuilder(groupBy)}
                onClickBar={onClickBar}
              />
            );
          }}
        </ParentSize>
      </div>
    </div>
  );
};

export default CompletionDataHistogramController;
