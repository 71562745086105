// import { NavigationObj } from '../../../utils/testData/mockHierarchyData';
// import { theme } from '../../../assets/siteLineTheme';
// import colorModeStore, {
//   ColorModeTypeEnum,
// } from '../../../stores/colorModeStore';
//
// export type NavigationBoxContentStylesParams = {
//   data: NavigationObj;
//   image: string;
// };
//
// export const navigationBoxContentStyles = (
//   params: NavigationBoxContentStylesParams
// ) => {
//   const { colorMode } = colorModeStore(
//     (state: { colorMode: ColorModeTypeEnum }) => ({
//       colorMode: state.colorMode,
//     })
//   );
//   const { data, image } = params;
//   /* istanbul ignore next */
//   return {
//     navigationBoxContentContainer: {
//       color:
//         data.url === null
//           ? theme.newColorPalette.textDarkBlack[colorMode]
//           : theme.newColorPalette.textDarkBlack[colorMode],
//       backgroundColor: data.url === null ? theme.colorPalette.plainWhite : '',
//       display: 'flex',
//       height: '100%',
//       width: '50%',
//       justifyContent: 'center',
//       alignItems: 'center',
//       textAlign: 'center' as 'center',
//       fontWeight: 'bold',
//       borderRadius: '.4em',
//       cursor: 'pointer',
//       boxShadow: data.url === null ? '2px 2px 4px rgba(0, 0, 0, 0.25)' : 'none',
//     },
//     navigationBoxContentTextContainer: {
//       display: 'flex',
//       flexDirection: 'column' as 'column',
//       alignItems: 'center',
//       justifyContent: image !== '' ? 'space-evenly' : 'center',
//       width: '100%',
//       height: '100%',
//       margin: '0em auto',
//     },
//     navigationBoxContentBookContainer: {
//       display: 'flex',
//       flexDirection: 'column' as 'column',
//       textAlign: 'center' as 'center',
//       fontSize: '0.9em',
//       height: 'fit-content',
//     },
//     navigationBoxContentImage: {
//       display: 'flex',
//       width: image !== '' ? '90%' : '0',
//       height: image !== '' ? '70%' : '0',
//       backgroundImage: image !== '' ? `url("${image}")` : 'none',
//       backgroundSize: 'cover',
//       backgroundPosition: 'center',
//       backgroundRepeat: 'no-repeat' as 'no-repeat',
//       borderRadius: '.1em',
//     },
//   };
// };
import { NavigationObj } from '../../../utils/testData/mockHierarchyData';
import { theme } from '../../../assets/siteLineTheme';
import { ColorModeTypeEnum } from '../../../stores/userPreferenceStore';

export type NavigationBoxContentStylesParams = {
  data: NavigationObj;
  image: string;
  isSelected: boolean | undefined;
  isInactive: boolean;
  colorMode: ColorModeTypeEnum;
};

export const navigationBoxContentStyles = (
  params: NavigationBoxContentStylesParams
) => {
  const { data, isSelected, isInactive, colorMode } = params;
  /* istanbul ignore next */
  return {
    navigationBoxContentContainer: {
      color:
        colorMode === ColorModeTypeEnum.BlackWhite
          ? theme.colorPalette.plainBlack
          : data.url !== null && data.url.length > 0
          ? theme.colorPalette.plainWhite
          : isInactive
          ? theme.colorPalette.plainWhite
          : data.parent_code === null
          ? isSelected
            ? theme.colorPalette.plainWhite
            : '#14718a'
          : theme.colorPalette.plainWhite,
      fontSize: window.innerWidth > 420 ? '1em' : '0.95em',
      height: '100%',
      width: '100%',
      backgroundColor:
        colorMode === ColorModeTypeEnum.BlackWhite
          ? theme.colorPalette.plainWhite
          : data.url !== null && data.url.length > 0
          ? theme.colorPalette.merchandisingDepartments.Beauty
          : isInactive
          ? '#bababa'
          : data.parent_code === null
          ? isSelected
            ? '#14718a'
            : theme.colorPalette.plainWhite
          : '#00b0c7',
      opacity: isInactive ? 0.55 : 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center' as 'center',
      borderRadius: '.5em',
      cursor: isInactive ? 'default' : 'pointer',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      border:
        isInactive || isSelected || data.url || data.parent_code
          ? undefined
          : '1px solid #14718a',
    },
    navigationBoxContentTextContainer: {
      width: '99%',
      margin: '0em auto',
    },
    navigationBoxContentBookContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      textAlign: 'center' as 'center',
      fontSize: '0.85em',
      height: 'fit-content',
    },
    outlineBox: {
      border:
        !isInactive && isSelected
          ? `solid 2px ${theme.newColorPalette.snackbar.successBlack.Zoidberg}`
          : undefined,
      borderRadius: '.5em',
      height: window.innerWidth > 420 ? '85%' : '95%',
      width: window.innerWidth > 420 ? '85%' : '95%',
    },
    navigationBoxContentContainerOutline: {
      height: window.innerWidth > 420 ? '85%' : '95%',
      width: window.innerWidth > 420 ? '85%' : '95%',
    },
  };
};
