export type SelectOptionType = { label: string; value: string };
export const TaskTypeList: SelectOptionType[] = [
  { label: 'Event', value: 'Event' },
  { label: 'Loss Prevention', value: 'Loss Prevention' },
  { label: 'Marketing Promotions', value: 'Marketing Promotions' },
  { label: 'Merchandising', value: 'Merchandising' },
  { label: 'Operations', value: 'Operations' },
  { label: 'Pricing & Signing', value: 'Pricing & Signing' },
  { label: 'Sephora', value: 'Sephora' },
  { label: 'Training & Recognition', value: 'Training & Recognition' },
  { label: 'Visual', value: 'Visual' },
  { label: 'Holiday', value: 'Holiday' },
  { label: 'Risk & Compliance', value: 'Risk & Compliance' },
  { label: 'Special Projects', value: 'Special Projects' },
  { label: 'Surveys', value: 'Surveys' },
];

export const CompletionStatusList: SelectOptionType[] = [
  { label: 'Completed', value: 'Completed' },
  { label: 'Incomplete', value: 'Incomplete' },
  { label: 'Overdue', value: 'Overdue' },
];

export const GroupByList: SelectOptionType[] = [
  { label: 'Task Type', value: 'TaskType' },
  { label: 'Territory', value: 'Territory' },
  { label: 'Region', value: 'Region' },
  { label: 'District', value: 'District' },
  { label: 'Store', value: 'Store' },
];
