import { groups } from 'src/context/constants';
// todo - find way to mock localStorage in a test in order to increase coverage
export const getGroupName = (userAssignedGroups?: string[]) => {
  const { SUPER_GABE, GABE, JAN, MICHAEL, STANLEY } = groups;
  const getUserInfoObj = localStorage.getItem('userInfoObj');
  const { SiteLineGroups } = getUserInfoObj
    ? JSON.parse(getUserInfoObj)
    : { SiteLineGroups: [] };
  const userGroups = userAssignedGroups?.length
    ? userAssignedGroups
    : SiteLineGroups;
  if (userGroups?.length) {
    switch (true) {
      case userGroups?.includes(SUPER_GABE):
        return SUPER_GABE;
      case userGroups?.includes(GABE):
        return GABE;
      case userGroups?.includes(JAN):
        return JAN;
      case userGroups?.includes(MICHAEL):
        return MICHAEL;
      case userGroups?.includes(STANLEY):
        return STANLEY;
      default:
        return STANLEY;
    }
  }
  return null;
};
