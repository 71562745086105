import { create, SetState } from 'zustand';

// import { getTasksWithAppliedFilters } from '../api/apiV1Task';
// import {
//   earliestDate,
//   easyPrintDataSculptor,
//   heatmapDataSculptor,
//   latestDate,
//   singleMonthDataSculptor,
// } from '../utils/taskDataFormatter';

export type FormattedTaskDataStoreProps = {
  readyForEasyPrintMonthData: Array<any>;
};

export const initialFormattedTaskDataStoreValues: FormattedTaskDataStoreProps =
  {
    readyForEasyPrintMonthData: [],
  };

const formattedTaskDataStore = create((set: SetState<any>) => ({
  ...initialFormattedTaskDataStoreValues,
  // todo -spike on using api call on zustand store
  // getRawTaskData: async (
  //   queryString: string,
  //   selectedMonth: number,
  //   selectedYear: number
  // ) => {
  //   await getTasksWithAppliedFilters(queryString)
  //     .then((response) => {
  //       set({ rawTaskData: response });
  //       set({ formattedTaskData: heatmapDataSculptor(response) });
  //       set({ earliestDate: earliestDate(response) });
  //       set({ latestDate: latestDate(response) });
  //       set({
  //         singleMonthData: singleMonthDataSculptor(
  //           heatmapDataSculptor(response),
  //           selectedMonth,
  //           selectedYear
  //         ),
  //       });
  //       set({readyForEasyPrintMonthData: easyPrintDataSculptor(response, selectedMonth, selectedYear)})
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // },
  // setSingleMonthData: (singleMonthData: any) => {
  //   set({ singleMonthData: singleMonthData });
  // },
  setReadyForEasyPrintMonthData: (sculptedEasyPrintData: any) => {
    set({ readyForEasyPrintMonthData: sculptedEasyPrintData });
  },
}));

export default formattedTaskDataStore;
