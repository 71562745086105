import { theme } from '../../../assets/siteLineTheme';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

export type DigitalMerchandisingCreateStylesParams = {
  level: number;
  isActive: boolean;
  colorMode: ColorModeTypeEnum;
};

export const digitalMerchandisingCreateStyles = (params: {
  level: number;
  colorMode: ColorModeTypeEnum;
  isActive: boolean;
}) => {
  const { level, isActive, colorMode } = params;
  return {
    menuItemContainer: {
      display: 'flex',
      alignContent: 'center',
      marginBottom: '.25em',
      fontSize: '1.2em',
      marginLeft: `calc((.85em) * ${level})`,
      color: isActive
        ? theme.newColorPalette.plainBlackWhite[colorMode]
        : theme.colorPalette.plainBlack,
      backgroundColor: isActive
        ? theme.newColorPalette.buttons.commentLightSubmitDarkGrey[colorMode]
        : undefined,
      cursor: 'pointer',
      minHeight: '1em',
    },
    icon: {
      size: '1.25em',
    },
    iconContainer: {
      marginRight: '.25em',
    },
  };
};

export type SectionContainerStylesParams = {};

export const sectionContainerStyles = () => {
  return {
    sectionsContainer: {},
    containerBox: {},
  };
};
