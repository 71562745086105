import React from 'react';
import { OrientationEnum } from '../Toolbar/CalendarToolbarV2';

export type GroupedSelectionButtonsV2Props = {
  label: string;
  GroupedSelectionButtonArray: any;
  buttonOrientation: OrientationEnum;
};

const GroupedSelectionButtonsV2: React.FC<GroupedSelectionButtonsV2Props> = (
  props
) => {
  const { label, buttonOrientation, GroupedSelectionButtonArray } = props;

  return (
    <div
      aria-label={`'grouped selections for ${label}`}
      style={{
        display: 'flex',
        flexDirection:
          buttonOrientation === OrientationEnum.Row
            ? ('row' as 'row')
            : ('column' as 'column'),
      }}
    >
      {GroupedSelectionButtonArray.map(
        (SelectionButton: any, index: number) => {
          return <div key={index}>{SelectionButton}</div>;
        }
      )}
    </div>
  );
};

export default GroupedSelectionButtonsV2;
