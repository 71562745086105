import { theme } from '../../../assets/siteLineTheme';
import { TaskStoreCompletionStatus } from '../../../models/TaskStoreCompletionStatus';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

type viewTaskStylesParams = {
  colorMode: ColorModeTypeEnum;
  task_type?: string;
  completion_status?: string;
  single_day_task?: boolean;
};

export const ViewTaskStyles = (params: viewTaskStylesParams) => {
  const { task_type, completion_status, single_day_task, colorMode } = params;

  return {
    root: {
      width: '100%',
    },
    drawerHeaderContainer: {
      width: '100%',
      position: 'relative',
    },
    displayDateContainer: {
      display: 'flex',
      flexDirection: 'column-reverse' as 'column-reverse',
      alignItems: 'flex-start',
    },
    closeIcon: {
      position: 'absolute',
    },
    titleHeader: {
      fontSize: '.85em',
      padding: '6px 0px',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    titleText: {
      paddingLeft: '10px',
      fontSize: '1.05em',
      fontWeight: 'bold',
      minWidth: single_day_task ? '6em' : '12em',
      width: 'fit-content',
      color: 'rgba(0, 0, 0, 0.54)'
    },
    storeTitleText: {
      paddingLeft: '10px',
      fontSize: '1.05em',
      fontWeight: 'bold',
      minWidth: single_day_task ? '6em' : '12em',
      width: 'fit-content',
      color: 'rgb(63, 81, 181)',
    },
    taskTypeChip: {
      backgroundColor: task_type
        ? colorMode !== ColorModeTypeEnum.BlackWhite
          ? theme.colorPalette.taskTypes.dark[task_type]
          : theme.colorPalette.plainBlack
        : 'orange',
      width: 'fit-content',
      borderRadius: '20px',
      color: theme.colorPalette.plainWhite,
      padding: '4px 8px',
      margin: '4px 0 4px 10px',
    },
    completionStatusChip: {
      display: 'flex',
      backgroundColor:
        completion_status === TaskStoreCompletionStatus.Completed
          ? theme.newColorPalette.completionStatus.completedBlack[colorMode]
          : theme.colorPalette.completionStatus.incomplete,
      width: 'fit-content',
      borderRadius: '20px',
      color: theme.colorPalette.plainWhite,
      padding: '4px 8px',
      margin: '4px 0 4px 10px',
    },
    taskDescContainer: {
      paddingTop: '5px',
    },
    taskCompletionChipText: {
      paddingRight: '5px',
    },

    scheduledDatePickerContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column' as 'column',
      width: '100%',
    },
    selectNewDatesButtonContainer: {
      display: 'flex',
      width: '100%',
    },
    selectNewDatesButtonRow: {
      display: 'flex',
      flexDirection: 'row' as 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  };
};
