import { theme } from '../../assets/siteLineTheme';

export type LetterIconStyleParams = {
  backgroundColor?: string;
};

export const letterIconStyle = (params: LetterIconStyleParams): any => {
  /* istanbul ignore next */
  const { backgroundColor } = params;
  return {
    text: {
      fontsize: '0.8em',
      fontFamily: 'Roboto-Light',
      fontWeight: '700',
      color: theme.colorPalette.plainWhite,
    },
    letterIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor:
        backgroundColor !== undefined
          ? backgroundColor
          : theme.colorPalette.dark,
      borderRadius: '50%',
      padding: '0.3em',
      border: `2px solid ${theme.colorPalette.plainWhite}`,
      cursor: 'pointer',
    },
  };
};
