export const dateSelectorStyles = () => {
  return {
    dateSelectorContainer: {
      marginLeft: '0.5em',
    },
    dateSelectorTextContainer: {
      display: 'flex',
      margin: '0.3em 0',
    },
  };
};
