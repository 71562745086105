import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../utils/analytics';
import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography, Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TaskFilterButton from '../Drawers/FilterDrawer/TaskFilterButton';
import FilterChipDisplay from '../Calendar/FilterChipDisplay';
import { theme } from '../../assets/siteLineTheme';
import CalendarCompletionStatusButtonGroup from '../Buttons/CalendarCompletionStatusButtonGroup';
import appliedFiltersStore from '../../stores/appliedFiltersStore';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import GenericButton from '../GenericButton/GenericButton';
import {ButtonEnum} from '../GenericButton/genericButttonUtils/ButtonEnum';
import {ViewType} from '../types/Calendar/ICalendarIndex';
import {viewOptions} from './toolbarConstants';
import {removeTime} from '../../utils/dateUtils';
import {BoxStyles} from "../Forms/styles/boxStyles";
import {ButtonStyles} from "../Forms/styles/buttonStyles";
import {ToolbarStyles} from "./styles/toolbarStyles";

export type ViewOptionsProps = {
  key: string;
  value: string;
  appStateSelector?: undefined;
  clickHandler: Function;
};

export type RoundedButtonProps = {
  displayText: string;
  labelText: string;
  appStateSelector?: undefined;
  clickHandler: Function;
};

export type ToolbarProps = {
  label: string;
  onNavigate: Function;
  viewChangeHandler: Function;
  view: ViewType;
  yearView: boolean;
  setRowOrder: Function;
  setRowOrderForceRerender: React.Dispatch<React.SetStateAction<boolean>>;
  rowOrderForceRerender: boolean;
  date: Date;
};

export const CalendarToolbar: React.FC<ToolbarProps> = (props) => {
  const {
    label,
    onNavigate,
    viewChangeHandler,
    view,
    yearView,
    setRowOrder,
    setRowOrderForceRerender,
    rowOrderForceRerender,
    date,
  } = props;

  let fullMonthName;
  if (label !== undefined) {
    const labelSplit = label.split(' ');
    if (labelSplit.length === 3) {
      // Monday Nov 21
      fullMonthName =
          labelSplit[0] +
          ' ' +
          labelSplit[1].substring(0, 3) +
          ' ' +
          labelSplit[2];
    } else if (labelSplit.length > 4) {
      // Nov 21 - Dec 2
      fullMonthName =
          labelSplit[0].substring(0, 3) +
          ' ' +
          labelSplit[1] +
          ' – ' +
          labelSplit[3].substring(0, 3) +
          ' ' +
          labelSplit[4];
    } else if (labelSplit.length === 4) {
      // Nov 21 - 26
      fullMonthName =
          labelSplit[0].substring(0, 3) +
          ' ' +
          labelSplit[1] +
          ' – ' +
          labelSplit[3];
    } else {
      // Nov 2022
      fullMonthName = labelSplit[0].substring(0, 3) + ' ' + labelSplit[1];
    }
  }

  const {userPreference} = userPreferenceStore(
      (state: { userPreference: UserPreferenceStoreState }) => ({
        userPreference: state.userPreference,
      })
  );

  const {appliedFilters, setAppliedFilters} = appliedFiltersStore(
      (state: { appliedFilters: any; setAppliedFilters: any }) => ({
        appliedFilters: state.appliedFilters,
        setAppliedFilters: state.setAppliedFilters,
      })
  );

  const [currDate, setCurrDate] = useState<string | undefined>(undefined);
  const [currView, setCurrView] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (
        currDate === undefined ||
        removeTime(date).toLocaleString() !== currDate ||
        view !== currView
    ) {
      setCurrDate(removeTime(date).toLocaleString());
      setCurrView(view);

      if (
          removeTime(date).toLocaleString() !== appliedFilters.current_date ||
          view !== appliedFilters.view_type
      ) {
        setAppliedFilters({
          ...appliedFilters,
          current_date: removeTime(date).toLocaleString(),
          view_type: view,
        });
      }
    }
  }, [date, view]);

  const handleForward = (next: string) => {
    sendAnalyticsEvent(analyticsEventConstants.CALENDAR_FORWARD);
    onNavigate(next);
    setRowOrder();
    setRowOrderForceRerender(!rowOrderForceRerender);
  };

  const handleBack = (prev: string) => {
    sendAnalyticsEvent(analyticsEventConstants.CALENDAR_BACK);
    onNavigate(prev);
    setRowOrder();
    setRowOrderForceRerender(!rowOrderForceRerender);
  };

  const handleToday = (today: string) => {
    sendAnalyticsEvent(analyticsEventConstants.CALENDAR_TODAY);
    onNavigate(today);
  };

  return (
    <Box role="toolbar-component" sx={BoxStyles(userPreference.colorMode).calendarToolBarComponent}>
      <Box sx={{width: '100%'}}>
        <Box sx={BoxStyles(userPreference.colorMode).calendarToolBarViewOptionsUl}>
          <Box sx={BoxStyles(userPreference.colorMode).calendarHalfFullMobile}>
            <Box sx={{marginRight: '1.5em',}}>
              <GenericButton
                variant={ButtonEnum.Filled}
                buttonTextColor={
                  theme.newColorPalette.zoidbergBlack[userPreference.colorMode]
                }
                clickCallback={() => handleToday('TODAY')}
              >
                TODAY
              </GenericButton>
            </Box>
            <Box sx={{display: 'flex', marginRight: '1em',}}>
              <ArrowBackIosIcon
                aria-label={'Previous'}
                onClick={() => handleBack('PREV')}
                  sx={ToolbarStyles(userPreference.colorMode).navArrow}
              />
              <ArrowForwardIosIcon
                aria-label={'Next'}
                onClick={() => handleForward('NEXT')}
                sx={ToolbarStyles(userPreference.colorMode).navArrow}
              />
            </Box>
            {!yearView && (
              <Box sx={BoxStyles(userPreference.colorMode).calendarToolBarNavigator}>
                <Typography
                  role="article"
                  style={ToolbarStyles(userPreference.colorMode).dateRangeLabel}
                  variant={'h6'}
                >
                  {fullMonthName}
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={BoxStyles(userPreference.colorMode).calendarHalfFullMobile}>
            {viewOptions.map((obj: ViewOptionsProps, index: number) => {
              return (
                <Box
                  key={index}
                  sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start',}}
                >
                  <Box>
                    <Button
                      data-testid={`button-${obj.key.toLowerCase()}`}
                      sx={obj.value === view ? ButtonStyles(userPreference.colorMode).calendarSelectedToolBarViewButton : ButtonStyles(userPreference.colorMode).calendarToolbarViewButton}
                      onClick={() => viewChangeHandler(obj)}
                    >
                      {obj.key}
                    </Button>
                  </Box>
                  {viewOptions.length - 1 !== index && (
                    <Box sx={{display: 'block', width: '2em',}}>
                      <Divider
                        orientation="vertical"
                        sx={{
                          backgroundColor: theme.newColorPalette.plainWhiteBlack[userPreference.colorMode],
                          margin: '0px auto 6px auto',
                          border: theme.colorPalette.light,
                          height: '1.05em',
                          width: '2px',
                        }}
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box sx={BoxStyles(userPreference.colorMode).calendarRightSection}>
        {appliedFilters?.store_number && (
          <CalendarCompletionStatusButtonGroup />
        )}
        {window.innerWidth > 421 && <FilterChipDisplay />}
        <Box sx={{display: 'flex', alignItems: 'center',}}>
          <TaskFilterButton />
        </Box>
      </Box>
    </Box>
  );
};
