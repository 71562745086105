export const attachmentsStyles = () => {
  return {
    root: {
      padding: '0 0 0 15px',
      color: '#3f51b5',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    listItemIconRoot: {
      minWidth: 35,
    },
  };
};
