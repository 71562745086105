import {ColorModeTypeEnum} from "../../../stores/userPreferenceStore";
import muiTheme from "../../../utils/mui/muiTheme";
import {theme} from "../../../assets/siteLineTheme";

export const PaperStyles = (colorMode: ColorModeTypeEnum) => {
    return {
        basicPaper: {
            padding: muiTheme.spacing(2),
            margin: '0 1em 1em 1em',
            color: 'rgba(0, 0, 0, 0.6)',
            background: theme.colorPalette.plainWhite,
        },
        headerPaper: {
            color: 'rgba(0, 0, 0, 0.6)',
            background: theme.colorPalette.plainWhite,
            padding: muiTheme.spacing(1),
        },
        filterSectionContainer: {
            display: 'flex',
            'flex-direction': 'column',
            margin: '1em',
            padding: '1em'
        },
        paperFileUpload: {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            padding: '10px',
            margin: '10px 0',
            background: 'rgba(0, 0, 0, 0.08)',
        },
    }

}