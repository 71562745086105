import {
  Snackbar,
  Box,
  Typography,
  SnackbarContent,
  IconButton,
  LinearProgress
} from '@mui/material';
import React, { useContext } from 'react';
import { theme } from '../../assets/siteLineTheme';
import { AppContext } from '../../context/AppContext';
import { Cancel } from '@mui/icons-material';
import snackbarPropsStore from '../../stores/snackbarPropsStore';
import muiTheme from 'src/utils/mui/muiTheme';

const useStyles = (colorMode: boolean) => {
    return {
      root: {
        width: '30vw',
        [muiTheme.breakpoints.down('sm')]: {
          width: '90vw',
          margin: '0px auto',
        },
      },
      success: {
        backgroundColor: colorMode
          ? theme.colorPalette.snackbar.success
          : theme.colorPalette.plainWhite,
      },
      error: {
        backgroundColor: colorMode
          ? theme.colorPalette.snackbar.error
          : theme.colorPalette.plainWhite,
      },
      loading: {
        backgroundColor: colorMode
          ? theme.colorPalette.snackbar.loading
          : theme.colorPalette.plainWhite,
      },
      closeIcon: {
        color: colorMode
          ? theme.colorPalette.plainWhite
          : theme.colorPalette.plainBlack,
        position: 'absolute',
        top: '3px',
        right: '3px',
      },
      snackbarContentContainer: {
        display: 'block',
        width: '100%',
        position: 'relative',
      },
      snackbarMessageContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },
      snackbarTitle: {
        fontWeight: 'bold',
      },
      loaderContainer: {
        paddingTop: '10px',
        width: '100%',
      },
    }
  }

export type SnackBarPropsProps = {
  success: {
    title: string;
    message: string;
  };
  error: {
    title: string;
    message: string;
  };
  loading?: {
    title: string;
    message: string;
  };
};

const SnackbarComponent: React.FC = () => {
  const { appState } = useContext(AppContext);
  const { colorMode } = appState;

  const { snackbarProps, setSnackbarProps } = snackbarPropsStore(
    (state: { snackbarProps: any; setSnackbarProps: any }) => ({
      snackbarProps: state.snackbarProps,
      setSnackbarProps: state.setSnackbarProps,
    })
  );

  const classes = useStyles(colorMode);
  const { actionStatus, isSnackbarVisible, correlationId } = snackbarProps;

  const snackbarTypeProps = {
    success: {
      title: 'Success!',
      message: 'Woohoo, We did it!',
    },
    error: {
      title: 'Error',
      message: !correlationId
        ? 'Uh oh, we goofed! Try Again.'
        : `Please include this Error ID when submitting feedback or opening an INC: ${correlationId}`,
    },
    loading: {
      title: 'Loading...',
      message: 'Your request is processing.. wait a second, please..',
    },
  };

  return (
    isSnackbarVisible && (
      <Snackbar
        sx={classes.root }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackbarVisible}
        autoHideDuration={
          actionStatus === 'loading'
            ? undefined
            : actionStatus === 'error'
            ? undefined
            : 3000
        }
        ClickAwayListenerProps={
          actionStatus === 'error' ? { onClickAway: () => null } : undefined
        }
        onClose={() =>
          setSnackbarProps({
            actionStatus: '',
            isSnackbarVisible: false,
            correlationId: undefined,
          })
        }
      >
        <SnackbarContent
          aria-describedby="Snackbar Content"
          sx={{...classes[actionStatus], ...classes.snackbarContentContainer}}
          message={
            <Box sx={classes.snackbarMessageContainer}>
              <Box>
                <Typography sx={classes.snackbarTitle}>
                  {snackbarTypeProps[actionStatus].title}
                </Typography>
                <Typography>
                  {snackbarTypeProps[actionStatus].message}
                </Typography>
              </Box>
              {actionStatus === 'loading' && (
                <Box sx={classes.loaderContainer}>
                  <LinearProgress />
                </Box>
              )}
              {actionStatus !== 'loading' && (
                <IconButton
                  role="close"
                  aria-label="Close Snackbar Button"
                  onClick={() =>
                    setSnackbarProps({
                      actionStatus: '',
                      isSnackbarVisible: false,
                    })
                  }
                  sx={classes.closeIcon}
                >
                  <Cancel />
                </IconButton>
              )}
            </Box>
          }
        />
      </Snackbar>
    )
  );
};

export default SnackbarComponent;
