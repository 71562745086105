import { CompletionStatusProps } from '../types/Calendar/ICalendarIndex';
import DistrictStoreNumberList from './DistrictStoreNumberList';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { theme } from '../../assets/siteLineTheme';
import React, { useEffect, useState } from 'react';
import { getStoreLocationInformationByRegionAndTaskID } from '../../api/apiV1Task';
import { StoreLocationInformationProps } from '../../models/StoreLocationInformation';
import appliedFiltersStore from '../../stores/appliedFiltersStore';
import { storeNumberAccordionStyles } from './storeNumberAccordionStyles';
import { IconButton } from '../Buttons/IconButton';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import baseModalStore from '../../stores/baseModalStore';
import commentsModalContentStore from '../../stores/commentsModalContentStore';
import { DialogModalSwitchEnum } from '../Modal/DialogModalSwitch';
import {
  analyticsEventConstants,
  sendAnalyticsEvent,
} from '../../utils/analytics';
import { useHistory } from 'react-router-dom';
import RegionStoreNumberList from "./RegionStoreNumberList";

const useStyles = (isPrintView?: boolean) => {
    return {
      accordionRoot: {
        paddingLeft: '1em',
      },
      accordionDetails: {
        paddingBottom: '.5em',
        paddingTop: '0em',

        display: 'flex',
        flexDirection: 'column',
      },
      accordionDetailsList: {
        padding: '0px',
        maxHeight: isPrintView ? 'fit-content' : '400px',
        overflowY: isPrintView ? undefined : 'scroll',
      },
      accordionListItem: {
        fontSize: '.85em',
        paddingBottom: '0em',
        paddingTop: '0em',
      },
      accordionDetailsTitle: {
        fontSize: '.85em',
      },
      accordionSummaryText: {
        margin: 'auto 0px',
      },
      accordionSummaryRoot: {
        height: 'fit-content',
      },
      completionStatusTextSpan: {
        color: theme.colorPalette.completionStatus.completed,
        fontStyle: 'italic',
      },
      overDueStatusRestSpan: {
        color: theme.colorPalette.completionStatus.overdue,
        fontStyle: 'italic',
      },
      commentIconAndCountContainer: {
        display: 'flex',
      },
      commentCountText: {
        fontSize: '.85em',
        marginLeft: '.3em',
        color: theme.colorPalette.backgroundDark,
      },
    }
  }

export type StoreNumberAccordionProps = {
  taskId: string;
  isPrintView?: boolean;
  completionStatuses?: CompletionStatusProps[];
};

const StoreNumberAccordion: React.FC<StoreNumberAccordionProps> = (props) => {
  const { appliedFilters } = appliedFiltersStore(
    (state: { appliedFilters: any }) => ({
      appliedFilters: state.appliedFilters,
    })
  );
  const { setBaseModalContent } = baseModalStore(
    (state: { setBaseModalContent: Function }) => ({
      setBaseModalContent: state.setBaseModalContent,
    })
  );

  const { setCommentsModalContent } = commentsModalContentStore(
    (state: { setCommentsModalContent: Function }) => ({
      setCommentsModalContent: state.setCommentsModalContent,
    })
  );

  const { taskId, isPrintView, completionStatuses } = props;
  const [storeNumbersAndNamesForRegion, setStoreNumbersAndNamesForRegion] =
    useState<StoreLocationInformationProps[]>([]);
  const classes = props.isPrintView
    ? useStyles(props.isPrintView)
    : useStyles();

  const storeNumberAccordionClasses = storeNumberAccordionStyles();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        let storeLocationInformation = [];
        if (appliedFilters.district_number) {
          storeLocationInformation =
            await getStoreLocationInformationByRegionAndTaskID(
              appliedFilters.district_number,
              taskId,
              'district'
            );
          if (storeLocationInformation.length > 0)
            setStoreNumbersAndNamesForRegion(storeLocationInformation);
        } else if (appliedFilters.region_number) {
          storeLocationInformation =
              await getStoreLocationInformationByRegionAndTaskID(
                  appliedFilters.region_number,
                  taskId,
                  'region'
              );
          if (storeLocationInformation.length > 0)
            setStoreNumbersAndNamesForRegion(storeLocationInformation);
        } else if (appliedFilters.territory_number) {
          storeLocationInformation =
            await getStoreLocationInformationByRegionAndTaskID(
              appliedFilters.territory_number,
              taskId,
              'territory'
            );
          if (storeLocationInformation.length > 0)
            setStoreNumbersAndNamesForRegion(storeLocationInformation);
        }
      } catch (error) {
        console.error(error);
        history.replace('/error');
      }
    })();
  }, []);

  const renderAccordionDetails = () => {
    let districtBucket = {};
    let regionBucket = {};
    if (appliedFilters.region_number) {
      districtBucket = storeNumbersAndNamesForRegion.reduce(
          (acc: any, location: StoreLocationInformationProps) => {
            acc[location.district_number] = acc[location.district_number]
                ? [...acc[location.district_number], location]
                : [location];
            return acc;
          },
          {}
      );
    } else if (appliedFilters.territory_number) {
      regionBucket = storeNumbersAndNamesForRegion.reduce(
        (acc: any, location: StoreLocationInformationProps) => {
          acc[location.region_number] = acc[location.region_number]
            ? [...acc[location.region_number], location]
            : [location];
          return acc;
        },
        {}
      );
    }
    return appliedFilters.district_number &&
      storeNumbersAndNamesForRegion.length ? (
      <>
        <div
          style={storeNumberAccordionClasses.districtAccordionHeaderContainer}
        >
          <Typography sx={classes.accordionSummaryText}>
            {`District ${appliedFilters.district_number} - ${storeNumbersAndNamesForRegion[0].district_name}`}
          </Typography>
          {storeNumbersAndNamesForRegion[0].comment_count_by_district !==
            undefined &&
            storeNumbersAndNamesForRegion[0].comment_count_by_district > 0 && (
              <div style={classes.commentIconAndCountContainer}>
                <IconButton
                  icon={IconEnum.ViewComments}
                  label={'Open comments modal'}
                  onClick={() => {
                    sendAnalyticsEvent(
                      analyticsEventConstants.VIEW_COMMENTS_FROM_DISTRICT_DROPDOWN
                    );
                    setCommentsModalContent({
                      task_id: taskId,
                      district_number: appliedFilters.district_number,
                      district_name:
                        storeNumbersAndNamesForRegion[0].district_name,
                    });
                    setBaseModalContent({
                      isActive: true,
                      dialogModalSwitchEnum:
                        DialogModalSwitchEnum.StoreComment,
                      width: window.innerWidth > 420 ? 0.25 : 0.8,
                      height: window.innerWidth > 420 ? 0.45 : 0.85,
                    });
                  }}
                />
                <Typography sx={classes.commentCountText}>
                  {` ${
                    storeNumbersAndNamesForRegion[0].comment_count_by_district
                      ? `(${storeNumbersAndNamesForRegion[0].comment_count_by_district})`
                      : ''
                  }`}
                </Typography>
              </div>
            )}
        </div>
        <DistrictStoreNumberList
          isPrintView={isPrintView}
          completionStatuses={completionStatuses}
          storeNumbersAndNamesForDistrict={storeNumbersAndNamesForRegion}
        />
      </>
    ) : appliedFilters.region_number && storeNumbersAndNamesForRegion.length ? (
        Object.keys(districtBucket).map((districtNumber) => {
          return (
              <Accordion key={districtNumber} sx={classes.accordionRoot}>
                <AccordionSummary
                    sx={classes.accordionSummaryRoot}
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                  <div
                      style={
                        storeNumberAccordionClasses.districtAccordionHeaderContainer
                      }
                  >
                    <Typography sx={classes.accordionSummaryText}>
                      {`District ${districtNumber} - ${districtBucket[districtNumber][0].district_name}`}
                    </Typography>
                    {districtBucket[districtNumber][0].comment_count_by_district !==
                        undefined &&
                        districtBucket[districtNumber][0].comment_count_by_district >
                        0 && (
                            <div style={classes.commentIconAndCountContainer}>
                              <IconButton
                                  icon={IconEnum.ViewComments}
                                  label={'Open comments modal'}
                                  onClick={() => {
                                    sendAnalyticsEvent(
                                        analyticsEventConstants.VIEW_COMMENTS_FROM_REGION_DROPDOWN
                                    );
                                    setCommentsModalContent({
                                      task_id: taskId,
                                      location_type: 'district',
                                      location_number:
                                      districtBucket[districtNumber][0].district_number,
                                      location_name:
                                      districtBucket[districtNumber][0].district_name,
                                    });
                                    setBaseModalContent({
                                      isActive: true,
                                      dialogModalSwitchEnum:
                                      DialogModalSwitchEnum.StoreComment,
                                      width: window.innerWidth > 420 ? 0.25 : 0.8,
                                      height: window.innerWidth > 420 ? 0.45 : 0.85,
                                    });
                                  }}
                              />
                              <Typography sx={classes.commentCountText}>
                                {`${
                                    districtBucket[districtNumber][0]
                                        .comment_count_by_district
                                        ? `(${districtBucket[districtNumber][0].comment_count_by_district})`
                                        : ''
                                }`}
                              </Typography>
                            </div>
                        )}
                  </div>
                </AccordionSummary>

                <AccordionDetails>
                  <DistrictStoreNumberList
                      isPrintView={isPrintView}
                      completionStatuses={completionStatuses}
                      storeNumbersAndNamesForDistrict={districtBucket[districtNumber]}
                  />
                </AccordionDetails>
              </Accordion>
          );
        })
    ) : ( /** for Territory **/
      Object.keys(regionBucket).map((regionNumber) => {
        return (
          <Accordion key={regionNumber} sx={classes.accordionRoot}>
            <AccordionSummary
              sx={classes.accordionSummaryRoot}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div
                style={
                  storeNumberAccordionClasses.districtAccordionHeaderContainer
                }
              >
                <Typography sx={classes.accordionSummaryText}>
                  {`Region ${regionNumber} - ${regionBucket[regionNumber][0].region_name}`}
                </Typography>
                {regionBucket[regionNumber][0].comment_count_by_region !==
                  undefined &&
                    regionBucket[regionNumber][0].comment_count_by_region >
                    0 && (
                    <div style={classes.commentIconAndCountContainer}>
                      <IconButton
                        icon={IconEnum.ViewComments}
                        label={'Open comments modal'}
                        onClick={() => {
                          sendAnalyticsEvent(
                            analyticsEventConstants.VIEW_COMMENTS_FROM_TERRITORY_DROPDOWN
                          );
                          setCommentsModalContent({
                            task_id: taskId,
                            location_type: 'region',
                            location_number:
                            regionBucket[regionNumber][0].region_number,
                            location_name:
                            regionBucket[regionNumber][0].region_name
                          });
                          setBaseModalContent({
                            isActive: true,
                            dialogModalSwitchEnum:
                              DialogModalSwitchEnum.StoreComment,
                            width: window.innerWidth > 420 ? 0.25 : 0.8,
                            height: window.innerWidth > 420 ? 0.45 : 0.85,
                          });
                        }}
                      />
                      <Typography sx={classes.commentCountText}>
                        {`${
                            regionBucket[regionNumber][0]
                            .comment_count_by_region
                            ? `(${regionBucket[regionNumber][0].comment_count_by_region})`
                            : ''
                        }`}
                      </Typography>
                    </div>
                  )}
              </div>
            </AccordionSummary>

            <AccordionDetails>
              <RegionStoreNumberList
                isPrintView={isPrintView}
                completionStatuses={completionStatuses}
                storeNumbersAndNamesForRegion={regionBucket[regionNumber]}
                setCommentsModalContent={setCommentsModalContent}
                setBaseModalContent={setBaseModalContent}
                taskId={taskId}
              />
            </AccordionDetails>
          </Accordion>
        );
      })
    );
  };

  return (
    <Accordion
      sx={classes.accordionRoot}
      expanded={props.isPrintView && true}
    >
      <AccordionSummary
        sx={classes.accordionSummaryRoot}
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={classes.accordionSummaryText}>
          {`(${storeNumbersAndNamesForRegion.length}) Stores in Filtered ${
            appliedFilters.district_number ? 'District' : appliedFilters.region_number ? 'Region' : 'Territory'
          } Assigned to this Task`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={classes.accordionDetails}>
        {renderAccordionDetails()}
      </AccordionDetails>
    </Accordion>
  );
};

export default StoreNumberAccordion;
