import { theme } from '../../../assets/siteLineTheme';
import { ColorModeTypeEnum } from '../../../stores/userPreferenceStore';

export type pdfNavigationBoxContentParams = {
  colorMode: ColorModeTypeEnum;
  type: string;
};

export const pdfNavigationContentStyles = (
  params: pdfNavigationBoxContentParams
) => {
  const { type, colorMode } = params;
  return {
    contentContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column' as 'column',
      height: '100%',
      textAlign: 'center' as 'center',
      cursor: 'pointer',
      borderRadius: '.5em',
      margin: '0 auto',
      backgroundColor:
        type === 'video/mp4'
          ? theme.newColorPalette.videoPage[colorMode]
          : theme.newColorPalette.pdfPage[colorMode],
    },
    iconBackground: {
      borderRadius: '100%',
      backgroundColor: theme.colorPalette.textDark,
      opacity: 0.8,
      padding: '.5em',
      margin: '.75em',
      display: 'flex',
      alignItems: 'center',
    },
    outlineBox: {
      height: window.innerWidth > 420 ? '85%' : '95%',
      width: window.innerWidth > 420 ? '85%' : '95%',
    },
    pdfContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    icon: {
      color: theme.newColorPalette.plainWhiteBlack[colorMode],
      size: '2.2em',
      minSize: '2.2em',
    },
    titleText: {
      color: theme.newColorPalette.plainWhiteBlack[colorMode],
      marginLeft: '.5em',
      fontSize: window.innerWidth > 420 ? '1.2em' : '0.8em',
      wordBreak: 'break-word' as 'break-word',
      width: '80%',
    },
  };
};
