import React from 'react';
import { BoxContentTypeEnum } from '../../BoxArray/BoxArray';
import NavigationBoxContent from '../NavigationBoxContent/NavigationBoxContent';
import TaskListViewContent from '../TaskListViewContent/TaskListViewContent';
import { TaskApiResponse } from '../../../models/apiV1TaskResponses';
import {
  DigitalMerchandisingLinkTypeEnum,
  NavigationObj,
} from '../../../utils/testData/mockHierarchyData';
import BreadcrumbBoxContent from '../BreadcrumbBoxContent/BreadcrumbBoxContent';
import RecentlyViewedBoxContent from '../RecentlyViewedBoxContent/RecentlyViewedBoxContent';
import GSiteNavigationBoxContent from '../GSiteNavigationBoxContent/GSiteNavigationBoxContent';
import { IconContext } from 'react-icons';
import IconSelector from '../../Chip/IconSelector';
import { IconEnum } from '../../Chip/chipUtils/ChipEnums';
import { boxContentSwitchStyles } from './boxContentSwitchStyles';
import PDFNavigationBoxContent from '../PDFNavigationBoxContent/PDFNavigationBoxContent';
import {TableRowTypeEnum} from "../../../utils/enum/TableRowTypeEnum";

export type BoxContentSwitchProps = {
  contentData: TaskApiResponse | NavigationObj | any;
  boxContentType: BoxContentTypeEnum | undefined;
  isLastElementInArray?: boolean | undefined;
  onClick?: Function | undefined;
  callBack?: Function | undefined;
  resetList?: Function | undefined;
  boxHeight?: string | undefined;
  isSelected?: boolean | undefined;
  fromDirectUrl?: { fromDirectUrl: boolean; url: string } | undefined;
};

const BoxContentSwitch: React.FC<BoxContentSwitchProps> = (props) => {
  const {
    contentData,
    boxContentType,
    isLastElementInArray,
    onClick,
    callBack,
    resetList,
    boxHeight,
    isSelected,
    fromDirectUrl,
  } = props;

  const boxContentSwitchClasses = boxContentSwitchStyles();

  switch (boxContentType) {
    case BoxContentTypeEnum.NavigationBoxContent:
      if (
        contentData.link_type === DigitalMerchandisingLinkTypeEnum.Flipping ||
        contentData.link_type === null
      ) {
        return (
          <NavigationBoxContent
            contentData={contentData}
            onClick={!contentData.is_active ? () => null : onClick}
            boxHeight={boxHeight}
            isSelected={isSelected}
            callBack={callBack}
            fromDirectUrl={fromDirectUrl}
          />
        );
      } else if (
        contentData.link_type === DigitalMerchandisingLinkTypeEnum.GSite
      ) {
        return (
          <GSiteNavigationBoxContent
            contentData={contentData}
            onClick={onClick}
            boxHeight={boxHeight}
          />
        );
      } else {
        return (
          <PDFNavigationBoxContent
            contentData={contentData}
            onClick={onClick}
          />
        );
      }

    case BoxContentTypeEnum.RecentlyViewedBoxContent:
      return (
        <RecentlyViewedBoxContent
          contentData={contentData}
          onClick={onClick}
          boxHeight={boxHeight}
          callBack={callBack}
        />
      );
    case BoxContentTypeEnum.TaskListViewContent:
      return (
        <TaskListViewContent
          contentData={contentData}
          type={TableRowTypeEnum.Task}
        />
      );
    case BoxContentTypeEnum.BreadcrumbContent:
      return (
        <div
          style={{
            display: 'flex',
            width: 'fit-content',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <BreadcrumbBoxContent contentData={contentData} onClick={onClick} />
          {isLastElementInArray === false && (
            <div
              aria-label={'Breadcrumb indicator arrow icon'}
              style={boxContentSwitchClasses.iconContainer}
            >
              <IconContext.Provider
                value={boxContentSwitchClasses.breadcrumbForwardIcon}
              >
                {IconSelector(IconEnum.Next)}
              </IconContext.Provider>
            </div>
          )}
        </div>
      );
    case BoxContentTypeEnum.NotificationContent:
      return (
        <TaskListViewContent
          contentData={contentData}
          type={TableRowTypeEnum.Notification}
          callBack={callBack}
          resetList={resetList}
        />
      );
    case BoxContentTypeEnum.CompletionDataDashboardListContent:
      return (
        <TaskListViewContent
          contentData={contentData}
          type={TableRowTypeEnum.CompletionDashboard}
          callBack={callBack}
        />
      );
    case BoxContentTypeEnum.SafetyRiskContent:
      return (
          <PDFNavigationBoxContent
              contentData={contentData}
              onClick={onClick}
          />
      );
    default: {
      return <></>;
    }
  }
};

export default BoxContentSwitch;
