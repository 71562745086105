import appliedFiltersStore from '../../stores/appliedFiltersStore';
import Chip from '../Chip/Chip';
import {ChipVariantEnum} from '../Chip/chipUtils/ChipEnums';
import React from 'react';
import {ChipStyles} from "../Forms/styles/chipStyles";
import {ColorModeTypeEnum} from "../../stores/userPreferenceStore";

const FilterChipDisplay: React.FC = () => {
    const { appliedFilters } = appliedFiltersStore(
        (state: { appliedFilters: any }) => ({
            appliedFilters: state.appliedFilters,
        })
    );

    return (
        <div style={ChipStyles(ColorModeTypeEnum.Zoidberg).root} className={'FilterChipDisplay'}>
            {appliedFilters.store_number !== undefined && (
                <Chip
                    variant={ChipVariantEnum.Outlined}
                >{`Store: ${appliedFilters.store_number}`}</Chip>
            )}

            {appliedFilters.district_number !== undefined && (
                <Chip
                    variant={ChipVariantEnum.Outlined}
                >{`District: ${appliedFilters.district_number}`}</Chip>
            )}

            {appliedFilters.region_number !== undefined && (
                <Chip
                    variant={ChipVariantEnum.Outlined}
                >{`Region: ${appliedFilters.region_number}`}</Chip>
            )}

            {appliedFilters.territory_number !== undefined && (
                <Chip
                    variant={ChipVariantEnum.Outlined}
                >{`Territory: ${appliedFilters.territory_number}`}</Chip>
            )}
        </div>
    );
};

export default FilterChipDisplay;
