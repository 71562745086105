import { FormikSelectItem } from './FormikElements/FormikSelect';
import { groups } from '../../context/constants';

export const formattedUserName = (userInfo: any) => {
  const userInfoObj = {
    empID: userInfo.empID,
    name: userInfo.given_name + ' ' + userInfo.family_name.substring(0, 1),
  };
  return userInfoObj;
};

export const eventItems: FormikSelectItem[] = [
  {
    label: 'Merchandising',
    value: 'Merchandising',
    abbreviation: 'Merch',
  },
  {
    label: 'Pricing & Signing',
    value: 'Pricing & Signing',
    abbreviation: 'P&S',
  },
  {
    label: 'Sephora',
    value: 'Sephora',
    abbreviation: 'S@K',
  },
  {
    label: 'Visual',
    value: 'Visual',
    abbreviation: 'Vis',
  },
  {
    label: 'Operations',
    value: 'Operations',
    abbreviation: 'Ops',
  },
  {
    label: 'Loss Prevention',
    value: 'Loss Prevention',
    abbreviation: 'LP',
  },
  {
    label: 'Training & Recognition',
    value: 'Training & Recognition',
    abbreviation: 'T&R',
  },
  {
    label: 'Marketing Promotions',
    value: 'Marketing Promotions',
    abbreviation: 'Promo',
  },
  {
    label: 'Event',
    value: 'Event',
    abbreviation: 'EV',
  },
  {
    label: 'Risk & Compliance',
    value: 'Risk & Compliance',
    abbreviation: 'R&C',
  },
  {
    label: 'Holiday',
    value: 'Holiday',
    abbreviation: 'Hol',
  },
  {
    label: 'Special Projects',
    value: 'Special Projects',
    abbreviation: 'SP',
  },
  {
    label: 'Surveys',
    value: 'Surveys',
    abbreviation: 'Surv',
  },
];

export const departmentItems: FormikSelectItem[] = [
  {
    label: 'Accessories',
    value: 'Accessories',
    abbreviation: '',
  },
  {
    label: 'Beauty',
    value: 'Beauty',
    abbreviation: '',
  },
  {
    label: 'Domestics/Luggage',
    value: 'Domestics/Luggage',
    abbreviation: '',
  },
  {
    label: 'Housewares/Seasonal',
    value: 'Housewares/Seasonal',
    abbreviation: '',
  },
  {
    label: 'Intimates/Sleepwear',
    value: 'Intimates/Sleepwear',
    abbreviation: '',
  },
  {
    label: 'Jewelry',
    value: 'Jewelry',
    abbreviation: '',
  },
  {
    label: 'Young Womens',
    value: 'Young Womens',
    abbreviation: '',
  },
  {
    label: 'Kids/Toys',
    value: 'Kids/Toys',
    abbreviation: '',
  },
  {
    label: 'Mens/Young Mens',
    value: 'Mens/Young Mens',
    abbreviation: '',
  },
  {
    label: 'Womens',
    value: 'Womens',
    abbreviation: '',
  },
  {
    label: 'Shoes',
    value: 'Shoes',
    abbreviation: '',
  },
  {
    label: 'Store',
    value: 'Store',
    abbreviation: '',
  },
];

export const ALL_STORES_STORE_VALUE = 'All';

export const FormButtonLabels = {
  SUBMIT_FOR_APPROVAL: 'SUBMIT FOR APPROVAL',
  EDIT_TASK: 'EDIT',
  CANCEL_TASK_EDIT: 'CANCEL EDIT',
  DELETE_TASK: 'DELETE',
  CANCEL: 'CANCEL',
  APPROVE_TASK: 'APPROVE',
  RETURN_TASK: 'RETURN',
  PRINT_TASK: 'PRINT',
  COMPLETE: 'MARK AS COMPLETE',
  REOPEN: 'REOPEN TASK',
  DUPLICATE_TASK: 'DUPLICATE TASK',
};

export const getTaskSchema = (initialValues: any, userGroup?: string) => {
  const { title, attachment_information, distro_file_information } =
    initialValues;
  let attachments: object[] = [];
  let distroConditionalViewForEdit: object[] = [
    [
      {
        fieldType: 'textbox',
        name: 'store_number',
        label: 'Store Number',
        maxLength: 4,
        gridItemSize: 6,
      },
    ],
    [
      {
        fieldType: 'switch',
        name: 'funded',
        label: 'Is this task funded?',
        gridItemSize: 12,
      },
      {
        fieldType: 'textbox',
        name: 'labor_hours',
        label: 'Enter Labor Hours',
      },
    ],
  ];

  let distroConditionalViewForApprove: object[] = [
    [
      {
        fieldType: 'textbox',
        name: 'store_number',
        label: 'Store Number',
        maxLength: 4,
        gridItemSize: 6,
        disabled: true,
      },
    ],
    [
      {
        fieldType: 'switch',
        name: 'funded',
        label: 'Is this task funded?',
        gridItemSize: 12,
        disabled: true,
      },
      {
        fieldType: 'textbox',
        name: 'labor_hours',
        label: 'Enter Labor Hours',
        disabled: true,
      },
    ],
  ];
  if (attachment_information?.length > 0) {
    attachments = [
      {
        fieldType: 'attachments',
        name: 'attachment_information',
        label: 'Attachments',
        fileNames: attachment_information,
      },
    ];
  }
  if (
    distro_file_information?.length > 0 &&
    userGroup !== groups.MICHAEL &&
    userGroup !== groups.STANLEY
  ) {
    distroConditionalViewForEdit = distroConditionalViewForApprove = [
      [
        {
          fieldType: 'attachments',
          name: 'distro_file_information',
          label: 'Uploaded Distribution List File',
          fileNames: distro_file_information,
        },
      ],
    ];
  }
  return {
    create: {
      header: 'Create New Task',
      formButtons: {
        submit: {
          show: true,
          label: FormButtonLabels.SUBMIT_FOR_APPROVAL,
        },
        cancel: {
          show: true,
          label: FormButtonLabels.CANCEL,
        },
      },
      fields: [
        [
          {
            fieldType: 'radio-group',
            name: 'store-assignment',
            prompt: 'What stores need to complete this task?',
            items: [
              {
                name: 'store-assignment-all',
                label: 'All',
              },
              {
                name: 'store-assignment-single-store',
                label: 'Single Store',
              },
              {
                name: 'store-assignment-store-list',
                label: 'List of Stores',
              },
            ],
          },
        ],
        [
          {
            fieldType: 'textbox',
            name: 'title',
            label: 'Task Title',
          },
          {
            fieldType: 'select',
            name: 'task_type',
            label: 'Task Type',
            items: eventItems,
            gridItemSize: 9,
          },
          {
            fieldType: 'select',
            type: 'select',
            name: 'department',
            label: 'Department',
            items: departmentItems,
            gridItemSize: 9,
          },
          {
            fieldType: 'textbox',
            type: 'date',
            name: 'start_date',
            label: 'Start Date',
            gridItemSize: 6,
            hideOnListOfStoresRadioChosen: true,
          },
          {
            fieldType: 'textbox',
            type: 'date',
            name: 'end_date',
            label: 'End Date',
            gridItemSize: 6,
            hideOnListOfStoresRadioChosen: true,
          },
        ],
        [
          {
            fieldType: 'switch',
            name: 'require_completion',
            label: 'Require associates to mark task as complete?',
          },
          {
            fieldType: 'switch',
            name: 'schedule_date',
            label: 'Allow associates to schedule execution date?',
          },
        ],
        [
          {
            fieldType: 'switch',
            name: 'funded',
            label: 'Is this task funded?',
            hideOnListOfStoresRadioChosen: true,
          },
          {
            fieldType: 'textbox',
            name: 'labor_hours',
            label: 'Task Labor Hours',
            gridItemSize: 6,
            hideOnListOfStoresRadioChosen: true,
          },
        ],
        [
          {
            fieldType: 'editor',
            name: 'task_description',
            label: 'Task Description',
          },
        ],
        [
          {
            fieldType: 'switch',
            name: 'leadership_calendar',
            label: 'Show task on Leadership Calendar?',
          },
          {
            fieldType: 'switch',
            name: 'year_at_a_glance',
            label: 'Show task on Year at a Glance Calendar?',
          },
        ],
        [
          {
            fieldType: 'upload',
            name: 'attachment_information',
            fieldTitle: 'drop attachments here',
            fieldSubTitle: 'or click to add files',
            maxNumOfUploadableFiles: 10,
            cumulativeFileSize: 50 * 1000 * 1024,
            fileTypes: [
              'image/*',
              'video/*',
              '.pdf',
              '.csv',
              '.xls',
              '.docx',
              '.xlsx',
              '.txt',
              '.ppt',
            ],
          },
        ],
      ],
    },

    view: {
      header: title,
      formButtons: {
        print: {
          show: true,
          label: FormButtonLabels.PRINT_TASK,
        },
        edit: {
          show: true,
          label: FormButtonLabels.EDIT_TASK,
        },
        completionStatus: {
          show: true,
          complete: {
            label: FormButtonLabels.COMPLETE,
          },
          reopen: {
            label: FormButtonLabels.REOPEN,
          },
        },
        duplicateTask: {
          show: true,
          label: FormButtonLabels.DUPLICATE_TASK,
        },
      },
      fields: [],
    },
    edit: {
      header: 'DRAFT',
      formButtons: {
        submit: {
          show: true,
          label: FormButtonLabels.SUBMIT_FOR_APPROVAL,
        },
        cancelEdit: {
          show: true,
          label: FormButtonLabels.CANCEL_TASK_EDIT,
        },
        deleteTask: {
          show: true,
          label: FormButtonLabels.DELETE_TASK,
        },
        duplicateTask: {
          show: true,
          label: FormButtonLabels.DUPLICATE_TASK,
        },
      },
      fields: [
        [
          {
            fieldType: 'textbox',
            name: 'title',
            label: 'Task Title',
          },
          {
            fieldType: 'select',
            name: 'task_type',
            label: 'Task Type',
            items: eventItems,
            gridItemSize: 12,
          },
          {
            fieldType: 'select',
            name: 'department',
            label: 'Department',
            items: departmentItems,
            gridItemSize: 12,
          },
          {
            fieldType: 'textbox',
            type: 'date',
            name: 'start_date',
            label:
              distro_file_information?.length > 0
                ? 'Earliest Start Date'
                : 'Start Date',
            disabled: distro_file_information?.length > 0 && true,
            gridItemSize: 6,
          },
          {
            fieldType: 'textbox',
            type: 'date',
            name: 'end_date',
            label:
              distro_file_information?.length > 0
                ? 'Latest End Date'
                : 'End Date',
            disabled: distro_file_information?.length > 0 && true,
            gridItemSize: 6,
          },
        ],
        [
          {
            fieldType: 'switch',
            name: 'require_completion',
            label: 'Require associates to mark task as complete?',
            disabled: true,
          },
          {
            fieldType: 'switch',
            name: 'schedule_date',
            label: 'Allow associates to schedule execution date?',
          },
        ],
        ...distroConditionalViewForEdit,
        [
          {
            fieldType: 'editor',
            name: 'task_description',
            label: 'Task Description',
          },
        ],
        [
          {
            fieldType: 'switch',
            name: 'leadership_calendar',
            label: 'Show on Leadership Calendar?',
          },
          {
            fieldType: 'switch',
            name: 'year_at_a_glance',
            label: 'Show on Year at a Glance Calendar?',
          },
        ],
        [
          {
            fieldType: 'upload',
            name: 'attachment_information',
            fieldTitle: 'drop attachments here',
            fieldSubTitle: 'or click to add files',
            maxNumOfUploadableFiles: 10,
            cumulativeFileSize: 50 * 1000 * 1024,
            fileTypes: [
              'image/*',
              'video/*',
              '.pdf',
              '.csv',
              '.xls',
              '.docx',
              '.xlsx',
              '.txt',
              '.ppt',
            ],
          },
        ],
      ],
    },
    approve: {
      header: title,
      formButtons: {
        approve: {
          show: true,
          label: FormButtonLabels.APPROVE_TASK,
        },
        returnTask: {
          show: true,
          label: FormButtonLabels.RETURN_TASK,
        },
        edit: {
          show: true,
          label: FormButtonLabels.EDIT_TASK,
        },
      },
      fields: [
        [
          {
            fieldType: 'textbox',
            name: 'title',
            label: 'Task Title',
            disabled: true,
          },
        ],
        [
          {
            fieldType: 'select',
            name: 'task_type',
            label: 'Task Type',
            items: eventItems,
            disabled: true,
            gridItemSize: 9,
          },
          {
            fieldType: 'select',
            name: 'department',
            label: 'Department',
            items: departmentItems,
            disabled: true,
            gridItemSize: 9,
          },
          {
            fieldType: 'textbox',
            type: 'date',
            name: 'start_date',
            label:
              distro_file_information?.length > 0
                ? 'Earliest Start Date'
                : 'Start Date',
            disabled: true,
            gridItemSize: 6,
          },
          {
            fieldType: 'textbox',
            type: 'date',
            name: 'end_date',
            label:
              distro_file_information?.length > 0
                ? 'Latest End Date'
                : 'End Date',
            disabled: true,
            gridItemSize: 6,
          },
        ],
        [
          {
            fieldType: 'switch',
            name: 'require_completion',
            label: 'Require associates to mark task as complete?',
            disabled: true,
          },
          {
            fieldType: 'switch',
            name: 'schedule_date',
            label: 'Allow associates to schedule execution date?',
          },
        ],
        ...distroConditionalViewForApprove,
        [
          {
            fieldType: 'editor',
            name: 'task_description',
            label: 'Task Description',
          },
        ],
        [
          {
            fieldType: 'switch',
            name: 'leadership_calendar',
            label: 'Show task on Leadership Calendar?',
            disabled: true,
          },
          {
            fieldType: 'switch',
            name: 'year_at_a_glance',
            label: 'Show task on Year at a Glance Calendar?',
            disabled: true,
          },
        ],
        [...attachments],
      ],
    },
  };
};
