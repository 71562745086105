/* eslint-disable no-eval */
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SafetyRiskComplianceDataWrapper from "../dataWrappers/SafetyRiskComplianceDataWrapper";

const queryClient = new QueryClient();

const SafetyRiskComplianceScreen: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <SafetyRiskComplianceDataWrapper />
    </QueryClientProvider>
  );
};

export default SafetyRiskComplianceScreen;
