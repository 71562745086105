import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';
import { theme } from '../../../assets/siteLineTheme';
import { TaskHistoryEnum } from './TaskHistoryModal';

export type ActivityHistoryContentStylesParams = {
  actionEnum: TaskHistoryEnum;
  colorMode: ColorModeTypeEnum;
};

export const activityHistoryBackgroundColorPicker = (
  actionEnum: TaskHistoryEnum
) => {
  switch (actionEnum) {
    case TaskHistoryEnum.ADD_COMMENT:
      return theme.colorPalette.activityHistory.addComment;
    case TaskHistoryEnum.APPROVE_TASK:
      return theme.colorPalette.activityHistory.approveTask;
    case TaskHistoryEnum.CREATE_TASK:
      return theme.colorPalette.activityHistory.createTask;
    case TaskHistoryEnum.RETURN_TASK:
      return theme.colorPalette.activityHistory.returnTask;
    case TaskHistoryEnum.TASK_MARKED_COMPLETE:
      return theme.colorPalette.activityHistory.markedComplete;
    case TaskHistoryEnum.REOPEN_COMPLETED_TASK:
      return theme.colorPalette.activityHistory.reopenTask;
    case TaskHistoryEnum.EDIT_TASK:
      return theme.colorPalette.activityHistory.editTask;
    case TaskHistoryEnum.SUBMIT_TASK:
      return theme.colorPalette.activityHistory.submitTask;
    case TaskHistoryEnum.STORE_SCHEDULED_DATE:
      return theme.colorPalette.activityHistory.scheduledDate;
    case TaskHistoryEnum.STORE_SCHEDULED_DATE_CHANGE:
      return theme.colorPalette.activityHistory.scheduledDate;
    case TaskHistoryEnum.STORE_SCHEDULED_DATE_RESET:
      return theme.colorPalette.activityHistory.scheduledDate;
    default:
      return '';
  }
};

export const activityHistoryTextColorPicker = (actionEnum: TaskHistoryEnum) => {
  if (actionEnum !== TaskHistoryEnum.ADD_COMMENT) {
    return theme.colorPalette.plainWhite;
  } else {
    return theme.colorPalette.plainBlack;
  }
};

export const activityHistoryContentStyles = (
  params: ActivityHistoryContentStylesParams
) => {
  const { actionEnum, colorMode } = params;
  /* istanbul ignore next */
  return {
    activityHistoryContainer: {
      height: 'fit-content',
    },
    activityHistoryContent: {
      height: 'fit-content',
      wordBreak: 'break-word' as 'break-word',
      fontSize: '.85em',
      marginLeft: '1.35em',
    },
    activityHistoryContentContainer: {
      justifyContent: 'space-between',
      display: 'flex',
      height: 'fit-content',
    },
    activityHistorySvgTypeContent: {
      display: 'flex',
    },
    centerFlex: {
      display: 'flex',
      alignItems: 'center',
    },
    activityHistoryTypeContent: {
      height: 'fit-content',
      width: 'fit-content',
      padding: '.25em .45em',
      margin: '.25em',
      borderRadius: '.75em',
      maxWidth: '80%',
      // boxShadow: `1px 3px 2px ${theme.colorPalette.drawerHeaderText}`,
      backgroundColor:
        colorMode !== ColorModeTypeEnum.BlackWhite
          ? activityHistoryBackgroundColorPicker(actionEnum)
          : theme.colorPalette.plainBlack,
    },
    activityHistoryTypeContentComment: {
      height: 'fit-content',
      padding: '.5em',
      margin: '.25em',
      borderRadius: '.2em',
      maxWidth: '80%',
      backgroundColor: theme.colorPalette.plainWhite,
    },
    activityHistorySvg: {
      position: 'relative' as 'relative',
      height: '100%',
      width: '35px',
      minWidth: '35px',
    },
    activityHistorySvgLine: {
      position: 'absolute' as 'absolute',
      display: 'inline-block',
      width: '2px',
      height: '100%',
      backgroundColor: 'gray',
      left: '0.65em',
    },
    activityHistoryDateText: {
      fontWeight: 700,
      fontSize: '.75em',
    },
    activityHistoryEmpNameText: {
      fontSize: '.75em',
    },
    activityHistorySvgCircle: {
      position: 'absolute' as 'absolute',
      display: 'inline-block',
      width: '1em',
      zIndex: '10005',
      height: '1em',
      borderRadius: '50%',
      border: `solid 3px ${
        colorMode !== ColorModeTypeEnum.BlackWhite
          ? activityHistoryBackgroundColorPicker(actionEnum)
          : theme.colorPalette.plainBlack
      }`,
      margin: '0.5em auto 0 auto',
      backgroundColor: theme.colorPalette.plainWhite,
    },
    activityHistoryDate: {
      width: '4.5em',
      minWidth: '5.5em',
      padding: '.25em .5em .25em .25em',
      display: 'flex',
      flexDirection: 'column' as 'column',
    },
    activityTypeText: {
      fontWeight: 700,
      marginBottom:
        actionEnum === TaskHistoryEnum.ADD_COMMENT ? '-.5em' : undefined,
      color:
        colorMode !== ColorModeTypeEnum.BlackWhite
          ? activityHistoryTextColorPicker(actionEnum)
          : theme.colorPalette.plainWhite,
      width:
        actionEnum === TaskHistoryEnum.TASK_MARKED_COMPLETE
          ? '8.5em'
          : ('fit-content' as 'fit-content'),
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'top',
      margin: '0.5em 0 0 0.5em',
    },
    column: {
      display: 'flex',
      alignItems: 'left',
      flexDirection: 'column' as 'column',
    },
    iconContainer: {
      marginBottom: '0.25em',
    },
  };
};
