export type ListViewWeekDayContainerParams = {
  boxHeight: string;
  boxWidth: string;
  margin?: string | undefined;
  justifyContent?: string | undefined;
};

export const listViewWeekDayContainerStyles = (
  params: ListViewWeekDayContainerParams
) => {
  const { boxHeight, boxWidth, margin, justifyContent } = params;
  return {
    boxArrayContainer: {
      display: 'flex',
      flexWrap:
        window.innerWidth > 420 ? ('wrap' as 'wrap') : ('nowrap' as 'nowrap'),
      justifyContent:
        justifyContent !== undefined ? justifyContent : 'space-evenly',
      margin: window.innerWidth > 420 ? margin : '0.6em',
      flexDirection: 'row' as 'row',
      width: 'auto',
    },
    boxContainer: {
      width: boxWidth,
      maxWidth: boxWidth,
      height: boxHeight,
    },
  };
};
