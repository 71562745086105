import React, {useState} from 'react';
import {FileUploadField} from '../FileUpload/FileUploadField';
import {v4 as uuidv4} from 'uuid';
import {Form, Formik} from 'formik';
import {createNewFeature, NewFeaturesObj} from '../../api/apiNewFeature';
import {ButtonStyles} from "../Forms/styles/buttonStyles";
import {Button} from "@mui/material";
import {ColorModeTypeEnum} from "../../stores/userPreferenceStore";

const NewFeaturesCreate: React.FC = () => {
  const [title, setTitle] = useState<string>('');
  const attachmentsId = uuidv4();

  const handleSubmit = async (values: any) => {
    const params: NewFeaturesObj = {
      title,
      attachments_id: attachmentsId,
      file_information: values.attachment_information[0],
    };
    await createNewFeature(params);
  };
  return (
    <>
      <div>New Feature</div>
      <div>
        Title :{' '}
        <input type={'text'} onChange={(e) => setTitle(e.target.value)} />
      </div>
      <div> Upload File : </div>
      <Formik
        initialValues={{ attachment_information: {} }}
        onSubmit={handleSubmit}
      >
        {({ values, dirty, isValid }) => {
          return (
            <>
              <Form>
                <FileUploadField
                  name={'attachment_information'}
                  fileTypes={['.pdf', '.mp4']}
                  fieldText={{
                    fieldTitle: 'drop attachments here',
                    fieldSubTitle: 'or click to add files',
                  }}
                  maxNumOfUploadableFiles={1}
                  cumulativeFileSize={50 * 1000 * 1024}
                  attachmentsId={attachmentsId}
                />
              <Button
                  type="submit"
                  sx={{...ButtonStyles(ColorModeTypeEnum.Zoidberg).root, ...ButtonStyles(ColorModeTypeEnum.Zoidberg).saveApplyFiltersButton}}
                  fullWidth
              >
                  Save this New Feature
              </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default NewFeaturesCreate;
