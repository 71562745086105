import { Drawer} from '@mui/material';
import { TaskForm } from '../../Forms/TaskForm';
import drawerPropsStore, {
  defaultDrawerProps,
} from '../../../stores/drawerPropsStore';
import React from 'react';
import muiTheme from 'src/utils/mui/muiTheme';


const TaskDrawer: React.FC = () => {
  // store
  const { drawerProps, setDrawerProps } = drawerPropsStore(
    (state: { drawerProps: any; setDrawerProps: any }) => ({
      drawerProps: state.drawerProps,
      setDrawerProps: state.setDrawerProps,
    })
  );

  const handleOnClose = () => {
    setDrawerProps({ ...defaultDrawerProps });
  };

  return (
    <Drawer
      ModalProps={{ disableEnforceFocus: true }}
      anchor="right"
      open={drawerProps.isDrawerOpen}
      onClose={handleOnClose}
      // classes={{ paperAnchorRight: classes.paperAnchorRight }}
      PaperProps={{
          sx: {
              width: '32%',
              [muiTheme.breakpoints.between('xs', 'sm')]: {
                  width: '100%',
              },
              minWidth: '320px',
              overflowY: 'hidden',
          }
      }}
    >
      <TaskForm />
    </Drawer>
  );
};

export default TaskDrawer;
