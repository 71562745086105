import { theme } from '../../assets/siteLineTheme';

export type ModalBackgroundStylesParams = {
  isActive: boolean;
  isInnerModal?: boolean;
};

export const modalBackgroundStyles = (params: ModalBackgroundStylesParams) => {
  /* istanbul ignore next */
  const { isActive, isInnerModal } = params;

  return {
    modalBackground: {
      width: '100vw',
      height: document.body.scrollHeight,
      backgroundColor: theme.colorPalette.plainBlack,
      opacity: 0.65,
      position: 'absolute' as 'absolute',
      zIndex: isInnerModal ? 10002 : 10000,
      left: 0,
      top: 0,
      display: isActive ? 'block' : 'none',
    },
  };
};
