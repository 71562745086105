import {StoreLocationInformationProps} from '../models/StoreLocationInformation';
import {AreaScopeEnum} from '../stores/taskCompletionDashboardStore';

export const formattedFilterLocationList = (
    level: AreaScopeEnum,
    storeLocationInformation: any
) => {
    const formattedLevelName =
        level === AreaScopeEnum.District
        ? 'district_number'
        : level === AreaScopeEnum.Territory
        ? 'territory_number'
        : level === AreaScopeEnum.Region
        ? 'region_number'
        : 'location_number';
    const name =
        level === AreaScopeEnum.District
        ? 'district_name'
        : level === AreaScopeEnum.Territory
        ? 'territory_name'
        : level === AreaScopeEnum.Region
        ? 'region_name'
        : 'location_name';

    if (storeLocationInformation) {
        const sortedStoreLocationInformation = storeLocationInformation.sort(
            (a: StoreLocationInformationProps, b: StoreLocationInformationProps) =>
                a[formattedLevelName] - b[formattedLevelName]
        );

        const sortedLocationInformation: string[] =
            sortedStoreLocationInformation.map(
                (store: StoreLocationInformationProps) => {
                    return (
                        store[formattedLevelName] &&
                        store[formattedLevelName] &&
                        `${store[formattedLevelName]} - ${store[name]}`
                    );
                }
            );

        const sortedLocationInformationDeduped = new Set(sortedLocationInformation);
        return [...sortedLocationInformationDeduped];
    } else {
        return [''];
    }
};
