import { create, SetState } from 'zustand';
import { DialogModalSwitchEnum } from '../components/Modal/DialogModalSwitch';

export type baseModalContentStoreState = {
  isActive: boolean;
  width?: number | undefined;
  height?: number | undefined;
  dialogModalSwitchEnum?: DialogModalSwitchEnum | undefined;
  top?: number | undefined;
  right?: number | undefined;
};

export const defaultBaseModalContentStoreState: baseModalContentStoreState = {
  isActive: false,
  width: undefined,
  height: undefined,
  dialogModalSwitchEnum: undefined,
  top: undefined,
  right: undefined,
};

const baseModalStore = create((set: SetState<any>) => ({
  baseModalContentStoreProps: { ...defaultBaseModalContentStoreState },

  setBaseModalContent: (
    baseModalContentStoreProps: baseModalContentStoreState
  ) => {
    set(() => ({ baseModalContentStoreProps }));
  },
  resetBaseModalContent: () => {
    set(() => ({
      baseModalContentStoreProps: { ...defaultBaseModalContentStoreState },
    }));
  },
}));

export default baseModalStore;
