import React from 'react';

export type UpDownIconProps = {
  size: number;
  color: string;
  label: string;
};

const UpDownIcon: React.FC<UpDownIconProps> = (props) => {
  const { size, color, label } = props;
  const verticalOffset = size * 0.025;

  const upTrianglePointX = size * 0.5;
  const upTrianglePointY = size * 0.25 - verticalOffset;
  const upTriangleBaseRightX = size * 0.75;
  const upTriangleBaseRightY = size * 0.5 - verticalOffset;
  const upTriangleBaseLeftX = size * 0.25;
  const upTriangleBaseLeftY = size * 0.5 - verticalOffset;
  const upTrianglePathString = `
    M ${upTrianglePointX} ${upTrianglePointY} 
    L ${upTriangleBaseRightX} ${upTriangleBaseRightY} 
    L ${upTriangleBaseLeftX} ${upTriangleBaseLeftY}
  `;

  const downTrianglePointX = size * 0.5;
  const downTrianglePointY = size * 0.75 + verticalOffset;
  const downTriangleBaseRightX = size * 0.75;
  const downTriangleBaseRightY = size * 0.5 + verticalOffset;
  const downTriangleBaseLeftX = size * 0.25;
  const downTriangleBaseLeftY = size * 0.5 + verticalOffset;
  const downTrianglePathString = `
    M ${downTrianglePointX} ${downTrianglePointY} 
    L ${downTriangleBaseRightX} ${downTriangleBaseRightY} 
    L ${downTriangleBaseLeftX} ${downTriangleBaseLeftY}
  `;

  return (
    <svg width={size} height={size} aria-label={label}>
      <path d={upTrianglePathString} fill={color} />
      <path d={downTrianglePathString} fill={color} />
    </svg>
  );
};

export default UpDownIcon;
