import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TaskListTableDataWrapper from '../dataWrappers/TaskListTableDataWrapper';
import { taskListViewScreenStyles } from './styles/taskListViewScreenStyles';

const queryClient = new QueryClient();

const TaskListViewScreen: React.FC = () => {
  const taskListViewScreenClasses = taskListViewScreenStyles();
  return (
    <QueryClientProvider client={queryClient}>
      <div style={taskListViewScreenClasses.screenContainer}>
        <TaskListTableDataWrapper />
      </div>
    </QueryClientProvider>
  );
};

export default TaskListViewScreen;
