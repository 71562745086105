export const generalStyles = () => {
  return {
    screenContainer: {
      display: 'flex',
      flexDirection:
        window.innerWidth > 420 ? ('row' as 'row') : ('column' as 'column'),
      justifyContent: 'space-around',
    },
    titleContainer: {
      fontSize: '2em',
      weight: 700,
    },
    formInputsContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      // gap: '.5em',
    },
    textInputItem: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      gap: '.25em',
      margin: '0.5em 0',
    },
    formTitle: {
      fontSize: '1.25em',
      fontWeight: 700,
    },
    menuContainer: {
      width: window.innerWidth > 420 ? '45%' : '100%',
      maxHeight: window.innerWidth > 420 ? window.innerHeight * .75 : '303px',
      overflowY: 'scroll' as 'scroll',
      margin: '1em 0.3em 0.1em 0.3em',
    },
    separateLineForMobile: {
      width: '95%',
      borderTop: '1.5px solid darkgrey',
      margin: '0.5em auto',
    },
    toggleContainer: {
      display: 'flex',
      width: '100%',
      margin: '0.25em 0',
      gap: '0.em ',
    },
    digitalMerchChangeForm: {
      display: 'flex',
      width: '100%'
    }
  };
};
