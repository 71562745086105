import React from 'react';
import ModalBackground from './ModalBackground';
import baseModalStore, {
  baseModalContentStoreState,
} from '../../stores/baseModalStore';
import ModalContent from './ModalContent';
import { baseModalStyles } from './baseModalStyles';
import DialogModalSwitch from './DialogModalSwitch';

const BaseModal: React.FC = () => {
  const { baseModalContentStoreProps, resetBaseModalContent } = baseModalStore(
    (state: {
      resetBaseModalContent: Function;
      setBaseModalContent: Function;
      baseModalContentStoreProps: baseModalContentStoreState;
    }) => ({
      baseModalContentStoreProps: state.baseModalContentStoreProps,
      setBaseModalContent: state.setBaseModalContent,
      resetBaseModalContent: state.resetBaseModalContent,
    })
  );

  const { isActive, width, height, dialogModalSwitchEnum, top, right } =
    baseModalContentStoreProps;

  const baseModalClasses = baseModalStyles();

  return (
    <div style={baseModalClasses.baseModalContainer}>
      <ModalBackground
        isActive={isActive}
        setIsActive={resetBaseModalContent}
      />
      <ModalContent
        isActive={isActive}
        width={width}
        height={height}
        dialogModalEnum={dialogModalSwitchEnum}
        top={top}
        right={right}
      >
        <DialogModalSwitch dialogModalEnum={dialogModalSwitchEnum} />
      </ModalContent>
    </div>
  );
};

export default BaseModal;
