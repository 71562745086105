import { Box, Typography } from '@mui/material';
import React from 'react';

export type GenericDropdownOptionProps = {
  option: GenericDropDownObject;
  classes: any; // to-do: update once no longer using mui createStyles/makeStyles
};

export type GenericDropDownObject = {
  title: string;
  task_type: string;
  dateRange: string;
  identifier: string;
  optionType: string;
};

const GenericDropdownOption: React.FC<GenericDropdownOptionProps> = (props) => {
  const { option, classes } = props;
  return (
    <Box key={option.identifier} sx={classes.optionContainer}>
      <Box sx={classes.optionTitleBox}>
        <Typography sx={classes.optionTitle}>{option.title}</Typography>
      </Box>
      <Box sx={classes.optionSubBox}>
        {Object.keys(option).map((key) => {
          if (key !== 'title' && key !== 'identifier') {
            return (
              <>
                <Typography
                    sx={{...classes.capitalize, ...classes.optionSubLabel}}
                >
                  {key}:
                </Typography>
                <Typography sx={classes.optionSubText}>
                  {option[key]}
                </Typography>
              </>
            );
          } else {
            return null;
          }
        })}
      </Box>
    </Box>
  );
};

export default GenericDropdownOption;
