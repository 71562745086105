import { Box, Typography } from '@mui/material';
import { theme } from '../../../assets/siteLineTheme';
import { eventItems } from '../../Forms/TaskUtils';
import React, { MouseEventHandler } from 'react';
import { TaskSearchBarOptions } from '../../../stores/models/SearchBarProps';
import userPreferenceStore, {
  ColorModeTypeEnum,
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import {ChipStyles} from "../../Forms/styles/chipStyles";

export type TaskDropdownOptionProps = {
  option: TaskSearchBarOptions;
  classes: any; // to-do: update once no longer using mui createStyles/makeStyles
  onClick: MouseEventHandler<HTMLElement>;
};

const TaskDropdownOption: React.FC<TaskDropdownOptionProps> = (props) => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const { option, classes, onClick } = props;
  const eventItem = eventItems.find((item) => item.label === option.task_type);
  return (
    <Box
      key={option.identifier}
      sx={classes.optionContainer}
      onClick={onClick}
    >
      <Box sx={classes.optionSubBox}>
        {Object.keys(option).map((key, index) => {
          if (key !== 'title' && key !== 'identifier' && key !== 'optionType') {
            if (key === 'task_type') {
              return (
                <Box
                  key={index}
                  sx={{...ChipStyles(userPreference.colorMode).typeChip,
                    backgroundColor:
                        userPreference.colorMode !== ColorModeTypeEnum.BlackWhite
                            ? theme.colorPalette.taskTypes.dark[option.task_type]
                            : theme.colorPalette.plainBlack,
                    color: theme.colorPalette.taskTypes.getTextColor(
                        option.task_type,
                        userPreference.colorMode
                    ),
                }}
                >
                  <Typography sx={ChipStyles(userPreference.colorMode).typeLabel}>
                    {eventItem?.abbreviation}
                  </Typography>
                </Box>
              );
            } else {
              return (
                <>
                  <Typography
                    key={index}
                    sx={ChipStyles(userPreference.colorMode).optionSubText}
                  >
                    {option[key]}
                  </Typography>
                </>
              );
            }
          } else {
            return null;
          }
        })}
      </Box>
      <Box sx={classes.optionTitleBox}>
        <Typography sx={classes.optionTitle}>{option.title}</Typography>
      </Box>
    </Box>
  );
};

export default TaskDropdownOption;
