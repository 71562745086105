// import { boxColorPicker } from '../../Box/boxColorPicker';
// import { BoxContentTypeEnum } from '../../BoxArray/BoxArray';
import { theme } from '../../../assets/siteLineTheme';

export type BreadcrumbBoxContentStylesParams = {
  data: { path: string; title: string };
};

export const breadcrumbBoxContentStyles = (
  params: BreadcrumbBoxContentStylesParams
) => {
  // const { data } = params;

  return {
    breadcrumbBoxContentContainer: {
      color: theme.colorPalette.completionStatus.scheduledDate,
      display: 'flex',
      height: '100%',
      // padding: '.25em .25em',
      margin: '.25em .25em',
      justifyContent: 'center',
      alignItems: 'center',
      // textAlign: 'center' as 'center',
      cursor: 'pointer',
    },
  };
};
