import React from 'react';
import ColorToggle from 'src/components/UserPreference/ColorToggle/ColorToggle';
import Logout from '../../UserPreference/Logout/Logout';
import { userPreferenceModalStyles } from './userPreferenceModalStyles';
import ViewType from '../../UserPreference/ViewType/ViewType';

const UserPreferenceModal: React.FC = () => {
  const userPreferenceClasses = userPreferenceModalStyles();

  return (
    <div style={userPreferenceClasses.userPreferenceContainer}>
      <ColorToggle />
      <ViewType />
      <div style={userPreferenceClasses.logoutContainer}>
        <Logout />
      </div>
    </div>
  );
};

export default UserPreferenceModal;
