import {ColorModeTypeEnum} from "../../../stores/userPreferenceStore";
import muiTheme from "../../../utils/mui/muiTheme";

export const GridStyles = (colorMode: ColorModeTypeEnum) => {
    return {
        basicGrid: {
            height: 'fit-content',
            marginTop: muiTheme.spacing(1),
            '& > *': {
                marginTop: muiTheme.spacing(1),
            },
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '10px',
            margin: '1em',
            width: '93%',
        },
        formContentContainer: {
            height: `100%`,
            flexWrap: 'nowrap',
            flexDirection: 'column',
        },
    }

}