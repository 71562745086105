import { theme } from '../../../assets/siteLineTheme';

export type iconButtonStylesParams = {
  color?: string | undefined;
  size?: string | undefined;
  margin?: string | undefined;
  backgroundColor?: string | undefined;
  padding?: string | undefined;
  borderRadius?: string | undefined;
  isActive?: boolean | undefined;
  textColor?: string | undefined;
  isDisabled?: boolean | undefined;
};

export const iconButtonStyles = (params: iconButtonStylesParams) => {
  /* istanbul ignore next */
  const {
    color,
    size,
    margin,
    backgroundColor,
    padding,
    borderRadius,
    isActive,
    textColor,
  } = params;

  return {
    activeStyle: {
      filter: isActive ? 'brightness(1.25)' : undefined,
      transform: isActive ? 'scale(1.1)' : undefined,
    },
    icon: {
      size: '100%',
      color: color !== undefined ? color : theme.colorPalette.snackbar.success,
    },
    iconWithLabel: {
      height: '100%',
      width: '70%',
      color: color !== undefined ? color : theme.colorPalette.snackbar.success,
      display: 'flex',
    },
    iconContainer: {
      cursor: 'pointer',
      height: size !== undefined ? size : '1.5em',
      width: size !== undefined ? size : '1.5em',
      margin: margin !== undefined ? margin : '',
      padding: padding !== undefined ? padding : '',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor:
        backgroundColor !== undefined ? backgroundColor : undefined,
      borderRadius: borderRadius !== undefined ? borderRadius : '',
      filter: isActive ? 'brightness(1.25)' : undefined,
      transform: isActive ? 'scale(1.1)' : undefined,
    },
    iconWithTextContainer: {
      cursor: 'pointer',
      height: size !== undefined ? size : '1.5em',
      width: 'auto',
      margin: margin !== undefined ? margin : '',
      padding: padding !== undefined ? padding : '',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor:
        backgroundColor !== undefined ? backgroundColor : undefined,
      borderRadius: borderRadius !== undefined ? borderRadius : '',
      filter: isActive ? 'brightness(1.25)' : undefined,
      transform: isActive ? 'scale(1.1)' : undefined,
      paddingLeft: '0.5em',
    },
    label: {
      color: textColor !== undefined ? textColor : 'white',
      paddingLeft: '0.5em',
      paddingRight: '0.5em',
    },
    disabledStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'not-allowed',
      backgroundColor: 'rgb(229, 229, 229)',
      height: size !== undefined ? size : '1.5em',
      width: 'auto',
      margin: margin !== undefined ? margin : '',
      padding: padding !== undefined ? padding : '',
      paddingLeft: '0.5em',
    },
  };
};
