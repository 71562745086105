import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TaskCalendarDataWrapper } from 'src/dataWrappers/TaskCalendarDataWrapper';

const queryClient = new QueryClient();

const TaskCalendarScreen: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="ScreenContainer">
        <TaskCalendarDataWrapper />
      </div>
    </QueryClientProvider>
  );
};

export default TaskCalendarScreen;
