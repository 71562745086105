// import { NavigationObj } from '../../../utils/testData/mockHierarchyData';
// import { theme } from '../../../assets/siteLineTheme';
// import colorModeStore, {
//   ColorModeTypeEnum,
// } from '../../../stores/colorModeStore';
//
// export type NavigationBoxContentStylesParams = {
//   data: NavigationObj;
//   image: string;
// };
//
// export const navigationBoxContentStyles = (
//   params: NavigationBoxContentStylesParams
// ) => {
//   const { colorMode } = colorModeStore(
//     (state: { colorMode: ColorModeTypeEnum }) => ({
//       colorMode: state.colorMode,
//     })
//   );
//   const { data, image } = params;
//   /* istanbul ignore next */
//   return {
//     navigationBoxContentContainer: {
//       color:
//         data.url === null
//           ? theme.newColorPalette.textDarkBlack[colorMode]
//           : theme.newColorPalette.textDarkBlack[colorMode],
//       backgroundColor: data.url === null ? theme.colorPalette.plainWhite : '',
//       display: 'flex',
//       height: '100%',
//       width: '50%',
//       justifyContent: 'center',
//       alignItems: 'center',
//       textAlign: 'center' as 'center',
//       fontWeight: 'bold',
//       borderRadius: '.4em',
//       cursor: 'pointer',
//       boxShadow: data.url === null ? '2px 2px 4px rgba(0, 0, 0, 0.25)' : 'none',
//     },
//     navigationBoxContentTextContainer: {
//       display: 'flex',
//       flexDirection: 'column' as 'column',
//       alignItems: 'center',
//       justifyContent: image !== '' ? 'space-evenly' : 'center',
//       width: '100%',
//       height: '100%',
//       margin: '0em auto',
//     },
//     navigationBoxContentBookContainer: {
//       display: 'flex',
//       flexDirection: 'column' as 'column',
//       textAlign: 'center' as 'center',
//       fontSize: '0.9em',
//       height: 'fit-content',
//     },
//     navigationBoxContentImage: {
//       display: 'flex',
//       width: image !== '' ? '90%' : '0',
//       height: image !== '' ? '70%' : '0',
//       backgroundImage: image !== '' ? `url("${image}")` : 'none',
//       backgroundSize: 'cover',
//       backgroundPosition: 'center',
//       backgroundRepeat: 'no-repeat' as 'no-repeat',
//       borderRadius: '.1em',
//     },
//   };
// };
import { theme } from '../../../assets/siteLineTheme';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';

export const recentlyViewedBoxContentStyles = () => {
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  /* istanbul ignore next */
  return {
    recentlyViewedBoxContentContainer: {
      fontSize: window.innerWidth > 420 ? '1.2em' : '0.95em',
      backgroundColor:
        window.innerWidth > 420
          ? theme.newColorPalette.recentlyBackground[userPreference.colorMode]
          : '',
      display: 'flex',
      height: '100%',
      width: window.innerWidth > 420 ? '85%' : '100%',
      justifyContent: window.innerWidth > 420 ? 'center' : 'flex-start',
      alignItems: window.innerWidth > 420 ? 'center' : 'flex-start',
      borderRadius: '.5em',
      marginTop: '0',
    },
    recentlyViewedBoxContentTextContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: window.innerWidth > 420 ? 'flex-end' : 'center',
      color:
        window.innerWidth > 420
          ? theme.newColorPalette.plainWhiteBlack[userPreference.colorMode]
          : theme.newColorPalette.textDarkBlack[userPreference.colorMode],
      width: window.innerWidth > 420 ? '50%' : '100%',
      margin: window.innerWidth > 420 ? '0' : '0em auto',
      textAlign:
        window.innerWidth > 420 ? ('left' as 'left') : ('center' as 'center'),
      wordBreak: 'break-all' as 'break-all',
      fontSize: '0.9em',
    },
    recentlyViewedBoxContentBookContainer: {
      display: 'flex',
      flexDirection:
        window.innerWidth > 420 ? ('row' as 'row') : ('column' as 'column'),
      textAlign: 'center' as 'center',
      fontSize: '0.85em',
      justifyContent: window.innerWidth > 420 ? 'space-between' : 'normal',
      height: 'fit-content',
      width: '90%',
    },
  };
};
