import { create, SetState } from 'zustand';
import { DialogModalSwitchEnum } from '../components/Modal/DialogModalSwitch';

export type innerBaseModalContentStoreState = {
  isActive: boolean;
  width?: number | undefined;
  height?: number | undefined;
  dialogModalSwitchEnum?: DialogModalSwitchEnum | undefined;
};

export const defaultInnerBaseModalContentStoreState: innerBaseModalContentStoreState =
  {
    isActive: false,
    width: undefined,
    height: undefined,
    dialogModalSwitchEnum: undefined,
  };

const innerBaseModalStore = create((set: SetState<any>) => ({
  innerBaseModalContentStoreProps: {
    ...defaultInnerBaseModalContentStoreState,
  },

  setInnerBaseModalContent: (
    innerBaseModalContentStoreProps: innerBaseModalContentStoreState
  ) => {
    set(() => ({ innerBaseModalContentStoreProps }));
  },
  resetInnerBaseModalContent: () => {
    set(() => ({
      innerBaseModalContentStoreProps: {
        ...defaultInnerBaseModalContentStoreState,
      },
    }));
  },
}));

export default innerBaseModalStore;
