import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import TaskCompletionDashboardDataWrapper from '../dataWrappers/TaskCompletionDashboardDataWrapper';

const queryClient = new QueryClient();

const CompletionDashboardScreen: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <TaskCompletionDashboardDataWrapper />
    </QueryClientProvider>
  );
};

export default CompletionDashboardScreen;
