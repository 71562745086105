import { useEffect, useReducer } from 'react';
import AppHeaderBar from 'src/components/AppHeaderBar/AppHeaderBar';
import { AppContext, StateGenerator } from './AppContext';
import appReducer, { actions } from './appReducer';
import SnackbarComponent from '../components/Snackbar/SnackbarComponent';
import MainDrawer from '../components/Drawers/MainDrawer/MainDrawer';
import BaseModal from '../components/Modal/BaseModal';
import InnerBaseModal from '../components/Modal/InnerBaseModal';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

const AppState = ({ children }: any) => {
  const stateGen = StateGenerator();
  const {
    userInfo,
    authState,
    userPermissions,
    screens,
    storeLocationInformation,
  } = stateGen;

  const colorMode = ColorModeTypeEnum.Zoidberg;

  const initialState = {
    userInfo,
    authState,
    userPermissions,
    screens,
    colorMode,
    storeLocationInformation,
  };

  const [appState, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    dispatch({
      type: actions.SET_USER_PERMISSION,
      payload: { userInfo, authState, userPermissions, screens },
    });
  }, [screens]);

  useEffect(() => {
    dispatch({
      type: actions.SET_STORE_LOCATIONS,
      payload: { storeLocationInformation },
    });
  }, [storeLocationInformation]);

  return (
    <AppContext.Provider
      value={{
        appState,
      }}
    >
      {authState?.isAuthenticated && <InnerBaseModal />}
      {authState?.isAuthenticated && <BaseModal />}
      {authState?.isAuthenticated && <AppHeaderBar />}
      {authState?.isAuthenticated && <MainDrawer />}
      {authState?.isAuthenticated && <SnackbarComponent />}
      {children}
    </AppContext.Provider>
  );
};

export default AppState;
