import { DigitalMerchandisingObj } from '../DigitalMerchandisingCreate';
import { NavigationObj } from '../../../utils/testData/mockHierarchyData';

export type HandlerMenuClickParams = {
  digitalObj: DigitalMerchandisingObj;
  paramMenu: NavigationObj;
  setDigitalState: Function;
};

export const handlerMenuClick = (params: HandlerMenuClickParams) => {
  const { digitalObj, paramMenu, setDigitalState } = params;

  if (digitalObj.isAdd || digitalObj.isModify) {
    const parentCode = paramMenu.parent_code;
    const selectedParentObj = digitalObj.data.find((menu: NavigationObj) => {
      return menu.code === parentCode;
    });

    setDigitalState({
      ...digitalObj,
      isAdd: false,
      isModify: true,
      nameValue: paramMenu.title,
      urlValue: paramMenu.url ? paramMenu.url : '',
      parentName:
        selectedParentObj && selectedParentObj.title !== undefined
          ? selectedParentObj.title
          : '',
      currentSelectedId: paramMenu.code,
      selectItem: paramMenu,
    });
  } else {
    setDigitalState({
      ...digitalObj,
      selectItem: paramMenu,
      currentSelectedId: paramMenu.code,
    });
  }
};
