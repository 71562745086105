import React, { ReactNode } from 'react';
import { IconEnum } from '../Chip/chipUtils/ChipEnums';
import userPreferenceStore, {
  UserPreferenceStoreState,
} from 'src/stores/userPreferenceStore';
import IconSelector from '../Chip/IconSelector';
import { Box } from '@mui/material';
import { genericButtonStyles } from './genericButttonUtils/genericButtonStyles';
import { ButtonEnum } from './genericButttonUtils/ButtonEnum';

export type GenericButtonProps = {
  children: ReactNode;
  buttonBackgroundColor?: string;
  buttonTextColor?: string;
  iconEnum?: IconEnum;
  variant: ButtonEnum;
  clickCallback: Function;
};

const GenericButton: React.FC<GenericButtonProps> = (props) => {
  const {
    children,
    iconEnum,
    buttonBackgroundColor,
    buttonTextColor,
    variant,
    clickCallback,
  } = props;
  const { userPreference } = userPreferenceStore(
    (state: { userPreference: UserPreferenceStoreState }) => ({
      userPreference: state.userPreference,
    })
  );
  const genericButtonClasses = genericButtonStyles(
    userPreference.colorMode,
    buttonBackgroundColor,
    buttonTextColor
  );
  return (
    <Box
      sx={{...genericButtonClasses.generalButton, ...(variant === ButtonEnum.Filled && genericButtonClasses.filledButton)}}
      onClick={() => clickCallback()}
    >
      {iconEnum && (
        <span style={genericButtonClasses.iconContainer}>
          {IconSelector(iconEnum)}
        </span>
      )}
      {children}
    </Box>
  );
};

export default GenericButton;
