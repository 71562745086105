import { theme } from '../../assets/siteLineTheme';
import { ColorModeTypeEnum } from 'src/stores/userPreferenceStore';

type reviewApprovalScreenStylesParams = {
  colorMode: ColorModeTypeEnum;
};

export const reviewApprovalAndCalendarScreenStyles = (
  params: reviewApprovalScreenStylesParams
) => {
  const { colorMode } = params;
  return {
    taskStatusButton: {
      backgroundColor: theme.newColorPalette.zoidbergWhite[colorMode],
      color: theme.newColorPalette.textDarkBlack[colorMode],
      padding: '4px 10px',
      '&:hover': {
        backgroundColor: theme.newColorPalette.zoidbergWhite[colorMode], // colorMode ? 'rgba(242, 111, 92, 0.85)' : undefined,
      },
    },
    selectedTaskStatusButton: {
      backgroundColor: theme.newColorPalette.plainWhiteBlack[colorMode],
      color: theme.newColorPalette.textDarkWhite[colorMode],
      padding: '4px 10px',
    },
    halfBox: {
      width: window.innerWidth > 420 ? '50%' : '100%',
    },
    halfBoxRight: {
      width: window.innerWidth > 420 ? '50%' : '100%',
      display: 'flex',
      justifyContent: window.innerWidth > 420 ? 'end' : 'center',
    },
    fullBox: {
      width: '100%',
    },
    toolbarContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column' as 'column',
      paddingBottom: '.75em',
    },
    toolbarSubContainer: {
      display: 'flex',
      flexDirection: window.innerWidth > 420 ? 'row' : 'column',
    },
    paperAnchorRight: {
      width: window.innerWidth > 420 ? '32%' : '100%',
      minWidth: '320px',
    },

    titleText: {
      color: theme.newColorPalette.plainWhiteBlack[colorMode],
    },
    addTaskIconButton: {
      cursor: 'pointer',
      fontSize: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: '0.35em',
      position: 'fixed' as 'fixed',
      bottom: '30px',
      right: '30px',
      zIndex: 1000,
      backgroundColor:
        theme.newColorPalette.backgroundAddTaskIconDarkBlack[colorMode],
      color: theme.colorPalette.light,
      width: '128px',
      height: '47px',
      borderRadius: '24px',
      boxShadow:
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    },
    printButtonRow: {
      display: 'flex',
      flexDirection: 'row-reverse' as 'row-reverse',
      width: '100%',
    },
  };
};
