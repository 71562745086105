import React from 'react';
import { tableCellStyles } from './tableCellStyles';

export type TableCellProps = {
  styleProps: {
    cellWidth?: string | undefined;
    justifyContent?: string | undefined;
    isMobile?: boolean | undefined;
  };
  children: React.ReactNode;
};

const TableCell: React.FC<TableCellProps> = (props) => {
  const { children, styleProps } = props;
  const { cellWidth, justifyContent, isMobile } = styleProps;

  return (
    <div
      style={
        tableCellStyles({ cellWidth, justifyContent, isMobile }).tableHeaderCell
      }
    >
      {children}
    </div>
  );
};

export default TableCell;
