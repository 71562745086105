import { Box, Typography } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/images/Full-Logo.svg';
import {
  forwardRef,
  PropsWithChildren,
  ReactElement,
  useContext,
  useRef,
} from 'react';
import { AppContext } from '../../context/AppContext';
import useFetchUserGroup from '../../hooks/fetchUserGroup';
import { groups as userGroups } from '../../context/constants';
import { TextEditor } from '../TextEditor/TextEditor';
import StoreNumberAccordion from '../Forms/StoreNumberAccordion';
import appliedFiltersStore from '../../stores/appliedFiltersStore';
import drawerPropsStore from '../../stores/drawerPropsStore';
import { monthDayYear } from '../../utils/taskDataFormatter';

const useStyles = () =>  {
    return {
      page: {
        height: '280mm',
        width: '210mm',
        backgroundColor: 'white',
        display: 'flex',
      },
      pageWithinBounds: {
        margin: '0.25in 0.25in 0.5in 0.25in',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      },
      header: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      contentBox: {
        display: 'flex',
      },
      taskTitleText: {
        fontSize: '36px',
        fontWeight: 700,
        lineHeight: '48px',
        marginBottom: '10px',
      },
      contentGroupBox: {
        border: `1px solid #7E838E`,
        padding: '1em',
        marginBottom: '10px',
        borderRadius: '8px',
      },
      leftPrintColumn: {
        marginRight: '10px',
        minWidth: '33%',
      },
      pairLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '.75em',
      },
      pairBodyText: {
        marginLeft: '10px',
      },
      fixedSizeBox: {
        height: '50px',
      },
      taskTypeChip: {
        border: '1px solid #7E838E',
        borderRadius: '25px',
        padding: '0px 12px',
        margin: '5px auto 5px 0',
        width: 'fit-content',
      },
      rightContentColumn: {
        minWidth: '50%',
        height: 'fit-content',
      },
    }
  }

const ViewTaskPrint = forwardRef<HTMLDivElement, PropsWithChildren<any>>(
  (props, ref) => {
    const { appState } = useContext<any>(AppContext);
    const { userInfo } = appState;

    const { appliedFilters } = appliedFiltersStore(
      (state: { appliedFilters: any }) => ({
        appliedFilters: state.appliedFilters,
      })
    );

    const { drawerProps } = drawerPropsStore((state: { drawerProps: any }) => ({
      drawerProps: state.drawerProps,
    }));

    const userOktaGroup = useFetchUserGroup(userInfo);

    const { viewEvent } = drawerProps.drawerContentProps;
    const { id } = viewEvent;
    const { distro_file_information } = viewEvent;

    const classes = useStyles();

    const editorContents = useRef<{}>();

    const startDateString = monthDayYear(new Date(viewEvent.start_date));
    const endDateString = monthDayYear(new Date(viewEvent.end_date));

    const PairContainer = (props: {
      label: string;
      body?: string;
      children?: ReactElement;
    }) => {
      return (
        <>
          <Typography sx={classes.pairLabel}>{props.label}</Typography>
          {props.body && (
            <Typography sx={classes.pairBodyText}>
              {props.body}
            </Typography>
          )}
          {props.children && props.children}
        </>
      );
    };

    const chipLabel =
      viewEvent.task_type === 'Merchandising' && viewEvent.department
        ? `${viewEvent.task_type} - ${viewEvent.department}`
        : viewEvent.task_type;

    return (
      <div style={classes.page} ref={ref}>
        <Box sx={classes.pageWithinBounds}>
          <Box sx={classes.header}>
            <Logo />
            <div>
              {/* <Typography>{format(new Date, "MMMM dd, yyyy hh:mm a")}</Typography> */}
              <Typography>Printed By: {userInfo.name}</Typography>
            </div>
          </Box>
          <Typography sx={classes.taskTitleText}>
            {viewEvent.title}
          </Typography>
          <Box sx={classes.contentBox}>
            <Box sx={classes.leftPrintColumn}>
              <Box sx={classes.contentGroupBox}>
                <Typography
                  sx={{...classes.pairLabel, ...classes.fixedSizeBox}}
                >
                  Assigned To:
                </Typography>
              </Box>
              <Box sx={classes.contentGroupBox}>
                <PairContainer label={'Task Type'}>
                  <Box sx={classes.taskTypeChip}>{chipLabel}</Box>
                </PairContainer>
                <PairContainer
                  label={'Date(s) for Task'}
                  body={`${startDateString} - ${endDateString}`}
                />
                {viewEvent.funded &&
                  (userOktaGroup === userGroups.MICHAEL ||
                    userOktaGroup === userGroups.STANLEY ||
                    appliedFilters.store_number) && (
                    <PairContainer
                      label={'Task Hours Allocated'}
                      body={`${viewEvent.labor_hours} ${
                        viewEvent.labor_hours === 1 ? 'Hour' : 'Hours'
                      }`}
                    />
                  )}

                {(viewEvent.store_number ||
                  appliedFilters.district_number ||
                  appliedFilters.territory_number ||
                  distro_file_information?.length) && (
                  <>
                    <Typography sx={classes.pairLabel}>
                      Store(s)
                    </Typography>
                    <Typography sx={classes.pairLabel}>
                      {viewEvent.store_number}
                    </Typography>
                  </>
                )}
                {/* Distro Display */}
                {(distro_file_information?.length ||
                  viewEvent.store_number === 'All') &&
                  (appliedFilters.district_number ||
                    appliedFilters.territory_number) && (
                    <Box sx={{ marginTop: '0px' }}>
                      <StoreNumberAccordion
                        taskId={id}
                        isPrintView={true}
                        completionStatuses={viewEvent.completion_statuses}
                      />
                    </Box>
                  )}
              </Box>
              <PairContainer label={'NOTES'} />
            </Box>
            <Box
              sx={{...classes.contentGroupBox, ...classes.rightContentColumn}}
            >
              <PairContainer label={'Task Description'}>
                <TextEditor
                  formMode={'view'}
                  delta={viewEvent.task_description}
                  editorContents={editorContents}
                  editorId={'printViewQuill'}
                />
              </PairContainer>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
);

ViewTaskPrint.displayName = 'ViewTaskPrint';

export default ViewTaskPrint;
